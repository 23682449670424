/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Badge from "@material-ui/core/Badge";

// @material-ui/icons
import PaymentIcon from "@material-ui/icons/Payment";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import FaceIcon from "@material-ui/icons/Face";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import InputIcon from "@material-ui/icons/Input";
import DescriptionIcon from "@material-ui/icons/Description";
import RemoveIcon from "@material-ui/icons/Remove";
import PollIcon from "@material-ui/icons/Poll";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";
import CustomerBill from "./CustomerBill";
import CustomerShipping from "./CustomerShipping";
import CustomerInvoice from "./CustomerInvoice";
import CustomerPay from "./CustomerPay";
import CustomerAnalysis from "./CustomerAnalysis";
import CustomerDay from "./CustomerDay";
import CustomerReceipt from "./CustomerReceipt";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  name: "",
  balance: "0.00",
  count_order: "0",
  count_shipping: "0",
  gen_receipt: "N",
  count_invoice: "0",
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.name",
  order_sort: "asc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
  member: "ALL",
  member_name: "",
  status: "ALL",
};

/* ========== ZONE Function ========== */
function Customer({ t }) {
  const mod = "customer";
  const prefix = "member";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.code", name: t(`attr.code`) },
    { code: "tb.name", name: t(`${prefix}.name`), order: "asc" },
    { code: "tb.gen_receipt", name: "เข้าระบบ" },
    { code: "order_balance", name: "ค้างส่ง", sort: false },
    { code: "tb.balance", name: "Wallet", sort: false },
    { code: "tb.credit", name: "วงเงิน" },
    { code: "so.date_due", name: "ค้างจ่าย", sort: false },
    { code: "tb.code", name: "คงเหลือ", sort: false },
    { code: "tb.credit_day", name: "Credit" },
    { code: "date_due", name: "วันชำระ" },
    { code: "over_due", name: "สถานะ" },
  ];
  const alignValue = [0, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const txtMember = useRef(null);
  const txtSearch = useRef(null);
  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [modalCustomer, setModalCustomer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [cboStatus, setCboStatus] = useState([]);
  const [dateRecord, setDateRecord] = useState(Func.dateFormat(new Date()));

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        if (id === undefined) {
          setCboStatus(result.stat);
          view(find);
        } else {
          setTab(0);
          open();
        }
      } else {
        Func.exitApp();
      }
    });
  };

  const search = (e) => {
    e.preventDefault();

    view({ ...find, search: txtSearch.current.value, page: 1 });
    setFind({ ...find, search: txtSearch.current.value, page: 1 });
  };

  const changeTab = (event, value) => {
    if (value == 2) setInvoiceId("");
    setTab(value);
  };

  const changeDate = (date) => {
    setDateRecord(date);
    setTab(1);
  };

  const changeReceipt = (date) => {
    setDateRecord(date);
    setTab(5);
  };

  const openInvoice = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      setLoading(false);
      if (result.status === "OK") {
        setAttr(result.data);

        setLoading(false);
      }
    });
  };

  const reloadCustomer = () => {
    open();
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      member: data.member,
      status: data.status,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.code,
              btnOpen(item),
              displayGenReceipt(item),
              displayShipping(item),
              displayBalance(item.balance),
              displayNumber(item.credit),
              displayBalance(item.order_balance),
              displayBalance(item.credit_balance),
              displayCredit(item.credit_day),
              item.date_due,
              displayStatus(item.over_due),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const btnOpen = (item) => {
    return (
      <NavLink to={`/admin/${mod}/${item.code}`}>
        <Button color="primary" simple>
          {item.name}
        </Button>
      </NavLink>
    );
  };

  const displayShipping = (item) => {
    if (item.shipping == 0) return "";
    else
      return (
        <code className="color-danger">
          {t(`bill.waitshipping`)} {item.shipping}
        </code>
      );
  };

  const displayGenReceipt = (item) => {
    if (item.gen_receipt === 'N') return "";

    return "รับบิล";
  };

  const displayCredit = (inp) => {
    if (inp > 0) return inp + " วัน";
    else return "-";
  };

  const displayStatus = (inp) => {
    if (inp === "9999") return "";
    else if (inp < 0)
      return <code className="color-danger">ค้างชำระ {inp * -1} วัน</code>;
    else if (inp > 0)
      return <code className="color-info">เหลือ {inp} วัน</code>;
    else {
      return <code className="color-primary">ครบกำหนด</code>;
    }
  };

  const img = (filename) => {
    return <img src={filename} width="30" />;
  };

  const open = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      setLoading(false);
      if (result.status === "OK") {
        setAttr(result.data);

        setLoading(false);
      }
    });
  };

  const searchOpen = () => {
    setModalCustomer(true);

    setTimeout(function () {
      txtMember.current.select();
    }, 500);
  };

  const searchCustomer = (e) => {
    e.preventDefault();

    setMemberList([]);

    if (txtMember.current.value == "") return;

    PostData(mod, "membersearch", {
      token: app.token,
      lang: app.lang,
      search: txtMember.current.value,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setMemberList((memberList) => [
            ...memberList,
            [textName(item), displayBalance(item.balance)],
          ]);
        });
      }
    });
  };

  const displayBalance = (balance) => {
    if (balance < 0)
      return (
        <code className="color-danger">{Func.numberDisplay(balance)}</code>
      );
    else if (balance > 0)
      return (
        <code className="color-success">{Func.numberDisplay(balance)}</code>
      );
    else return "";
  };

  const displayNumber = (number) => {
    if (number < 0) return Func.numberDisplay(number);
    else if (number > 0) return Func.numberDisplay(number);
    else return "-";
  };

  const textName = (item) => {
    return (
      <Button
        simple
        color="github"
        onClick={() => window.location.replace(`/admin/customer/${item.code}`)}
      >
        <i className="fas fa-user-edit" /> {item.name}
      </Button>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <NavLink to={`/admin/${mod}/${item.code}`}>
        <Button color="success" className={classes.actionButton} size="sm">
          {t(`element.open`)} <InputIcon />
        </Button>
      </NavLink>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={6}>
                  <form noValidate onSubmit={search}>
                    <TextField
                      fullWidth
                      InputProps={{
                        inputRef: txtSearch,
                        startAdornment: (
                          <InputAdornment position="start">
                            ลูกค้า :
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </form>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        setFind({ ...find, status: e.target.value, page: 1 });
                        view({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        ตรวจสอบสถานะ
                      </MenuItem>
                      {cboStatus.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                      renderHeader(header[8]),
                      renderHeader(header[9]),
                      renderHeader(header[10]),
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[]}
                    colorsColls={[]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>{renderDetail()}</GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t(`${prefix}.info_summary`),
                tabIcon: MonetizationOnIcon,
                tabContent: (
                  <CustomerDay
                    changeDate={changeDate}
                    changeReceipt={changeReceipt}
                    openInvoice={openInvoice}
                  />
                ),
              },
              {
                tabName: (
                  <Badge badgeContent={attr.count_order * 1} color="secondary">
                    {t(`${prefix}.info_bill`)}
                  </Badge>
                ),
                tabIcon: ListAltIcon,
                tabContent: (
                  <CustomerBill
                    dateRecord={dateRecord}
                    openInvoice={openInvoice}
                  />
                ),
              },
              {
                tabName: (
                  <Badge
                    badgeContent={attr.count_invoice * 1}
                    color="secondary"
                  >
                    {t(`${prefix}.invoice`)}
                  </Badge>
                ),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <CustomerInvoice
                    invoiceId={invoiceId}
                    reloadCustomer={reloadCustomer}
                  />
                ),
              },
              {
                tabName: (
                  <Badge
                    badgeContent={attr.count_shipping * 1}
                    color="secondary"
                  >
                    {t(`${prefix}.shippings`)}
                  </Badge>
                ),
                tabIcon: LocalShippingIcon,
                tabContent: (
                  <CustomerShipping reloadCustomer={reloadCustomer} />
                ),
              },
              {
                tabName: t(`${prefix}.pay`),
                tabIcon: PaymentIcon,
                tabContent: <CustomerPay />,
              },
              // {
              //   tabName: "ใบเสร็จ",
              //   tabIcon: PaymentIcon,
              //   tabContent: <CustomerReceipt dateRecord={dateRecord} />,
              // },
              {
                tabName: t(`${prefix}.analysis`),
                tabIcon: PollIcon,
                tabContent: <CustomerAnalysis />,
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Button color="info" size="lg" block onClick={() => searchOpen()}>
            <FaceIcon /> {attr.name} {attr.gen_receipt === "Y" ? <sup>***</sup> : null}
          </Button>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Button color="success" size="lg" block onClick={() => searchOpen()}>
            <AccountBalanceWalletIcon /> {Func.numberDisplay(attr.balance)} ฿
          </Button>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Button color="danger" size="lg" block onClick={() => searchOpen()}>
            <RemoveIcon /> {Func.numberDisplay(attr.order_balance)} ฿
          </Button>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <NavLink to={`/admin/${mod}`} className={classes.colorWarning}>
            <Button color="warning" size="lg" block>
              <ArrowBackIcon /> {t("element.back")}
            </Button>
          </NavLink>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalCustomer = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalCustomer}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalCustomer(false)}
        aria-labelledby="modal-customer-title"
        aria-describedby="modal-customer-description"
      >
        <DialogTitle
          id="modal-customer-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalCustomer(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {t(`${prefix}.name`)} : {attr.name}
          </h4>
        </DialogTitle>
        <DialogContent id="modal-customer" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchCustomer}>
                <CustomInput
                  labelText={t("element.search")}
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtMember,
                    onChange: (e) => { },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableData={memberList}
                customHeadClassesForCells={[1]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[1]}
                customCellClasses={[classes.right]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else {
      return renderEdit();
    }
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderModalCustomer()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(Customer);
