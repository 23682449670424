/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "../../services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initFind = {
  search: "",
  order_by: "pd.id",
  order_sort: "desc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
  branch: "ALL",
};

/* ========== ZONE Function ========== */
function StockRawmat({ t }) {
  const mod = "stock";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const perm = useSelector((state) => state.perm);

  const initHeader = [
    { code: "pd.id", name: t(`${mod}.id`), order: "asc" },
    { code: "pd.name_th", name: t(`${mod}.name`) },
    { code: "br.name_th", name: t(`${mod}.branch`) },
    { code: "tb.quantity", name: t(`${mod}.quantity`) },
    { code: "ut.name_th", name: t(`${mod}.unit`), sort: false },
  ];
  const alignValue = [3];
  const alignItem = [classes.right];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [cboBranch, setCboBranch] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboBranch(result.branch);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      kind: "RM",
      branch: data.branch,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.product_id,
              item.product_name,
              item.branch_name,
              Func.numberDisplay(item.quantity),
              item.unit_name,
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="primary">
                  <AssignmentIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("element.viewlist")}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer className="viewlist">
                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="search"
                      fullWidth
                      InputProps={{
                        onChange: (e) => {
                          view({ ...find, search: e.target.value, page: 1 });
                          setFind({ ...find, search: e.target.value, page: 1 });
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            {t("element.search")} :
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </GridItem>
                  {perm.perm.branchall === "Y" ? (
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                      <FormControl fullWidth>
                        <Select
                          value={find.branch}
                          onChange={(e) => {
                            view({ ...find, branch: e.target.value, page: 1 });
                            setFind({
                              ...find,
                              branch: e.target.value,
                              page: 1,
                            });
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="ALL"
                          >
                            {t(`${mod}.branch`)}
                          </MenuItem>
                          {cboBranch &&
                            cboBranch.map((item) => (
                              <MenuItem
                                key={item.code}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={item.code}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  ) : null}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <Table
                      striped
                      hover
                      tableHead={[
                        renderHeader(header[0]),
                        renderHeader(header[1]),
                        renderHeader(header[2]),
                        renderHeader(header[3]),
                        renderHeader(header[4]),
                      ]}
                      tableData={record}
                      customHeadClassesForCells={alignValue}
                      customHeadCellClasses={alignItem}
                      customClassesForCells={alignValue}
                      customCellClasses={alignItem}
                      coloredColls={[3]}
                      colorsColls={["primary"]}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel className={classes.selectLabel}></InputLabel>
                      <Select
                        value={find.perPage}
                        onChange={(e) => {
                          view({
                            ...find,
                            page: 1,
                            perPage: e.target.value,
                            page: 1,
                          });
                          setFind({
                            ...find,
                            page: 1,
                            perPage: e.target.value,
                            page: 1,
                          });
                        }}
                      >
                        {cboPerPage &&
                          cboPerPage.map((item) => (
                            <MenuItem
                              key={item.id}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.id}
                            >
                              {t(item.name)}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={8}
                    lg={9}
                    className={classes.right}
                  >
                    <Pagination
                      style={{ float: "right", marginTop: "20px" }}
                      count={pageTotal}
                      showFirstButton
                      showLastButton
                      page={pageNo}
                      onChange={(e, p) => {
                        view({ ...find, page: p });
                        setFind({ ...find, page: p });
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(StockRawmat);
