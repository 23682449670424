/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

/* ========== ZONE Function ========== */
function PosSlip({ t, attr, list }) {
  const mod = "posbill";
  const prefix = "bill";
  const classes = useStyles();

  const quantityDisplay = (num) => {
    const fl = Math.floor(num);
    if (fl == num) return Func.numberDisplay(num, 0);
    else return Func.numberDisplay(num);
  };

  const displayShipping = () => {
    if (attr.transfer == 2) {
      return `จัดส่งสาขา ${attr.branch_name}`;
    } else if (attr.transfer == 3) {
      return attr.address;
    } else {
      return "";
    }
  };

  const renderHeader = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td align="center">
              <h3>ใบเสร็จ (ย่อ)</h3>
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">เลขที่ใบเสร็จ: {attr.id}</td>
          </tr>
          <tr>
            <td align="left">
              วันที่บิล: {Func.dateTimeDisplay(attr.date_create, 3)}
            </td>
          </tr>
          {attr.shipping == "Y" ? (
            <tr>
              <td align="left">
                วันกำหนดส่ง: {Func.dateTimeDisplay(attr.date_shipping, 3)}
              </td>
            </tr>
          ) : null}
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          {attr.member_code > 0 ? (
            <tr>
              <td align="left">ลูกค้า: {attr.member_name}</td>
            </tr>
          ) : null}
          {attr.member_code > 0 ? (
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
          ) : null}
          {attr.member_code > 0 ? (
            <tr>
              <td align="left">ยอดค้างชำระ: {attr.balance}</td>
            </tr>
          ) : null}
          {attr.remark === "" ? null : (
            <tr>
              <td align="left">หมายเหตุ: {attr.remark}</td>
            </tr>
          )}
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderOrder = () => {
    return (
      <table width="100%">
        <thead>
          <tr>
            <th align="left" colSpan={3}>
              {t(`${prefix}.order`)}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
          </tr>
          {list.map((item, i) => (
            <tr key={item.code}>
              <td align="left" valign="top">
                {i + 1}.
              </td>
              <td align="left" valign="top">
                {item.product_name} ({quantityDisplay(item.quantity)}{" "}
                {item.unit_name} x {Func.numberDisplay(item.price)} ฿)
                {item.quantity_return > 0 ?
                  <span> / คืน {quantityDisplay(item.quantity_return)} {item.unit_name}</span> : null}
              </td>
              <td align="right" valign="top">
                {item.amount > 0 ? Func.numberDisplay(item.amount) : "ยกเลิก"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderSummary = () => {
    return (
      <table width="100%">
        <thead>
          <tr>
            <th align="right" width="70%">
              {t(`${prefix}.amount`)}
            </th>
            <th align="right">{Func.numberDisplay(attr.amount)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.vat`)}</th>
            <th align="right">{Func.numberDisplay(attr.vat)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.total`)}</th>
            <th align="right">{Func.numberDisplay(attr.total)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.shipping_price`)}</th>
            <th align="right">{Func.numberDisplay(attr.shipping_price)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.nettotal`)}</th>
            <th align="right">{Func.numberDisplay(attr.nettotal)}</th>
          </tr>
        </thead>
      </table>
    );
  };

  const renderFooter = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="2">
              <p>
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td align="right">ผู้รับ:</td>
            <td align="center">....................................</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderShipping = () => {
    return (
      <>
        <div style={{ borderBottom: "1px solid black" }}>
          <br />
          <br />
        </div>
        <br />
        <table width="100%">
          <tbody>
            <tr>
              <td align="center">
                <h3>บิลส่งของ</h3>
              </td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td align="left">เลขที่ใบเสร็จ: {attr.id}</td>
            </tr>
            <tr>
              <td align="left">
                วันที่: {Func.dateTimeDisplay(attr.date_create, 3)}
              </td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td align="left">ลูกค้า: {attr.member_name}</td>
            </tr>
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
            <tr>
              <td align="left">สถานที่จัดส่ง: {displayShipping()}</td>
            </tr>
            <tr>
              <td align="left">
                วันกำหนดส่ง: {Func.dateTimeDisplay(attr.date_shipping, 3)}
              </td>
            </tr>
            {attr.remark === "" ? null : (
              <tr>
                <td align="left">หมายเหตุ: {attr.remark}</td>
              </tr>
            )}
            <tr>
              <td>
                <p></p>
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%">
          <thead>
            <tr>
              <th colSpan={3} align="left">
                รายการจัดส่ง
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
            </tr>
            {list &&
              list.map((item, i) => (
                <tr key={item.code}>
                  <td align="left" valign="top">
                    {i + 1}.
                  </td>
                  <td align="left" valign="top">
                    {item.product_name}
                  </td>
                  <td align="right" valign="top">
                    {item.quantity > 0
                      ? quantityDisplay(item.quantity) + " " + item.unit_name
                      : "ยกเลิก"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <table width="100%">
          <tbody>
            <tr>
              <td colSpan="2">
                <p>
                  <br />
                </p>
              </td>
            </tr>
            <tr>
              <td align="right">ผู้รับ:</td>
              <td align="center">....................................</td>
            </tr>
          </tbody>
        </table>
      </>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="print">
      <div className="slips">
        {renderHeader()}
        {renderOrder()}
        {renderSummary()}
        {renderFooter()}
        {attr.shipping == "Y" ? renderShipping() : null}
      </div>
    </div>
  );
}

export default withNamespaces()(PosSlip);

PosSlip.defaultProps = {
  attr: {},
  list: [],
};
