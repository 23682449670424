/*eslint-disable*/
import React from "react";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

/* ========== ZONE Variable ========== */

/* ========== ZONE Function ========== */
function DataPrint({ t, attr, list }) {
  const renderHeader = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td align="center">
              <h3>ตาราง {attr.id}</h3>
              <h4>{attr.name}</h4>
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderOrder = () => {
    return (
      <table
        width="100%"
        border="1"
        cellPadding="7"
        cellSpacing="0"
        className="table"
      >
        <thead>
          <tr>
            <th align="center" width="5%">
              No.
            </th>
            <th align="left" width="20%">
              ID
            </th>
            <th align="left">Description</th>
            <th align="center" width="20%">
              Type
            </th>
            <th align="center" width="10%">
              Key
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, i) => (
            <tr key={item.code}>
              <td align="center" valign="top">
                {i + 1}.
              </td>
              <td align="left" valign="top">
                {item.id}
              </td>
              <td align="left" valign="top">
                {item.name === "" ? item.id : item.name}
              </td>
              <td align="center" valign="top">
                {item.attr_type}
              </td>
              <td align="center" valign="top">
                {item.pkey}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="a4">
      {renderHeader()}
      {renderOrder()}
    </div>
  );
}

export default withNamespaces()(DataPrint);
