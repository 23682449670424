/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import PrintIcon from "@material-ui/icons/Print";
import CancelIcon from "@material-ui/icons/Cancel";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import InputIcon from "@material-ui/icons/Input";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import SlipShipping from "views/Print/SlipShipping";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name_th: "",
  name_en: "",
  status: "99",
  total: "0.00",
  remark: "",
  date_record: new Date(),
  so_code: "",
  branch_code: "",
  branch_name: "",
  member_name: "",
};

const initFind = {
  search: "",
  date_start: Func.dateAdd(-1),
  date_stop: Func.dateAdd(7),
  order_by: "tb.date_shipping",
  order_sort: "asc",
  status: "1",
  branch: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function Shipping({ t }) {
  const mod = "shipping";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.id", name: t(`${prefix}.id`) },
    { code: "tb.member_code", name: t(`${prefix}.member`) },
    { code: "tb.branch_code", name: t(`${prefix}.branch`) },
    { code: "tb.branch_warehouse", name: t(`${prefix}.warehouse`) },
    { code: "tb.address", name: t(`${prefix}.address`) },
    { code: "tb.remark", name: t(`${prefix}.remark`) },
    { code: "tb.shipping_price", name: t(`${prefix}.shipping_price`) },
    { code: "tb.date_create", name: "วันที่บิล" },
    { code: "tb.date_shipping", name: "กำหนดส่ง", order: "asc" },
    { code: "tb.approve_date_2", name: "วันจัดส่ง" },
    { code: "tb.status_shipping", name: t(`${prefix}.status`) },
  ];
  const alignValue = [0, 1, 7, 8, 9, 10, 11];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const users = useSelector((state) => state.user);
  const slipRef = useRef();
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [cboStatus, setCboStatus] = useState([]);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [slip, setSlip] = useState(initAttr);
  const [slipList, setSlipList] = useState([]);
  const [cboBranch, setCboBranch] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboBranch(result.branch);

        if (id === undefined) {
          view({ ...initFind, branch: users.branch_code, page: 1 });
          setFind({ ...initFind, branch: users.branch_code, page: 1 });
        } else {
          setTab(0);
          open();
        }
      } else {
        Func.exitApp();
      }
    });
  };

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      branch: data.branch,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              btn(item),
              btnOpen(item),
              item.member_name,
              item.branch_name,
              item.branch_warehouse,
              displayShipping(item),
              item.remark,
              Func.numberDisplay(item.shipping_price),
              Func.dateTimeDisplay(item.date_create),
              Func.dateTimeDisplay(item.date_shipping),
              Func.dateTimeDisplay(item.approve_date_2),
              displayStatus(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const btnOpen = (item) => {
    return (
      <NavLink to={`/admin/${mod}/${item.code}`}>
        <Button color="primary" simple>
          {item.id}
        </Button>
      </NavLink>
    );
  };

  const print = (item) => {
    setLoading(true);
    setSlip({});

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);

        handlePrint();
      }
    });
  };

  const printOpen = () => {
    print(attr);
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const displayShipping = (item) => {
    if (item.transfer == 2) {
      return <div>จัดส่งสาขา {item.branch_transfer}</div>;
    } else if (item.transfer == 3) {
      return (
        <div style={{ whiteSpace: "normal", width: "300px" }}>
          {item.address}
        </div>
      );
    } else {
      return "-";
    }
  };

  const displayShippingOpen = (item) => {
    if (item.transfer == 2) {
      return `จัดส่งสาขา ${item.branch_transfer}`;
    } else if (item.transfer == 3) {
      return item.address;
    } else {
      return "-";
    }
  };

  const open = () => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              item.price_display,
              item.quantity_display,
              item.amount_display,
            ],
          ]);
        });

        setList((list) => [
          ...list,
          [
            "",
            "",
            "",
            "",
            "",
            "",
            <h4>{t(`${prefix}.amount`)}</h4>,
            <h4>{Func.numberDisplay(result.data.amount)}</h4>,
          ],
          [
            "",
            "",
            "",
            "",
            "",
            "",
            <h4>{t(`${prefix}.vat`)}</h4>,
            <h4>{Func.numberDisplay(result.data.vat)}</h4>,
          ],
          [
            "",
            "",
            "",
            "",
            "",
            "",
            <h4>{t(`${prefix}.total`)}</h4>,
            <h4>{Func.numberDisplay(result.data.total)}</h4>,
          ],
        ]);

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    return result;
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              code: attr.code,
              data: {
                remark: attr.remark,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open();
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print(item)}
          size="sm"
        >
          {t(`element.print`)} <PrintIcon />
        </Button>
        {item.status_shipping == "1" ? (
          <Button
            color="success"
            className={classes.actionButton}
            onClick={() => editTransfer(item.code)}
            size="sm"
          >
            {t(`${prefix}.shipping`)} <LocalShippingIcon />
          </Button>
        ) : null}
      </>
    );
  };

  const editTransfer = (code) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edittransfer", {
            code: code,
          }).then((result) => {
            setLoading(false);
            if (result.status === "OK") {
              view(find);

              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{id}
      </SweetAlert>
    );
  };

  const editCancel = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editcancel", {
            code: id,
          }).then((result) => {
            setLoading(false);
            if (result.status === "OK") {
              window.location.href = "/admin/shipping";

              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{id}
      </SweetAlert>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    id="search"
                    fullWidth
                    InputProps={{
                      onChange: (e) => {
                        view({ ...find, search: e.target.value, page: 1 });
                        setFind({ ...find, search: e.target.value, page: 1 });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {t("element.search")} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
                <GridItem xs={12}></GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => {
                        view({ ...find, date_start: value, page: 1 });
                        setFind({ ...find, date_start: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => {
                        view({ ...find, date_stop: value, page: 1 });
                        setFind({ ...find, date_stop: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.branch}
                      onChange={(e) => {
                        view({ ...find, branch: e.target.value, page: 1 });
                        setFind({ ...find, branch: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.branch`)}
                      </MenuItem>
                      {cboBranch.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      "",
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                      renderHeader(header[8]),
                      renderHeader(header[9]),
                      renderHeader(header[10]),
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[7, 8, 9]}
                    colorsColls={["primary", "danger", "success"]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>{attr.id}</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/${mod}`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>{renderStatus()}</GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        {attr.status_shipping <= 1 ? (
                          <Button color="success" onClick={edit}>
                            <SaveIcon /> {t("element.save")}
                          </Button>
                        ) : null}
                        {attr.status_shipping <= 4 ? (
                          <Button color="info" onClick={() => printOpen()}>
                            <PrintIcon /> {t("element.print")}
                          </Button>
                        ) : null}
                        {attr.status_shipping <= 2 ? (
                          <Button color="danger" onClick={() => editCancel()}>
                            <CancelIcon /> {t("element.cancel")}
                          </Button>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: t("element.order"),
                tabIcon: ListAltIcon,
                tabContent: renderOrder(),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderOrder = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${prefix}.num`),
              t(`${prefix}.image`),
              t(`${prefix}.product_id`),
              t(`${prefix}.product_name`),
              t(`${prefix}.unit`),
              t(`${prefix}.price`),
              t(`${prefix}.quantity`),
              t(`${prefix}.amount`),
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[5, 6, 7]}
            colorsColls={["primary", "primary", "success"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              success
              labelText={t(`${prefix}.member`)}
              id="member_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.member_name,
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              success
              labelText={t(`${prefix}.date_record`)}
              id="date_record"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: Func.dateDisplay(attr.date_record),
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              success
              labelText={t(`${prefix}.shipping_price`)}
              id="shipping_price"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: Func.numberDisplay(attr.shipping_price),
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              success
              labelText={t(`${prefix}.branch`)}
              id="branch_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.branch_name,
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              success
              labelText={t(`${prefix}.warehouse`)}
              id="branch_warehouse"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.branch_name,
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <CustomInput
              success
              labelText={t(`${prefix}.address`)}
              id="address"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: displayShippingOpen(attr),
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText={t(`${prefix}.remark`)}
              id="remark"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.remark,
              }}
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status_shipping * 1}
        alternativeLabel
        className={classes.bgTransparent}
      >
        <Step>
          <StepLabel>
            <p>
              <b>รอจัดส่ง</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        {attr.status_shipping == "1" ? (
          <Step>
            <StepLabel>
              <p>
                <b>จัดส่ง</b>
              </p>
              <p className={classes.colorDefault}>
                <Button
                  color="success"
                  className={classes.actionButton}
                  size="sm"
                  onClick={() => editTransfer(id)}
                >
                  {t(`${prefix}.shipping`)} <LocalShippingIcon />
                </Button>
              </p>
            </StepLabel>
          </Step>
        ) : (
          <Step>
            <StepLabel>
              <p>
                <b>จัดส่งแล้ว</b>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_2}</p>
              <p className={classes.colorDefault}>{attr.approve_date_2}</p>
            </StepLabel>
          </Step>
        )}
      </Stepper>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <SlipShipping ref={slipRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else {
      return renderEdit();
    }
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderPrint()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(Shipping);
