/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons

// core components
import Table from "components/Table/Table.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

/* ========== ZONE Function ========== */
function PosSummary({ t }) {
  const mod = "possummary";
  const prefix = "bill";
  const classes = useStyles();

  const alignValue = [0, 2];
  const alignItem = [classes.center, classes.right];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [record, setRecord] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    view();
  }, [id]);

  const view = () => {
    setRecord([]);
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        result.salequantity.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.product_id,
              item.product_name,
              Func.numberDisplay(item.quantity),
              item.unit_name,
            ],
          ]);
        });

        setRecord((record) => [
          ...record,
          {
            total: true,
            title: t(`ยอดขายรอบปัจจุบัน`),
            colspan: "2",
            amount: Func.numberDisplay(result.summary.round),
          },
          {
            total: true,
            title: t(`จำนวนเงินในลิ้นชัก`),
            colspan: "2",
            amount: Func.numberDisplay(result.summary.balance),
          },
        ]);
      }
      setLoading(false);
    });
  };

  const renderViewList = () => {
    return (
      <Table
        striped
        hover
        tableHead={[
          t(`${prefix}.product_id`),
          t(`${prefix}.product_name`),
          t(`${prefix}.quantity`),
          "",
        ]}
        tableData={record}
        customHeadClassesForCells={alignValue}
        customHeadCellClasses={alignItem}
        customClassesForCells={alignValue}
        customCellClasses={alignItem}
        coloredColls={[2]}
        colorsColls={["primary"]}
      />
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(PosSummary);
