/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Badge from "components/Badge/Badge.js";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import InputIcon from "@material-ui/icons/Input";

// core components
import MuiAlert from "@material-ui/lab/Alert";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import { API_URL } from "services/Define";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name_th: "",
  name_en: "",
  status_invoice: "Y",
  status: "99",
  total: "0.00",
  remark: "",
  date_po: new Date(),
  supplier_code: "",
  branch_code: "",
  branch_name: "",
};

const initField = {
  code: "",
  product_code: "",
  product_id: "",
  product_name: "",
  price: "0.00",
  quantity: "0",
  amount: "0.00",
  total: "0.00",
  unit_name: "",
  filepic: "",
};

const initInv = {
  code: "",
  invoice_id: "",
  date_due: new Date(),
  date_invoice: new Date(),
  payment_code: "",
  amount: "",
  vat: "",
  total: "0.00",
  status_pay: "N",
  remark: "",
};

const initFind = {
  search: "",
  date_start: Func.dateAdd(-30),
  // date_start: Func.dateFirstMonth(),
  date_stop: new Date(),
  order_by: "tb.id",
  order_sort: "desc",
  status: "ALL",
  status_invoice: "ALL",
  page: 1,
  perPage: 30,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function PurchaseBill({ t }) {
  const mod = "purchasebill";
  const prefix = "po";
  const path = "purchasebill";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.id", name: t(`${prefix}.id`), order: "desc" },
    { code: "br.name_th", name: t(`${prefix}.branch`) },
    { code: "tb.date_po", name: t(`${prefix}.date_po`) },
    { code: "tb.remark", name: t(`${prefix}.remark`) },
    { code: "tb.total", name: t(`${prefix}.amount`) },
    {
      code: "tb.count_receive",
      name: t(`${prefix}.count_receive`),
      sort: false,
    },
    { code: "tb.status", name: t(`${prefix}.status`) },
    { code: "tb.status_invoice", name: "ใบแจ้งหนี้" },
    { code: "tb.status_invoice", name: t(`${prefix}.status_invoice`) },
  ];
  const alignValue = [0, 2, 5, 6, 7, 8, 9];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 30, name: "element.show_30_record" },
    { id: 100, name: "element.show_100_record" },
  ];
  const cboVat = [
    { id: "1", title: t(`${prefix}.novat`) },
    { id: "2", title: t(`${prefix}.vats`) },
  ];
  const cboStatusInvoice = [
    { code: "ALL", name: t(`${prefix}.status_invoice_all`) },
    { code: "Y", name: t(`${prefix}.status_invoice_Y`) },
    { code: "N", name: t(`${prefix}.status_invoice_N`) },
  ];

  const app = useSelector((state) => state.appState);
  const txtSearchList = useRef(null);
  const txtPrice = useRef(null);
  const txtQuantity = useRef(null);
  const txtInvoiceId = useRef(null);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [searchList, setSearchList] = useState("");
  const [cboStatus, setCboStatus] = useState([]);
  const [cboPayment, setCboPayment] = useState([]);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [order, setOrder] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [field, setField] = useState(initField);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [inv, setInv] = useState(initInv);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [modalNew, setModalNew] = useState(false);
  const [modalList, setModalList] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalInvoice, setModalInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [vat, setVat] = useState(cboStatusInvoice[0]);
  const [checkList, setCheckList] = useState(0);
  const [branch, setBranch] = useState("");
  const [poCode, setPoCode] = useState("");

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboPayment(result.paymenttype);
        setCboStatus(result.stat);
        setBranch(result.branch);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      id: id,
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      status_invoice: data.status_invoice,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              btnOpen(item),
              item.branch_name,
              Func.dateDisplay(item.date_po),
              item.remark,
              Func.numberDisplay(item.total),
              item.count_receive,
              displayStatus(item),
              item.count_invoice,
              displayInvoice(item.status_invoice),
              btn(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const btnOpen = (item) => {
    return (
      <Button
        color="primary"
        simple
        onClick={() => {
          open(item.code);
        }}
      >
        {item.id}
      </Button>
    );
  };

  const displayStatus = (item) => {
    if (item.status == "2")
      return (
        <Button
          color="warning"
          size="sm"
          className={classes.actionButton}
          onClick={() => addReceive(item.code)}
        >
          <CheckCircleIcon /> {t(`${prefix}.status_finish`)}
        </Button>
      );
    else
      return (
        <code className={`color-${item.color}`}>
          <i className={item.icon} /> {item.status_display}
        </code>
      );
  };

  const displayInvoice = (inp) => {
    return inp === "Y" ? (
      <CheckCircleOutlineIcon className={classes.colorSuccess} />
    ) : (
      <AvTimerIcon className={classes.colorGray} />
    );
  };

  const searchOrder = (search) => {
    const btnOrder = (item) => {
      return (
        <div>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={() => showList(item)}
          >
            <AddIcon className={classes.icon} />
          </Button>
        </div>
      );
    };

    PostData(mod, "searchproduct", {
      token: app.token,
      lang: app.lang,
      search: search,
      id: poCode,
    }).then((result) => {
      if (result.status === "OK") {
        setOrder([]);

        result.data.map((item, i) => {
          setOrder((order) => [
            ...order,
            [
              <img src={item.filepic} width="30" />,
              item.name,
              item.price,
              btnOrder(item),
            ],
          ]);
        });
      }
    });
  };

  const showList = (item) => {
    setModalAdd(false);

    loadList({
      code: 0,
      product_code: item.code,
      product_id: item.id,
      product_name: item.name,
      price: item.price,
      quantity: 1,
      amount: item.price,
      unit_name: item.unit_name,
      filepic: item.filepic,
    });
  };

  const openList = (code) => {
    setLoading(true);
    setField(initField);

    PostData(mod, "openlist", {
      token: app.token,
      lang: app.lang,
      code: code,
    }).then((result) => {
      if (result.status === "OK") {
        loadList(result.data);
      }
      setLoading(false);
    });
  };

  const loadList = (data) => {
    setField(data);

    setModalEdit(true);

    setTimeout(function () {
      txtPrice.current.select();
    }, 500);
  };

  const saveList = () => {
    if (field.code > 0) editList();
    else addList();
  };

  const addList = () => {
    setModalEdit(false);
    setLoading(true);
    PostData(mod, "addlist", {
      token: app.token,
      lang: app.lang,
      code: poCode,
      data: {
        branch_code: attr.branch_code,
        po_code: poCode,
        price: field.price,
        quantity: field.quantity,
        product_code: field.product_code,
      },
    }).then((result) => {
      if (result.status === "OK") {
        open(poCode);
        showNotify(t("alert.addcomplete"), "success");
      }
      setLoading(false);
    });
  };

  const editList = () => {
    setModalEdit(false);
    setLoading(true);
    PostData(mod, "editlist", {
      token: app.token,
      lang: app.lang,
      code: field.code,
      quantity: field.quantity,
      data: {
        price: field.price,
        quantity: field.quantity,
      },
    }).then((result) => {
      if (result.status === "OK") {
        open(poCode);
        showNotify(t("alert.editcomplete"), "success");
      }
      setLoading(false);
    });
  };

  const btnList = (item, status) => {
    if (status < 3) {
      return (
        <div>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={() => openList(item.code)}
          >
            <EditIcon className={classes.icon} />
          </Button>
          <Button
            round
            color="danger"
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={() => delList(item)}
          >
            <DeleteIcon className={classes.icon} />
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  const btnInvoice = (item) => {
    if (item.status <= 1) {
      return (
        <div>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={() => openInvoice(item.code)}
          >
            <EditIcon className={classes.icon} />
          </Button>
          <Button
            round
            color="danger"
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={() => delInvoice(item)}
          >
            <DeleteIcon className={classes.icon} />
          </Button>
        </div>
      );
    } else {
      return null;
    }
  };

  const delList = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          PostData(mod, "dellist", {
            token: app.token,
            lang: app.lang,
            code: item.code,
            ref_code: poCode,
          }).then((result) => {
            setAlert(null);
            if (result.status === "OK") {
              open(poCode);
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const addInvoice = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_add_title")}
        onConfirm={() => {
          setAlert(null);
          setModalInvoice(false);
          setLoading(true);
          PostData(mod, "addinvoice", {
            token: app.token,
            lang: app.lang,
            code: poCode,
            ref_code: poCode,
            data: {
              po_code: poCode,
              invoice_id: inv.invoice_id,
              date_due: Func.dateFormat(inv.date_due),
              date_invoice: Func.dateFormat(inv.date_invoice),
              payment_code: inv.payment_code,
              remark: inv.remark,
              amount: inv.amount,
              vat: inv.vat,
            },
          }).then((result) => {
            if (result.status === "OK") {
              open(poCode);
              // window.location.reload();
              showNotify(t("alert.addcomplete"), "success");
            }
            setLoading(false);
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_add_msg")}
      </SweetAlert>
    );
  };

  const editInvoice = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setModalInvoice(false);
          setLoading(true);
          PostData(mod, "editinvoice", {
            token: app.token,
            lang: app.lang,
            code: inv.code,
            ref_code: poCode,
            data: {
              invoice_id: inv.invoice_id,
              date_due: Func.dateFormat(inv.date_due),
              date_invoice: Func.dateFormat(inv.date_invoice),
              payment_code: inv.payment_code,
              remark: inv.remark,
              amount: inv.amount,
              vat: inv.vat,
            },
          }).then((result) => {
            if (result.status === "OK") {
              open(poCode);
              showNotify(t("alert.editcomplete"), "success");
            }
            setLoading(false);
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const openInvoice = (code) => {
    setLoading(true);
    setField(initField);

    PostData(mod, "openinvoice", {
      token: app.token,
      lang: app.lang,
      code: code,
    }).then((result) => {
      if (result.status === "OK") {
        setInv({
          ...result.data,
          date_due: new Date(result.data.date_due),
          date_invoice: new Date(result.data.date_invoice),
        });

        setModalInvoice(true);
      }
      setLoading(false);
    });
  };

  const delInvoice = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          PostData(mod, "delinvoice", {
            token: app.token,
            lang: app.lang,
            code: item.code,
            ref_code: poCode,
          }).then((result) => {
            setAlert(null);
            if (result.status === "OK") {
              open(poCode);
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const open = (code) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);
    setInvoice([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: code,
    }).then((result) => {
      if (result.status === "OK") {
        setPoCode(code);
        setModalList(true);
        setAttr({ ...result.data, date_po: new Date(result.data.date_po) });
        setCheckList(result.list.length);

        cboVat.map((item, i) => {
          if (result.data.paytype == item.id) setVat(item);
        });

        result.invoice.map((item, i) => {
          setInvoice((invoice) => [
            ...invoice,
            [
              i + 1,
              item.invoice_id,
              Func.dateDisplay(item.date_invoice),
              Func.dateDisplay(item.date_due),
              Func.numberDisplay(item.amount),
              Func.numberDisplay(item.vat),
              Func.numberDisplay(item.total),
              item.payment_name,
              item.remark,
              displayStatus(item),
              btnInvoice(item),
            ],
          ]);
        });

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              item.price_display,
              item.quantity_display,
              item.quantity_receive,
              item.amount_display,
              btnList(item, result.data.status),
            ],
          ]);
        });

        if (result.data.paytype == 2) {
          setList((list) => [
            ...list,
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              <h4>{t(`${prefix}.amount`)}</h4>,
              <h4>{Func.numberDisplay(result.data.amount)}</h4>,
              "",
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              <h4>
                {t(`${prefix}.vat`)} {result.vat}%
              </h4>,
              <h4>{Func.numberDisplay(result.data.vat)}</h4>,
              "",
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              <h4>{t(`${prefix}.total`)}</h4>,
              <h4>{Func.numberDisplay(result.data.total)}</h4>,
              "",
            ],
          ]);
        } else {
          setList((list) => [
            ...list,
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              <h4>{t(`${prefix}.total`)}</h4>,
              <h4>{Func.numberDisplay(result.data.total)}</h4>,
              "",
            ],
          ]);
        }

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);
            setModalNew(false);

            PostData(mod, "add", {
              data: {
                supplier_code: id,
                remark: attr.remark,
                date_po: Func.dateFormat(attr.date_po),
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                view(find);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);
            setModalList(false);

            PostData(mod, "edit", {
              code: poCode,
              data: {
                remark: attr.remark,
                date_po: Func.dateFormat(attr.date_po),
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                view(find);
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const copy = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "copy", {
              ref_code: poCode,
              data: {
                supplier_code: id,
                remark: attr.remark,
                date_po: Func.dateFormat(attr.date_po),
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                window.location.assign(`/admin/${mod}/${result.code}`);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const print = (code) => {
    window.open(`${API_URL}/app/print/po-${code}.pdf`, "_blank");
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print(item.code)}
        >
          {t(`element.print`)} <PrintIcon />
        </Button>
      </>
    );
  };

  const editStatus = (key) => {
    let found = false;

    if (attr.status == 0 && key == 1) {
      found = true;
    } else if (attr.status == 1 && key == 2) {
      found = true;
    } else if (attr.status <= 2 && key === 9) {
      found = true;
    }

    if (found) {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              code: poCode,
              data: {
                status: key,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open(poCode);
                view(find);
                setAlert(null);
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const addReceive = (code) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "addreceive", {
            code: code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              window.location.replace(`/admin/receive/${result.code}`);
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const editVat = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edit", {
            code: poCode,
            data: {
              paytype: data.id,
            },
          }).then((result) => {
            setAlert(null);

            if (result.status === "OK") {
              open(poCode);
              view(find);
              setAlert(null);
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const btnAddList = () => {
    if (attr.status < 3) {
      return (
        <Button
          round
          color="info"
          size="sm"
          onClick={() => {
            searchOrder(searchList);
            setModalAdd(true);

            setTimeout(function () {
              txtSearchList.current.select();
            }, 500);
          }}
        >
          <AddIcon className={classes.icon} /> {t("element.add")}
        </Button>
      );
    } else {
      return null;
    }
  };

  const btnAddInvoice = () => {
    if (attr.status_invoice === "N") {
      return (
        <Button
          round
          color="info"
          size="sm"
          onClick={() => {
            setInv(initInv);
            setModalInvoice(true);

            setTimeout(function () {
              txtInvoiceId.current.select();
            }, 500);
          }}
        >
          <AddIcon className={classes.icon} /> {t("element.add")}
        </Button>
      );
    } else {
      return null;
    }
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <div>
        <GridContainer className="viewlist">
          <GridItem xs={12} sm={6} md={6}>
            <TextField
              id="search"
              fullWidth
              InputProps={{
                onChange: (e) => {
                  view({ ...find, search: e.target.value, page: 1 });
                  setFind({ ...find, search: e.target.value, page: 1 });
                },
                startAdornment: (
                  <InputAdornment position="start">
                    {t("element.search")} :
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
            />
          </GridItem>
          <GridItem xs={12}></GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                dateFormat="DD/MM/yyyy"
                closeOnSelect
                value={find.date_start}
                onChange={(value) => {
                  view({ ...find, date_start: value, page: 1 });
                  setFind({ ...find, date_start: value, page: 1 });
                }}
                className={classes.center}
                renderInput={(props) => {
                  return (
                    <TextField
                      fullWidth
                      InputProps={{
                        ...props,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginBottom: "4px" }}
                          >
                            {t("element.start")} :
                          </InputAdornment>
                        ),
                        endAdornment: <DateRangeIcon />,
                        autoComplete: "off",
                        readOnly: true,
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                dateFormat="DD/MM/yyyy"
                closeOnSelect
                value={find.date_stop}
                onChange={(value) => {
                  view({ ...find, date_stop: value, page: 1 });
                  setFind({ ...find, date_stop: value, page: 1 });
                }}
                className={classes.center}
                renderInput={(props) => {
                  return (
                    <TextField
                      fullWidth
                      InputProps={{
                        ...props,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ marginBottom: "4px" }}
                          >
                            {t("element.stop")} :
                          </InputAdornment>
                        ),
                        endAdornment: <DateRangeIcon />,
                        autoComplete: "off",
                        readOnly: true,
                      }}
                    />
                  );
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                value={find.status}
                onChange={(e) => {
                  view({ ...find, status: e.target.value, page: 1 });
                  setFind({ ...find, status: e.target.value, page: 1 });
                }}
              >
                <MenuItem
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value="ALL"
                >
                  {t(`${prefix}.status`)}
                </MenuItem>
                {cboStatus &&
                  cboStatus.map((item) => (
                    <MenuItem
                      key={item.code}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <Select
                value={find.status_invoice}
                onChange={(e) => {
                  view({
                    ...find,
                    status_invoice: e.target.value,
                    page: 1,
                  });
                  setFind({
                    ...find,
                    status_invoice: e.target.value,
                    page: 1,
                  });
                }}
              >
                {cboStatusInvoice &&
                  cboStatusInvoice.map((item) => (
                    <MenuItem
                      key={item.code}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12}>
            <Table
              striped
              hover
              tableHead={[
                renderHeader(header[0]),
                renderHeader(header[1]),
                renderHeader(header[2]),
                renderHeader(header[3]),
                renderHeader(header[4]),
                renderHeader(header[5]),
                renderHeader(header[6]),
                renderHeader(header[7]),
                renderHeader(header[8]),

                <Button
                  color="primary"
                  className={classes.actionButton}
                  onClick={() => {
                    setModalNew(true);
                    setAttr({ ...initAttr, branch_name: branch });
                  }}
                >
                  {t(`element.add`)}
                  <AddIcon />
                </Button>,
              ]}
              tableData={record}
              customHeadClassesForCells={alignValue}
              customHeadCellClasses={alignItem}
              customClassesForCells={alignValue}
              customCellClasses={alignItem}
              coloredColls={[5, 7]}
              colorsColls={["primary", "primary"]}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}></InputLabel>
              <Select
                value={find.perPage}
                onChange={(e) => {
                  view({
                    ...find,
                    page: 1,
                    perPage: e.target.value,
                    page: 1,
                  });
                  setFind({
                    ...find,
                    page: 1,
                    perPage: e.target.value,
                    page: 1,
                  });
                }}
              >
                {cboPerPage &&
                  cboPerPage.map((item) => (
                    <MenuItem
                      key={item.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.id}
                    >
                      {t(item.name)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
            <Pagination
              style={{ float: "right", marginTop: "20px" }}
              count={pageTotal}
              showFirstButton
              showLastButton
              page={pageNo}
              onChange={(e, p) => {
                view({ ...find, page: p });
                setFind({ ...find, page: p });
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderAdd = () => {
    return (
      <div>
        {renderInfo()}

        <GridContainer>
          <GridItem xs={12} className={classes.right}>
            <Button color="success" onClick={add}>
              <SaveIcon /> {t("element.save")}
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>{renderStatus()}</GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        {attr.status <= 1 ? (
                          <Button color="success" onClick={edit}>
                            <SaveIcon /> {t("element.save")}
                          </Button>
                        ) : null}
                        {attr.status <= 4 ? (
                          <Button color="info" onClick={() => print(attr.code)}>
                            <PrintIcon /> {t("element.print")}
                          </Button>
                        ) : null}
                        <Button color="info" onClick={copy}>
                          <FileCopyIcon /> {t("element.copy")}
                        </Button>
                        {attr.status <= 2 ? (
                          <Button color="danger" onClick={() => editStatus(9)}>
                            <CancelIcon /> {t("element.cancel")}
                          </Button>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: t("element.order"),
                tabIcon: ListAltIcon,
                tabContent: renderOrder(),
              },
              {
                tabName: t(`${prefix}.invoice`),
                tabIcon: ListAltIcon,
                tabContent: renderInvoice(),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderOrder = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${prefix}.num`),
              t(`${prefix}.image`),
              t(`${prefix}.product_id`),
              t(`${prefix}.product_name`),
              t(`${prefix}.unit`),
              t(`${prefix}.price`),
              t(`${prefix}.quantity`),
              t(`${prefix}.quantity_receive`),
              t(`${prefix}.amount`),
              btnAddList(),
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
            ]}
            coloredColls={[5, 6, 7, 8]}
            colorsColls={["primary", "primary", "success", "primary"]}
          />
        </GridItem>
        <GridItem xs={12}>
          <p></p>
          {checkList > 0 ? (
            <GridContainer>
              <GridItem xs={6} className={classes.right}>
                {attr.status < 3 ? (
                  <CustomDropdown
                    buttonText={
                      <>
                        <MonetizationOnIcon /> {vat.title}
                      </>
                    }
                    buttonProps={{
                      color: "info",
                    }}
                    dropdownList={cboVat}
                    onClick={editVat}
                  />
                ) : null}
              </GridItem>
              <GridItem xs={6}>
                {attr.status == 0 ? (
                  <Button color="success" onClick={() => editStatus(1)}>
                    <CheckCircleIcon /> {t(`${prefix}.status_send`)}
                  </Button>
                ) : null}
                {attr.status == 1 ? (
                  <Button color="success" onClick={() => editStatus(2)}>
                    <CheckCircleIcon /> {t(`${prefix}.status_approve`)}
                  </Button>
                ) : null}
                {attr.status == 2 ? (
                  <Button color="success" onClick={() => addReceive(poCode)}>
                    <CheckCircleIcon /> {t(`${prefix}.status_finish`)}
                  </Button>
                ) : null}
              </GridItem>
            </GridContainer>
          ) : null}
        </GridItem>
      </GridContainer>
    );
  };

  const renderInvoice = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${prefix}.num`),
              t(`${prefix}.invoice_id`),
              t(`${prefix}.date_invoice`),
              t(`${prefix}.date_due`),
              t(`${prefix}.amount`),
              t(`${prefix}.vat`),
              t(`${prefix}.total`),
              t(`${prefix}.payment_type`),
              t(`${prefix}.remark`),
              t(`${prefix}.status`),
              btnAddInvoice(),
            ]}
            tableData={invoice}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 9, 10]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
              classes.center,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 9, 10]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
              classes.center,
              classes.center,
            ]}
            coloredColls={[4, 5, 6]}
            colorsColls={["primary", "primary", "success"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const changePrice = (e) => {
    let price = e.target.value;
    let quantity = field.quantity;
    let amount = Func.numberDisplay(price * quantity);

    setField({ ...field, price: price, amount: amount });
  };

  const changeAmount = (e) => {
    let price = field.price;
    let quantity = e.target.value;
    let amount = Func.numberDisplay(price * quantity);

    setField({ ...field, quantity: quantity, amount: amount });
  };

  const renderModalAdd = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalAdd}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalAdd(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-add-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalAdd(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t("element.add")}</h4>
        </DialogTitle>
        <DialogContent id="modal-add" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                labelText={t("element.search")}
                id="search_order"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  inputRef: txtSearchList,
                  onChange: (e) => {
                    setSearchList(e.target.value);
                    searchOrder(e.target.value);
                  },
                  value: searchList,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableData={order}
                customHeadClassesForCells={[2, 3]}
                customHeadCellClasses={[classes.right, classes.right]}
                customClassesForCells={[2, 3]}
                customCellClasses={[classes.right, classes.right]}
                coloredColls={[2]}
                colorsColls={["primary"]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  const renderModalEdit = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalEdit}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalEdit(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-edit-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalEdit(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t("element.edit")}</h4>
        </DialogTitle>
        <DialogContent id="modal-edit" className={classes.modalBody}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <img src={field.filepic} width="100%" />
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  labelText={t(`${prefix}.product_id`)}
                  id="product_id"
                  success
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: field.product_id,
                    disabled: true,
                  }}
                />
                <CustomInput
                  labelText={t(`${prefix}.product_name`)}
                  id="product_name"
                  success
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: field.product_name,
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <p></p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Table
                  striped
                  hover
                  tableData={[
                    [
                      <b>{t(`${prefix}.price`)}</b>,
                      <Input
                        inputRef={txtPrice}
                        id="price"
                        placeholder="0.00"
                        inputProps={{
                          "aria-label": "price",
                          value: field.price,
                          type: "number",
                        }}
                        classes={{
                          input: classes.right,
                        }}
                        color="primary"
                        fullWidth={false}
                        onChange={changePrice}
                      />,
                    ],
                    [
                      <b>
                        {t(`${prefix}.quantity`)} [{field.unit_name}]
                      </b>,
                      <Input
                        inputRef={txtQuantity}
                        id="quantity"
                        placeholder="0.00"
                        inputProps={{
                          "aria-label": "quantity",
                          value: field.quantity,
                          type: "number",
                        }}
                        classes={{
                          input: classes.right,
                        }}
                        color="primary"
                        fullWidth={false}
                        onChange={changeAmount}
                      />,
                    ],
                    [
                      <b>{t(`${prefix}.amount`)}</b>,
                      <span>{field.amount}</span>,
                    ],
                  ]}
                  customHeadClassesForCells={[1]}
                  customHeadCellClasses={[classes.right]}
                  customClassesForCells={[1]}
                  customCellClasses={[classes.right]}
                  coloredColls={[1]}
                  colorsColls={["primary"]}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={saveList} color="success">
            <SaveIcon /> {t(`element.save`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderModalInvoice = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalInvoice}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalInvoice(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-invoice-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalInvoice(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {inv.code > 0
              ? t("element.edit") + " " + t(`${prefix}.invoice`)
              : t("element.add") + " " + t(`${prefix}.invoice`)}
          </h4>
        </DialogTitle>
        <DialogContent id="modal-invoice" className={classes.modalBody}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText={t(`${prefix}.invoice_id`)}
                  id="inv-invoice_id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtInvoiceId,
                    onChange: (e) =>
                      setInv({ ...inv, invoice_id: e.target.value }),
                    value: inv.invoice_id,
                  }}
                  alignCenter
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.labelDpk}>
                  {t(`${prefix}.date_invoice`)}
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "__/__/____" }}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    value={inv.date_invoice}
                    onChange={(value) => {
                      setInv({ ...inv, date_invoice: value });
                    }}
                    className={classes.center}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="inv-payment_code"
                    className={classes.labelSelect}
                  >
                    {t(`${prefix}.payment_type`)}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={inv.payment_code}
                    inputProps={{
                      name: "inv-payment_code",
                    }}
                    onChange={(e) =>
                      setInv({ ...inv, payment_code: e.target.value })
                    }
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {t("element.pleaseselect")}
                    </MenuItem>
                    {cboPayment &&
                      cboPayment.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.labelDpk}>
                  {t(`${prefix}.date_due`)}
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "__/__/____" }}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    value={inv.date_due}
                    onChange={(value) => {
                      setInv({ ...inv, date_due: value });
                    }}
                    className={classes.center}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${prefix}.remark`)}
                  id="inv-remark"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) => setInv({ ...inv, remark: e.target.value }),
                    value: inv.remark,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableData={[
                  [
                    <b>{t(`${prefix}.amount`)}</b>,
                    <Input
                      id="inv-amount"
                      placeholder="0.00"
                      inputProps={{
                        "aria-label": "inv-amount",
                        value: inv.amount,
                        type: "number",
                      }}
                      classes={{
                        input: classes.right,
                      }}
                      color="primary"
                      fullWidth={false}
                      onChange={(e) => {
                        setInv({
                          ...inv,
                          amount: e.target.value,
                          total: Func.numberDisplay(
                            e.target.value * 1 + inv.vat * 1
                          ),
                        });
                      }}
                    />,
                  ],
                  [
                    <b>{t(`${prefix}.vat`)}</b>,
                    <Input
                      id="inv-quantity"
                      placeholder="0.00"
                      inputProps={{
                        "aria-label": "inv-quantity",
                        value: inv.vat,
                        type: "number",
                      }}
                      classes={{
                        input: classes.right,
                      }}
                      color="primary"
                      fullWidth={false}
                      onChange={(e) => {
                        setInv({
                          ...inv,
                          vat: e.target.value,
                          total: Func.numberDisplay(
                            e.target.value * 1 + inv.amount * 1
                          ),
                        });
                      }}
                    />,
                  ],
                  [<b>{t(`${prefix}.total`)}</b>, <span>{inv.total}</span>],
                ]}
                customHeadClassesForCells={[1]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[1]}
                customCellClasses={[classes.right]}
                coloredColls={[1]}
                colorsColls={["primary"]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            <GridItem xs={6} className={classes.left}></GridItem>
            <GridItem xs={6} className={classes.right}>
              {" "}
              <Button
                onClick={() => {
                  if (inv.code > 0) editInvoice();
                  else addInvoice();
                }}
                color="success"
              >
                <SaveIcon /> {t(`element.save`)}
              </Button>
            </GridItem>
          </GridContainer>
        </DialogActions>
      </Dialog>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <CustomInput
              success
              labelText={t(`${prefix}.branch`)}
              id="branch_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.branch_name,
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <InputLabel className={classes.labelDpk}>
              {t(`${prefix}.date_po`)}
            </InputLabel>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                inputProps={{
                  placeholder: "__/__/____",
                }}
                dateFormat="DD/MM/yyyy"
                closeOnSelect
                value={attr.date_po}
                onChange={(value) => {
                  setAttr({ ...attr, date_po: value });
                }}
                className={classes.center}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText={
                <>
                  {t(`${prefix}.remark`)}
                  <span className={classes.colorDanger}></span>
                </>
              }
              id="remark"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.remark,
              }}
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status * 1}
        alternativeLabel
        className={classes.bgTransparent}
      >
        <Step>
          <StepLabel>
            <p>
              <b>{t(`${prefix}.status_send`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <p>
              <b>{t(`${prefix}.status_approve`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_2}</p>
            <p className={classes.colorDefault}>{attr.approve_date_2}</p>
          </StepLabel>
        </Step>
        {attr.status == 9 ? (
          <Step>
            <StepLabel>
              <p>
                <Badge color="danger">{t(`${prefix}.status_cancel`)}</Badge>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_9}</p>
              <p className={classes.colorDefault}>{attr.approve_date_9}</p>
            </StepLabel>
          </Step>
        ) : (
          <Step>
            <StepLabel>
              <p>
                <b>{t(`${prefix}.status_finish`)}</b>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_3}</p>
              <p className={classes.colorDefault}>{attr.approve_date_3}</p>
            </StepLabel>
          </Step>
        )}
      </Stepper>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalNew = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalNew}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalNew(false)}
        aria-labelledby="modal-new-title"
        aria-describedby="modal-new-description"
      >
        <DialogTitle
          id="modal-new-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalNew(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent id="modal-new" className={classes.modalBody}>
          {renderAdd()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderEdit()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderPage = () => {
    return renderViewList();
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderModalList()}
      {renderModalNew()}
      {renderModalAdd()}
      {renderModalEdit()}
      {renderModalInvoice()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(PurchaseBill);
