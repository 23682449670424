/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import DateRangeIcon from "@material-ui/icons/DateRange";
import AlertIcon from "@material-ui/icons/AddAlert";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";

// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import i18n from "components/Translation/Translate.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import PosSlipDay from "views/Pos/PosSlipDay";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initFind = {
  search: "",
  date_start: Func.dateAdd(-30),
  date_stop: new Date(),
  order_by: "tb.code",
  order_sort: "desc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

const initAttr = {
  code: "",
  id: "",
};

/* ========== ZONE Function ========== */
function CustomerPrint({ t }) {
  const mod = "customerprint";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.code", name: t(`attr.code`), order: "desc" },
    { code: "tb.date_record", name: "วันที่" },
    { code: "tb.emp_code", name: "พนักงาน" },
    { code: "tb.amount", name: "จำนวนเงิน" },
    { code: "tb.vat", name: "Vat" },
    { code: "tb.total", name: "รวม" },
    { code: "tb.shipping_price", name: "ค่าจัดส่ง" },
    { code: "tb.nettotal", name: "รวมสุทธิ" },
    { code: "tb.pay", name: "ชำระแล้ว" },
    { code: "tb.balance", name: "ค้างชำระ" },
  ];
  const alignValue = [0, 1, 3, 4, 5, 6, 7, 8, 9, 10];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const slipRef = useRef(null);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [modalList, setModalList] = useState(false);
  const [slip, setSlip] = useState({});
  const [slipList, setSlipList] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      member: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const btn = (item) => {
    return (
      <Button
        color="info"
        className={classes.actionButton}
        onClick={() => print(item)}
        size="sm"
      >
        {t(`element.print`)} <PrintIcon />
      </Button>
    );
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              item.code,
              Func.dateDisplay(item.date_record),
              <span>
                <img src={item.filepic} width="20" /> {item.product_name}
              </span>,
              item.unit_name,
              Func.numberDisplay(item.price),
              Func.numberDisplay(item.quantity),
              Func.numberDisplay(item.amount),
            ],
          ]);
        });

        setLoading(false);
        setModalList(true);
      }
    });
  };

  const print = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "print", {
      token: app.token,
      lang: app.lang,
      member_code: id,
      code: item.code,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);
        handlePrint();
      }
    });
  };

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {item.invoice_id}
      </Button>
    );
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => {
                        view({ ...find, date_start: value, page: 1 });
                        setFind({ ...find, date_start: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => {
                        view({ ...find, date_stop: value, page: 1 });
                        setFind({ ...find, date_stop: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Button color="info" onClick={() => view(find)}>
                    Reload
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  renderHeader(header[6]),
                  renderHeader(header[7]),
                  renderHeader(header[8]),
                  renderHeader(header[9]),
                  "พิมพ์",
                ]}
                tableData={record.map((item) => {
                  return [
                    btnOpen(item),
                    Func.dateDisplay(item.date_record),
                    item.user_create,
                    Func.numberDisplay(item.amount),
                    Func.numberDisplay(item.vat),
                    Func.numberDisplay(item.total),
                    Func.numberDisplay(item.shipping_price),
                    Func.numberDisplay(item.nettotal),
                    Func.numberDisplay(item.pay),
                    Func.numberDisplay(item.balance),
                    btn(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[7, 8, 9]}
                colorsColls={["primary", "success", "danger"]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}></InputLabel>
                <Select
                  value={find.perPage}
                  onChange={(e) => {
                    view({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                    setFind({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                  }}
                >
                  {cboPerPage &&
                    cboPerPage.map((item) => (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {t(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                count={pageTotal}
                showFirstButton
                showLastButton
                page={pageNo}
                onChange={(e, p) => {
                  view({ ...find, page: p });
                  setFind({ ...find, page: p });
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.invoice_id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderList()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              "#",
              "วันที่",
              "สินค้า",
              "หน่วย",
              "ราคา",
              "จำนวน",
              "รวม",
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[4, 5, 6]}
            colorsColls={["danger", "success", "primary"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <PosSlipDay ref={slipRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      <br />
      {renderViewList()}
      {renderModalList()}
      {renderPrint()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(CustomerPrint);
