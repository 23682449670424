/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MuiAlert from "@material-ui/lab/Alert";
import InputIcon from "@material-ui/icons/Input";
import PrintIcon from "@material-ui/icons/Print";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import i18n from "components/Translation/Translate.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import PosSlipRound from "../Pos/PosSlipRound";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  enable: "Y",
  status: "99",
  branch_name: "",
  emp_name: "",
};

const initFind = {
  search: "",
  date_start: Func.dateAdd(-30),
  date_stop: new Date(),
  order_by: "tb.id",
  order_sort: "desc",
  status: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function AdminShift({ t }) {
  const mod = "adminshift";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.id", name: t(`${mod}.id`), order: "desc" },
    { code: "tb.branch_code", name: t(`${mod}.branch`) },
    { code: "em.name", name: t(`${mod}.emp_name`) },
    { code: "tb.approve_date_1", name: t(`${mod}.date_login`) },
    { code: "tb.approve_date_3", name: t(`${mod}.date_logout`) },
    { code: "tb.quantity_all", name: t(`${mod}.quantity_stock`) },
    { code: "tb.quantity_check", name: t(`${mod}.quantity`) },
    { code: "tb.quantity_diff", name: t(`${mod}.quantity_diff`) },
    { code: "tb.status", name: t(`${mod}.status`) },
  ];
  const alignValue = [0, 3, 4, 5, 6, 7, 8, 9];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.center,
    classes.right,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const slipRound = useRef(null);

  const { id } = useParams();
  const [cboStatus, setCboStatus] = useState([]);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [adjust, setAdjust] = useState([]);
  const [payout, setPayout] = useState([]);
  const [poinvoice, setPoInvoice] = useState([]);
  const [cashier, setCashier] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [slip, setSlip] = useState({});
  const [slipList, setSlipList] = useState([]);
  const [slipList2, setSlipList2] = useState([]);
  const [notify, setNotify] = useState(null);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const handlePrintRound = useReactToPrint({
    content: () => slipRound.current,
  });

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);

        if (id === undefined) {
          view(find);
        } else {
          setTab(0);
          open();
        }
      } else {
        Func.exitApp();
      }
    });
  };

  const changeTab = (event, value) => {
    setTab(value);
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              btnOpen(item),
              item.branch_name,
              item.emp_name,
              Func.dateTimeDisplay(item.approve_date_1),
              Func.dateTimeDisplay(item.approve_date_3),
              Func.numberDisplay(item.quantity_all),
              Func.numberDisplay(item.quantity_check),
              Func.numberDisplay(item.quantity_diff),
              displayStatus(item),
              btn(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const btnOpen = (item) => {
    return (
      <NavLink to={`/admin/${mod}/${item.code}`}>
        <Button color="primary" simple>
          {item.id}
        </Button>
      </NavLink>
    );
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const open = () => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);
    setAdjust([]);
    setPayout([]);
    setPoInvoice([]);
    setCashier([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              Func.numberDisplay(item.quantity_stock),
              Func.numberDisplay(item.quantity),
              Func.numberDisplay(item.quantity_diff),
            ],
          ]);
        });

        result.adjust.map((item, i) => {
          setAdjust((adjust) => [
            ...adjust,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              Func.numberDisplay(item.quantity_stock),
              Func.numberDisplay(item.quantity),
              Func.numberDisplay(item.quantity_diff),
              Func.dateTimeDisplay(item.date_create),
              displayStock(item.round),
            ],
          ]);
        });

        result.payout.map((item, i) => {
          setPayout((payout) => [
            ...payout,
            [
              i + 1,
              item.id,
              item.branch_name,
              item.type_name,
              item.detail,
              Func.dateDisplay(item.date_record),
              Func.numberDisplay(item.price),
              Func.checkDisplay(item.enable),
            ],
          ]);
        });

        result.poinvoice.map((item, i) => {
          setPoInvoice((poinvoice) => [
            ...poinvoice,
            [
              i + 1,
              item.invoice_id,
              Func.dateDisplay(item.date_invoice),
              Func.dateDisplay(item.date_due),
              Func.numberDisplay(item.amount),
              Func.numberDisplay(item.vat),
              Func.numberDisplay(item.total),
              item.payment_name,
              item.remark,
              displayStatus(item),
              item.emp_name,
            ],
          ]);
        });

        result.cashier.map((item, i) => {
          setCashier((cashier) => [
            ...cashier,
            [
              i + 1,
              item.id,
              displayCashType(item.cashtype),
              Func.dateTimeDisplay(item.date_create),
              Func.numberDisplay(item.amount),
              Func.numberDisplay(item.balance_before),
              Func.numberDisplay(item.balance_after),
            ],
          ]);
        });

        setLoading(false);
      }
    });
  };

  const displayCashType = (inp) => {
    if (inp === "IN")
      return (
        <code className={`color-success`}>
          <i className="fas fa-plus-circle" /> {t(`pettycash.cashin`)}
        </code>
      );
    else
      return (
        <code className={`color-danger`}>
          <i className="fas fa-minus-circle" /> {t(`pettycash.cashout`)}
        </code>
      );
  };

  const displayStock = (round) => {
    if (round === 1)
      return (
        <code className={`color-info`}>
          <i className="fas fa-plus-circle" /> นับสต็อค
        </code>
      );
    else
      return (
        <code className={`color-primary`}>
          <i className="fas fa-plus-circle" /> เพิ่ม ครั้งที่ {round}
        </code>
      );
  };

  const print = (item) => {
    setLoading(true);
    PostData(mod, "cashierprint", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.member_list);
        setSlipList2(result.payout_list);

        handlePrintRound();
      } else {
        showNotify(result.msg, "danger");
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        <NavLink to={`/admin/${mod}/${item.code}`}>
          <Button color="success" className={classes.actionButton}>
            {t(`element.open`)} <InputIcon />
          </Button>
        </NavLink>
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print(item)}
        >
          {t(`element.print`)} <PrintIcon />
        </Button>
      </>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    id="search"
                    fullWidth
                    InputProps={{
                      onChange: (e) => {
                        view({ ...find, search: e.target.value, page: 1 });
                        setFind({ ...find, search: e.target.value, page: 1 });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {t("element.search")} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}></GridItem>
                <GridItem xs={12} sm={6} md={3}></GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => {
                        view({ ...find, date_start: value, page: 1 });
                        setFind({ ...find, date_start: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => {
                        view({ ...find, date_stop: value, page: 1 });
                        setFind({ ...find, date_stop: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${mod}.status`)}
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                      renderHeader(header[8]),
                      "",
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[5, 6, 7]}
                    colorsColls={["primary", "success", "danger"]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>
                    {attr.id} : {attr.emp_name}
                  </h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/${mod}`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>{renderStatus()}</GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: renderInfo(),
              },
              {
                tabName: "คลัง",
                tabIcon: ListAltIcon,
                tabContent: renderOrder(),
              },
              {
                tabName: "นับสต็อค",
                tabIcon: ListAltIcon,
                tabContent: renderAdjust(),
              },
              {
                tabName: "รายการจ่าย",
                tabIcon: ListAltIcon,
                tabContent: renderPayout(),
              },
              {
                tabName: "ชำระเงิน PO",
                tabIcon: ListAltIcon,
                tabContent: renderPoInvoice(),
              },
              {
                tabName: "แคชเชียร์",
                tabIcon: ListAltIcon,
                tabContent: renderCashier(),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderOrder = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${mod}.num`),
              t(`${mod}.image`),
              t(`${mod}.product_id`),
              t(`${mod}.product_name`),
              t(`${mod}.unit`),
              t(`${mod}.quantity_stock`),
              t(`${mod}.quantity`),
              t(`${mod}.quantity_diff`),
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[5, 6, 7]}
            colorsColls={["primary", "success", "danger"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderAdjust = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${mod}.num`),
              t(`${mod}.image`),
              t(`${mod}.product_id`),
              t(`${mod}.product_name`),
              t(`${mod}.unit`),
              t(`${mod}.quantity_stock`),
              t(`${mod}.quantity`),
              t(`${mod}.quantity_diff`),
              "วันที่",
              "รอบ",
            ]}
            tableData={adjust}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
              classes.center,
            ]}
            coloredColls={[5, 6, 7]}
            colorsColls={["primary", "success", "danger"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderPayout = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${mod}.num`),
              "รหัส",
              "สาขา",
              "ประเภท",
              "รายการ",
              "วันที่",
              "จำนวนเงิน",
              "สถานะ",
            ]}
            tableData={payout}
            customHeadClassesForCells={[0, 1, 2, 5, 6, 7]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 5, 6, 7]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.center,
            ]}
            coloredColls={[6]}
            colorsColls={["primary"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderPoInvoice = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`po.num`),
              t(`po.invoice_id`),
              t(`po.date_invoice`),
              t(`po.date_due`),
              t(`po.amount`),
              t(`po.vat`),
              t(`po.total`),
              t(`po.payment_type`),
              t(`po.remark`),
              t(`po.status`),
              "ผู้ชำระเงิน",
            ]}
            tableData={poinvoice}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 9, 10]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
              classes.center,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6, 7, 9, 10]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.center,
              classes.center,
              classes.center,
            ]}
            coloredColls={[4, 5, 6]}
            colorsColls={["primary", "primary", "success"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderCashier = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${mod}.num`),
              "เลขที่บิล",
              "ประเภท",
              "วันที่",
              "จำนวนเงิน",
              "เงินลิ้นชัก ก่อน",
              "เงินลิ้นชัก หลัง",
            ]}
            tableData={cashier}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5, 6]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[4, 5, 6]}
            colorsColls={["primary", "success", "danger"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <CustomInput
              labelText={t(`${mod}.branch`)}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.branch_name,
                disabled: true,
              }}
              success
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <CustomInput
              labelText={t(`${mod}.emp_name`)}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.emp_name,
                disabled: true,
              }}
              success
            />
          </GridItem>
          <GridItem xs={12} className={classes.center}>
            <Button color="info" onClick={() => print(attr)}>
              <PrintIcon /> {t("element.print")}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderStatus = () => {
    if (attr.status == 9) {
      return (
        <MuiAlert
          elevation={3}
          variant="filled"
          severity="error"
          style={{ marginBottom: "15px" }}
        >
          {t(`${mod}.status_cancel`)}
        </MuiAlert>
      );
    } else {
      return (
        <Stepper
          activeStep={attr.status * 1}
          alternativeLabel
          className={classes.bgTransparent}
        >
          <Step>
            <StepLabel>
              <p>
                <b>{t(`${mod}.status_send`)}</b>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_1}</p>
              <p className={classes.colorDefault}>{attr.approve_date_1}</p>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <p>
                <b>{t(`${mod}.status_approve`)}</b>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_2}</p>
              <p className={classes.colorDefault}>{attr.approve_date_2}</p>
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>
              <p>
                <b>{t(`${mod}.status_finish`)}</b>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_3}</p>
              <p className={classes.colorDefault}>{attr.approve_date_3}</p>
            </StepLabel>
          </Step>
        </Stepper>
      );
    }
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <PosSlipRound
          ref={slipRound}
          attr={slip}
          member_list={slipList}
          payout_list={slipList2}
        />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else {
      return renderEdit();
    }
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderPrint()}
      {renderLoading()}
      {notify}
    </div>
  );
}

export default withNamespaces()(AdminShift);
