/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

/* ========== ZONE Function ========== */
function TransferPrintSum({ t, attr, list }) {
  const mod = "posbill";
  const prefix = "bill";
  const classes = useStyles();

  const quantityDisplay = (num) => {
    const fl = Math.floor(num);
    if (fl == num) return Func.numberDisplay(num, 0);
    else return Func.numberDisplay(num);
  };

  const renderHeader = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td align="center">
              <h3>บิลส่งของรวม {attr.show === "ALL" ? "" : " (รอส่ง)"}</h3>
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">รถส่งของ: {attr.car_name}</td>
          </tr>
          <tr>
            <td align="left">วันที่: {Func.dateDisplay(attr.date_bill, 3)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderOrder = () => {
    return (
      <table width="100%" cellPadding={0} cellSpacing={0}>
        <tbody>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td>
              {list.map((item, i) => (
                <table
                  width="100%"
                  cellPadding={3}
                  cellSpacing={0}
                  key={item.code}
                >
                  <thead>
                    <tr>
                      <th align="left" valign="top" colSpan={2}>
                        {item.name}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.data.map((value, j) => (
                      <tr key={value.code}>
                        <td align="left" valign="top">
                          {" "}
                          {j + 1}. {value.product_name}
                        </td>
                        <td align="right" valign="top">
                          {quantityDisplay(value.quantity)} {value.unit_name}
                        </td>
                      </tr>
                    ))}
                    {item.comment.length > 0 ? (
                      <tr>
                        <td align="left" valign="top" colSpan={2}>
                          *** หมายเหตุ
                        </td>
                      </tr>
                    ) : null}
                    {item.comment.map((value, j) => (
                      <tr key={j}>
                        <td align="left" valign="top" colSpan={2}>
                          - {value.remark}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan={2}>&nbsp;</td>
                    </tr>
                  </tbody>
                </table>
              ))}
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="print">
      <div className="slips">
        {renderHeader()}
        {renderOrder()}
      </div>
    </div>
  );
}

export default withNamespaces()(TransferPrintSum);

TransferPrintSum.defaultProps = {
  attr: {},
  list: [],
};
