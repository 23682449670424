/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import InputIcon from "@material-ui/icons/Input";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";
import DataPrint from "./DataPrint";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  name: "",
  balance: "0.00",
  count_order: "0",
  count_shipping: "0",
  count_invoice: "0",
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "asc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function DataField({ t }) {
  const mod = "datafield";
  const prefix = "attr";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.code", name: t(`attr.code`), order: "asc" },
    { code: "tb.id", name: t(`${prefix}.id`) },
    { code: "tb.name", name: t(`${prefix}.name`) },
    { code: "tb.attr_type", name: t(`${prefix}.type`) },
    { code: "tb.pkey", name: t(`${prefix}.pkey`) },
  ];
  const alignValue = [0, 2, 3, 4];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.right,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const slipRef = useRef();
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [slipList, setSlipList] = useState([]);
  const [slip, setSlip] = useState(initAttr);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
    pageStyle: `
        @page {
           
        }
    
        @media all {
            .pagebreak {
                display: none;
            }
        }
    
        @media print {
            .pagebreak {
                page-break-before: always;
            }
        }
    `,
  });

  const handleText = (value, i) => {
    const newText = [...nameList];
    const item = nameList[i];
    newText[i] = { code: item.code, name: value };

    setNameList(newText);
  };

  const view = (data) => {
    setLoading(true);
    setRecord([]);
    setNameList([]);

    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setNameList((nameList) => [
            ...nameList,
            { code: item.code, name: item.name },
          ]);
        });

        setRecord(result.data);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const refresh = () => {
    setLoading(true);
    console.log("x");

    PostData(mod, "refresh", {
      token: app.token,
      lang: app.lang,
      id: id,
    }).then((result) => {
      setLoading(false);
      if (result.status === "OK") {
        view(find);
        showNotify(t("alert.editcomplete"), "success");
      } else {
        showNotify(t("alert.editerror"), "danger");
      }
    });
  };

  const save = () => {
    setLoading(true);

    PostData(mod, "save", {
      token: app.token,
      lang: app.lang,
      data: nameList,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        showNotify(t("alert.editcomplete"), "success");
      } else {
        showNotify(t("alert.editerror"), "danger");
      }
    });
  };

  const print = () => {
    setLoading(true);
    setSlip({});
    setSlipList([]);

    PostData(mod, "print", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);

        handlePrint();
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <NavLink to={`/admin/${mod}/${item.code}`} tabIndex={-1}>
        <Button
          color="success"
          className={classes.actionButton}
          size="sm"
          tabIndex={-1}
        >
          {t(`element.open`)} <InputIcon />
        </Button>
      </NavLink>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="search"
                    value={find.search}
                    fullWidth
                    InputProps={{
                      onChange: (e) => {
                        view({ ...find, search: e.target.value, page: 1 });
                        setFind({ ...find, search: e.target.value, page: 1 });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {t("element.search")} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderAction(),
                    ]}
                    tableData={record.map((item, i) => {
                      return [
                        item.code,
                        item.id,
                        renderName(item, i),
                        item.attr_type,
                        item.pkey,
                      ];
                    })}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[]}
                    colorsColls={[]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderName = (item, i) => {
    return (
      <TextField
        key={item.code}
        value={nameList[i].name}
        fullWidth
        InputProps={{
          tabIndex: i + 1,
          onChange: (e) => handleText(e.target.value, i),
        }}
      />
    );
  };

  const renderAction = () => {
    return (
      <CustomDropdown
        dropup
        buttonText={t(`element.action`)}
        buttonProps={{
          color: "primary",
          size: "sm",
        }}
        dropdownList={[
          {
            id: "reload",
            title: (
              <div>
                <i className="fas fa-file-invoice-dollar" />{" "}
                {t("element.reload")}
              </div>
            ),
          },
          {
            id: "save",
            title: (
              <div>
                <i className="fas fa-save" /> {t("element.save")}
              </div>
            ),
          },
          {
            id: "print",
            title: (
              <div>
                <i className="fas fa-print" /> {t(`element.print`)}
              </div>
            ),
          },
        ]}
        onClick={(item) => {
          switch (item.id) {
            case "reload":
              refresh();
              break;
            case "save":
              save();
              break;
            case "print":
              print();
              break;
          }
        }}
      />
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <DataPrint ref={slipRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderPrint()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(DataField);
