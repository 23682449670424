/*================================================*\
   __                 ____  _
  |   \  ___  __ __  |  _ || |  _ _   ___
  | |) |/ -_) \ V /  |  __|| | | | | / -_)
  |___/ \___|  \_/   |_|   |_| \_,_| \___| o o o   
      
\*================================================*/

// export const API_URL = "http://trn-backend.wintech1995.com";
// export const BASE_URL = "http://trn-backend.wintech1995.com";

export const API_URL = "https://trn-backend.com";
export const BASE_URL = "https://trn-backend.com";
