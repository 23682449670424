import { SET_PERM } from '../types';

const initialState = {
    menu: [],
    perm: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PERM:
            return action.payload;
        default:
            return state;
    }
};
