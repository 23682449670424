import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-pro-react/components/tasksStyle.js";

const useStyles = makeStyles(styles);

export default function TaskMenu({ data, checkValue, setValue }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([...checkValue]);
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log(newChecked)
    setChecked(newChecked);
    setValue(newChecked);
  };
  const tableCellClasses = classnames(classes.tableCell);
  return (
    <GridContainer>
      {data &&
        data.map((item, i) => (
          <GridItem key={item.main.code} xs={12} style={{ padding: "10px" }}>
            <GridContainer>
              <GridItem xs={3}>
                <h5>{item.main.name} :</h5>
              </GridItem>
              <GridItem xs={9}>
                <FormGroup row style={{ margin: "5px" }}>
                  {item.data &&
                    item.data.map((value) => (
                      <FormControlLabel
                        key={value.code}
                        control={
                          <Checkbox
                            checked={checked.indexOf(value.code) !== -1}
                            tabIndex={-1}
                            onClick={() => handleToggle(value.code)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.root,
                            }}
                          />
                        }
                        label={value.name}
                      />
                    ))}
                </FormGroup>
              </GridItem>
            </GridContainer>
            <Divider />
          </GridItem>
        ))}
    </GridContainer>
  );
}

TaskMenu.propTypes = {
  tasksIndexes: PropTypes.arrayOf(PropTypes.number),
  tasks: PropTypes.arrayOf(PropTypes.node),
  checkedIndexes: PropTypes.array,
};
