/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CloseIcon from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  wallet_type: "",
  member_code: "",
  wallet_type: "",
  amount: "",
  remark: "",
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "desc",
  status: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function MemberWallet({ t }) {
  const mod = "memberwallet";
  const prefix = "attr";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.code", name: t(`${prefix}.code`), order: "desc" },
    { code: "tb.wallet_type", name: `Type` },
    { code: "tb.remark", name: `หมายเหตุ` },
    { code: "tb.amount", name: `จำนวนเงิน` },
    { code: "tb.wallet_before", name: `ก่อนเติม` },
    { code: "tb.wallet_after", name: `หลังเติม` },
    { code: "tb.enable", name: `สถานะ` },
  ];
  const alignValue = [0, 1, 3, 4, 5, 6, 7];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [balance, setBalance] = useState("0.00");

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      status: data.status,
      member: id,
    }).then((result) => {
      if (result.status === "OK") {
        setBalance(result.member.balance);
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.code,
              item.wallet_type,
              item.remark,
              item.amount,
              item.wallet_before,
              item.wallet_after,
              displayStatus(item.enable),
              item.date_create,
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const displayStatus = (inp) => {
    if (inp === "Y")
      return (
        <code className={`color-success`}>
          <i className="fad fa-check" /> ปกติ
        </code>
      );
    else
      return (
        <code className={`color-danger`}>
          <i className="fad fa-times" /> ยกเลิก
        </code>
      );
  };

  const checkEmpty = () => {
    let result = true;

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setModal(false);
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              code: id,
              amount: attr.amount,
              data: {
                member_code: id,
                wallet_type: attr.wallet_type,
                amount: attr.amount,
                remark: attr.remark,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                view(find);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <div>
        <GridContainer className="viewlist">
          <GridItem xs={12}>
            <h4>Wallet : {balance} ฿</h4>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12}>
            <Table
              striped
              hover
              tableHead={[
                renderHeader(header[0]),
                renderHeader(header[1]),
                renderHeader(header[2]),
                renderHeader(header[3]),
                renderHeader(header[4]),
                renderHeader(header[5]),
                renderHeader(header[6]),
                <div>
                  <Button
                    round
                    color="success"
                    className={classes.actionButton}
                    onClick={() => {
                      setAttr({ ...initAttr, wallet_type: "DEPOSIT" });
                      setModal(true);
                    }}
                  >
                    <AddIcon />
                  </Button>
                  <Button
                    round
                    color="danger"
                    className={classes.actionButton}
                    onClick={() => {
                      setAttr({ ...initAttr, wallet_type: "WITHDRAW" });
                      setModal(true);
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                </div>,
              ]}
              tableData={record}
              customHeadClassesForCells={alignValue}
              customHeadCellClasses={alignItem}
              customClassesForCells={alignValue}
              customCellClasses={alignItem}
              coloredColls={[3, 4, 5]}
              colorsColls={["primary", "danger", "success"]}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}></InputLabel>
              <Select
                value={find.perPage}
                onChange={(e) => {
                  view({
                    ...find,
                    page: 1,
                    perPage: e.target.value,
                    page: 1,
                  });
                  setFind({
                    ...find,
                    page: 1,
                    perPage: e.target.value,
                    page: 1,
                  });
                }}
              >
                {cboPerPage &&
                  cboPerPage.map((item) => (
                    <MenuItem
                      key={item.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.id}
                    >
                      {t(item.name)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
            <Pagination
              style={{ float: "right", marginTop: "20px" }}
              count={pageTotal}
              showFirstButton
              showLastButton
              page={pageNo}
              onChange={(e, p) => {
                view({ ...find, page: p });
                setFind({ ...find, page: p });
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12}>
            <CustomInput
              labelText="จำนวนเงิน"
              id="amount"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.amount,
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <CustomInput
              labelText="หมายเหตุ"
              id="remark"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.remark,
              }}
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderModal = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modal}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {attr.wallet_type === "DEPOSIT"
              ? "เพิ่ม Wallet"
              : attr.wallet_type === "WITHDRAW"
              ? "ลด Wallet"
              : ""}
          </h4>
        </DialogTitle>
        <DialogContent id="modal-edit" className={classes.modalBody}>
          {renderInfo()}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={add} color="success">
            <SaveIcon /> {t(`element.save`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderLoading()}
      {renderModal()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(MemberWallet);
