/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Badge from "components/Badge/Badge.js";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import PosSlip from "./PosSlip";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  product_id: "",
  product_code: "",
  product_name: "",
  quantity: "",
  quantity_return: "",
};

const initFind = {
  search: "",
  date_start: Func.dateAdd(-30),
  date_stop: new Date(),
  order_by: "so.id",
  order_sort: "desc",
  status: "ALL",
  customer: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function PosReturn({ t, member }) {
  const mod = "posreturn";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "so.id", name: t(`${prefix}.id`), order: "desc" },
    { code: "pd.filepic", name: t(`${prefix}.image`) },
    { code: "pd.id", name: t(`${prefix}.product_id`) },
    { code: "tb.product_code", name: t(`${prefix}.product_name`) },
    { code: "pd.unit_code", name: t(`${prefix}.unit`) },
    { code: "tb.price", name: t(`${prefix}.price`) },
    { code: "tb.quantity", name: t(`${prefix}.quantity`) },
    { code: "tb.amount", name: t(`${prefix}.amount`) },
    { code: "tb.quantity_return", name: t(`${prefix}.return`) },
  ];
  const alignValue = [0, 1, 2, 4, 5, 6, 7, 8];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [cboStatus, setCboStatus] = useState([]);
  const [cboTransfer, setCboTransfer] = useState([]);
  const [cboBranch, setCboBranch] = useState([]);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [slipList, setSlipList] = useState([]);
  const [slip, setSlip] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalNumpad, setModalNumpad] = useState(false);
  const slipRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboTransfer(result.transfer);
        setCboBranch(result.branch);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const view = (data) => {
    if (member.code > 0) {
      setLoading(true);
      PostData(mod, "view", {
        token: app.token,
        lang: app.lang,
        search: data.search,
        page: data.page,
        per_page: data.perPage,
        orderby: data.order_by,
        asc: data.order_sort,
        start: Func.dateFormat(data.date_start),
        stop: Func.dateFormat(data.date_stop),
        status: data.status,
        member: member.code,
      }).then((result) => {
        if (result.status === "OK") {
          setRecord(result.data);

          setPageTotal(result.total_pages);
          setPageNo(result.page);
        }
        setLoading(false);
      });
    }
  };

  const runderNumpad = () => {
    const cssValue = {
      padding: "10px 20px",
      fontSize: "2em",
      background: "#f2f2f2",
      textAlign: "right",
      color: "#666",
      marginBottom: "10px",
    };

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modalNumpad}
        keepMounted
        onClose={() => setModalNumpad(false)}
        aria-labelledby="modal-numpad-title"
        aria-describedby="modal-numpad-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalNumpad(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {attr.product_name} ({attr.quantity} {attr.unit_name})
          </h4>
        </DialogTitle>
        <DialogContent id="modal-numpad-description">
          <div style={{ minWidth: "300px", paddingTop: "20px" }}>
            <div style={{ overflow: "hidden" }}>
              <div>
                <form>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: attr.quantity_return,
                      autoComplete: "off",
                      placeholder: "0",
                      style: cssValue,
                      onChange: (e) =>
                        setAttr({ ...attr, quantity_return: e.target.value }),
                    }}
                    alignRight
                  />
                </form>
              </div>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="25%">{btnNumpad(7)}</td>
                    <td width="25%">{btnNumpad(8)}</td>
                    <td width="25%">{btnNumpad(9)}</td>
                  </tr>
                  <tr>
                    <td>{btnNumpad(4)}</td>
                    <td>{btnNumpad(5)}</td>
                    <td>{btnNumpad(6)}</td>
                  </tr>
                  <tr>
                    <td>{btnNumpad(1)}</td>
                    <td>{btnNumpad(2)}</td>
                    <td>{btnNumpad(3)}</td>
                  </tr>
                  <tr>
                    <td>{btnNumpad(".")}</td>
                    <td>{btnNumpad(0)}</td>
                    <td>
                      <Button
                        simple
                        color="primary"
                        className={
                          classes.actionButton + " " + classes.actionButtonRound
                        }
                        block
                        size={"lg"}
                        style={{ height: "80px", fontSize: "20px" }}
                        onClick={() => addNumpad("-")}
                      >
                        <i className="fas fa-backspace"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <Button
                        simple
                        color="danger"
                        className={
                          classes.actionButton + " " + classes.actionButtonRound
                        }
                        block
                        size={"lg"}
                        style={{ height: "80px", fontSize: "20px" }}
                        onClick={() => editReturn()}
                      >
                        {t(`${prefix}.return`)}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const btnNumpad = (num) => {
    return (
      <Button
        simple
        color="primary"
        className={classes.actionButton + " " + classes.actionButtonRound}
        block
        size={"lg"}
        style={{ height: "80px", fontSize: "20px" }}
        onClick={() => addNumpad(num)}
      >
        {num}
      </Button>
    );
  };

  const addNumpad = (num) => {
    let value = attr.quantity_return;

    if (num == "-") {
      value = value.substr(0, value.length - 1);
    } else {
      value += "" + num;
    }

    setAttr({ ...attr, quantity_return: value });
  };

  const displayTotal = (number, cls) => {
    if (number > 0)
      return <div className={cls}>{Func.numberDisplay(number)}</div>;
    else return "-";
  };

  const editReturn = () => {
    if (attr.quantity_return <= 0 || attr.quantity_return > attr.quantity) {
      showNotify(t("ปริมาณไม่ถูกต้อง"), "danger");
    } else {
      setLoading(true);
      setModalNumpad(false);

      PostData(mod, "editreturn", {
        token: app.token,
        lang: app.lang,
        code: attr.code,
        member: member.code,
        quantity_return: attr.quantity_return,
      }).then((result) => {
        setLoading(false);

        if (result.status === "OK") {
          view(find);
        } else {
          // alert(t("alert.editerror"));
        }
      });
    }
  };

  const print = () => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      member: member.code,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);

        handlePrint();
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <Button
        color="danger"
        className={classes.actionButton}
        size="sm"
        onClick={() => {
          setAttr({
            ...item,
            quantity_return: "",
          });
          setModalNumpad(true);
        }}
      >
        {t(`${prefix}.return`)} <CancelIcon />
      </Button>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.customer}
                      onChange={(e) => {
                        view({ ...find, customer: e.target.value, page: 1 });
                        setFind({ ...find, customer: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.member`)}
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="MEMBER"
                      >
                        {member.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  renderHeader(header[6]),
                  renderHeader(header[7]),
                  renderHeader(header[8]),
                  <Button
                    color="info"
                    className={classes.actionButton}
                    size="sm"
                    onClick={() => print()}
                  >
                    {t(`element.print`)} <PrintIcon />
                  </Button>,
                ]}
                tableData={record.map((item) => {
                  return [
                    item.so_id,
                    <img src={item.filepic} width="30" />,
                    item.product_id,
                    item.product_name,
                    item.unit_name,
                    item.price,
                    displayTotal(item.quantity, "color-success"),
                    item.amount,
                    displayTotal(item.quantity_return, "color-danger"),
                    btn(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}></InputLabel>
                <Select
                  value={find.perPage}
                  onChange={(e) => {
                    view({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                    setFind({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                  }}
                >
                  {cboPerPage &&
                    cboPerPage.map((item) => (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {t(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                count={pageTotal}
                showFirstButton
                showLastButton
                page={pageNo}
                onChange={(e, p) => {
                  view({ ...find, page: p });
                  setFind({ ...find, page: p });
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <PosSlip ref={slipRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {runderNumpad()}
      {renderPrint()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(PosReturn);
