import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Line, Bar } from "react-chartjs-2";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ReplyAllIcon from "@material-ui/icons/ReplyAll";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

// core components
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

const useStyles = makeStyles(styles);

const initChart = {
  label: [],
  line_1: [],
  line_2: [],
};

function DashboardAdmin({ t }) {
  const mod = "dashboard";
  const app = useSelector((state) => state.appState);
  const classes = useStyles();
  const [chartLabel, setChartLabel] = useState([]);
  const [chartSale, setChartSale] = useState(initChart);
  const [chartProduct, setChartProduct] = useState([]);
  const [saleOrder, setSaleOrder] = useState([]);
  const [saleWaiting, setSaleWaiting] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [menu, setMenu] = useState([]);
  const [summary, setSummary] = useState({
    member: "0",
    quantity: "0",
    sale: "0",
    shipping: "0",
  });

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {}).then((result) => {
      if (result.status === "OK") {
        let list = [];

        setSaleOrder(result.saleorder);
        setSaleWaiting(result.waitingpay);
        setShipping(result.shipping);
        setSummary(result.summary);
        setMenu(result.menu);

        setChartLabel(result.chartproduct.label);

        /* ChartSale */
        setChartSale({
          label: result.chartsale.label,
          line_1: result.chartsale.line_total,
          line_2: result.chartsale.line_avg,
        });

        /* ChartProduct */
        result.chartproduct.data.map((item, i) => {
          list.push({
            label: `${item.title.product_name} (${item.title.unit_name})`,
            data: item.list,
            fill: true,
            backgroundColor: getColor(i, "0.2"),
            borderColor: getColor(i, "1.0"),
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          });
        });
        setChartProduct(list);
      } else {
        Func.exitApp();
      }
    });
  };

  const getColor = (num, opacity) => {
    let color = [
      "rgba(255, 0, 0, {opacity})",
      "rgba(0, 255, 0, {opacity})",
      "rgba(0, 255, 255, {opacity})",
      "rgba(255, 128, 64, {opacity})",
      "rgba(255, 0, 255, {opacity})",
      "rgba(0, 64, 128, {opacity})",
      "rgba(128, 0, 255, {opacity})",
    ];

    num = num < color.length ? num : 0;

    return color[num].split("{opacity}").join(opacity);
  };

  const renderButton = () => {
    return (
      <GridContainer>
        {menu.map((item) => (
          <GridItem xs={12} sm={4} md={3} lg={2} key={item.id}>
            <NavLink to={`/admin/${item.link}`}>
              <Button
                color={item.color}
                block
                style={{ justifyContent: "left" }}
              >
                <i className="fas fa-grip-vertical" /> {t(`menu.${item.id}`)}
              </Button>
            </NavLink>
          </GridItem>
        ))}
      </GridContainer>
    );
  };

  const renderStatus = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <AssignmentIndIcon />
              </CardIcon>
              <p className={classes.cardCategory}>สมาชิกใหม่</p>
              <h3 className={classes.cardTitle} style={{ fontSize: "1.825em" }}>
                {Func.numberDisplay(summary.member, 0)}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <ReplyAllIcon />
                <a href="/admin/member">ดูทั้งหมด</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <MonetizationOnIcon />
              </CardIcon>
              <p className={classes.cardCategory}>ยอดขาย (฿)</p>
              <h3 className={classes.cardTitle} style={{ fontSize: "1.825em" }}>
                {Func.numberDisplay(summary.sale)}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <ReplyAllIcon />
                <a href="/admin/customer">ดูทั้งหมด</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <LocalAtmIcon />
              </CardIcon>
              <p className={classes.cardCategory}>ปริมาณขาย (kg)</p>
              <h3 className={classes.cardTitle} style={{ fontSize: "1.825em" }}>
                {Func.numberDisplay(summary.quantity)}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <ReplyAllIcon />
                <a href="/admin/customer">ดูทั้งหมด</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <LocalShippingIcon />
              </CardIcon>
              <p className={classes.cardCategory}>ปริมาณจัดส่ง</p>
              <h3 className={classes.cardTitle} style={{ fontSize: "1.825em" }}>
                {Func.numberDisplay(summary.shipping, 0)}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <ReplyAllIcon />
                <a href="/admin/customer">ดูทั้งหมด</a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderTable = () => {
    const displayStatus = (item) => {
      return (
        <code className={`color-${item.color}`}>
          <i className={item.icon} /> {item.status_name}
        </code>
      );
    };

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <LocalAtmIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                ค้างจ่าย <small>- 10 รายการ</small>
              </h4>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="info"
                tableHead={["ลูกค้า", "วันครบกำหนด", "จำนวนเงิน"]}
                tableData={saleWaiting.map((item) => {
                  return [
                    <NavLink to={`/admin/customer/${item.code}`}>
                      <Button color="primary" simple>
                        {item.name}
                      </Button>
                    </NavLink>,
                    Func.dateDisplay(item.date_due),
                    Func.numberDisplay(item.order_balance),
                  ];
                })}
                customHeadClassesForCells={[1, 2]}
                customHeadCellClasses={[classes.center, classes.right]}
                customClassesForCells={[1, 2]}
                customCellClasses={[classes.center, classes.right]}
                coloredColls={[2]}
                colorsColls={["danger"]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} style={{ display: "none" }}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <LocalAtmIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                การขาย <small>- 10 รายการล่าสุด</small>
              </h4>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="info"
                tableHead={["รหัส", "ลูกค้า", "วันที่", "จำนวนเงิน", "สถานะ"]}
                tableData={saleOrder.map((item) => {
                  return [
                    item.id,
                    item.member_name,
                    Func.dateTimeDisplay(item.date_create),
                    Func.numberDisplay(item.nettotal),
                    displayStatus(item),
                  ];
                })}
                customHeadClassesForCells={[0, 2, 3, 4]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right,
                  classes.center,
                ]}
                customClassesForCells={[0, 2, 3, 4]}
                customCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right,
                  classes.center,
                ]}
                coloredColls={[3]}
                colorsColls={["primary"]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning" icon>
              <CardIcon color="warning">
                <LocalShippingIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                จัดส่ง <small>- 10 รายการล่าสุด</small>
              </h4>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="info"
                tableHead={["ลูกค้า", "สาขา", "คลัง", "กำหนดส่ง", "สถานะ"]}
                tableData={shipping.map((item) => {
                  return [
                    <NavLink to={`/admin/shipping/${item.code}`}>
                      <Button color="primary" simple>
                        {item.member_name}
                      </Button>
                    </NavLink>,
                    item.branch_name,
                    item.branch_warehouse,
                    Func.dateTimeDisplay(item.date_shipping),
                    displayStatus(item),
                  ];
                })}
                customHeadClassesForCells={[3, 4]}
                customHeadCellClasses={[classes.center, classes.center]}
                customClassesForCells={[3, 4]}
                customCellClasses={[classes.center, classes.center]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderChart = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                รายได้ <small>- 30 วันหลังสุด</small>
              </h4>
            </CardHeader>
            <CardBody>
              <Line
                height={100}
                options={{
                  responsive: true,
                  interaction: {
                    intersect: false,
                  },
                  scales: {
                    x: {
                      display: true,
                      title: {
                        display: true,
                      },
                    },
                    y: {
                      display: true,
                      title: {
                        display: true,
                      },
                      suggestedMin: 0,
                    },
                  },
                }}
                data={{
                  labels: chartSale.label,
                  datasets: [
                    {
                      label: "# of Total",
                      data: chartSale.line_1,
                      fill: true,
                      backgroundColor: "rgba(255, 99, 132, 0.2)",
                      borderColor: "rgba(196, 0, 0, 1.0)",
                      cubicInterpolationMode: "monotone",
                      tension: 0.4,
                    },
                    {
                      label: "# of Average",
                      data: chartSale.line_2,
                      fill: false,
                      backgroundColor: "rgb(128, 128, 128, 0.2)",
                      borderColor: "rgba(128, 128, 128, 1.0)",
                      cubicInterpolationMode: "monotone",
                      tension: 0.4,
                    },
                  ],
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                ปริมาณสินค้า <small>- 7 วันหลังสุด</small>
              </h4>
            </CardHeader>
            <CardBody>
              <Line
                height={100}
                options={{
                  responsive: true,
                  interaction: {
                    intersect: false,
                  },
                  scales: {
                    x: {
                      display: true,
                      title: {
                        display: true,
                      },
                    },
                    y: {
                      display: true,
                      title: {
                        display: true,
                      },
                      suggestedMin: 0,
                    },
                  },
                }}
                data={{
                  labels: chartLabel,
                  datasets: chartProduct,
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  return (
    <div>
      {renderStatus()}
      {renderButton()}
      {renderChart()}
      {renderTable()}
    </div>
  );
}

export default withNamespaces()(DashboardAdmin);
