import { SET_USER, CLEAR_USER } from '../types';

const initialState = {
  code: '',
  name: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    case CLEAR_USER:
      return action.payload;
    default:
      return state;
  }
};
