/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import DateRangeIcon from "@material-ui/icons/DateRange";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import i18n from "components/Translation/Translate.js";
import Button from "components/CustomButtons/Button.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import { API_URL } from "services/Define";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initFind = {
    search: "",
    date_start: Func.dateAdd(-30),
    date_stop: new Date(),
    order_by: "tb.id",
    order_sort: "desc",
    status: "ALL",
    branch: "ALL",
    member: "ALL",
    member_name: "",
    shift_code: "ALL",
    gen_receipt: "ALL",
    round: "",
    page: 1,
    perPage: 10,
    pageTotal: 0,
};

/* ========== ZONE Function ========== */
function RpSystemBill({ t }) {
    const mod = "rp_systembill";
    const prefix = "bill";
    const classes = useStyles();

    const initHeader = [
        { code: "tb.date_create", name: t(`${prefix}.date_record`) },
        { code: "tb.qty", name: 'Qty', sort: false },
        { code: "tb.amount", name: t(`${prefix}.amount`) },
        { code: "tb.vat", name: t(`${prefix}.vat`) },
        { code: "tb.total", name: t(`${prefix}.total`) },
        { code: "tb.shipping_price", name: t(`${prefix}.shipping_price`) },
        { code: "tb.nettotal", name: t(`${prefix}.nettotal`) },
    ];
    const alignValue = [0, 1, 2, 3, 4, 5, 6];
    const alignItem = [
        classes.center,
        classes.right,
        classes.right,
        classes.right,
        classes.right,
        classes.right,
        classes.right,
    ];

    const app = useSelector((state) => state.appState);

    const [record, setRecord] = useState([]);
    const [find, setFind] = useState(initFind);
    const [header, setHeader] = useState(initHeader);
    const [loading, setLoading] = useState(false);
    const [cboStatus, setCboStatus] = useState([]);
    const [cboBranch, setCboBranch] = useState([]);

    useEffect(() => {
        i18n.changeLanguage(app.lang);
        init();
    }, []);

    const init = () => {
        PostData(mod, "init", {
            token: app.token,
            lang: app.lang,
        }).then((result) => {
            if (result.status === "OK") {
                setCboStatus(result.stat);
                setCboBranch(result.branch);

                view(find);
            } else {
                Func.exitApp();
            }
        });
    };

    const view = (data) => {
        setFind(data);
        setLoading(true);
        setRecord([]);

        PostData(mod, "view", {
            token: app.token,
            lang: app.lang,
            start: Func.dateFormat(data.date_start),
            stop: Func.dateFormat(data.date_stop),
            branch: data.branch,
        }).then((result) => {
            if (result.status === "OK") {
                result.data.map((item, i) => {
                    setRecord((record) => [
                        ...record,
                        [
                            btnOpen(item),
                            Func.numberDisplay(item.cnt, 0),
                            Func.numberDisplay(item.amount),
                            Func.numberDisplay(item.vat),
                            Func.numberDisplay(item.total),
                            Func.numberDisplay(item.shipping_price),
                            Func.numberDisplay(item.nettotal),
                        ],
                    ]);
                });

                if (result.data.length > 0)
                    setRecord((record) => [
                        ...record,
                        [
                            <h5>{t(`${prefix}.total`)}</h5>,
                            <h5>{Func.numberDisplay(result.sum.cnt, 0)}</h5>,
                            <h5>{Func.numberDisplay(result.sum.amount)}</h5>,
                            <h5>{Func.numberDisplay(result.sum.vat)}</h5>,
                            <h5>{Func.numberDisplay(result.sum.total)}</h5>,
                            <h5>{Func.numberDisplay(result.sum.shipping_price)}</h5>,
                            <h5>{Func.numberDisplay(result.sum.nettotal)}</h5>,
                        ],
                    ]);
            }
            setLoading(false);
        });
    };

    const printSum = () => {
        let param = '';
        param += 'date_start=' + Func.dateFormat(find.date_start);
        param += '&date_stop=' + Func.dateFormat(find.date_stop);
        param += `&branch=${find.branch}`;

        window.open(`${API_URL}/app/print/systembill.pdf?${param}`, "_blank");
    };

    const printDetail = (item) => {
        let param = `date=${item.date_record}`;
        param += `&branch=${find.branch}`;

        window.open(`${API_URL}/app/print/systembill_detail.pdf?${param}`, "_blank");
    };

    const btnOpen = (item) => {
        return (
            <Button color="primary" simple onClick={() => printDetail(item)}>
                {Func.dateDisplay(item.date_record)}
            </Button>
        );
    };

    const renderHeader = (data) => {
        let icon = <i className="fas fa-sort"></i>;
        if (data.order === "desc") {
            icon = <i className="fad fa-sort-up"></i>;
        } else if (data.order === "asc") {
            icon = <i className="fad fa-sort-down"></i>;
        } else {
            icon = <i className="fad fa-sort"></i>;
        }

        if (data.sort === undefined || data.sort)
            return (
                <div
                    onClick={() => {
                        let tmp = [];
                        header.map((item, i) => {
                            if (data.code === item.code) {
                                const ordersort = item.order === "asc" ? "desc" : "asc";
                                view({ ...find, order_sort: ordersort, order_by: data.code });
                                setFind({
                                    ...find,
                                    order_sort: ordersort,
                                    order_by: data.code,
                                });
                                tmp[i] = {
                                    code: item.code,
                                    name: item.name,
                                    order: ordersort,
                                    sort: item.sort,
                                };
                            } else {
                                tmp[i] = {
                                    code: item.code,
                                    name: item.name,
                                    order: "",
                                    sort: item.sort,
                                };
                            }
                        });

                        setHeader(tmp);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {data.name} {icon}
                </div>
            );
        else return <div>{data.name}</div>;
    };

    const renderViewList = () => {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card className={classes.boxPrimary}>
                        <CardHeader color="primary" icon className={classes.boxHead}>
                            <h4 className={classes.boxTitle}>
                                <i className="fas fa-file-alt color-primary" />
                                {t("element.report")}
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className="viewlist">
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/yyyy"
                                            closeOnSelect
                                            value={find.date_start}
                                            onChange={(value) => view({ ...find, date_start: value })}
                                            className={classes.center}
                                            renderInput={(props) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        InputProps={{
                                                            ...props,
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    style={{ marginBottom: "4px" }}
                                                                >
                                                                    {t("element.start")} :
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: <DateRangeIcon />,
                                                            autoComplete: "off",
                                                            readOnly: true,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/yyyy"
                                            closeOnSelect
                                            value={find.date_stop}
                                            onChange={(value) => view({ ...find, date_stop: value })}
                                            className={classes.center}
                                            renderInput={(props) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        InputProps={{
                                                            ...props,
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    style={{ marginBottom: "4px" }}
                                                                >
                                                                    {t("element.stop")} :
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: <DateRangeIcon />,
                                                            autoComplete: "off",
                                                            readOnly: true,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={find.branch}
                                            onChange={(e) => {
                                                view({ ...find, branch: e.target.value, page: 1 });
                                            }}
                                        >
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value="ALL"
                                            >
                                                {t(`${prefix}.branch`)}
                                            </MenuItem>
                                            {cboBranch.map((item) => (
                                                <MenuItem
                                                    key={item.code}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <Button color="info" onClick={() => printSum()}>
                                        <i className="fas fa-print fa-fw" /> พิมพ์บิลรวม
                                    </Button>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12}>
                                    <Table
                                        striped
                                        hover
                                        tableHead={[
                                            renderHeader(header[0]),
                                            renderHeader(header[1]),
                                            renderHeader(header[2]),
                                            renderHeader(header[3]),
                                            renderHeader(header[4]),
                                            renderHeader(header[5]),
                                            renderHeader(header[6]),
                                        ]}
                                        tableData={record}
                                        customHeadClassesForCells={alignValue}
                                        customHeadCellClasses={alignItem}
                                        customClassesForCells={alignValue}
                                        customCellClasses={alignItem}
                                        coloredColls={[4, 5, 6]}
                                        colorsColls={["primary", "success", "danger"]}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    const renderLoading = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    /* ========== ZONE Render ========== */
    return (
        <div>
            {renderViewList()}
            {renderLoading()}
        </div>
    );
}

export default withNamespaces()(RpSystemBill);
