import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Lock from "@material-ui/icons/Lock";
import Notifications from "@material-ui/icons/Notifications";
import LanguageIcon from "@material-ui/icons/Language";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";

// core components
import Button from "components/CustomButtons/Button.js";
import i18n from "components/Translation/Translate.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { setLanguage } from "redux/actions/AppStateAction";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import enFlag from "assets/img/flags/GB.png";
import thFlag from "assets/img/flags/TH.png";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const dispatch = useDispatch();

  const app = useSelector((state) => state.appState);
  const { notifyOrder, notifyShipping, notifyMove, notifyBalance } = props;
  const [openNotifyOrder, setOpenNotifyOrder] = useState(null);
  const [openNotifyShipping, setOpenNotifyShipping] = useState(null);
  const [openNotifyMove, setOpenNotifyMove] = useState(null);
  const [openProfile, setOpenProfile] = useState(null);

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const classes = useStyles();
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: false,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: false,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const logout = () => {
    PostData("authen", "logout", {}).then((result) => {
      if (result.status === "OK") {
        window.location.replace("/auth/login");
      }
    });
  };

  const renderNotifyOrder = () => {
    const displayTopic = (item) => {
      return (
        <span>
          <i className="far fa-user" /> {item.name}{" "}
          <code className="color-danger">ค้างจ่าย {item.cnt}</code>
        </span>
      );
    };

    const clickNotify = (event) => {
      if (openNotifyOrder && openNotifyOrder.contains(event.target)) {
        setOpenNotifyOrder(null);
      } else {
        setOpenNotifyOrder(event.currentTarget);
      }
    };

    const closeNotify = () => {
      setOpenNotifyOrder(null);
    };

    return (
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotifyOrder ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={clickNotify}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <Notifications
            className={classes.headerLinksSvg + " " + classes.links}
          />
          <span
            className={classes.notifications}
            style={{ background: "red", padding: "0 2px", color: "white" }}
          >
            {notifyOrder.count > 20 ? "20+" : notifyOrder.count}
          </span>
          <Hidden mdUp implementation="css">
            <span onClick={clickNotify} className={classes.linkText}>
              {i18n.t(`element.notification`)}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotifyOrder)}
          anchorEl={openNotifyOrder}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotifyOrder,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="deposit-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={closeNotify}>
                  <MenuList role="menu">
                    {notifyOrder.data.map((item) => (
                      <MenuItem
                        key={item.code}
                        onClick={() => {
                          window.location.replace(
                            `/admin/customer/${item.code}`
                          );
                        }}
                        className={dropdownItem}
                      >
                        {displayTopic(item)}
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={() => {
                        window.location.replace(`/admin/customer`);
                      }}
                      className={dropdownItem}
                    >
                      <div className="center">
                        <i className="fas fa-list-ul"></i>{" "}
                        {i18n.t(`element.showall`)}
                      </div>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  const renderNotifyShipping = () => {
    const displayTopic = (item) => {
      return (
        <span>
          <i className="far fa-user" /> {item.name}{" "}
          <code className="color-danger">ค้างส่ง {item.cnt}</code>
        </span>
      );
    };

    const clickNotify = (event) => {
      if (openNotifyShipping && openNotifyShipping.contains(event.target)) {
        setOpenNotifyShipping(null);
      } else {
        setOpenNotifyShipping(event.currentTarget);
      }
    };

    const closeNotify = () => {
      setOpenNotifyShipping(null);
    };

    return (
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotifyShipping ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={clickNotify}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <LocalShippingIcon
            className={classes.headerLinksSvg + " " + classes.links}
          />
          <span
            className={classes.notifications}
            style={{
              background: "orangered",
              padding: "0 2px",
              color: "white",
            }}
          >
            {notifyShipping.count > 20 ? "20+" : notifyShipping.count}
          </span>
          <Hidden mdUp implementation="css">
            <span onClick={clickNotify} className={classes.linkText}>
              {i18n.t(`element.notification`)}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotifyShipping)}
          anchorEl={openNotifyShipping}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotifyShipping,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="deposit-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={closeNotify}>
                  <MenuList role="menu">
                    {notifyShipping.data.map((item) => (
                      <MenuItem
                        key={item.code}
                        onClick={() => {
                          window.location.replace(
                            `/admin/customer/${item.code}`
                          );
                        }}
                        className={dropdownItem}
                      >
                        {displayTopic(item)}
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={() => {
                        window.location.replace(`/admin/customer`);
                      }}
                      className={dropdownItem}
                    >
                      <div className="center">
                        <i className="fas fa-list-ul"></i>{" "}
                        {i18n.t(`element.showall`)}
                      </div>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  const renderNotifyMove = () => {
    const displayTopic = (item) => {
      return (
        <span>
          {item.id} <code className="color-success">{item.remark}</code>
        </span>
      );
    };

    const clickNotify = (event) => {
      if (openNotifyMove && openNotifyMove.contains(event.target)) {
        setOpenNotifyMove(null);
      } else {
        setOpenNotifyMove(event.currentTarget);
      }
    };

    const closeNotify = () => {
      setOpenNotifyMove(null);
    };

    const moveReceive = (item) => {
      PostData("notification", "receive", {
        token: app.token,
        lang: app.lang,
        code: item.code,
        data: {
          move_code: item.code,
          remark: "",
        },
      }).then((result) => {
        if (result.status === "OK") {
          window.location.replace(`/admin/movereceive/${result.code}`);
        }
      });
    };

    return (
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotifyMove ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={clickNotify}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <LocalShippingIcon
            className={classes.headerLinksSvg + " " + classes.links}
          />
          <span
            className={classes.notifications}
            style={{
              background: "darkgreen",
              padding: "0 2px",
              color: "white",
            }}
          >
            {notifyMove.count > 20 ? "20+" : notifyMove.count}
          </span>
          <Hidden mdUp implementation="css">
            <span onClick={clickNotify} className={classes.linkText}>
              {i18n.t(`element.notification`)}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openNotifyMove)}
          anchorEl={openNotifyMove}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotifyMove,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="deposit-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={closeNotify}>
                  <MenuList role="menu">
                    {notifyMove.data.map((item) => (
                      <MenuItem
                        key={item.code}
                        onClick={() => moveReceive(item)}
                        className={dropdownItem}
                      >
                        {displayTopic(item)}
                      </MenuItem>
                    ))}
                    <MenuItem
                      onClick={() => {
                        window.location.replace(`/admin/movestore`);
                      }}
                      className={dropdownItem}
                    >
                      <div className="center">
                        <i className="fas fa-list-ul"></i>{" "}
                        {i18n.t(`element.showall`)}
                      </div>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  const renderTranslation = () => {
    return (
      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Language"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={classes.buttonLink}
          muiClasses={{
            label: "",
          }}
        >
          <LanguageIcon
            className={classes.headerLinksSvg + " " + classes.links}
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {i18n.t(`element.changelanguage`)}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={() => changeLanguage("th")}
                      className={dropdownItem}
                    >
                      <img src={thFlag} alt="..." /> ภาษาไทย
                    </MenuItem>
                    <MenuItem
                      onClick={() => changeLanguage("en")}
                      className={dropdownItem}
                    >
                      <img src={enFlag} alt="..." /> English
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  };

  const renderLogout = () => {
    return (
      <Button
        color="transparent"
        simple
        aria-label="Lock out"
        justIcon
        className={classes.buttonLink}
        muiClasses={{
          label: "",
        }}
        onClick={logout}
      >
        <Lock className={classes.headerLinksSvg + " " + classes.links} />
        <Hidden mdUp implementation="css">
          <span className={classes.linkText}>{i18n.t(`element.lockout`)}</span>
        </Hidden>
      </Button>
    );
  };

  return (
    <div className={wrapper}>
      {notifyBalance === "NONE" ? null : (
        <Button color="white" justIcon round>
          <i className="fas fa-cash-register" />
        </Button>
      )}
      {notifyBalance === "NONE" ? null : (
        <CustomInput
          size={"lg"}
          formControlProps={{
            className: classes.top + " " + classes.search,
          }}
          inputProps={{
            placeholder: "",
            inputProps: {
              "aria-label": "Search",
              className: classes.searchInput,
            },
            value: Func.numberDisplay(notifyBalance, 2) + " ฿",
            disabled: true,
          }}
          alignCenter
        />
      )}
      {notifyOrder.count > 0 ? renderNotifyOrder() : null}
      {notifyShipping.count > 0 ? renderNotifyShipping() : null}
      {notifyMove.count > 0 ? renderNotifyMove() : null}
      {renderTranslation()}
      {renderLogout()}
    </div>
  );
}
