/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import SlipShipping from "views/Print/SlipShipping";

import styles from "assets/jss/main-style.js";

function StepIcon(props) {
  const { active, completed } = props;
  const icons_complete = {
    1: <AddCircleIcon color="primary" />,
    2: <LocalShippingIcon color="primary" />,
    3: <MonetizationOnIcon color="primary" />,
    4: <CancelIcon color="error" />,
  };
  const icons = {
    1: <AddCircleIcon color="disabled" />,
    2: <LocalShippingIcon color="disabled" />,
    3: <MonetizationOnIcon color="disabled" />,
    4: <CancelIcon color="disabled" />,
  };
  return (
    <div>
      {completed
        ? icons_complete[String(props.icon)]
        : icons[String(props.icon)]}
    </div>
  );
}

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
};

const initFind = {
  search: "",
  date_start: Func.dateAdd(-30),
  date_stop: new Date(),
  order_by: "tb.id",
  order_sort: "desc",
  status: "1",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function PosTransfer({ t }) {
  const mod = "postransfer";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.id", name: t(`${prefix}.id`), order: "desc" },
    { code: "tb.member_code", name: t(`${prefix}.member`) },
    { code: "tb.branch_code", name: t(`${prefix}.branch`) },
    { code: "tb.branch_warehouse", name: t(`${prefix}.warehouse`) },
    { code: "tb.address", name: t(`${prefix}.address`) },
    { code: "tb.shipping_price", name: t(`${prefix}.shipping_price`) },
    { code: "tb.date_create", name: "วันที่บิล" },
    { code: "tb.date_shipping", name: "กำหนดส่ง" },
    { code: "tb.approve_date_2", name: "วันจัดส่ง" },
    { code: "tb.status_shipping", name: t(`${prefix}.status`) },
  ];
  const alignValue = [0, 1, 6, 7, 8, 9, 10];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [cboStatus, setCboStatus] = useState([]);
  const [record, setRecord] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalList, setModalList] = useState(false);
  const [slip, setSlip] = useState(initAttr);
  const slipRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      member: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const displayShipping = (item) => {
    if (item.transfer == 2) {
      return <div>จัดส่งสาขา {item.branch_transfer}</div>;
    } else if (item.transfer == 3) {
      return (
        <div style={{ whiteSpace: "normal", width: "300px" }}>
          {item.address}
        </div>
      );
    } else {
      return "";
    }
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {item.id}
      </Button>
    );
  };

  const editTransfer = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edittransfer", {
            code: item.code,
          }).then((result) => {
            setLoading(false);
            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
              view(find);
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{item.id}
      </SweetAlert>
    );
  };

  const editCancel = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editcancel", {
            code: item.code,
          }).then((result) => {
            setLoading(false);
            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
              view(find);
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{item.id}
      </SweetAlert>
    );
  };

  const displayTotal = (number, cls) => {
    return <div className={cls}>{Func.numberDisplay(number)}</div>;
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              Func.numberDisplay(item.price),
              Func.numberDisplay(item.quantity),
              Func.numberDisplay(item.amount),
              Func.numberDisplay(item.quantity_old),
              Func.numberDisplay(item.quantity_return),
            ],
          ]);
        });

        setList((list) => [
          ...list,
          {
            total: true,
            title: t(`${prefix}.amount`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.amount),
          },
          {
            total: true,
            title: t(`${prefix}.vat`) + " " + result.vat + " %",
            colspan: "8",
            amount: Func.numberDisplay(result.data.vat),
          },
          {
            total: true,
            title: t(`${prefix}.total`),
            colspan: "8",
            amount: displayTotal(result.data.total, classes.colorPrimary),
          },
          {
            total: true,
            title: t(`${prefix}.shipping`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.shipping_price),
          },
          {
            total: true,
            title: t(`${prefix}.nettotal`),
            colspan: "8",
            amount: displayTotal(result.data.nettotal, classes.colorPrimary),
          },
        ]);

        setModalList(true);
      }
      setLoading(false);
    });
  };

  const print = (item) => {
    setLoading(true);
    setSlip({});

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);

        handlePrint();
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        {item.status_shipping !== "1" ? null : (
          <Button
            color="success"
            className={classes.actionButton}
            size="sm"
            onClick={() => editTransfer(item)}
          >
            {t(`${prefix}.shipping`)} <LocalShippingIcon />
          </Button>
        )}
        {item.status_shipping !== "1" ? null : (
          <Button
            color="danger"
            className={classes.actionButton}
            onClick={() => editCancel(item)}
            size="sm"
          >
            {t(`element.cancel`)} <CancelIcon />
          </Button>
        )}
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print(item)}
          size="sm"
        >
          {t(`element.print`)} <PrintIcon />
        </Button>
      </>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => {
                        view({ ...find, date_start: value, page: 1 });
                        setFind({ ...find, date_start: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => {
                        view({ ...find, date_stop: value, page: 1 });
                        setFind({ ...find, date_stop: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  "",
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  renderHeader(header[6]),
                  renderHeader(header[7]),
                  renderHeader(header[8]),
                  renderHeader(header[9]),
                ]}
                tableData={record.map((item) => {
                  return [
                    btn(item),
                    btnOpen(item),
                    item.member_name,
                    item.branch_name,
                    item.branch_warehouse,
                    displayShipping(item),
                    Func.numberDisplay(item.shipping_price),
                    Func.dateTimeDisplay(item.date_create),
                    Func.dateTimeDisplay(item.date_shipping),
                    Func.dateTimeDisplay(item.approve_date_2),
                    displayStatus(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[7, 8, 9]}
                colorsColls={["primary", "danger", "success"]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}></InputLabel>
                <Select
                  value={find.perPage}
                  onChange={(e) => {
                    view({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                    setFind({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                  }}
                >
                  {cboPerPage.map((item) => (
                    <MenuItem
                      key={item.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.id}
                    >
                      {t(item.name)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                count={pageTotal}
                showFirstButton
                showLastButton
                page={pageNo}
                onChange={(e, p) => {
                  view({ ...find, page: p });
                  setFind({ ...find, page: p });
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderStatus()}
          {renderOrder()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderOrder = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${prefix}.num`),
              t(`${prefix}.image`),
              t(`${prefix}.product_id`),
              t(`${prefix}.product_name`),
              t(`${prefix}.unit`),
              t(`${prefix}.price`),
              t(`${prefix}.quantity`),
              t(`${prefix}.amount`),
              "ปริมาณเดิม",
              t(`${prefix}.return`),
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[5, 6, 7]}
            colorsColls={["primary", "primary", "success"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status * 1}
        alternativeLabel
        className={classes.bgTransparent}
        style={{ paddingBottom: 0 }}
      >
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_1`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_2`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_2}</p>
            <p className={classes.colorDefault}>{attr.approve_date_2}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_3`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_3}</p>
            <p className={classes.colorDefault}>{attr.approve_date_3}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              {attr.status == 9 ? (
                <Badge color="danger">{t(`${prefix}.step_9`)}</Badge>
              ) : (
                <b>{t(`${prefix}.step_9`)}</b>
              )}
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_9}</p>
            <p className={classes.colorDefault}>{attr.approve_date_9}</p>
          </StepLabel>
        </Step>
      </Stepper>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <SlipShipping ref={slipRef} attr={slip} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalList()}
      {renderPrint()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(PosTransfer);
