// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import SettingsIcon from "@material-ui/icons/Settings";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import StoreIcon from "@material-ui/icons/Store";
import SettingsSystemDaydreamIcon from "@material-ui/icons/SettingsSystemDaydream";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import StyleIcon from "@material-ui/icons/Style";
import DescriptionIcon from "@material-ui/icons/Description";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import InboxIcon from "@material-ui/icons/Inbox";
import ReceiptIcon from "@material-ui/icons/Receipt";

// views
import Dashboard from "views/Dashboard/Dashboard";
import Permission from "views/Permission/Permission";
import Menu from "views/Tool/Menu";
import Employee from "views/Employee/Employee";
import Task from "views/Employee/Task";
import Member from "views/Member/Member";
import Prefix from "views/Setting/Prefix";
import Province from "views/Setting/Province";
import Bank from "views/Setting/Bank";
import Log from "views/Tool/Log";
import MemberType from "views/Member/MemberType";
import Product from "views/Product/Product";
import Brand from "views/Product/Brand";
import Branch from "views/Setting/Branch";
import Company from "views/Setting/Company";
import Department from "views/Setting/Department";
import PO from "views/Purchase/PO";
import Supplier from "views/Purchase/Supplier";
import Warehouse from "views/Store/Warehouse";
import Receive from "views/Store/Receive";
import RawMaterial from "views/Product/RawMaterial";
import Promotion from "views/Product/Promotion";
import Category from "views/Product/Category";
import PayoutType from "views/Account/PayoutType";
import Payout from "views/Account/Payout";
import MoveStore from "views/Store/MoveStore";
import MoveReceive from "views/Store/MoveReceive";
import Requisition from "views/Store/Requisition";
import RequestRaw from "views/Production/RequestRaw";
import Pos from "views/Pos/Pos";
import EditProfile from "views/Employee/EditProfile";
import ChangePassword from "views/Employee/ChangePassword";
import Stock from "views/Store/Stock";
import PushFG from "views/Production/PushFG";
import PoInvoice from "views/Financial/PoInvoice";
import StockRawmat from "views/Store/StockRawmat";
import PushInit from "views/Store/PushInit";
import AdjustStock from "views/Account/AdjustStock";
import PettyCash from "views/Financial/PettyCash";
import CheckStock from "views/Store/CheckStock";
import AdminShift from "views/Employee/AdminShift";
import Customer from "views/Member/Customer";
import RpSale from "views/Report/RpSale";
import DataDic from "views/Tool/DataDic";
import RpSaleDaily from "views/Report/RpSaleDaily";
import RpSaleWeekly from "views/Report/RpSaleWeekly";
import RpSaleMonthly from "views/Report/RpSaleMonthly";
import RpProductDaily from "views/Report/RpProductDaily";
import RpProductWeekly from "views/Report/RpProductWeekly";
import RpProductMonthly from "views/Report/RpProductMonthly";
import RpProfitDaily from "views/Report/RpProfitDaily";
import RpProfitWeekly from "views/Report/RpProfitWeekly";
import RpProfitMonthly from "views/Report/RpProfitMonthly";
import Invoice from "views/Member/Invoice";
import Shipping from "views/Pos/Shipping";
import BankAccount from "views/Setting/BankAccount";
import RpProduct from "views/Report/RpProduct";
import PushStore from "views/Production/PushStore";
import RpAllLog from "views/Report/RpAllLog";
import RpAllQuantity from "views/Report/RpAllQuantity";
import PromotionAll from "views/Product/PromotionAll";
import RpPayout from "views/Report/RpPayout";
import RpPoInvoice from "views/Report/RpPoInvoice";
import Car from "views/Setting/Car";
import Transfer from "views/Transfer/Transfer";
import RpWallet from "views/Report/RpWallet";
import Notify from "views/Setting/Notify";
import PayTransfer from "views/Account/PayTransfer";
import RpPayTransfer from "views/Report/RpPayTransfer";
import PaymentTransfer from "views/Financial/PaymentTransfer";
import RpPayment from "views/Report/RpPayment";
import RawStore from "views/Store/RawStore";
import RawReceive from "views/Store/RawReceive";
import PurchaseSupplier from "views/Purchase/PurchaseSupplier";
import PurchaseInvoice from "views/Purchase/PurchaseInvoice";
import PurchaseShop from "views/Purchase/PurchaseShop";
import RpReceipt from "views/Report/RpReceipt";
import RpInvoice from "views/Report/RpInvoice";
import RpInvoicePay from "views/Report/RpInvoicePay";
import RpSystemBill from "views/Report/RpSystemBill";
import RpInvoicePrint from "views/Report/RpInvoicePrint";
import RpDelivery from "views/Report/RpDelivery";
import DeliveryOrder from "views/Transfer/DeliveryOrder";
import GenCode from "views/Tool/GenCode";
import RpInvoicePaySum from "./views/Report/RpInvoicePaySum";
import RpPushFG from "./views/Report/RpPushFG";

var dashRoutes = [
  {
    id: "dashboard",
    path: "/dashboard",
    name: "menu.dashboard",
    rtlName: "",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    color: "#FF0000",
    weight: "500",
  },
  {
    id: "pos",
    path: "/pos",
    name: "menu.pos",
    rtlName: "",
    icon: ShoppingBasketIcon,
    component: Pos,
    layout: "/admin",
    color: "#FF0080",
    weight: "500",
  },
  {
    id: "checkstock",
    path: "/checkstock",
    name: "menu.checkstock",
    rtlName: "",
    icon: InboxIcon,
    component: CheckStock,
    layout: "/admin",
    color: "#3F8DF8",
    weight: "500",
  },
  {
    id: "customer",
    path: "/customer",
    name: "menu.customer",
    rtlName: "",
    icon: ReceiptIcon,
    component: Customer,
    layout: "/admin",
    color: "#8000ff",
    weight: "500",
  },
  {
    id: "transfer",
    path: "/transfer",
    name: "menu.transfer",
    rtlName: "",
    icon: LocalShippingIcon,
    component: Transfer,
    layout: "/admin",
    color: "#408080",
    weight: "500",
  },
  {
    id: "shipping",
    path: "/shipping",
    name: "menu.shipping",
    rtlName: "",
    icon: LocalShippingIcon,
    component: Shipping,
    layout: "/admin",
    color: "#800000",
    weight: "500",
  },
  {
    id: "purchase_menu",
    collapse: true,
    name: "menu.purchase",
    rtlName: "",
    icon: ReceiptIcon,
    state: "purchaseCollapse",
    color: "#008040",
    views: [
      {
        id: "po",
        path: "/purchaseorder",
        name: "menu.po",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PO,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "purchasesupplier",
        path: "/purchasesupplier",
        name: "menu.purchasesupplier",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PurchaseSupplier,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "purchaseshop",
        path: "/purchaseshop",
        name: "menu.purchaseshop",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PurchaseShop,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "purchaseinvoice",
        path: "/purchaseinvoice",
        name: "menu.po_invoice",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PurchaseInvoice,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "supplier",
        path: "/supplier",
        name: "menu.supplier",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Supplier,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "store_menu",
    collapse: true,
    name: "menu.warehouse",
    rtlName: "",
    icon: StoreIcon,
    state: "storeCollapse",
    color: "#804000",
    views: [
      {
        id: "receive",
        path: "/receive",
        name: "menu.receive",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Receive,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "requestraw",
        path: "/requestraw",
        name: "menu.requestraw",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RequestRaw,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "requisition",
        path: "/requisition",
        name: "menu.requisition",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Requisition,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "movestore",
        path: "/movestore",
        name: "menu.movestore",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: MoveStore,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "movereceive",
        path: "/movereceive",
        name: "menu.movereceive",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: MoveReceive,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rawstore",
        path: "/rawstore",
        name: "menu.rawstore",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RawStore,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rawreceive",
        path: "/rawreceive",
        name: "menu.rawreceive",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RawReceive,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "warehouse",
        path: "/warehouse",
        name: "menu.warehouse",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Warehouse,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "stock",
        path: "/stock",
        name: "menu.stock",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Stock,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "stock_rawmat",
        path: "/stock_rawmat",
        name: "menu.stock_rawmat",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: StockRawmat,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "production_menu",
    collapse: true,
    name: "menu.production",
    rtlName: "",
    icon: SettingsSystemDaydreamIcon,
    state: "productionCollapse",
    color: "#400080",
    views: [
      {
        id: "pushfg",
        path: "/pushfg",
        name: "menu.pushfg",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PushFG,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "pushstore",
        path: "/pushstore",
        name: "menu.pushstore",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PushStore,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "account_menu",
    collapse: true,
    name: "menu.account",
    rtlName: "",
    icon: LocalAtmIcon,
    state: "accountCollapse",
    color: "#8B0000",
    views: [
      {
        id: "payout",
        path: "/payout",
        name: "menu.payout",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Payout,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "paytransfer",
        path: "/paytransfer",
        name: "menu.paytransfer",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PayTransfer,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "adjuststock",
        path: "/adjuststock",
        name: "menu.adjuststock",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: AdjustStock,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "payout_type",
        path: "/payout_type",
        name: "menu.payout_type",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PayoutType,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "delivery_order",
        path: "/delivery_order",
        name: "menu.delivery_order",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: DeliveryOrder,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "financial_menu",
    collapse: true,
    name: "menu.financial",
    rtlName: "",
    icon: AccountBalanceIcon,
    state: "financialCollapse",
    color: "#ff0000",
    views: [
      {
        id: "invoice",
        path: "/invoice",
        name: "menu.invoice",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Invoice,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "po_invoice",
        path: "/po_invoice",
        name: "menu.po_invoice",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PoInvoice,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "paymenttransfer",
        path: "/paymenttransfer",
        name: "menu.paymenttransfer",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PaymentTransfer,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "pettycash",
        path: "/pettycash",
        name: "menu.pettycash",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PettyCash,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "member_menu",
    collapse: true,
    name: "menu.member",
    rtlName: "",
    icon: ContactPhoneIcon,
    state: "memberCollapse",
    color: "#F08080",
    views: [
      {
        id: "member",
        path: "/member",
        name: "menu.member",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Member,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "member_type",
        path: "/member_type",
        name: "menu.member_type",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: MemberType,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "product_menu",
    collapse: true,
    name: "menu.product",
    rtlName: "",
    icon: StyleIcon,
    state: "productCollapse",
    color: "#000080",
    views: [
      {
        id: "product",
        path: "/product",
        name: "menu.product",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Product,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rawmaterial",
        path: "/rawmaterial",
        name: "menu.rawmaterial",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RawMaterial,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "promotion",
        path: "/promotion",
        name: "menu.promotion",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Promotion,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "promotion_all",
        path: "/promotion_all",
        name: "menu.promotion_all",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PromotionAll,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "category",
        path: "/category",
        name: "menu.category",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Category,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "brand",
        path: "/brand",
        name: "menu.brand",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Brand,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "report_menu",
    collapse: true,
    name: "menu.report",
    rtlName: "",
    icon: DescriptionIcon,
    state: "reportCollapse",
    color: "#FF4500",
    views: [
      {
        id: "rp_sale",
        path: "/rp_sale",
        name: "menu.rp_sale",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpSale,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_payment",
        path: "/rp_payment",
        name: "menu.rp_payment",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpPayment,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_invoiceprint",
        path: "/rp_invoiceprint",
        name: "menu.rp_invoiceprint",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpInvoicePrint,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_invoicepay",
        path: "/rp_invoicepay",
        name: "menu.rp_invoicepay",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpInvoicePay,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_invoicepaysum",
        path: "/rp_invoicepaysum",
        name: "menu.rp_invoicepaysum",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpInvoicePaySum,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_invoice",
        path: "/rp_invoice",
        name: "menu.rp_invoice",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpInvoice,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_receipt",
        path: "/rp_receipt",
        name: "menu.rp_receipt",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpReceipt,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_systembill",
        path: "/rp_systembill",
        name: "menu.rp_systembill",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpSystemBill,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_delivery",
        path: "/rp_delivery",
        name: "menu.rp_delivery",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpDelivery,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_payout",
        path: "/rp_payout",
        name: "menu.rp_payout",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpPayout,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_paytransfer",
        path: "/rp_paytransfer",
        name: "menu.rp_paytransfer",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpPayTransfer,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_poinvoice",
        path: "/rp_poinvoice",
        name: "menu.rp_poinvoice",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpPoInvoice,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_pushfg",
        path: "/rp_pushfg",
        name: "menu.pushfg",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpPushFG,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_saledaily",
        path: "/rp_saledaily",
        name: "menu.rp_saledaily",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpSaleDaily,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_saleweekly",
        path: "/rp_saleweekly",
        name: "menu.rp_saleweekly",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpSaleWeekly,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_salemonthly",
        path: "/rp_salemonthly",
        name: "menu.rp_salemonthly",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpSaleMonthly,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_product",
        path: "/rp_product",
        name: "menu.rp_product",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpProduct,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_productdaily",
        path: "/rp_productdaily",
        name: "menu.rp_productdaily",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpProductDaily,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_productweekly",
        path: "/rp_productweekly",
        name: "menu.rp_productweekly",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpProductWeekly,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_productmonthly",
        path: "/rp_productmonthly",
        name: "menu.rp_productmonthly",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpProductMonthly,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_profitdaily",
        path: "/rp_profitdaily",
        name: "menu.rp_profitdaily",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpProfitDaily,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_profitweekly",
        path: "/rp_profitweekly",
        name: "menu.rp_profitweekly",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpProfitWeekly,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_profitmonthly",
        path: "/rp_profitmonthly",
        name: "menu.rp_profitmonthly",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpProfitMonthly,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_alllog",
        path: "/rp_alllog",
        name: "menu.rp_alllog",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpAllLog,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_allquantity",
        path: "/rp_allquantity",
        name: "menu.rp_allquantity",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpAllQuantity,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "rp_wallet",
        path: "/rp_wallet",
        name: "menu.rp_wallet",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: RpWallet,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "setting_menu",
    collapse: true,
    name: "menu.setting",
    rtlName: "",
    icon: SettingsIcon,
    state: "settingCollapse",
    color: "#87CEEB",
    views: [
      {
        id: "company",
        path: "/company",
        name: "menu.company",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Company,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "branch",
        path: "/branch",
        name: "menu.branch",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Branch,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "notify",
        path: "/notify",
        name: "menu.notify",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Notify,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "car",
        path: "/car",
        name: "menu.car",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Car,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "pushinit",
        path: "/pushinit",
        name: "menu.pushinit",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: PushInit,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "province",
        path: "/province",
        name: "menu.province",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Province,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "bank",
        path: "/bank",
        name: "menu.bank",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Bank,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "bank_account",
        path: "/bank_account",
        name: "menu.bank_account",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: BankAccount,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "department",
        path: "/department",
        name: "menu.department",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Department,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "position",
        path: "/position",
        name: "menu.position",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Department,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "prefix",
        path: "/prefix",
        name: "menu.prefix",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Prefix,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "admin_menu",
    collapse: true,
    name: "menu.employee",
    rtlName: "",
    icon: AssignmentIndIcon,
    state: "adminCollapse",
    color: "#40E0D0",
    views: [
      {
        id: "adminshift",
        path: "/adminshift",
        name: "menu.adminshift",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: AdminShift,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "employee",
        path: "/employee",
        name: "menu.employee",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Employee,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "task",
        path: "/task",
        name: "menu.task",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Task,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "editprofile",
        path: "/editprofile",
        name: "menu.editprofile",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: EditProfile,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "changepassword",
        path: "/changepassword",
        name: "menu.changepassword",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: ChangePassword,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
  {
    id: "tool_menu",
    collapse: true,
    name: "menu.tool",
    rtlName: "Admin Tool",
    icon: VerifiedUserIcon,
    state: "toolCollapse",
    color: "#708090",
    views: [
      {
        id: "permission",
        path: "/permission",
        name: "menu.permission",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Permission,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "datadic",
        path: "/datadic",
        name: "menu.datadic",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: DataDic,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "menu",
        path: "/menu",
        name: "menu.menu",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Menu,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "gencode",
        path: "/gencode",
        name: "menu.gencode",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: GenCode,
        layout: "/admin",
        color: "#333333",
      },
      {
        id: "log",
        path: "/log",
        name: "menu.log",
        rtlName: "",
        mini: "-",
        rtlMini: "",
        component: Log,
        layout: "/admin",
        color: "#333333",
      },
    ],
  },
];

export default dashRoutes;
