/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";

// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import DateRangeIcon from "@material-ui/icons/DateRange";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import i18n from "components/Translation/Translate.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import { API_URL } from "services/Define";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";
import { dateTimeDisplay } from "services/Function";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  branch_code: "",
  member_code: "",
  payment_type: "",
  date_record: "",
  date_shipping: "",
  time_shipping: "",
  name: "",
  tel: "",
  date_due: "",
  payment: "",
  remark: "",
  amount: "",
  discount: "",
  transfer: "",
  shipping: "",
  shipping_price: "",
  vat: "",
  total: "",
  money: "",
  pay: "",
  balance: "",
  money_change: "",
  address: "",
  emp_code: "",
  branch_code: "",
  branch_name: "",
  branch_warehouse: "",
  wallet_pay: "",
  wallet_balance: "",
};

const initFind = {
  search: "",
  date_start: moment(Func.dateFirstMonth()),
  date_stop: moment(new Date()),
  round_start: moment(Func.dateFirstMonth()),
  round_stop: moment(new Date()),
  order_by: "tb.id",
  order_sort: "desc",
  status: "ALL",
  branch: "ALL",
  member: "ALL",
  member_name: "",
  shift_code: "ALL",
  payment: "ALL",
  account: "ALL",
  round: "",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function RpPayment({ t }) {
  const mod = "rp_payment";
  const prefix = "bill";
  const txtMember = useRef(null);
  const txtRound = useRef(null);
  const classes = useStyles();
  const [modalCustomer, setModalCustomer] = useState(false);
  const [modalRound, setModalRound] = useState(false);
  const [modalList, setModalList] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [roundList, setRoundList] = useState([]);

  const initHeader = [
    { code: "tb.id", name: t(`${prefix}.date_record`), order: "desc" },
    { code: "tb.member_code", name: t(`${prefix}.member`) },
    { code: "tb.payment_type", name: "การชำระ" },
    { code: `tb.account_code`, name: `บัญชีโอน` },
    { code: "tb.amount", name: t(`${prefix}.amount`) },
    { code: "tb.shift_id", name: "รหัสรอบ" },
    { code: "tb.emp_code", name: "พนักงาน" },
    { code: "tb.shift_start", name: "เข้างาน" },
    { code: "tb.shift_stop", name: "ออกงาน" },
  ];
  const alignValue = [0, 2, 4, 5, 6, 7, 8];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);

  const [attr, setAttr] = useState(initAttr);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [loading, setLoading] = useState(false);
  const [cboStatus, setCboStatus] = useState([]);
  const [cboBranch, setCboBranch] = useState([]);
  const [cboPayment, setCboPayment] = useState([]);
  const [cboAccount, setCboAccount] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboBranch(result.branch);
        setCboPayment(result.paymenttype);
        setCboAccount(result.account);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setFind(data);
    setLoading(true);
    setRecord([]);

    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      branch: data.branch,
      member: data.member,
      shift_code: data.shift_code,
      payment: data.payment,
      account: data.payment === "2" ? data.account : "ALL",
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              btnOpen(item),
              item.member_name,
              displayPayment(item.payment_type),
              item.account_name,
              Func.numberDisplay(item.amount),
              item.shift_id,
              item.employee_name,
              Func.dateTimeDisplay(item.shift_start),
              Func.dateTimeDisplay(item.shift_stop),
            ],
          ]);
        });

        if (result.data.length > 0)
          setRecord((record) => [
            ...record,
            [
              "",
              "",
              "",
              <h5>{t(`${prefix}.total`)}</h5>,
              <h5>{Func.numberDisplay(result.sum.amount)}</h5>,
              "",
              "",
              "",
              "",
            ],
          ]);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const excel = (data) => {
    setFind(data);
    setLoading(true);

    PostData(mod, "excel", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      branch: data.branch,
      member: data.member,
      shift_code: data.shift_code,
      payment: data.payment,
      account: data.payment === "2" ? data.account : "ALL",
    }).then((result) => {
      if (result.status === "OK") {
        window.open(`${API_URL}/app/print/${mod}-${result.code}.pdf`, "_self");
      }
      setLoading(false);
    });
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      date_record: item.id,
      shift: item.shift_code,
      member: item.member_code,
      payment_type: item.payment_type,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              item.id,
              Func.dateTimeDisplay(item.date_create),
              Func.numberDisplay(item.amount),
              displayPayment(item.payment_type),
              item.account_name,
              displayStatus(item),
            ],
          ]);
        });

        setModalList(true);
      }
      setLoading(false);
    });
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {Func.dateDisplay(item.id)}
      </Button>
    );
  };

  const searchCustomer = (e) => {
    e.preventDefault();

    setMemberList([]);

    if (txtMember.current.value == "") return;

    PostData(mod, "membersearch", {
      token: app.token,
      lang: app.lang,
      search: txtMember.current.value,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setMemberList((memberList) => [
            ...memberList,
            [displayName(item), displayTel(item), displaySelect(item)],
          ]);
        });
      }
    });
  };

  const displayTotal = (number, cls) => {
    return <div className={cls}>{Func.numberDisplay(number)}</div>;
  };

  const displayPayment = (inp) => {
    if (inp == "CASH") return "เงินสด";
    else if (inp == "TRANSFER") return "โอนเงิน";
    else if (inp == "CREDIT") return "เงินเชื่อ";
    else if (inp == "WALLET") return "Wallet";
    else return "";
  };

  const searchRound = (e) => {
    e.preventDefault();

    setRoundList([]);

    if (txtRound.current.value == "") return;

    PostData(mod, "roundsearch", {
      token: app.token,
      lang: app.lang,
      search: txtRound.current.value,
      start: Func.dateTimeFormat(find.round_start.toDate()),
      stop: Func.dateTimeFormat(find.round_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRoundList((roundList) => [
            ...roundList,
            [
              <Button simple color="github" onClick={() => findRound(item)}>
                {item.id}
              </Button>,
              <Button simple color="github" onClick={() => findRound(item)}>
                <i className="far fa-user" /> {item.emp_name}
              </Button>,
              displayStatus(item),
              <div>
                <div>เข้า : {dateTimeDisplay(item.date_start)}</div>
                <div>ออก : {dateTimeDisplay(item.date_stop)}</div>
              </div>,
            ],
          ]);
        });
      }
    });
  };

  const searchRoundDate = (data) => {
    setRoundList([]);

    PostData(mod, "roundsearch", {
      token: app.token,
      lang: app.lang,
      search: txtRound.current.value,
      start: Func.dateTimeFormat(data.round_start.toDate()),
      stop: Func.dateTimeFormat(data.round_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRoundList((roundList) => [
            ...roundList,
            [
              <Button simple color="github" onClick={() => findRound(item)}>
                {item.id}
              </Button>,
              <Button simple color="github" onClick={() => findRound(item)}>
                <i className="far fa-user" /> {item.emp_name}
              </Button>,
              displayStatus(item),
              <div>
                <div>เข้า : {dateTimeDisplay(item.date_start)}</div>
                <div>ออก : {dateTimeDisplay(item.date_stop)}</div>
              </div>,
            ],
          ]);
        });
      }
    });
  };

  const displayName = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="far fa-user" /> {item.name}
      </Button>
    );
  };

  const displayTel = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="fas fa-phone-volume" /> {item.tel}
      </Button>
    );
  };

  const displaySelect = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="fas fa-chevron-right" />
      </Button>
    );
  };

  const findMember = (item) => {
    setFind({ ...find, member: item.code, member_name: item.name });

    setModalCustomer(false);
  };

  const findRound = (item) => {
    setFind({
      ...find,
      shift_code: item.code,
      round: `${item.id} - ${item.emp_name}`,
    });

    setModalRound(false);
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const renderDateRound = () => {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
      "5 Days": [moment(start).subtract(5, "days"), moment(end)],
      "1 Week": [moment(start).subtract(7, "days"), moment(end)],
      "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
      "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    };
    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={find.round_start}
          end={find.round_stop}
          local={{ format: "DD/MM/YYYY HH:mm", sundayFirst: false }}
          applyCallback={(start, stop) => {
            setFind({ ...find, round_start: start, round_stop: stop });
            searchRoundDate({ ...find, round_start: start, round_stop: stop });
          }}
        >
          <TextField
            fullWidth
            value={`${find.round_start.format("DD/MM/YYYY HH:mm")} 
            ${t(`element.to`)} 
            ${find.round_stop.format("DD/MM/YYYY HH:mm")}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t("element.date")} :
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </DateTimeRangeContainer>
      </div>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.boxPrimary}>
            <CardHeader color="primary" icon className={classes.boxHead}>
              <h4 className={classes.boxTitle}>
                <i className="fas fa-file-alt color-primary" />
                {t("element.report")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => view({ ...find, date_start: value })}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => view({ ...find, date_stop: value })}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    InputProps={{
                      value: find.round,
                      startAdornment: (
                        <InputAdornment position="start">รอบ :</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      readOnly: true,
                    }}
                    onClick={() => {
                      setModalRound(true);

                      setTimeout(function () {
                        txtRound.current.select();
                      }, 500);
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.branch}
                      onChange={(e) => {
                        setFind({ ...find, branch: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.branch`)}
                      </MenuItem>
                      {cboBranch.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12}></GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    InputProps={{
                      value: find.member_name,
                      startAdornment: (
                        <InputAdornment position="start">
                          {t(`${prefix}.member`)} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      readOnly: true,
                    }}
                    onClick={() => {
                      setModalCustomer(true);

                      setTimeout(function () {
                        txtMember.current.select();
                      }, 500);
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.payment}
                      onChange={(e) => {
                        setFind({ ...find, payment: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        วิธีชำระ
                      </MenuItem>
                      {cboPayment.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                {find.payment === "2" ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <Select
                        value={find.account}
                        onChange={(e) => {
                          setFind({
                            ...find,
                            account: e.target.value,
                            page: 1,
                          });
                        }}
                      >
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value="ALL"
                        >
                          บัญชีโอน
                        </MenuItem>
                        {cboAccount.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                ) : null}
                <GridItem xs={12} className={classes.center}>
                  <Button color="info" onClick={() => view(find)}>
                    <i className="fas fa-file-alt fa-fw" /> Show report
                  </Button>
                  <Button color="success" onClick={() => excel(find)}>
                    <i className="fas fa-file-excel fa-fw" /> Export To Excel
                  </Button>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                      renderHeader(header[8]),
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[4]}
                    colorsColls={["primary"]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalCustomer = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalCustomer}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalCustomer(false)}
        aria-labelledby="modal-customer-title"
        aria-describedby="modal-customer-description"
      >
        <DialogTitle
          id="modal-customer-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalCustomer(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t(`${prefix}.member`)}</h4>
        </DialogTitle>
        <DialogContent id="modal-customer" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchCustomer}>
                <CustomInput
                  labelText={t("element.search")}
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtMember,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>
              <Table
                hover
                tableData={memberList}
                customHeadClassesForCells={[2]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[2]}
                customCellClasses={[classes.right]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              view({ ...find, member: "ALL", member_name: "" });
              setModalCustomer(false);
            }}
            color="info"
          >
            <PeopleOutlineIcon /> {t(`report.showall`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderModalRound = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalRound}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalRound(false)}
        aria-labelledby="modal-round-title"
        aria-describedby="modal-round-description"
      >
        <DialogTitle
          id="modal-round-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalRound(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>รอบ</h4>
        </DialogTitle>
        <DialogContent id="modal-round" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchRound}>
                <CustomInput
                  labelText="พนักงาน"
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtRound,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>{renderDateRound()}</GridItem>
            <GridItem xs={12}>
              <Table
                hover
                tableData={roundList}
                customHeadClassesForCells={[2]}
                customHeadCellClasses={[classes.center]}
                customClassesForCells={[2]}
                customCellClasses={[classes.center]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              view({ ...find, shift_code: "ALL", round: "" });
              setModalRound(false);
            }}
            color="info"
          >
            <PeopleOutlineIcon /> {t(`report.showall`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {attr.date_record} / {attr.member_name} /{" "}
            {displayPayment(attr.payment_type)} / รอบ {attr.shift_start} ถึง{" "}
            {attr.shift_stop}
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderList()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              "เลขที่บิล",
              "วันที่",
              "จำนวนเงิน",
              "วิธีชำระ",
              "บัญชี",
              "สถานะ",
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.center,
              classes.center,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.center,
              classes.center,
              classes.center,
            ]}
            coloredColls={[2]}
            colorsColls={["primary"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalList()}
      {renderModalCustomer()}
      {renderModalRound()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(RpPayment);
