/*================================================*\
*  Author : Programmer895
*  Created Date : 19/10/2020
\*================================================*/
import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Image from "material-ui-image";

export const numberDisplay = (number, point = 2) => {
  number = number === undefined ? 0 : number * 1;
  return number.toFixed(point).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const moneyDisplay = (number, point = 2, currency = "฿") => {
  number = number === undefined ? 0 : number * 1;
  return currency + " " + numberDisplay(number, point);
};

export const dateDisplay = (date, style = 1) => {
  let dt = new Date(date);

  let d = dt.getDate();
  let m = dt.getMonth() + 1;
  let y = dt.getFullYear();

  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;

  if (style == 2) {
    y = y - 2000;
  } else if (style == 3) {
    y = y + 543;
  } else if (style == 4) {
    y = y - 1957;
  }

  if (y > 0) return `${d}/${m}/${y}`;
  else return "";
};

export const dateTimeDisplay = (date, style = 1) => {
  let dt = new Date(date);

  let d = dt.getDate();
  let m = dt.getMonth() + 1;
  let y = dt.getFullYear();
  let h = dt.getHours();
  let i = dt.getMinutes();

  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;
  h = h < 10 ? "0" + h : h;
  i = i < 10 ? "0" + i : i;

  if (style == 2) {
    y = y - 2000;
  } else if (style == 3) {
    y = y + 543;
  }

  if (y > 0) return `${d}/${m}/${y} ${h}:${i}`;
  else return "";
};

export const dateTimeFormat = (date) => {
  let dt = new Date(date);

  let d = dt.getDate();
  let m = dt.getMonth() + 1;
  let y = dt.getFullYear();
  let h = dt.getHours();
  let i = dt.getMinutes();
  let s = dt.getSeconds();

  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;
  h = h < 10 ? "0" + h : h;
  i = i < 10 ? "0" + i : i;
  s = s < 10 ? "0" + s : s;

  return `${y}-${m}-${d} ${h}:${i}:${s}`;
};

export const dateFormat = (date) => {
  let dt = new Date(date);

  let d = dt.getDate();
  let m = dt.getMonth() + 1;
  let y = dt.getFullYear();

  d = d < 10 ? "0" + d : d;
  m = m < 10 ? "0" + m : m;

  return `${y}-${m}-${d}`;
};

export const timeFormat = (date) => {
  let dt = new Date(date);

  let h = dt.getHours();
  let m = dt.getMinutes();
  let s = dt.getSeconds();

  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;

  return `${h}:${m}:${s}`;
};

export const timeSetup = (str) => {
  let h = 0;
  let m = 0;
  let hh = "";
  let mm = "";

  if (str.length > 5 || str.length < 4) {
    return "";
  } else if (str.length == 4) {
    h = str.substr(0, 2) * 1;
    m = str.substr(2, 2) * 1;
  } else if (str.length == 5) {
    h = str.substr(0, 2) * 1;
    m = str.substr(3, 2) * 1;
  }

  if (h > 23 || m > 59) {
    return "";
  }

  hh = h < 10 ? "0" + h : h;
  mm = m < 10 ? "0" + m : m;

  return `${hh}:${mm}`;
};

export const dateFirstMonth = () => {
  let dt = new Date();

  return new Date(dt.getFullYear(), dt.getMonth(), 1, 0, 0, 0, 0);
};

export const dateAdd = (day) => {
  let dt = new Date();
  dt.setDate(dt.getDate() + day);

  return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0, 0);
};

export const checkDisplay = (inp) => {
  return inp === "Y" ? (
    <i className="fas fa-toggle-on" style={{ color: "#1873cf" }} />
  ) : (
    <i className="fas fa-toggle-off" style={{ color: "gray" }} />
  );
};

export const showLoading = () => {
  return (
    <SweetAlert
      style={{ display: "block", marginTop: "-100px" }}
      title="Loading..."
      onConfirm={() => { }}
      showConfirm={false}
    />
  );
};

export const filePic = (filename) => {
  return (
    <div>
      <Image src={filename ? filename : ""} />
    </div>
  );
};

export const checkBool = (inp) => {
  return inp === "Y" ? true : false;
};

export const checkBase = (inp) => {
  return inp ? "Y" : "N";
};

export const verifyEmail = (value) => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
// function that verifies if a string has a given length or not
export const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};
// function that verifies if value contains only numbers
export const verifyNumber = (value) => {
  var numberRex = new RegExp("^[0-9]+$");
  if (numberRex.test(value)) {
    return true;
  }
  return false;
};
// verifies if value is a valid URL
export const verifyUrl = (value) => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};

export const exitApp = () => {
  window.location.replace("/auth/login");
};
