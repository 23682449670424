/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

/* ========== ZONE Function ========== */
function SlipShipping({ t, attr, list }) {
  const mod = "posbill";
  const prefix = "bill";
  const classes = useStyles();

  const quantityDisplay = (num) => {
    const fl = Math.floor(num);
    if (fl == num) return Func.numberDisplay(num, 0);
    else return Func.numberDisplay(num);
  };

  const displayShipping = () => {
    if (attr.transfer == 2) {
      return `จัดส่งสาขา ${attr.branch_name}`;
    } else if (attr.transfer == 3) {
      return attr.address;
    } else {
      return "";
    }
  };

  const renderHeader = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td align="center">
              <h3>บิลส่งของ</h3>
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">เลขที่ใบเสร็จ: {attr.id}</td>
          </tr>
          <tr>
            <td align="left">
              วันที่: {Func.dateTimeDisplay(attr.date_create, 3)}
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">ลูกค้า: {attr.member_name}</td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">สถานที่จัดส่ง: {displayShipping()}</td>
          </tr>
          {attr.car_code > 0 ? (
            <tr>
              <td align="left">รถส่งของ: {attr.car_name}</td>
            </tr>
          ) : null}
          <tr>
            <td align="left">
              วันกำหนดส่ง: {Func.dateTimeDisplay(attr.date_shipping, 3)}
            </td>
          </tr>
          <tr>
            <td align="left">หมายเหตุ: {attr.remark}</td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderOrder = () => {
    return (
      <table width="100%">
        <thead>
          <tr>
            <td colSpan={3}>รายการจัดส่ง</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
          </tr>
          {list &&
            list.map((item, i) => (
              <tr key={item.code}>
                <td align="left" valign="top">
                  {i + 1}.
                </td>
                <td align="left" valign="top">
                  {item.product_name}
                </td>
                <td align="right" valign="top">
                  {quantityDisplay(item.quantity)} {item.unit_name}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const renderFooter = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="2">
              <p>
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td align="right">พนักงานขาย:</td>
            <td align="center">....................................</td>
          </tr>
          <tr>
            <td></td>
            <td align="center">({attr.emp_name})</td>
          </tr>
        </tbody>
      </table>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="print">
      <div className="slips">
        {renderHeader()}
        {renderOrder()}
        {renderFooter()}
      </div>
    </div>
  );
}

export default withNamespaces()(SlipShipping);
