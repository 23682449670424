/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Input from "@material-ui/core/Input";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
    code: "",
    product_pic: "",
    product_id: "",
    product_name: "",
    quantity: "",
    unit_name: "",
    price: "",
    addprice: "",
    subprice: "",
    enable: "Y",
};

const initFind = {
    search: "",
    order_by: "tb.code",
    order_sort: "desc",
    promotion: "ALL",
    product_id: "",
    product_name: "",
    quantity: "",
    page: 1,
    perPage: 10,
    pageTotal: 0,
};

/* ========== ZONE Function ========== */
function PromotionAll({ t }) {
    const mod = "promotion_all";
    const prefix = "attr";
    const classes = useStyles();

    const initHeader = [
        { code: "tb.code", name: t(`${prefix}.code`), sort: false },
        { code: "tb.promotion_code", name: `โปรโมชั่น`, sort: false },
        { code: "tb.group_code", name: `ซื้อขั้นต่ำ`, sort: false },
        { code: "tb.product_code", name: "รูปสินค้า", sort: false },
        { code: "tb.product_code", name: `รหัสสินค้า`, sort: false },
        { code: "tb.product_code", name: `สินค้า`, sort: false },
        { code: "pd.unit_code", name: `หน่วย`, sort: false },
        { code: "tb.date_start", name: `ราคา`, sort: false },
    ];
    const alignValue = [0, 2, 3, 6, 7, 8];
    const alignItem = [
        classes.center,
        classes.center,
        classes.center,
        classes.center,
        classes.right,
        classes.center,
    ];
    const cboPerPage = [
        { id: 10, name: "element.show_10_record" },
        { id: 20, name: "element.show_20_record" },
        { id: 100, name: "element.show_100_record" },
    ];

    const app = useSelector((state) => state.appState);
    const txtPrice = useRef(null);
    const { id } = useParams();
    const [pageTotal, setPageTotal] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [record, setRecord] = useState([]);
    const [attr, setAttr] = useState(initAttr);
    const [find, setFind] = useState(initFind);
    const [header, setHeader] = useState(initHeader);
    const [alert, setAlert] = useState(null);
    const [notify, setNotify] = useState(null);
    const [tab, setTab] = useState(0);
    const [modalEdit, setModalEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cboPromotion, setCboPromotion] = useState([]);

    useEffect(() => {
        i18n.changeLanguage(app.lang);
        init();
    }, [id]);

    const init = () => {
        PostData(mod, "init", {
            token: app.token,
            lang: app.lang,
        }).then((result) => {
            if (result.status === "OK") {
                if (id === undefined) {
                    setCboPromotion(result.promotion);
                    view(find);
                } else if (id === "add") {
                    setAttr(initAttr);
                } else {
                    setTab(0);
                    open();
                }
            } else {
                Func.exitApp();
            }
        });
    };

    const changeTab = (event, value) => {
        setTab(value);
    };

    const inputChange = (e) => {
        setAttr({ ...attr, [e.target.id]: e.target.value });
    };

    const checkChange = (e) => {
        setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
    };

    const selectChange = (e) => {
        setAttr({ ...attr, [e.target.name]: e.target.value });
    };

    const view = (data) => {
        setLoading(true);
        PostData(mod, "view", {
            token: app.token,
            lang: app.lang,
            search: data.search,
            page: data.page,
            per_page: data.perPage,
            orderby: data.order_by,
            asc: data.order_sort,
            promotion: data.promotion,
            product_id: data.product_id,
            product_name: data.product_name,
            quantity: data.quantity,
        }).then((result) => {
            if (result.status === "OK") {
                setRecord([]);

                result.data.map((item, i) => {
                    setRecord((record) => [
                        ...record,
                        [
                            item.code,
                            item.promotion_name,
                            item.group_quantity,
                            <img src={item.product_pic} width="30" />,
                            item.product_id,
                            item.product_name,
                            item.unit_name,
                            item.price,
                            btn(item)
                        ],
                    ]);
                });

                setPageTotal(result.total_pages);
                setPageNo(result.page);
            }
            setLoading(false);
        });
    };

    const open = (item) => {
        setLoading(true);
        setAttr(initAttr);

        PostData(mod, "open", {
            token: app.token,
            lang: app.lang,
            code: item.code,
        }).then((result) => {
            if (result.status === "OK") {
                setAttr(result.data);
                setModalEdit(true);

                setLoading(false);
            }
        });
    };

    const edit = () => {
        if (attr.price === "") return;
        if (!confirm('Are you sure?')) return;

        setLoading(true);
        setModalEdit(false);

        PostData(mod, "edit", {
            code: attr.code,
            data: {
                price: attr.price,
            },
        }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
                view(find);
                showNotify(t("alert.editcomplete"), "success");
            } else {
                showNotify(t("alert.editerror"), "danger");
            }
        });
    };

    const editAddPrice = () => {
        if (attr.addprice === "") return;
        if (!confirm('Are you sure?')) return;

        setLoading(true);
        setModalEdit(false);

        PostData(mod, "addprice", {
            code: attr.code,
            product_code: attr.product_code,
            amount: attr.addprice,
        }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
                view(find);
                showNotify(t("alert.editcomplete"), "success");
            } else {
                showNotify(t("alert.editerror"), "danger");
            }
        });
    };

    const editSubPrice = () => {
        if (attr.subprice === "") return;
        if (!confirm('Are you sure?')) return;

        setLoading(true);
        setModalEdit(false);

        PostData(mod, "subprice", {
            code: attr.code,
            product_code: attr.product_code,
            amount: attr.subprice,
        }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
                view(find);
                showNotify(t("alert.editcomplete"), "success");
            } else {
                showNotify(t("alert.editerror"), "danger");
            }
        });
    };

    const showNotify = (msg, color) => {
        setNotify(
            <Snackbar
                place="tr"
                color={color}
                icon={AlertIcon}
                message={msg}
                open={true}
                closeNotification={() => setNotify(null)}
                close
            />
        );

        setTimeout(function () {
            setNotify(null);
        }, 3000);
    };

    const btn = (item) => {
        return (
            <Button
                round
                color="success"
                className={classes.actionButton + " " + classes.actionButtonRound}
                onClick={() => open(item)}
            >
                <EditIcon className={classes.icon} />
            </Button>
        );
    };

    const renderHeader = (data) => {
        let icon = <i className="fas fa-sort"></i>;
        if (data.order === "desc") {
            icon = <i className="fad fa-sort-up"></i>;
        } else if (data.order === "asc") {
            icon = <i className="fad fa-sort-down"></i>;
        } else {
            icon = <i className="fad fa-sort"></i>;
        }

        if (data.sort === undefined || data.sort)
            return (
                <div
                    onClick={() => {
                        let tmp = [];
                        header.map((item, i) => {
                            if (data.code === item.code) {
                                const ordersort = item.order === "asc" ? "desc" : "asc";
                                view({ ...find, order_sort: ordersort, order_by: data.code });
                                setFind({
                                    ...find,
                                    order_sort: ordersort,
                                    order_by: data.code,
                                });
                                tmp[i] = {
                                    code: item.code,
                                    name: item.name,
                                    order: ordersort,
                                    sort: item.sort,
                                };
                            } else {
                                tmp[i] = {
                                    code: item.code,
                                    name: item.name,
                                    order: "",
                                    sort: item.sort,
                                };
                            }
                        });

                        setHeader(tmp);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {data.name} {icon}
                </div>
            );
        else return <div>{data.name}</div>;
    };

    const renderViewList = () => {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <AssignmentIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className="viewlist">
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={find.promotion}
                                            onChange={(e) => {
                                                view({ ...find, promotion: e.target.value, page: 1 });
                                                setFind({ ...find, promotion: e.target.value, page: 1 });
                                            }}
                                        >
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value="ALL"
                                            >
                                                โปรโมชั่น
                                            </MenuItem>
                                            {cboPromotion.map((item) => (
                                                <MenuItem
                                                    key={item.code}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <TextField
                                        id="search_quantity"
                                        fullWidth
                                        InputProps={{
                                            onChange: (e) => {
                                                view({ ...find, quantity: e.target.value, page: 1 });
                                                setFind({ ...find, quantity: e.target.value, page: 1 });
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    ซื้อขั้นต่ำ :
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            autoComplete: "off",
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <TextField
                                        id="search_productid"
                                        fullWidth
                                        InputProps={{
                                            onChange: (e) => {
                                                view({ ...find, product_id: e.target.value, page: 1 });
                                                setFind({ ...find, product_id: e.target.value, page: 1 });
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    รหัสสินค้า :
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            autoComplete: "off",
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <TextField
                                        id="search_productname"
                                        fullWidth
                                        InputProps={{
                                            onChange: (e) => {
                                                view({ ...find, product_name: e.target.value, page: 1 });
                                                setFind({ ...find, product_name: e.target.value, page: 1 });
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    ชื่อสินค้า :
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            autoComplete: "off",
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12}>
                                    <Table
                                        striped
                                        hover
                                        tableHead={[
                                            renderHeader(header[0]),
                                            renderHeader(header[1]),
                                            renderHeader(header[2]),
                                            renderHeader(header[3]),
                                            renderHeader(header[4]),
                                            renderHeader(header[5]),
                                            renderHeader(header[6]),
                                            renderHeader(header[7]),
                                            ""
                                        ]}
                                        tableData={record}
                                        customHeadClassesForCells={alignValue}
                                        customHeadCellClasses={alignItem}
                                        customClassesForCells={alignValue}
                                        customCellClasses={alignItem}
                                        coloredColls={[7]}
                                        colorsColls={["primary"]}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <InputLabel className={classes.selectLabel}></InputLabel>
                                        <Select
                                            value={find.perPage}
                                            onChange={(e) => {
                                                view({
                                                    ...find,
                                                    page: 1,
                                                    perPage: e.target.value,
                                                    page: 1,
                                                });
                                                setFind({
                                                    ...find,
                                                    page: 1,
                                                    perPage: e.target.value,
                                                    page: 1,
                                                });
                                            }}
                                        >
                                            {cboPerPage &&
                                                cboPerPage.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected,
                                                        }}
                                                        value={item.id}
                                                    >
                                                        {t(item.name)}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem
                                    xs={12}
                                    sm={6}
                                    md={8}
                                    lg={9}
                                    className={classes.right}
                                >
                                    <Pagination
                                        style={{ float: "right", marginTop: "20px" }}
                                        count={pageTotal}
                                        showFirstButton
                                        showLastButton
                                        page={pageNo}
                                        onChange={(e, p) => {
                                            view({ ...find, page: p });
                                            setFind({ ...find, page: p });
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    const renderModalEdit = () => {
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={modalEdit}
                maxWidth={"sm"}
                fullWidth
                keepMounted
                onClose={() => setModalEdit(false)}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle
                    id="modal-edit-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setModalEdit(false)}
                    >
                        <CloseIcon className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>
                        {attr.promotion_name} / ซื้อขั้นต่ำ : {attr.group_quantity}
                    </h4>
                </DialogTitle>
                <DialogContent id="modal-edit" className={classes.modalBody}>
                    <form autoComplete="off">
                        <GridContainer>
                            <GridItem xs={12} sm={6}>
                                <img src={attr.product_pic} width="100%" />
                            </GridItem>
                            <GridItem xs={12} sm={6}>
                                <CustomInput
                                    labelText={t(`po.product_id`)}
                                    id="product_id"
                                    success
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: attr.product_id,
                                        disabled: true,
                                    }}
                                />
                                <CustomInput
                                    labelText={t(`po.product_name`)}
                                    id="product_name"
                                    success
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: attr.product_name,
                                        disabled: true,
                                    }}
                                />
                                <CustomInput
                                    labelText="หน่วย"
                                    id="unit_name"
                                    success
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: attr.unit_name,
                                        disabled: true,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <p></p>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Table
                                    striped
                                    hover
                                    tableData={[
                                        [
                                            <b>เปลี่ยนแปลงราคา</b>,
                                            <Input
                                                inputRef={txtPrice}
                                                id="price"
                                                placeholder="0.00"
                                                inputProps={{
                                                    "aria-label": "price",
                                                    value: attr.price,
                                                    type: "number",
                                                }}
                                                classes={{
                                                    input: classes.right,
                                                }}
                                                color="primary"
                                                fullWidth={false}
                                                onChange={(e) => setAttr({ ...attr, price: e.target.value })}
                                            />,
                                            <Button onClick={edit} color="primary">
                                                <SaveIcon /> {t("element.save")}
                                            </Button>
                                        ],
                                        [
                                            <b>เพิ่มราคาทุกสินค้าเดียวกัน</b>,
                                            <Input
                                                id="addprice"
                                                placeholder="0.00"
                                                inputProps={{
                                                    "aria-label": "addprice",
                                                    value: attr.addprice,
                                                    type: "number",
                                                }}
                                                classes={{
                                                    input: classes.right,
                                                }}
                                                color="primary"
                                                fullWidth={false}
                                                onChange={(e) => setAttr({ ...attr, addprice: e.target.value })}
                                            />,
                                            <Button onClick={editAddPrice} color="success">
                                                <SaveIcon /> เพิ่มราคา
                                            </Button>
                                        ],
                                        [
                                            <b>ลดราคาทุกสินค้าเดียวกัน</b>,
                                            <Input
                                                id="subprice"
                                                placeholder="0.00"
                                                inputProps={{
                                                    "aria-label": "subprice",
                                                    value: attr.subprice,
                                                    type: "number",
                                                }}
                                                classes={{
                                                    input: classes.right,
                                                }}
                                                color="primary"
                                                fullWidth={false}
                                                onChange={(e) => setAttr({ ...attr, subprice: e.target.value })}
                                            />,
                                            <Button onClick={editSubPrice} color="danger">
                                                <SaveIcon /> ลดราคา
                                            </Button>
                                        ],
                                    ]}
                                    customHeadClassesForCells={[1, 2]}
                                    customHeadCellClasses={[classes.right, classes.right]}
                                    customClassesForCells={[1, 2]}
                                    customCellClasses={[classes.right, classes.right]}
                                    coloredColls={[1]}
                                    colorsColls={["primary"]}
                                />
                            </GridItem>
                        </GridContainer>
                    </form>
                </DialogContent>
            </Dialog>
        );
    };

    const renderLoading = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    /* ========== ZONE Render ========== */
    return (
        <div>
            {renderViewList()}
            {renderModalEdit()}
            {renderLoading()}
            {alert}
            {notify}
        </div>
    );
}

export default withNamespaces()(PromotionAll);
