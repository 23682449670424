import React from "react";
import { useSelector } from "react-redux";

import DashboardAdmin from "./DashboardAdmin";
import DashboardDefault from "./DashboardDefault";
import DashboardSale from "./DashboardSale";

export default function Dashboard() {
  const users = useSelector((state) => state.user);

  if (users.dashboard == "admin") return <DashboardAdmin />;
  else if (users.dashboard == "user") return <DashboardSale />;
  else if (users.dashboard == "sale") return <DashboardSale />;
  else return <DashboardDefault />;
}
