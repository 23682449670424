/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Badge from "components/Badge/Badge.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import UploadPicture from "./UploadPicture";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import InputIcon from "@material-ui/icons/Input";
import SearchIcon from "@material-ui/icons/Search";
import ListAltIcon from "@material-ui/icons/ListAlt";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";

// core components
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import PosSlip from "views/Pos/PosSlip";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  branch_code: "",
  member_code: "",
  payment_type: "",
  date_record: "",
  name: "",
  tel: "",
  date_due: "",
  payment: "",
  remark: "",
  amount: "",
  discount: "",
  transfer: "",
  shipping: "",
  shipping_price: "",
  vat: "",
  total: "",
  money: "",
  pay: "",
  balance: "",
  money_change: "",
  address: "",
  emp_code: "",
  image: "",
  branch_code: "",
  branch_name: "",
  payment_name: "",
  account_bank: "",
  account_name: "",
  account_accno: "",
  account_code: "",
  transfer_bank: "",
  transfer_date: new Date(),
  transfer_time: "",
  transfer_remark: "",
};

const initFind = {
  search: "",
  order_by: "tb.id",
  order_sort: "desc",
  status: "ALL",
  status_invoice: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function CustomerInvoice({ t, invoiceId, reloadCustomer }) {
  const mod = "customerinvoice";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.id", name: t(`${prefix}.id`), order: "desc" },
    { code: "tb.date_create", name: t(`${prefix}.date_record`) },
    { code: "tb.amount", name: t(`${prefix}.amount`) },
    { code: "tb.account_code", name: "บัญชีธนาคาร" },
    { code: "tb.status", name: t(`${prefix}.status`) },
  ];
  const alignValue = [0, 1, 2, 4, 5];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const slipRef = useRef(null);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [cboStatus, setCboStatus] = useState([]);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [modalList, setModalList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [slip, setSlip] = useState({});
  const [slipList, setSlipList] = useState([]);
  const [cboBank, setCboBank] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    setFind({ ...find, search: invoiceId });
    init();
  }, [id]);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboBank(result.bank);

        view({ ...find, search: invoiceId });
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      status: data.status,
      member: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {item.id}
      </Button>
    );
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const displayBank = (item) => {
    if (item.account_code > 0)
      return (
        <span>
          <img src={item.bank_pic} width="20" /> {item.acc_name} - {item.acc_no}
        </span>
      );
    else return null;
  };

  const uploadPicture = (filedata) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "upload", {
            code: attr.code,
            token: app.token,
            lang: app.lang,
            filedata: filedata,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              open(attr);
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("upload.upload")}
      </SweetAlert>
    );
  };

  const editInvoice = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editinvoice", {
            token: app.token,
            lang: app.lang,
            code: attr.code,
            data: {
              transfer_bank: attr.transfer_bank,
              transfer_date: Func.dateFormat(attr.transfer_date),
              transfer_time: attr.transfer_time,
              transfer_remark: attr.transfer_remark,
            },
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const editStatus = (code, status) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edit", {
            code: code,
            data: {
              status: status,
            },
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({
          ...result.data,
          transfer_date: new Date(result.data.transfer_date),
        });

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              item.so_id,
              Func.dateTimeDisplay(item.date_create),
              displayStatus(item),

              Func.numberDisplay(item.nettotal),
              Func.numberDisplay(item.pay),
              Func.numberDisplay(item.balance),
            ],
          ]);
        });

        setLoading(false);
        setModalList(true);
      }
    });
  };

  const print = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.invoicelist);

        handlePrint();
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        {/* <Button
          color="success"
          className={classes.actionButton}
          size="sm"
          onClick={() => open(item)}
        >
          {t(`element.open`)} <InputIcon />
        </Button>
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print(item)}
          size="sm"
        >
          {t(`element.print`)} <PrintIcon />
        </Button> */}
        {item.status <= 2 ? (
          <Button
            color="danger"
            className={classes.actionButton}
            size="sm"
            onClick={() => editStatus(item.code, 9)}
          >
            ยกเลิก <CancelIcon />
          </Button>
        ) : null}
      </>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6}>
                  <TextField
                    id="search"
                    value={find.search}
                    fullWidth
                    InputProps={{
                      onChange: (e) => {
                        view({ ...find, search: e.target.value, page: 1 });
                        setFind({ ...find, search: e.target.value, page: 1 });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {t("element.search")} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  "",
                ]}
                tableData={record.map((item) => {
                  return [
                    btnOpen(item),
                    Func.dateTimeDisplay(item.date_create),
                    Func.numberDisplay(item.amount),
                    displayBank(item),
                    displayStatus(item),
                    btn(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[5, 6, 7]}
                colorsColls={["primary", "danger", "success"]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}></InputLabel>
                <Select
                  value={find.perPage}
                  onChange={(e) => {
                    view({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                    setFind({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                  }}
                >
                  {cboPerPage &&
                    cboPerPage.map((item) => (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {t(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                count={pageTotal}
                showFirstButton
                showLastButton
                page={pageNo}
                onChange={(e, p) => {
                  view({ ...find, page: p });
                  setFind({ ...find, page: p });
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderStatus()}
          <CustomTabs
            headerColor="rose"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.order"),
                tabIcon: ListAltIcon,
                tabContent: renderList(),
              },
              {
                tabName: "การชำระเงิน",
                tabIcon: ListAltIcon,
                tabContent: renderSlip(),
              },
            ]}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const renderSlip = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                labelText="การชำระเงิน"
                id="payment_name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.account_code > 0 ? "โอนเงิน" : "เงินสด",
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="ธนาคารรับเงิน"
                id="account_bank"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.account_bank,
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="บัญชีรับเงิน"
                id="account_name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.account_name,
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="เลขบัญชีรับเงิน"
                id="account_accno"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.account_accno,
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="transfer_bank"
                  className={classes.labelSelect}
                >
                  ธนาคารที่โอน
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={attr.transfer_bank}
                  inputProps={{
                    name: "transfer_bank",
                  }}
                  onChange={selectChange}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {t("element.pleaseselect")}
                  </MenuItem>
                  {cboBank &&
                    cboBank.map((item) => (
                      <MenuItem
                        key={item.code}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.code}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputLabel className={classes.labelDpk}>วันที่โอน</InputLabel>
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  inputProps={{
                    placeholder: "__/__/____",
                  }}
                  dateFormat="DD/MM/yyyy"
                  closeOnSelect
                  value={attr.transfer_date}
                  onChange={(value) => {
                    setAttr({ ...attr, transfer_date: value });
                  }}
                  className={classes.center}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="เวลาโอน"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) =>
                    setAttr({
                      ...attr,
                      transfer_time: e.target.value,
                    }),
                  onBlur: (e) =>
                    setAttr({
                      ...attr,
                      transfer_time: Func.timeSetup(e.target.value),
                    }),
                  value: attr.transfer_time,
                }}
                alignCenter
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="หมายเหตุ"
                id="transfer_remark"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: inputChange,
                  value: attr.transfer_remark,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <Button color="success" onClick={editInvoice}>
                <SaveIcon /> {t("element.save")}
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardBody>
              <a href={attr.image} target="_blank">
                <div className="fileinput text-center">
                  <div className={"thumbnail"} style={{ maxWidth: "100%" }}>
                    <img src={attr.image} alt="..." />
                  </div>
                </div>
              </a>
              <UploadPicture uploadData={uploadPicture} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              "เลขที่บิล",
              "วันที่",
              "สถานะ",
              "จำนวนเงิน",
              "ชำระแล้ว",
              "ค้างชำระ",
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[2, 3, 4]}
            colorsColls={["primary", "success", "danger"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status * 1}
        alternativeLabel
        className={classes.bgTransparent}
      >
        <Step>
          <StepLabel>
            <p>
              <b>รอชำระ</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        {attr.status == 9 ? (
          <Step>
            <StepLabel>
              <p>
                <Badge color="danger">ยกเลิก</Badge>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_9}</p>
              <p className={classes.colorDefault}>{attr.approve_date_9}</p>
            </StepLabel>
          </Step>
        ) : (
          <Step>
            <StepLabel>
              <p>
                <b>ชำระแล้ว</b>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_3}</p>
              <p className={classes.colorDefault}>{attr.approve_date_3}</p>
            </StepLabel>
          </Step>
        )}
      </Stepper>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <PosSlip ref={slipRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalList()}
      {renderPrint()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(CustomerInvoice);
