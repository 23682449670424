/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Datetime from "react-datetime";
import copy from "copy-to-clipboard";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import DateRangeIcon from "@material-ui/icons/DateRange";
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import i18n from "components/Translation/Translate.js";
import Button from "components/CustomButtons/Button.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import { API_URL } from "services/Define";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initFind = {
    search: "",
    date_start: Func.dateAdd(-30),
    date_stop: new Date(),
    order_by: "tb.id",
    order_sort: "asc",
    branch: "ALL",
    status: "ALL",
    limit: 100,
    page: 1,
    perPage: 10,
    pageTotal: 0,
};

/* ========== ZONE Function ========== */
function RpInvoicePaySum({ t }) {
    const mod = "rp_invoicepaysum";
    const prefix = "bill";
    const classes = useStyles();

    const initHeader = [
        { code: "page", name: "Page", sort: false },
        { code: "link", name: "Link", sort: false },
        { code: "copy", name: "", sort: false },
        { code: "copy", name: "", sort: false },
    ];
    const alignValue = [2, 3];
    const alignItem = [
        classes.center,
        classes.center,
    ];
    const cboPerPage = [
        { id: 10, name: "element.show_10_record" },
        { id: 20, name: "element.show_20_record" },
        { id: 100, name: "element.show_100_record" },
    ];

    const app = useSelector((state) => state.appState);

    const [record, setRecord] = useState([]);
    const [find, setFind] = useState(initFind);
    const [header, setHeader] = useState(initHeader);
    const [loading, setLoading] = useState(false);
    const [notify, setNotify] = useState(null);
    const [cboLimit, setCboLimit] = useState([]);
    const [deliveryLink, setDeliveryLink] = useState("");

    useEffect(() => {
        i18n.changeLanguage(app.lang);
        init();
    }, []);

    const init = () => {
        PostData(mod, "init", {
            token: app.token,
            lang: app.lang,
        }).then((result) => {
            if (result.status === "OK") {
                setCboLimit(result.limit);

                view(find);
            } else {
                Func.exitApp();
            }
        });
    };

    const view = (data) => {
        setFind(data);
        setLoading(true);
        setRecord([]);

        PostData(mod, "view", {
            token: app.token,
            lang: app.lang,
            search: data.search,
            page: data.page,
            per_page: data.perPage,
            orderby: data.order_by,
            asc: data.order_sort,
            branch: data.branch,
            limit: data.limit,
            start: Func.dateFormat(data.date_start),
            stop: Func.dateFormat(data.date_stop),
            status: data.status,
        }).then((result) => {
            if (result.status === "OK") {
                let content = "";
                result.data.map((item, i) => {
                    setRecord((record) => [
                        ...record,
                        [
                            btnOpen(item),
                            btnLink(item),
                            btnCopy(item),
                            btnDownload(item),
                        ],
                    ]);
                    content += `${item.link}\n`
                });

                setDeliveryLink(content)
            }
            setLoading(false);
        });
    };

    const btnCopy = (item) => {
        return <Button color="info" onClick={() => copyLink(item.link)}>
            <i className="fas fa-copy fa-fw" /> Copy
        </Button>;
    };

    const btnDownload = (item) => {
        return <Button color="danger" onClick={() => window.open(item.download, "_self")}>
            <i className="fas fa-download fa-fw" /> Download
        </Button>;
    };

    const copyLink = (data) => {
        copy(data);
        showNotify("Copy success", "success");
    };


    const showNotify = (msg, color) => {
        setNotify(
            <Snackbar
                place="tr"
                color={color}
                icon={AlertIcon}
                message={msg}
                open={true}
                closeNotification={() => setNotify(null)}
                close
            />
        );

        setTimeout(function () {
            setNotify(null);
        }, 3000);
    };

    const btnOpen = (item) => {
        return (
            <Button color="primary" simple onClick={() => window.open(item.link, "_blank")}>
                {item.name}
            </Button>
        );
    };

    const btnLink = (item) => {
        return (
            <Button color="danger" simple
                style={{ textTransform: "inherit" }}
                onClick={() => window.open(item.link, "_blank")}>
                {item.link}
            </Button>
        );
    };

    const renderHeader = (data) => {
        let icon = <i className="fas fa-sort"></i>;
        if (data.order === "desc") {
            icon = <i className="fad fa-sort-up"></i>;
        } else if (data.order === "asc") {
            icon = <i className="fad fa-sort-down"></i>;
        } else {
            icon = <i className="fad fa-sort"></i>;
        }

        if (data.sort === undefined || data.sort)
            return (
                <div
                    onClick={() => {
                        let tmp = [];
                        header.map((item, i) => {
                            if (data.code === item.code) {
                                const ordersort = item.order === "asc" ? "desc" : "asc";
                                view({ ...find, order_sort: ordersort, order_by: data.code });
                                setFind({
                                    ...find,
                                    order_sort: ordersort,
                                    order_by: data.code,
                                });
                                tmp[i] = {
                                    code: item.code,
                                    name: item.name,
                                    order: ordersort,
                                    sort: item.sort,
                                };
                            } else {
                                tmp[i] = {
                                    code: item.code,
                                    name: item.name,
                                    order: "",
                                    sort: item.sort,
                                };
                            }
                        });

                        setHeader(tmp);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {data.name} {icon}
                </div>
            );
        else return <div>{data.name}</div>;
    };

    const renderViewList = () => {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card className={classes.boxPrimary}>
                        <CardHeader color="primary" icon className={classes.boxHead}>
                            <h4 className={classes.boxTitle}>
                                <i className="fas fa-file-alt color-primary" />
                                {t("element.report")}
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className="viewlist">
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/yyyy"
                                            closeOnSelect
                                            value={find.date_start}
                                            onChange={(value) => view({ ...find, date_start: value })}
                                            className={classes.center}
                                            renderInput={(props) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        InputProps={{
                                                            ...props,
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    style={{ marginBottom: "4px" }}
                                                                >
                                                                    {t("element.start")} :
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: <DateRangeIcon />,
                                                            autoComplete: "off",
                                                            readOnly: true,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/yyyy"
                                            closeOnSelect
                                            value={find.date_stop}
                                            onChange={(value) => view({ ...find, date_stop: value })}
                                            className={classes.center}
                                            renderInput={(props) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        InputProps={{
                                                            ...props,
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    style={{ marginBottom: "4px" }}
                                                                >
                                                                    {t("element.stop")} :
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: <DateRangeIcon />,
                                                            autoComplete: "off",
                                                            readOnly: true,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={find.limit}
                                            onChange={(e) => {
                                                view({ ...find, limit: e.target.value, page: 1 });
                                            }}
                                        >
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value="ALL"
                                            >
                                                จำนวนบิล
                                            </MenuItem>
                                            {cboLimit.map((item) => (
                                                <MenuItem
                                                    key={item.code}
                                                    classes={{
                                                        root: classes.selectMenuItem,
                                                        selected: classes.selectMenuItemSelected,
                                                    }}
                                                    value={item.code}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <Button color="info" onClick={() => copyLink(deliveryLink)}>
                                        <i className="fas fa-copy fa-fw" /> Copy all
                                    </Button>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12}>
                                    <Table
                                        striped
                                        hover
                                        tableHead={[
                                            renderHeader(header[0]),
                                            renderHeader(header[1]),
                                            renderHeader(header[2]),
                                            renderHeader(header[3]),
                                        ]}
                                        tableData={record}
                                        customHeadClassesForCells={alignValue}
                                        customHeadCellClasses={alignItem}
                                        customClassesForCells={alignValue}
                                        customCellClasses={alignItem}
                                        coloredColls={[]}
                                        colorsColls={[]}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    const renderLoading = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    /* ========== ZONE Render ========== */
    return (
        <div>
            {renderViewList()}
            {renderLoading()}
            {notify}
        </div>
    );
}

export default withNamespaces()(RpInvoicePaySum);
