/*eslint-disable*/
import React, { useState, useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { Avatar, Fab, makeStyles, Typography } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { arrayMoveImmutable } from "array-move";
import "./product_sort.css";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertIcon from "@material-ui/icons/AddAlert";
import CheckIcon from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
    userSelect: "none",
  },
  item: {
    position: "relative",
    flexShrink: 0,
    display: "flex",
    margin: 8,
    userSelect: "none",
    boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
    // borderRadius: "100%",
    padding: "5px",
    cursor: "pointer",
  },
  image: {
    width: 150,
    height: 150,
    pointerEvents: "none",
  },
  button: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  dragged: {
    boxShadow:
      "0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    "& button": {
      opacity: 0,
    },
  },
  name: {
    display: "flex",
    alignItems: "center",
    cursor: "grab",
    position: "absolute",
    bottom: 0,
    left: 0,
    background: "white",
    padding: "2px 4px",
    borderRadius: 4,
  },
  font: {
    fontFamily: '"Sarabun", "Helvetica", "Arial", sans-serif',
  },
  center: {
    textAlign: "center !important",
  },
});

const initAttr = {
  code: "",
  id: "",
  name_th: "",
  name_en: "",
  sort: "",
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "asc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function ProductSort({ t }) {
  const mod = "category";
  const prefix = "attr";
  const classes = useStyles();

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [record, setRecord] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "initsort", {
      token: app.token,
      lang: app.lang,
      cat_code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);
      } else {
        Func.exitApp();
      }
    });
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setRecord((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const edit = () => {
    PostData(mod, "productsort", {
      code: attr.code,
      cat_code: id,
      data: record,
    }).then((result) => {
      if (result.status === "OK") {
        init();
        showNotify(t("alert.editcomplete"), "success");
      } else {
        showNotify(t("alert.editerror"), "danger");
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderInfo = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <SortableList
            onSortEnd={onSortEnd}
            className={classes.root}
            draggedItemClassName={classes.dragged}
          >
            {record.map((item) => (
              <SortableItem key={item.code}>
                <div className={classes.item}>
                  <Avatar
                    className={classes.image}
                    alt={item.name}
                    src={item.filepic}
                    imgProps={{ draggable: false }}
                    variant="square"
                  />
                  <div className={classes.name}>
                    <DragIndicatorIcon />
                    <Typography variant="caption" className={classes.font}>
                      {item.name}
                    </Typography>
                  </div>
                  <Fab
                    color="secondary"
                    size="small"
                    className={classes.button}
                    aria-label="sort"
                  >
                    {item.sort}
                  </Fab>
                </div>
              </SortableItem>
            ))}
          </SortableList>
        </GridItem>
        <GridItem xs={12} className={classes.center}>
          <hr />
          <Button color="success" onClick={edit}>
            <SaveIcon /> {t("element.save")}
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderInfo()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(ProductSort);
