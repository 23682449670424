/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import AlertIcon from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
    finish: 0,
    remain: 0,
    total: 0,
    percent: 0.0,
};

/* ========== ZONE Function ========== */
function GenCode({ t }) {
    const mod = "gencode";
    const classes = useStyles();
    const [notify, setNotify] = useState(null);

    const app = useSelector((state) => state.appState);
    const [attr, setAttr] = useState(initAttr);
    const [start, setStart] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // i18n.changeLanguage(app.lang);

        let timer = setInterval(() => {
            if (start && !loading) {
                genCode();
            }
        }, 1000);
        return () => clearInterval(timer);

    }, [start, loading]);

    const showNotify = (msg, color) => {
        setNotify(
            <Snackbar
                place="tr"
                color={color}
                icon={AlertIcon}
                message={msg}
                open={true}
                closeNotification={() => setNotify(null)}
                close
            />
        );

        setTimeout(function () {
            setNotify(null);
        }, 3000);
    };

    const init = () => {
        PostData(mod, "init", {
            token: app.token,
            lang: app.lang,
        }).then((result) => {
            if (result.status === "OK") {
                setAttr(result.data)
            } else {
                // Func.exitApp();
            }
        });
    };

    const genCode = () => {
        setLoading(true)
        PostData(mod, "gencode", {
            token: app.token,
            lang: app.lang,
        }).then((result) => {
            if (result.status === "OK") {
                // showNotify(t("alert.editcomplete"), "success");
                setAttr(result.data);
                setLoading(false);

                if (!result.data.run) {
                    setStart(false);
                }
            } else {
                // Func.exitApp();
            }
        });
    };

    const renderPage = () => {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <h4>Invoice pay code : {attr.finish} / {attr.total} ({attr.percent}%)</h4>
                                </GridItem>
                                <GridItem xs={6}>
                                    {start ? <Button
                                        color="danger"
                                        onClick={() => setStart(false)}
                                    >
                                        Stop
                                    </Button> :
                                        <Button
                                            color="success"
                                            onClick={() => setStart(true)}
                                        >
                                            Run
                                        </Button>}
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    const renderLoading = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    /* ========== ZONE Render ========== */
    return (
        <div>
            {renderPage()}
            {/* {renderLoading()} */}
            {notify}
        </div>
    );
}

export default withNamespaces()(GenCode);
