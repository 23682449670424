import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { PostData } from "../services/Request";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import { withNamespaces } from "react-i18next";
import { useDispatch } from "react-redux";
import { setUser } from "redux/actions/UserAction";

import route from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";

var ps;
var routes = [];

const useStyles = makeStyles(styles);

function Dashboard({ props, t }) {
  const users = useSelector((state) => state.user);
  const perm = useSelector((state) => state.perm);

  const { ...rest } = props;
  // states and functions
  // const [countNotification, setCountNotification] = useState("0");
  const [notifyOrder, setNotifyOrder] = useState({ count: 0, data: [] });
  const [notifyShipping, setNotifyShipping] = useState({ count: 0, data: [] });
  const [notifyMove, setNotifyMove] = useState({ count: 0, data: [] });
  // const [notifyBalance, setNotifyBalance] = useState("0.00");
  const [mobileOpen, setMobileOpen] = useState(false);
  const [miniActive, setMiniActive] = useState(false);
  const [image, setImage] = useState(null);
  // const [image, setImage] = useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = useState("white");
  const [bgColor, setBgColor] = useState("white");
  // const [hasImage, setHasImage] = useState(true);
  const [fixedClasses, setFixedClasses] = useState("dropdown");
  const [logo, setLogo] = useState(require("assets/img/logo.svg"));
  const dispatch = useDispatch();
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  const mainPanelClassesMini =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: true,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });

  const checkAuthen = () => {
    PostData("authen", "check", {
      token: users.token,
    }).then((result) => {
      if (result.status !== "OK") {
        window.location.replace("/auth/login");
      }
    });
  };

  const findArray = (arr, inp, def) => {
    let result = arr.find(({ id }) => id === inp);
    if (result === undefined) result = def;

    return result;
  };

  const setRoute = () => {
    var result = [];

    route.map((main, i) => {
      if (main.collapse) {
        let found = false;
        let temp_sub = [];

        main.views.map((sub, i) => {
          let permiss = findArray(perm.menu, sub.id, { enable: "N" });
          if (permiss.enable === "Y") {
            found = true;
            temp_sub = [...temp_sub, sub];
          }
        });

        if (found) {
          let temp_main = main;
          temp_main.views = temp_sub;

          result = [...result, temp_main];
        }
      } else {
        let permiss = findArray(perm.menu, main.id, { enable: "N" });

        if (permiss.enable === "Y") {
          result = [...result, main];
        }
      }
    });

    return result;
  };

  routes = setRoute();

  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    // console.log(users);
    // console.log(perm);
    // checkAuthen();
    init();

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);

  const init = () => {
    PostData("notification", "init", {}).then((result) => {
      if (result.status === "OK") {
        setNotifyOrder(result.order);
        setNotifyShipping(result.shipping);
        setNotifyMove(result.move);

        dispatch(setUser({ ...users, balance: result.balance }));

        setTimeout(function () {
          init();
        }, 60000);
      }
    });
  };

  // functions for changeing the states from components
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleBgColorClick = (bgColor) => {
    switch (bgColor) {
      case "white":
        setLogo(require("assets/img/logo.svg"));
        break;
      default:
        setLogo(require("assets/img/logo-white.svg"));
        break;
    }
    setBgColor(bgColor);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/pos";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        let p = window.location.pathname.split("/");
        if (p[2] !== undefined) {
          if ("/" + p[2] === routes[i].path) {
            return t(routes[i].name);
          }
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getRoutes2 = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes2(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path + "/:id"}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const renderFixedPlugin = () => {
    return (
      <FixedPlugin
        handleImageClick={handleImageClick}
        handleColorClick={handleColorClick}
        handleBgColorClick={handleBgColorClick}
        color={color}
        bgColor={bgColor}
        bgImage={image}
        handleFixedClick={handleFixedClick}
        fixedClasses={fixedClasses}
        sidebarMinimize={sidebarMinimize.bind(this)}
        miniActive={miniActive}
      />
    );
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={t(`app.name`)}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={getRoute() ? miniActive : true}
        users={users}
        notifyOrder={notifyOrder}
        notifyShipping={notifyShipping}
        notifyMove={notifyMove}
        notifyBalance={users.balance}
        {...rest}
      />
      <div
        className={getRoute() ? mainPanelClasses : mainPanelClassesMini}
        ref={mainPanel}
      >
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={getRoute() ? miniActive : true}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          notifyOrder={notifyOrder}
          notifyShipping={notifyShipping}
          notifyMove={notifyMove}
          notifyBalance={users.balance}
          {...rest}
        />

        <div className={classes.content}>
          <div className={classes.container}>
            <Switch>
              {getRoutes2(routes)}
              {getRoutes(routes)}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withNamespaces()(Dashboard);
