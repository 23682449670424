/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import AlertIcon from "@material-ui/icons/AddAlert";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import SlipShipping from "views/Print/SlipShipping";

import styles from "assets/jss/main-style.js";

function StepIcon(props) {
  const { active, completed } = props;
  const icons_complete = {
    1: <AddCircleIcon color="primary" />,
    2: <LocalShippingIcon color="primary" />,
    3: <MonetizationOnIcon color="primary" />,
    4: <CancelIcon color="error" />,
  };
  const icons = {
    1: <AddCircleIcon color="disabled" />,
    2: <LocalShippingIcon color="disabled" />,
    3: <MonetizationOnIcon color="disabled" />,
    4: <CancelIcon color="disabled" />,
  };
  return (
    <div>
      {completed
        ? icons_complete[String(props.icon)]
        : icons[String(props.icon)]}
    </div>
  );
}

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  branch_code: "",
  member_code: "",
  payment_type: "",
  date_record: "",
  date_shipping: "",
  time_shipping: "",
  name: "",
  tel: "",
  date_due: "",
  payment: "",
  remark: "",
  amount: "",
  discount: "",
  transfer: "",
  shipping: "",
  shipping_price: "",
  vat: "",
  total: "",
  money: "",
  pay: "",
  balance: "",
  money_change: "",
  address: "",
  emp_code: "",
  branch_code: "",
  branch_name: "",
  branch_warehouse: "",
  wallet_pay: "",
  wallet_balance: "",
  transfer_remark: "",
};

const initFind = {
  show: "TIME",
  search: "",
  date_start: new Date(),
  date_stop: new Date(),
  order_by: "tb.status_shipping",
  order_sort: "desc",
  status: "ALL",
  branch: "ALL",
  warehouse: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function TransferShipping({ t, dateRecord, reloadCustomer }) {
  const mod = "transfershipping";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.id", name: t(`${prefix}.id`), order: "desc" },
    { code: "tb.member_code", name: "ลูกค้า" },
    { code: "tb.branch_code", name: t(`${prefix}.branch`) },
    { code: "tb.branch_warehouse", name: t(`${prefix}.warehouse`) },
    { code: "tb.address", name: t(`${prefix}.address`) },
    { code: "tb.car_total", name: t(`${prefix}.shipping_price`) },
    { code: "tb.shipping_balance", name: "ค้างชำระ" },
    { code: "tb.date_shipping", name: "กำหนดส่ง" },
    { code: "tb.approve_date_2", name: "วันจัดส่ง" },
    { code: "tb.status_shipping", name: t(`${prefix}.status`) },
    { code: "tb.transfer_remark", name: "หมายเหตุ" },
  ];
  const alignValue = [0, 1, 6, 7, 8, 9, 10];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const txtAmount = useRef(null);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [cboStatus, setCboStatus] = useState([]);
  const [cboTransfer, setCboTransfer] = useState([]);
  const [cboBranch, setCboBranch] = useState([]);
  const [cboCar, setCboCar] = useState([]);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [slip, setSlip] = useState(initAttr);
  const [slipList, setSlipList] = useState([]);
  const [modalList, setModalList] = useState(false);
  const [tab, setTab] = useState(0);
  const [checked, setChecked] = useState([]);
  const [modalPayment, setModalPayment] = useState(false);
  const [order, setOrder] = useState([]);
  const slipRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    setFind({
      ...find,
      date_start: new Date(dateRecord),
      date_stop: new Date(dateRecord),
    });

    init();
  }, [id, init, setFind]);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboTransfer(result.transfer);
        setCboBranch(result.branch);
        setCboCar(result.car);

        view({
          ...find,
          date_start: new Date(dateRecord),
          date_stop: new Date(dateRecord),
        });
      } else {
        Func.exitApp();
      }
    });
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const handleCheck = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      show: data.show,
      branch: data.branch,
      warehouse: data.warehouse,
      car: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);
    setTab(0);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({
          ...result.data,
          date_record: new Date(result.data.date_record),
          date_due: new Date(result.data.date_due),
          date_shipping: new Date(result.data.date_shipping),
        });

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              item.price,
              item.quantity,
              item.amount,
              Func.numberDisplay(item.quantity_old),
              Func.numberDisplay(item.quantity_return),
            ],
          ]);
        });

        setList((list) => [
          ...list,
          {
            total: true,
            title: t(`${prefix}.amount`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.amount),
          },
          {
            total: true,
            title: t(`${prefix}.vat`) + " " + result.vat + " %",
            colspan: "8",
            amount: Func.numberDisplay(result.data.vat),
          },
          {
            total: true,
            title: t(`${prefix}.total`),
            colspan: "8",
            amount: displayTotal(result.data.total, classes.colorPrimary),
          },
          {
            total: true,
            title: "ปริมาณ (Kg)",
            colspan: "8",
            amount: Func.numberDisplay(result.data.quantity),
          },
          {
            total: true,
            title: "ค่าขนส่ง / Kg",
            colspan: "8",
            amount: Func.numberDisplay(result.data.car_price),
          },
          {
            total: true,
            title: t(`${prefix}.shipping`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.shipping_price),
          },
          {
            total: true,
            title: t(`${prefix}.nettotal`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.nettotal),
          },
          {
            total: true,
            title: "",
            colspan: "8",
            amount: "",
          },
          {
            total: true,
            title: "ค่าขนส่ง",
            colspan: "8",
            amount: Func.numberDisplay(result.data.car_total),
          },
          {
            total: true,
            title: t(`${prefix}.pay`),
            colspan: "8",
            amount: displayTotal(
              result.data.shipping_pay,
              classes.colorSuccess
            ),
          },
          {
            total: true,
            title: t(`${prefix}.balance`),
            colspan: "8",
            amount: displayTotal(
              result.data.shipping_balance,
              classes.colorDanger
            ),
          },
        ]);

        setModalList(true);
      }
      setLoading(false);
    });
  };

  const addPay = () => {
    if (confirm("คุณต้องการบันทึกข้อมูล ใช่หรือไม่?")) {
      setLoading(true);
      setModalPayment(false);

      PostData(mod, "addpay", {
        car_code: id,
        checked: checked,
        amount: attr.amount,
      }).then((result) => {
        setLoading(false);

        if (result.status === "OK") {
          reloadCustomer();
          view(find);
          setChecked([]);

          showNotify(t("alert.editcomplete"), "success");
        } else {
          showNotify(t("alert.editerror"), "danger");
        }
      });
    }
  };

  const displayTotal = (number, cls) => {
    return <div className={cls}>{Func.numberDisplay(number)}</div>;
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {item.id}
      </Button>
    );
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const displayShipping = (item) => {
    if (item.transfer == 2) {
      return <div>จัดส่งสาขา {item.branch_transfer}</div>;
    } else if (item.transfer == 3) {
      return (
        <div style={{ whiteSpace: "normal", width: "300px" }}>
          {item.address}
        </div>
      );
    } else {
      return "";
    }
  };

  const edit = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setModalList(false);
          setLoading(true);

          PostData(mod, "edit", {
            code: attr.code,
            data: {
              transfer: attr.transfer,
              branch_transfer: attr.branch_transfer,
              address: attr.transfer === "3" ? attr.address : "",
              branch_warehouse:
                attr.transfer === "3" ? attr.branch_warehouse : "",
              car_code: attr.transfer === "3" ? attr.car_code : "",
              remark: attr.remark,
              date_shipping: Func.dateFormat(attr.date_shipping),
              time_shipping: attr.time_shipping,
              shipping_price: attr.shipping_price,
              date_record: Func.dateFormat(attr.date_record),
              date_due: Func.dateFormat(attr.date_due),
            },
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const displayPayment = (inp) => {
    if (inp == "CASH") return "เงินสด";
    else if (inp == "TRANSFER") return "โอนเงิน";
    else if (inp == "CREDIT") return "เงินเชื่อ";
    else if (inp == "WALLET") return "Wallet";
    else return "";
  };

  const print = (item) => {
    setLoading(true);
    setSlip({});

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);

        handlePrint();
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        {item.status_shipping === "1" ? (
          <Button
            color="success"
            className={classes.actionButton}
            size="sm"
            onClick={() => editTransfer(item)}
          >
            {t(`${prefix}.shipping`)} <LocalShippingIcon />
          </Button>
        ) : null}
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print(item)}
          size="sm"
        >
          {t(`element.print`)} <PrintIcon />
        </Button>
        {item.status_shipping === "1" && item.status_cancel === "Y" ? (
          <Button
            color="danger"
            className={classes.actionButton}
            onClick={() => editCancel(item)}
            size="sm"
          >
            เลิกจัดส่ง <CancelIcon />
          </Button>
        ) : null}
      </>
    );
  };

  const editTransfer = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edittransfer", {
            code: item.code,
          }).then((result) => {
            setLoading(false);
            if (result.status === "OK") {
              reloadCustomer();
              view(find);

              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{item.id}
      </SweetAlert>
    );
  };

  const editCancel = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editcancel", {
            code: item.code,
          }).then((result) => {
            setLoading(false);
            if (result.status === "OK") {
              reloadCustomer();
              view(find);

              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{item.id}
      </SweetAlert>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const openPay = (chk) => {
    setLoading(true);
    setAttr(initAttr);
    setOrder([]);

    PostData(mod, "openpay", {
      token: app.token,
      lang: app.lang,
      code: id,
      checked: chk,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setChecked(chk);

        result.list.map((item, i) => {
          setOrder((order) => [...order, [item.id, item.balance]]);
        });

        setLoading(false);
        setModalPayment(true);

        setTimeout(function () {
          txtAmount.current.select();
        }, 500);
      }
    });
  };

  const transferAll = (chk) => {
    setLoading(true);

    PostData(mod, "transferall", {
      token: app.token,
      lang: app.lang,
      code: id,
      checked: chk,
    }).then((result) => {
      if (result.status === "OK") {
        view(find);
        setChecked(chk);

        setLoading(false);
      }
    });
  };

  const renderCheckAll = () => {
    return (
      <Checkbox
        key="ALL"
        className={classes.positionAbsolute}
        tabIndex={-1}
        onClick={(e) => {
          setChecked([]);
          if (e.target.checked)
            record.map((item) => {
              setChecked((checked) => [...checked, item.code]);
            });
        }}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot,
        }}
      />
    );
  };

  const renderCheck = (item) => {
    if (item.transfer_status != 1) return null;
    else
      return (
        <Checkbox
          key={item.code}
          className={classes.positionAbsolute}
          tabIndex={-1}
          checked={checked.indexOf(item.code) !== -1}
          onClick={() => handleCheck(item.code)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
        />
      );
  };

  const renderAction = () => {
    return (
      <CustomDropdown
        dropup
        buttonText={t(`${prefix}.action`)}
        buttonProps={{
          color: "primary",
          size: "sm",
        }}
        dropdownList={[
          {
            id: "payment",
            title: (
              <div>
                <i className="fas fa-file-invoice-dollar" />{" "}
                {t(`${prefix}.payment`)}
              </div>
            ),
          },
          {
            id: "transfer",
            title: (
              <div>
                <i className="fad fa-shipping-timed" /> จัดส่ง
              </div>
            ),
          },
        ]}
        onClick={(item) => {
          switch (item.id) {
            case "payment":
              openPay(checked);
              break;
            case "transfer":
              transferAll(checked);
              break;
          }
        }}
      />
    );
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.show}
                      onChange={(e) => {
                        view({ ...find, show: e.target.value, page: 1 });
                        setFind({ ...find, show: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="TIME"
                      >
                        แสดงตามช่วงเวลา
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        แสดงทั้งหมด
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                {find.show === "TIME" ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/yyyy"
                        closeOnSelect
                        value={find.date_start}
                        onChange={(value) => {
                          view({ ...find, date_start: value, page: 1 });
                          setFind({ ...find, date_start: value, page: 1 });
                        }}
                        className={classes.center}
                        renderInput={(props) => {
                          return (
                            <TextField
                              fullWidth
                              InputProps={{
                                ...props,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginBottom: "4px" }}
                                  >
                                    {t("element.start")} :
                                  </InputAdornment>
                                ),
                                endAdornment: <DateRangeIcon />,
                                autoComplete: "off",
                                readOnly: true,
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                ) : null}
                {find.show === "TIME" ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/yyyy"
                        closeOnSelect
                        value={find.date_stop}
                        onChange={(value) => {
                          view({ ...find, date_stop: value, page: 1 });
                          setFind({ ...find, date_stop: value, page: 1 });
                        }}
                        className={classes.center}
                        renderInput={(props) => {
                          return (
                            <TextField
                              fullWidth
                              InputProps={{
                                ...props,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginBottom: "4px" }}
                                  >
                                    {t("element.stop")} :
                                  </InputAdornment>
                                ),
                                endAdornment: <DateRangeIcon />,
                                autoComplete: "off",
                                readOnly: true,
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                ) : null}
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.branch}
                      onChange={(e) => {
                        view({ ...find, branch: e.target.value, page: 1 });
                        setFind({ ...find, branch: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.branch`)}
                      </MenuItem>
                      {cboBranch &&
                        cboBranch.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.warehouse}
                      onChange={(e) => {
                        view({ ...find, warehouse: e.target.value, page: 1 });
                        setFind({
                          ...find,
                          warehouse: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        คลังจัดส่ง
                      </MenuItem>
                      {cboBranch &&
                        cboBranch.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderCheckAll(),
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  renderHeader(header[6]),
                  renderHeader(header[7]),
                  renderHeader(header[8]),
                  renderHeader(header[9]),
                  renderHeader(header[10]),
                  renderAction(),
                ]}
                tableData={record.map((item) => {
                  return [
                    renderCheck(item),
                    btnOpen(item),
                    item.member_name,
                    item.branch_name,
                    item.branch_warehouse,
                    displayShipping(item),
                    Func.numberDisplay(item.car_total),
                    Func.numberDisplay(item.shipping_balance),
                    Func.dateTimeDisplay(item.date_shipping),
                    Func.dateTimeDisplay(item.approve_date_2),
                    displayStatus(item),
                    item.remark,
                    btn(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[6, 7]}
                colorsColls={["primary", "danger"]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}></InputLabel>
                <Select
                  value={find.perPage}
                  onChange={(e) => {
                    view({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                    setFind({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                  }}
                >
                  {cboPerPage &&
                    cboPerPage.map((item) => (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {t(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                count={pageTotal}
                showFirstButton
                showLastButton
                page={pageNo}
                onChange={(e, p) => {
                  view({ ...find, page: p });
                  setFind({ ...find, page: p });
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderStatus()}
          <CustomTabs
            headerColor="rose"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.order"),
                tabIcon: ListAltIcon,
                tabContent: renderOrder(),
              },
              {
                tabName: t("element.info"),
                tabIcon: ListAltIcon,
                tabContent: renderInfo(),
              },
            ]}
          />
          {renderDetail()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderShipping = () => {
    return (
      <GridContainer>
        <GridItem xs={12} style={{ marginTop: "10px" }}>
          <div>
            <div style={{ textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Radio
                    checked={attr.transfer === "1"}
                    onChange={(e) =>
                      setAttr({ ...attr, transfer: e.target.value })
                    }
                    value="1"
                    name="transfer_1"
                    aria-label="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="รับของทันที"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
              style={{ textAlign: "left" }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={attr.transfer === "2"}
                    onChange={(e) =>
                      setAttr({ ...attr, transfer: e.target.value })
                    }
                    value="2"
                    name="transfer_2"
                    aria-label="2"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="รับของที่สาขา"
              />
            </div>
            {attr.transfer === "2" ? (
              <Card style={{ marginTop: 0 }}>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="branch_warehouse"
                          className={classes.labelSelect}
                        >
                          {t(`${prefix}.branch`)}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={attr.branch_transfer}
                          inputProps={{
                            name: "branch_transfer",
                          }}
                          onChange={(e) => {
                            setAttr({
                              ...attr,
                              branch_transfer: e.target.value,
                            });
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="0"
                          >
                            {t(`${prefix}.branch`)}
                          </MenuItem>
                          {cboBranch.map((item) => (
                            <MenuItem
                              key={item.code}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.code}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <InputLabel className={classes.labelDpk}>
                        วันจัดส่ง
                      </InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          inputProps={{
                            placeholder: "__/__/____",
                          }}
                          dateFormat="DD/MM/yyyy"
                          closeOnSelect
                          value={attr.date_shipping}
                          onChange={(value) => {
                            setAttr({ ...attr, date_shipping: value });
                          }}
                          className={classes.center}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={`เวลาจัดส่ง`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: e.target.value,
                            }),
                          onBlur: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: Func.timeSetup(e.target.value),
                            }),
                          value: attr.time_shipping,
                        }}
                        alignCenter
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            ) : null}
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
              style={{ textAlign: "left" }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={attr.transfer === "3"}
                    onChange={(e) =>
                      setAttr({ ...attr, transfer: e.target.value })
                    }
                    value="3"
                    name="transfer_3"
                    aria-label="3"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="จัดส่งสินค้า"
              />
            </div>
            {attr.transfer === "3" ? (
              <Card style={{ marginTop: 0 }}>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="car_code"
                          className={classes.labelSelect}
                        >
                          รถส่งของ
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={attr.car_code}
                          inputProps={{
                            name: "car_code",
                          }}
                          onChange={(e) => {
                            setAttr({
                              ...attr,
                              car_code: e.target.value,
                            });
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="0"
                          >
                            -
                          </MenuItem>
                          {cboCar.map((item) => (
                            <MenuItem
                              key={item.code}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.code}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="branch_warehouse"
                          className={classes.labelSelect}
                        >
                          คลังจัดส่ง
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={attr.branch_warehouse}
                          inputProps={{
                            name: "branch_warehouse",
                          }}
                          onChange={(e) => {
                            setAttr({
                              ...attr,
                              branch_warehouse: e.target.value,
                            });
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="0"
                          >
                            คลังจัดส่ง
                          </MenuItem>
                          {cboBranch.map((item) => (
                            <MenuItem
                              key={item.code}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.code}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={`ค่าจัดส่ง`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({
                              ...attr,
                              shipping_price: e.target.value,
                            }),
                          value: attr.shipping_price,
                        }}
                        alignCenter
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <InputLabel className={classes.labelDpk}>
                        วันจัดส่ง
                      </InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          inputProps={{
                            placeholder: "__/__/____",
                          }}
                          dateFormat="DD/MM/yyyy"
                          closeOnSelect
                          value={attr.date_shipping}
                          onChange={(value) => {
                            setAttr({ ...attr, date_shipping: value });
                          }}
                          className={classes.center}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={`เวลาจัดส่ง`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: e.target.value,
                            }),
                          onBlur: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: Func.timeSetup(e.target.value),
                            }),
                          value: attr.time_shipping,
                        }}
                        alignCenter
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={`ที่อยู่จัดส่ง`}
                        id="address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({ ...attr, address: e.target.value }),
                          value: attr.address,
                          multiline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            ) : null}
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText={t(`${prefix}.branch`)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.branch_name,
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="ประเภท"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: displayPayment(attr.payment_main),
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText={t(`${prefix}.payment_type`)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: displayPayment(attr.payment_type),
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.labelDpk}>
                  {t(`${prefix}.date_record`)}
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "__/__/____",
                    }}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    value={attr.date_record}
                    onChange={(value) => {
                      setAttr({ ...attr, date_record: value });
                    }}
                    className={classes.center}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.labelDpk}>
                  {t(`${prefix}.date_due`)}
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "__/__/____",
                    }}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    value={attr.date_due}
                    onChange={(value) => {
                      setAttr({ ...attr, date_due: value });
                    }}
                    className={classes.center}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${prefix}.remark`)}
                  id="remark"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.remark,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText="หมายเหตุ ยกเลิก"
                  id="remark_cancel"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.remark_cancel,
                    disabled: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6}>
            {renderShipping()}
          </GridItem>
          <GridItem xs={12} className={classes.right}>
            <Button onClick={edit} color="success">
              <SaveIcon /> {t(`element.save`)}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.user_create")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.date_create")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                {Func.dateTimeDisplay(attr.date_create)}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.user_update")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.date_update")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                {Func.dateTimeDisplay(attr.date_update)}
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status * 1}
        alternativeLabel
        className={classes.bgTransparent}
        style={{ paddingBottom: 0 }}
      >
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_1`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_2`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_2}</p>
            <p className={classes.colorDefault}>{attr.approve_date_2}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_3`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_4}</p>
            <p className={classes.colorDefault}>{attr.approve_date_4}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              {attr.status == 9 ? (
                <Badge color="danger">{t(`${prefix}.step_9`)}</Badge>
              ) : (
                <b>{t(`${prefix}.step_9`)}</b>
              )}
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_9}</p>
            <p className={classes.colorDefault}>{attr.approve_date_9}</p>
          </StepLabel>
        </Step>
      </Stepper>
    );
  };

  const renderOrder = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${prefix}.num`),
              t(`${prefix}.image`),
              t(`${prefix}.product_id`),
              t(`${prefix}.product_name`),
              t(`${prefix}.unit`),
              t(`${prefix}.price`),
              t(`${prefix}.quantity`),
              t(`${prefix}.amount`),
              "ปริมาณเดิม",
              t(`${prefix}.return`),
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[5, 6, 7]}
            colorsColls={["primary", "primary", "success"]}
          />
        </GridItem>
        <GridItem xs={12} className={classes.center}>
          <Button onClick={() => print(attr)} color="info">
            <PrintIcon /> {t(`element.print`)}
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalPay = () => {
    const cssValue = {
      padding: "10px 20px",
      fontSize: "2em",
      background: "#f2f2f2",
      textAlign: "right",
      color: "#666",
      marginBottom: "10px",
    };

    return (
      <Dialog
        size={"sm"}
        open={modalPayment}
        onClose={() => setModalPayment(false)}
      >
        <DialogTitle
          id="modal-pay-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalPayment(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>การชำระเงิน</h4>
        </DialogTitle>
        <DialogContent id="modal-pay-description">
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12}>
                <br />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Table
                  tableData={order}
                  customHeadClassesForCells={[1]}
                  customHeadCellClasses={[classes.right]}
                  customClassesForCells={[1]}
                  customCellClasses={[classes.right]}
                  coloredColls={[1]}
                  colorsColls={["primary"]}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText={t(`${prefix}.balance`)}
                  error
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: Func.numberDisplay(attr.order_balance),
                    disabled: true,
                  }}
                  alignRight
                />
                <CustomInput
                  success
                  labelText={t(`${prefix}.payamount`)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtAmount,
                    autoComplete: "off",
                    placeholder: "0.00",
                    style: cssValue,
                    type: "number",
                    value: attr.amount === "0" ? "" : attr.amount,
                    onChange: (e) =>
                      setAttr({ ...attr, amount: e.target.value }),
                  }}
                  style={{ paddingTop: 0 }}
                  alignRight
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={addPay} color="success">
            <SaveIcon /> {t(`element.save`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <SlipShipping ref={slipRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalList()}
      {renderPrint()}
      {renderModalPay()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(TransferShipping);
