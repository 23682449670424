import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { setUser } from "redux/actions/UserAction";
import SweetAlert from "react-bootstrap-sweetalert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import MessageIcon from "@material-ui/icons/Message";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import CloseIcon from "@material-ui/icons/Close";
import PageviewIcon from "@material-ui/icons/Pageview";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import InboxIcon from "@material-ui/icons/Inbox";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import CancelIcon from "@material-ui/icons/Cancel";
import MoneyIcon from "@material-ui/icons/Money";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import Info from "@material-ui/icons/Info";
import Check from "@material-ui/icons/Check";

// core components
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Accordion from "components/Accordion/Accordion.js";
import HelpOutline from "@material-ui/icons/HelpOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CardHeader from "components/Card/CardHeader.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import i18n from "components/Translation/Translate.js";
import Slider from "react-slick";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomTabs from "components/CustomTabs/CustomTabs.js";

import Snackbar from "components/Snackbar/Snackbar.js";
import NavPills from "components/NavPills/NavPills.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import Table from "components/Table/Table.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import "./PosStyle.css";

import styles from "assets/jss/main-style.js";
import PosBill from "./PosBill";
import PosTransfer from "./PosTransfer";
import PosSummary from "./PosSummary";
import PosSlip from "./PosSlip";
import PosSlipRound from "./PosSlipRound";
import PosReturn from "./PosReturn";
import CardFooter from "components/Card/CardFooter";

const useStyles = makeStyles(styles);

/********************************************\
* ZONE INIT *
\********************************************/
const initMember = {
  code: "0",
  id: "",
  name: "",
  company_name: "",
  company_id: "",
  tel: "",
  lineid: "",
  idcard: "",
  email: "",
  address: "",
  district: "",
  city: "",
  zipcode: "",
  promotion_code: "",
  type_code: "",
  province_code: "",
  image: ``,
  shipping: "Y",
  enable: "Y",
  credit_day: "",
  balance: "",
};

const initCustomer = {
  code: "0",
  id: "",
  name: "-",
  company_name: "",
  company_id: "",
  tel: "",
  image: ``,
  enable: "Y",
  credit_day: "",
  credit_balance: "",
  balance_today: "0.00",
  balance_all: "0.00",
  show: "Y",
  balance: "",
};

const initSummary = {
  amount: 0.0,
  vat: 0.0,
  discount: 0.0,
  shipping: 0.0,
  quantity: 0.0,
  total: 0.0,
};

const initOrder = {
  mode: "",
  code: "",
  product_code: "",
  value: "",
  remark: "",
};

const initCashier = {
  type: "IN",
  amount: "",
};

const initAttr = {
  status_shipping: "0",
  transfer: "1",
  branch_transfer: "0",
  branch_warehouse: "0",
  address: "",
  date_shipping: new Date(),
  time_shipping: "",
  money: "",
  money_change: "",
  remark: "",
  car_code: "0",
  sum_round: "0.00",
  sum_today: "0.00",
  balance: "0.00",
  wallet_pay: "0.00",
  wallet_balance: "0.00",
};

const initAdmin = {
  balance: "NONE",
  branch_code: "",
  code: "",
  date_create: "",
  date_login: "",
  date_update: "",
  email: "",
  enable: "",
  filepic: "",
  id: "",
  idcard: "",
  name: "",
};

function Pos({ t }) {
  /********************************************\
  * ZONE VAR *
  \********************************************/
  const mod = "pos";
  const prefix = "bill";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);
  const users = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const txtScanBarcode = useRef(null);
  const txtSearchProduct = useRef(null);
  const txtSearchMember = useRef(null);
  const txtMember = useRef(null);
  const txtQuantity = useRef(null);
  const txtMoney = useRef(null);
  const txtRemark = useRef(null);
  const slipRef = useRef(null);
  const slipRound = useRef(null);

  const [modalMember, setModalMember] = useState(false);
  const [modalCashier, setModalCashier] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [modalNumpad, setModalNumpad] = useState(false);
  const [modalSummary, setModalSummary] = useState(false);
  const [modalRemark, setModalRemark] = useState(false);

  const [record, setRecord] = useState([]);
  const [cboBranch, setCboBranch] = useState([]);
  const [cboCar, setCboCar] = useState([]);
  const [cboBank, setCboBank] = useState([]);
  const [cboProvince, setCboProvince] = useState([]);
  const [cboPrice, setCboPrice] = useState([]);
  const [saleOrder, setSaleOrder] = useState([]);
  const [keepOrder, setKeepOrder] = useState([]);
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [memberTransfer, setMemberTransfer] = useState([]);
  const [list, setList] = useState([]);
  const [billDay, setBillDay] = useState([]);
  const [billAll, setBillAll] = useState([]);
  const [totalDay, setTotalDay] = useState(0);
  const [totalAll, setTotalAll] = useState(0);
  const [saleQuantity, setSaleQuantity] = useState([]);

  const [admin, setAdmin] = useState(initAdmin);
  const [member, setMember] = useState(initMember);
  const [customer, setCustomer] = useState(initCustomer);
  const [order, setOrder] = useState(initOrder);
  const [summary, setSummary] = useState(initSummary);
  const [cashier, setCashier] = useState(initCashier);
  const [attr, setAttr] = useState(initAttr);
  const [slip, setSlip] = useState({});
  const [slipList, setSlipList] = useState([]);
  const [slipList2, setSlipList2] = useState([]);

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [catCode, setCatCode] = useState("ALL");

  const [tabMember, setTabMember] = useState(0);
  const [tabPayment, setTabPayment] = useState(0);
  const [tabBill, setTabBill] = useState(0);
  const [tabSummary, setTabSummary] = useState(0);
  const [imgSize, setImgSize] = useState("");
  const [focus, setFocus] = useState(true);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    main.init();
  }, [main]);

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  const handlePrintRound = useReactToPrint({
    content: () => slipRound.current,
  });

  const changeTabBill = (tab) => {
    let amount = 0.0;
    let balance = 0.0;
    setAttr({ ...attr, money: "", money_change: "0.00" });

    if (tabPayment === 3) {
      switch (tab) {
        case 0:
          let total = summary.total * 1;
          amount = total > customer.balance ? 0 : summary.total;
          balance = customer.balance - amount;
          setAttr({ ...attr, wallet_pay: amount, wallet_balance: balance });
          break;
        case 1:
          amount = totalDay > customer.balance ? 0 : totalDay;
          balance = customer.balance - amount;
          setAttr({ ...attr, wallet_pay: amount, wallet_balance: balance });
          break;
        case 2:
          amount = totalAll > customer.balance ? 0 : totalAll;
          balance = customer.balance - amount;
          setAttr({ ...attr, wallet_pay: amount, wallet_balance: balance });
          break;
      }
    }

    setTabBill(tab);
  };

  const changeTabPayment = (tab) => {
    let amount = 0.0;
    let balance = 0.0;

    if (tab === 3) {
      switch (tabBill) {
        case 0:
          let total = summary.total * 1;
          amount = total > customer.balance ? 0 : summary.total;
          balance = customer.balance - amount;
          setAttr({ ...attr, wallet_pay: amount, wallet_balance: balance });
          break;
        case 1:
          amount = totalDay > customer.balance ? 0 : totalDay;
          balance = customer.balance - amount;
          setAttr({ ...attr, wallet_pay: amount, wallet_balance: balance });
          break;
        case 2:
          amount = totalAll > customer.balance ? 0 : totalAll;
          balance = customer.balance - amount;
          setAttr({ ...attr, wallet_pay: amount, wallet_balance: balance });
          break;
      }
    }
    setTabPayment(tab);
  };

  /********************************************\
  * ZONE MAIN MODULE *
  \********************************************/
  const main = {
    init: () => {
      /* main.init */
      PostData(mod, "init", {
        token: app.token,
        lang: app.lang,
      }).then((result) => {
        if (result.status === "OK") {
          setCategory(result.category);
          setCboProvince(result.province);
          setCboPrice(result.promotion);
          setCboBranch(result.branch);
          setCboCar(result.car);
          setCboBank(result.bank);
          setList(result.cart_list);
          setSummary(result.cart);
          setAdmin(result.employee);
          setCatCode(result.catstart);
          setImgSize(result.possize);

          if (result.cart.member_code > 0) setCustomer(result.member);

          main.loadProduct(result.catstart);
          if (focus) txtScanBarcode.current.focus();
        } else {
          Func.exitApp();
        }
      });
    },
    loadProduct: (category) => {
      /* main.loadProduct */
      PostData(mod, "search", {
        token: app.token,
        lang: app.lang,
        category: category == undefined ? catCode : category,
        search: txtScanBarcode.current.value,
      }).then((result) => {
        if (result.status === "OK") {
          setProduct(result.data);
        }
      });
    },
  };

  /********************************************\
  * ZONE POS MODULE * ขายหน้าร้าน *
  \********************************************/
  const pos = {
    searchCat: (item) => {
      /* pos.searchCat */
      setProduct([]);
      setCatCode(item.code);
      PostData(mod, "search", {
        token: app.token,
        lang: app.lang,
        category: item.code,
        search: txtSearchProduct.current.value,
      }).then((result) => {
        if (result.status === "OK") {
          setCatCode(item.code);
          setProduct(result.data);
        }
      });
    },
    searchBarcode: (e) => {
      /* pos.searchBarcode */
      e.preventDefault();

      PostData(mod, "scanbarcode", {
        token: app.token,
        lang: app.lang,
        quantity: 1,
        type: 1,
        member: customer.code,
        search: txtScanBarcode.current.value,
      }).then((result) => {
        if (result.status === "OK") {
          setList(result.order);
          setSummary(result.summary);
          txtScanBarcode.current.value = "";
          if (focus) txtScanBarcode.current.focus();
        } else {
        }
      });
    },
    searchProduct: (e) => {
      /* pos.searchProduct */
      e.preventDefault();

      setProduct([]);
      setCatCode("ALL");

      PostData(mod, "search", {
        token: app.token,
        lang: app.lang,
        category: "ALL",
        search: txtSearchProduct.current.value,
      }).then((result) => {
        if (result.status === "OK") {
          setProduct(result.data);
        }
      });
    },
    searchMember: (e) => {
      /* pos.searchMember */
      e.preventDefault();

      if (txtSearchMember.current.value != "") {
        PostData(mod, "searchmember", {
          token: app.token,
          lang: app.lang,
          search: txtSearchMember.current.value,
        }).then((result) => {
          if (result.status === "OK") {
            setCustomer(result.data);
          }
        });
      }
    },
    clear: () => {
      /* pos.clear */
      PostData(mod, "orderclear", {
        token: app.token,
        lang: app.lang,
      }).then((result) => {
        if (result.status === "OK") {
          setCustomer(initCustomer);
          setSummary(initSummary);
          setList([]);
        }
      });
    },
    remove: (item) => {
      /* pos.remove */
      return (
        <Button
          simple
          color="danger"
          block
          size="sm"
          onClick={() => pos.removeOrder(item)}
          style={{ margin: 0, padding: 0 }}
        >
          <i className="fas fa-trash-alt" />
        </Button>
      );
    },
    addOrder: (item) => {
      /* pos.addOrder */
      PostData(mod, "orderadd", {
        token: app.token,
        lang: app.lang,
        member: customer.code,
        product: item.product_code,
        quantity: 1,
        type: 1,
      }).then((result) => {
        if (result.status === "OK") {
          setList(result.order);
          setSummary(result.summary);
          if (focus) txtScanBarcode.current.focus();
        }
      });
    },
    removeOrder: (item) => {
      /* pos.removeOrder */
      PostData(mod, "orderdel", {
        token: app.token,
        lang: app.lang,
        code: item.code,
      }).then((result) => {
        if (result.status === "OK") {
          setList(result.order);
          setSummary(result.summary);
          if (focus) txtScanBarcode.current.focus();
        }
      });
    },
    editQuantity: () => {
      /* pos.editQuantity */
      setModalNumpad(false);
      setLoading(true);
      PostData(mod, "orderadd", {
        token: app.token,
        lang: app.lang,
        member: customer.code,
        product: order.product_code,
        quantity: order.value,
        type: 3,
      }).then((result) => {
        setLoading(false);
        if (result.status === "OK") {
          setList(result.order);
          setSummary(result.summary);
          if (focus) txtScanBarcode.current.focus();
        }
      });
    },
    editRemark: () => {
      /* pos.editRemark */
      setModalRemark(false);
      setLoading(true);
      PostData(mod, "editremark", {
        token: app.token,
        lang: app.lang,
        code: order.code,
        remark: order.remark,
      }).then((result) => {
        setLoading(false);
        if (result.status === "OK") {
          setList(result.order);
          setSummary(result.summary);
          if (focus) txtScanBarcode.current.focus();
        }
      });
    },
    clearMember: () => {
      /* pos.clearMember */
      setLoading(true);
      PostData(mod, "clearmember", {
        token: app.token,
        lang: app.lang,
      }).then((result) => {
        setLoading(false);
        if (result.status === "OK") {
          setCustomer(initCustomer);
          setList(result.order);
          setSummary(result.summary);
        }
      });
    },
    numpad: (num) => {
      /* pos.numpad */
      let value = order.value;

      if (num == "-") {
        value = value.substr(0, value.length - 1);
      } else {
        value += "" + num;
      }

      setOrder({ ...order, value: value });
    },
    btnNumpad: (num) => {
      /* pos.btnNumpad */
      return (
        <Button
          simple
          color="primary"
          className={classes.actionButton + " " + classes.actionButtonRound}
          block
          size={"lg"}
          style={{ height: "80px", fontSize: "20px" }}
          onClick={() => pos.numpad(num)}
        >
          {num}
        </Button>
      );
    },
    btn: (item) => {
      /* pos.btn */
      return (
        <>
          <div>
            <small>฿ {item.price}</small>
          </div>
          <Button
            simple
            color="primary"
            block
            onClick={() => {
              setOrder({
                ...order,
                code: item.code,
                product_code: item.product_code,
                mode: "quantity",
                value: "",
              });
              setModalNumpad(true);

              setTimeout(function () {
                if (focus) txtQuantity.current.focus();
              }, 500);
            }}
            style={{ margin: 0, padding: 0, fontSize: "20px" }}
          >
            ×{" "}
            {item.quantity == Math.floor(item.quantity)
              ? Func.numberDisplay(item.quantity, 0)
              : item.quantity}{" "}
            {item.unit_name}
          </Button>
        </>
      );
    },
    topic: (item) => {
      /* pos.topic */
      return (
        <div style={{ width: "150px", whiteSpace: "normal", marginTop: "5px" }}>
          {item.name}
          <Button
            simple
            color="info"
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={() => {
              setOrder({ ...order, code: item.code, remark: item.remark });
              setModalRemark(true);
              setTimeout(function () {
                if (focus) txtRemark.current.focus();
              }, 500);
            }}
          >
            <MessageIcon className={classes.icon} />
          </Button>
        </div>
      );
    },
    saveSize: (size) => {
      /* pos.saveSize */
      PostData(mod, "savesize", {
        token: app.token,
        lang: app.lang,
        size: size,
      }).then((result) => {
        if (result.status === "OK") {
          setImgSize(size);
        }
      });
    },
  };

  /********************************************\
  * ZONE POS RENDER * ขายหน้าร้าน *
  \********************************************/
  const renderPos = {
    product: () => {
      /* renderPos.product */
      return (
        <Card className="card">
          <CardBody className="product-cover">
            <Grid container>
              {product.map((item) => (
                <Grid item xs={12} sm={4} md={3} lg={2} key={item.code}>
                  <div className="box-product">
                    <Card
                      className="product"
                      onClick={() => pos.addOrder(item)}
                    >
                      <CardActionArea>
                        <CardMedia
                          className={imgSize}
                          image={item.filepic}
                          height={20}
                        />
                        <CardContent className="content">
                          <Typography
                            className="topic"
                            style={{ height: "60px" }}
                          >
                            {item.name}
                          </Typography>
                          {item.quantity > 0 ? (
                            <Typography className="price">
                              {Func.numberDisplay(item.quantity, 2)}{" "}
                              {item.unit_name}
                            </Typography>
                          ) : (
                            <Typography className="nostock">หมด</Typography>
                          )}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                </Grid>
              ))}
            </Grid>
          </CardBody>
          <CardFooter product>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Button
                  simple={imgSize != "large"}
                  color="primary"
                  onClick={() => pos.saveSize("large")}
                >
                  <i className="far fa-image" /> Large
                </Button>
                <Button
                  simple={imgSize != "normal"}
                  color="success"
                  onClick={() => pos.saveSize("normal")}
                >
                  <i className="far fa-image" /> Normal
                </Button>
                <Button
                  simple={imgSize != "small"}
                  color="danger"
                  onClick={() => pos.saveSize("small")}
                >
                  <i className="far fa-image" /> Small
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.right}>
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={focus}
                        name="focus"
                        tabIndex={-1}
                        onChange={(e) => setFocus(e.target.checked)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label="Focus"
                  />
                </div>
              </Grid>
            </Grid>
          </CardFooter>
        </Card>
      );
    },
    product_: () => {
      /* renderPos.product */
      const settings = {
        // infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        rows: 2,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      };
      return (
        <Card className="card">
          <CardBody>
            <Slider {...settings}>
              {product.map((item) => (
                <div key={item.code} className="box-product">
                  <Card className="product" onClick={() => pos.addOrder(item)}>
                    <CardActionArea>
                      <CardMedia
                        className={imgSize}
                        image={item.filepic}
                        height={20}
                      />
                      <CardContent className="content">
                        <Typography
                          className="topic"
                          style={{ height: "60px" }}
                        >
                          {item.name}
                        </Typography>
                        {item.quantity > 0 ? (
                          <Typography className="price">
                            {Func.numberDisplay(item.quantity, 0)}{" "}
                            {item.unit_name}
                          </Typography>
                        ) : (
                          <Typography className="nostock">หมด</Typography>
                        )}
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </div>
              ))}
            </Slider>
          </CardBody>
          <CardFooter product>
            <Button
              simple={imgSize != "large"}
              color="primary"
              onClick={() => pos.saveSize("large")}
            >
              <i className="far fa-image" /> Large
            </Button>
            <Button
              simple={imgSize != "normal"}
              color="success"
              onClick={() => pos.saveSize("normal")}
            >
              <i className="far fa-image" /> Normal
            </Button>
            <Button
              simple={imgSize != "small"}
              color="danger"
              onClick={() => pos.saveSize("small")}
            >
              <i className="far fa-image" /> Small
            </Button>
          </CardFooter>
        </Card>
      );
    },
    order: () => {
      /* renderPos.order */
      return (
        <Card className="card">
          <CardBody>
            <Table
              tableHead={[t(`${prefix}.product`), "Quantity", ""]}
              tableData={list.map((item) => {
                return [pos.topic(item), pos.btn(item), pos.remove(item)];
              })}
              customClassesForCells={[1, 2]}
              customCellClasses={[classes.center, classes.right]}
              customHeadCellClasses={[classes.center, classes.right]}
              customHeadClassesForCells={[1, 2]}
            />
            <GridContainer>
              <GridItem xs={6} sm={8} className={classes.right}>
                <h4>Total :</h4>
              </GridItem>
              <GridItem xs={6} sm={4} className={classes.right}>
                <h4>{Func.numberDisplay(summary.total)}</h4>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      );
    },
    member: () => {
      /* renderPos.member */
      return customer.code == 0 ? null : (
        <Card className={classes.boxPrimary}>
          <CardHeader color="primary" icon className={classes.boxHead}>
            <GridContainer>
              <GridItem xs={10}>
                <h4 className={classes.boxTitle}>
                  <i className="fas fa-user color-primary" />
                  {customer.name} {customer.gen_receipt === "Y" ? "*" : ""}
                </h4>
              </GridItem>
              <GridItem xs={2} style={{ float: "right !important" }}>
                <Button
                  simple
                  color="danger"
                  block
                  size="sm"
                  onClick={pos.clearMember}
                >
                  <i className="fas fa-times" />
                </Button>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <table cellPadding="5" style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <th align="right">Wallet :</th>
                  <td className={classes.left}>{customer.balance}</td>
                </tr>
                <tr>
                  <th align="right">เบอร์โทร :</th>
                  <td className={classes.left}>{customer.tel}</td>
                </tr>
                <tr>
                  <th align="right">วันเครดิต :</th>
                  <td className={classes.left}>{mem.overDue(customer.over_due)}</td>
                </tr>
                <tr>
                  <th align="right">คงเหลือเครดิต :</th>
                  <td className={classes.left}>
                    {Func.numberDisplay(customer.credit)} ฿
                  </td>
                </tr>
                <tr>
                  <th align="right">ประเภทส่วนลด :</th>
                  <td className={classes.left}>{customer.promotion_name}</td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
      );
    },
    numpad: () => {
      /* renderPos.numpad */
      const cssValue = {
        padding: "10px 20px",
        fontSize: "2em",
        background: "#f2f2f2",
        textAlign: "right",
        color: "#666",
        marginBottom: "10px",
      };

      return (
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          style={{ zIndex: "2000" }}
          open={modalNumpad}
          keepMounted
          onClose={() => setModalNumpad(false)}
          aria-labelledby="modal-numpad-title"
          aria-describedby="modal-numpad-description"
        >
          <DialogContent id="modal-numpad-description">
            <div style={{ minWidth: "300px", paddingTop: "20px" }}>
              <div style={{ overflow: "hidden" }}>
                <div>
                  <form>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        inputRef: txtQuantity,
                        value: order.value,
                        onChange: (e) =>
                          setOrder({ ...order, value: e.target.value }),
                        autoComplete: "off",
                        placeholder: "0",
                        style: cssValue,
                      }}
                      alignRight
                    />
                  </form>
                </div>
                <table width="100%">
                  <tbody>
                    <tr>
                      <td width="25%">{pos.btnNumpad(7)}</td>
                      <td width="25%">{pos.btnNumpad(8)}</td>
                      <td width="25%">{pos.btnNumpad(9)}</td>
                    </tr>
                    <tr>
                      <td>{pos.btnNumpad(4)}</td>
                      <td>{pos.btnNumpad(5)}</td>
                      <td>{pos.btnNumpad(6)}</td>
                    </tr>
                    <tr>
                      <td>{pos.btnNumpad(1)}</td>
                      <td>{pos.btnNumpad(2)}</td>
                      <td>{pos.btnNumpad(3)}</td>
                    </tr>
                    <tr>
                      <td>{pos.btnNumpad(".")}</td>
                      <td>{pos.btnNumpad(0)}</td>
                      <td>
                        <Button
                          simple
                          color="primary"
                          className={
                            classes.actionButton +
                            " " +
                            classes.actionButtonRound
                          }
                          block
                          size={"lg"}
                          style={{ height: "80px", fontSize: "20px" }}
                          onClick={() => pos.numpad("-")}
                        >
                          <i className="fas fa-backspace"></i>
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <Button
                          simple
                          color="info"
                          className={
                            classes.actionButton +
                            " " +
                            classes.actionButtonRound
                          }
                          block
                          size={"lg"}
                          style={{ height: "80px", fontSize: "20px" }}
                          onClick={() => {
                            if (order.mode == "quantity") {
                              pos.editQuantity();
                            } else if (order.mode == "shipping") {
                              pay.setShipping();
                            }
                          }}
                        >
                          ตกลง
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      );
    },
    remark: () => {
      /* renderPos.remark */
      return (
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={modalRemark}
          keepMounted
          onClose={() => setModalRemark(false)}
          aria-labelledby="modal-remark-title"
          aria-describedby="modal-remark-description"
        >
          <DialogContent id="modal-remark-description">
            <CustomInput
              labelText={t(`Note`)}
              id="remark"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                inputRef: txtRemark,
                onChange: (e) => setOrder({ ...order, remark: e.target.value }),
                value: order.remark,
                multiline: true,
                rows: 5,
              }}
            />
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={pos.editRemark} color="success">
              <SaveIcon /> {t(`element.save`)}
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
    search: () => {
      /* renderPos.search */
      return (
        <Card className="card">
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <form noValidate onSubmit={pos.searchBarcode}>
                  <TextField
                    fullWidth
                    InputProps={{
                      inputRef: txtScanBarcode,
                      startAdornment: (
                        <InputAdornment position="start">
                          Barcode :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </form>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <form noValidate onSubmit={pos.searchProduct}>
                  <TextField
                    fullWidth
                    InputProps={{
                      inputRef: txtSearchProduct,
                      startAdornment: (
                        <InputAdornment position="start">
                          {t(`${mod}.product`)} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </form>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      );
    },
    category: () => {
      /* renderPos.category */
      return (
        <div className="cat">
          <table>
            <tbody>
              <tr>
                {category.map((item) => (
                  <td key={item.code}>
                    <Button
                      color={catCode == item.code ? "rose" : "primary"}
                      onClick={() => pos.searchCat(item)}
                    >
                      {item.name}
                    </Button>
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      );
    },
    categoryPic: () => {
      /* renderPos.categoryPic */
      const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      };

      return (
        <Slider {...settings}>
          {category.map((item) => (
            <div key={item.code} className="category">
              <Card className="category-item">
                <CardActionArea>
                  <CardMedia
                    className="category-media"
                    image={item.filepic}
                    onClick={() => pos.searchCat(item)}
                  />
                  <CardContent className="content">
                    <Typography className="category-topic">
                      {item.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </div>
          ))}
        </Slider>
      );
    },
    button: () => {
      /* renderPos.button */
      return (
        <GridContainer>
          <GridItem xs={4}>
            <Button
              color="info"
              size="lg"
              block
              onClick={() => pay.openPayment()}
            >
              <i className="fas fa-money-bill-wave" /> ชำระเงิน
            </Button>
          </GridItem>
          <GridItem xs={4}>
            <Button color="primary" size="lg" block onClick={mem.show}>
              <i className="fas fa-user" /> {t(`${mod}.member`)}
            </Button>
          </GridItem>
          <GridItem xs={4}>
            <Button color="warning" size="lg" block onClick={pos.clear}>
              <i className="far fa-sticky-note" /> {t(`${mod}.clear`)}
            </Button>
          </GridItem>
          <GridItem xs={4}>
            <Button color="success" size="lg" block onClick={pay.keep}>
              <i className="fas fa-box" /> {t(`${mod}.keep`)}
            </Button>
          </GridItem>
          <GridItem xs={4}>
            <Button color="rose" size="lg" block onClick={sal.show}>
              <i className="fas fa-funnel-dollar" /> {t(`รายการขาย`)}
            </Button>
          </GridItem>
          <GridItem xs={4}>
            <Button color="danger" size="lg" block onClick={cah.cashierOut}>
              <i className="fas fa-sign-out-alt" /> {t(`${mod}.jobout`)}
            </Button>
          </GridItem>
        </GridContainer>
      );
    },
  };

  /********************************************\
  * ZONE MEMBER MODULE * สมาชิก *
  \********************************************/
  const mem = {
    show: () => {
      /* mem.show */
      setTabMember(0);
      setMember(initMember);
      setModalMember(true);

      setTimeout(function () {
        txtMember.current.select();
      }, 500);
    },
    overDue: (inp) => {
      if (inp === "9999") return "";
      else if (inp < 0)
        return <code className="color-danger">ค้างชำระ {inp * -1} วัน</code>;
      else if (inp > 0)
        return <code className="color-info">เหลือ {inp} วัน</code>;
      else {
        return <code className="color-primary">ครบกำหนด</code>;
      }
    },
    btnEdit: (item) => {
      /* mem.btnEdit */
      return (
        <div>
          <Button
            color="success"
            className={classes.actionButton}
            size="sm"
            onClick={() => mem.openEdit(item.code)}
          >
            <i className="fas fa-user btnicon" />
            เปิด
          </Button>
          <Button
            color="reddit"
            className={classes.actionButton}
            size="sm"
            onClick={() => mem.openDiscount(item.code)}
          >
            <i className="fas fa-dollar-sign btnicon" />
            ส่วนลด
          </Button>
        </div>
      );
    },
    textName: (item) => {
      /* mem.textName */
      return (
        <Button simple color="github" onClick={() => mem.select(item)}>
          <i className="far fa-user" /> {item.name}
        </Button>
      );
    },
    search: (e) => {
      /* mem.search */
      e.preventDefault();

      setMemberList([]);

      if (txtMember.current.value == "") return;
      PostData(mod, "membersearch", {
        token: app.token,
        lang: app.lang,
        search: txtMember.current.value,
      }).then((result) => {
        if (result.status === "OK") {
          result.data.map((item, i) => {
            setMemberList((memberList) => [
              ...memberList,
              [mem.textName(item), mem.btnEdit(item)],
            ]);
          });
        }
      });
    },
    select: (item) => {
      /* mem.select */
      PostData(mod, "memberselect", {
        token: app.token,
        lang: app.lang,
        code: item.code,
      }).then((result) => {
        if (result.status === "OK") {
          setCustomer(result.data);
          setList(result.order);
          setSummary(result.summary);
          setModalMember(false);
          if (result.data.over_due < 0) {
            mem.alertCredit(result.data);
          }
        }
      });
    },
    clear: () => {
      /* mem.clear */
      setMember(initMember);
    },
    openEdit: (code) => {
      /* mem.openEdit */
      setTabMember(1);
      PostData(mod, "memberopen", {
        token: app.token,
        lang: app.lang,
        code: code,
      }).then((result) => {
        if (result.status === "OK") {
          setMember(result.data);
          setTabMember(2);
        }
      });
    },
    openDiscount: (code) => {
      /* mem.openDiscount */
      PostData(mod, "memberopen", {
        token: app.token,
        lang: app.lang,
        code: code,
      }).then((result) => {
        if (result.status === "OK") {
          setMember(result.data);
          setTabMember(3);
        }
      });
    },
    changeTab: (value) => {
      /* mem.changeTab */
      if (value == 1) {
        setMember(initMember);
        setMemberTransfer([]);
      }
      setTabMember(value);
    },
    checkEmpty: () => {
      /* mem.checkEmpty */
      let result = true;

      if (!Func.verifyLength(member.name, 1)) result = false;

      return result;
    },
    add: () => {
      /* mem.add */
      if (!mem.checkEmpty()) {
        showNotify(t("alert.pleaseinsert"), "danger");
      } else {
        setAlert(
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={t("alert.confirm_add_title")}
            onConfirm={() => {
              setAlert(null);
              setModalMember(false);
              setLoading(true);

              PostData(mod, "memberadd", {
                token: app.token,
                lang: app.lang,
                data: {
                  id: member.id,
                  name: member.name,
                  company_name: member.company_name,
                  company_id: member.company_id,
                  tel: member.tel,
                  lineid: member.lineid,
                  idcard: member.idcard,
                  email: member.email,
                  address: member.address,
                  district: member.district,
                  city: member.city,
                  zipcode: member.zipcode,
                  promotion_code: member.promotion_code,
                  type_code: member.type_code,
                  province_code: member.province_code,
                },
              }).then((result) => {
                setLoading(false);

                if (result.status === "OK") {
                  showNotify(t("alert.addcomplete"), "success");
                } else {
                  showNotify(t("alert.adderror"), "danger");
                }
              });
            }}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            confirmBtnText={t("alert.ok")}
            cancelBtnText={t("alert.cancel")}
            showCancel
          >
            {t("alert.confirm_add_msg")}
          </SweetAlert>
        );
      }
    },
    edit: () => {
      /* mem.edit */
      if (!mem.checkEmpty()) {
        showNotify(t("alert.pleaseinsert"), "danger");
      } else {
        setAlert(
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={t("alert.confirm_edit_title")}
            onConfirm={() => {
              setAlert(null);
              setModalMember(false);
              setLoading(true);

              PostData(mod, "memberedit", {
                token: app.token,
                lang: app.lang,
                code: member.code,
                data: {
                  id: member.id,
                  name: member.name,
                  company_name: member.company_name,
                  company_id: member.company_id,
                  tel: member.tel,
                  lineid: member.lineid,
                  idcard: member.idcard,
                  email: member.email,
                  address: member.address,
                  district: member.district,
                  city: member.city,
                  zipcode: member.zipcode,
                  promotion_code: member.promotion_code,
                  type_code: member.type_code,
                  province_code: member.province_code,
                },
              }).then((result) => {
                setLoading(false);

                if (result.status === "OK") {
                  showNotify(t("alert.editcomplete"), "success");
                } else {
                  showNotify(t("alert.editerror"), "danger");
                }
              });
            }}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            confirmBtnText={t("alert.ok")}
            cancelBtnText={t("alert.cancel")}
            showCancel
          >
            {t("alert.confirm_edit_msg")}
          </SweetAlert>
        );
      }
    },
    editDiscount: () => {
      /* mem.editDiscount */
      if (member.promotion_code <= 0) {
        showNotify(t("alert.pleaseinsert"), "danger");
      } else {
        setAlert(
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title={t("alert.confirm_edit_title")}
            onConfirm={() => {
              setAlert(null);
              setModalMember(false);
              setLoading(true);

              PostData(mod, "memberedit", {
                token: app.token,
                lang: app.lang,
                code: member.code,
                data: {
                  promotion_code: member.promotion_code,
                },
              }).then((result) => {
                setLoading(false);

                if (result.status === "OK") {
                  if (customer.code > 0) {
                    mem.select(member);
                  }

                  showNotify(t("alert.editcomplete"), "success");
                } else {
                  showNotify(t("alert.editerror"), "danger");
                }
              });
            }}
            onCancel={() => setAlert(null)}
            confirmBtnCssClass={classes.button + " " + classes.success}
            cancelBtnCssClass={classes.button + " " + classes.danger}
            confirmBtnText={t("alert.ok")}
            cancelBtnText={t("alert.cancel")}
            showCancel
          >
            {t("alert.confirm_edit_msg")}
          </SweetAlert>
        );
      }
    },
    inputChange: (e) => {
      /* mem.inputChange */
      setMember({ ...member, [e.target.id]: e.target.value });
    },
    selectChange: (e) => {
      /* mem.selectChange */
      setMember({ ...member, [e.target.name]: e.target.value });
    },
    alertCredit: (item) => {
      /* mem.alertCredit */
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={<div className="color-danger">{item.credit_day}</div>}
          onConfirm={() => {
            setAlert(null);
            pos.clearMember();
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.danger}
          cancelBtnCssClass={classes.button + " " + classes.success}
          confirmBtnText="ยกเลิกทำรายการ"
          cancelBtnText="ดำเนินการต่อไป"
          showCancel
        >
          {item.name}  เครดิตไม่เพียงพอ
        </SweetAlert>
      );
    },
  };

  /********************************************\
  * ZONE MEMBER RENDER * สมาชิก *
  \********************************************/
  const renderMem = {
    modal: () => {
      /* renderMem.modal */
      return (
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={modalMember}
          maxWidth={"sm"}
          keepMounted
          fullWidth
          onClose={() => setModalMember(false)}
          aria-labelledby="modal-member-title"
          aria-describedby="modal-member-description"
          style={{ padding: "0 !important", maxWidth: "470px", margin: "auto" }}
        >
          <DialogTitle
            id="modal-list-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setModalMember(false)}
            >
              <CloseIcon className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>สมาชิก</h4>
          </DialogTitle>
          <DialogContent
            id="modal-member-description"
            style={{ padding: "0 !important" }}
          >
            {renderMem.content()}
          </DialogContent>
        </Dialog>
      );
    },
    content: () => {
      /* renderMem.content */
      return (
        <NavPills
          color="primary"
          alignCenter
          active={tabMember}
          changeValue={(e, v) => mem.changeTab(v)}
          tabs={[
            {
              tabButton: "สมาชิก",
              tabIcon: PageviewIcon,
              tabContent: renderMem.search(),
            },
            {
              tabButton: "ลงทะเบียน",
              tabIcon: PersonAddIcon,
              tabContent: (
                <Grid container>
                  <Grid item xs={12}>
                    {renderMem.info()}
                  </Grid>
                  <Grid item xs={6}>
                    <Button color="warning" onClick={mem.clear}>
                      <HighlightOffIcon /> {t("element.clear")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button color="success" onClick={mem.add}>
                      <SaveIcon /> {t("element.save")}
                    </Button>
                  </Grid>
                </Grid>
              ),
            },
            {
              tabButton: "ข้อมูล",
              tabIcon: EditIcon,
              tabContent:
                member.code > 0 ? (
                  <Grid container>
                    <Grid item xs={12}>
                      {renderMem.info()}
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Button color="warning" onClick={mem.clear}>
                        <HighlightOffIcon /> {t("element.clear")}
                      </Button>
                    </Grid> */}
                    {/* <Grid item xs={6}>
                      <Button color="success" onClick={mem.edit}>
                        <SaveIcon /> {t("element.save")}
                      </Button>
                    </Grid> */}
                  </Grid>
                ) : null,
            },
            {
              tabButton: "ส่วนลด",
              tabIcon: LocalAtmIcon,
              tabContent: renderMem.discount(),
            },
          ]}
        />
      );
    },
    search: () => {
      /* renderMem.search */
      return (
        <Grid container>
          <Grid item xs={12}>
            <form noValidate onSubmit={mem.search}>
              <TextField
                id="search"
                fullWidth
                InputProps={{
                  onChange: (e) => { },
                  inputRef: txtMember,
                  startAdornment: (
                    <InputAdornment position="start">
                      {t(`${mod}.search`)} :
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </form>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ marginTop: "20px", overflowY: "auto", height: "500px" }}
          >
            <Table
              striped
              hover
              tableData={memberList}
              customHeadClassesForCells={[1]}
              customHeadCellClasses={[classes.right]}
              customClassesForCells={[1]}
              customCellClasses={[classes.right]}
              coloredColls={[]}
              colorsColls={[]}
            />
          </Grid>
        </Grid>
      );
    },
    info: () => {
      /* renderMem.info */
      const mod = "member";
      const cssGrid = { padding: "0 10px" };
      return (
        <Accordion
          active={0}
          collapses={[
            {
              title: "ข้อมูลส่วนตัว",
              content: (
                <form autoComplete="off">
                  <Grid container>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={
                          <div>
                            {t(`${mod}.id`)}
                            <span className={classes.colorDanger}> *</span>
                          </div>
                        }
                        id="id"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.id,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={
                          <div>
                            {t(`${mod}.name`)}
                            <span className={classes.colorDanger}> *</span>
                          </div>
                        }
                        id="name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.name,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={t(`${mod}.email`)}
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.email,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={t(`${mod}.tel`)}
                        id="tel"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.tel,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={t(`${mod}.lineid`)}
                        id="lineid"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.lineid,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="type_code"
                          className={classes.labelSelect}
                        >
                          {t(`${mod}.type`)}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={member.type_code}
                          inputProps={{
                            name: "type_code",
                          }}
                          onChange={mem.selectChange}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {t("element.pleaseselect")}
                          </MenuItem>
                          {category &&
                            category.map((item) => (
                              <MenuItem
                                key={item.code}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={item.code}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </form>
              ),
            },
            {
              title: "ข้อมูลบริษัท",
              content: (
                <form autoComplete="off">
                  <Grid container>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={t(`${mod}.company_name`)}
                        id="company_name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.company_name,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={<div>{t(`${mod}.company_id`)}</div>}
                        id="company_id"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.company_id,
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              ),
            },
            {
              title: "ที่อยู่",
              content: (
                <form autoComplete="off">
                  <Grid container>
                    <Grid item xs={12} style={cssGrid}>
                      <CustomInput
                        labelText={t(`${mod}.address`)}
                        id="address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.address,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={t(`${mod}.district`)}
                        id="district"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.district,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={t(`${mod}.city`)}
                        id="city"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.city,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="province_code"
                          className={classes.labelSelect}
                        >
                          {t(`${mod}.province`)}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={member.province_code}
                          inputProps={{
                            name: "province_code",
                          }}
                          onChange={mem.selectChange}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                            }}
                          >
                            {t("element.pleaseselect")}
                          </MenuItem>
                          {cboProvince &&
                            cboProvince.map((item) => (
                              <MenuItem
                                key={item.code}
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected,
                                }}
                                value={item.code}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={cssGrid}>
                      <CustomInput
                        labelText={t(`${mod}.zipcode`)}
                        id="zipcode"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: mem.inputChange,
                          value: member.zipcode,
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              ),
            },
          ]}
        />
      );
    },
    discount: () => {
      /* renderMem.discount */
      const mod = "member";
      const cssGrid = { padding: "0 10px" };
      if (member.code > 0)
        return (
          <form autoComplete="off">
            <Grid container>
              <Grid item xs={12} sm={6} style={cssGrid}>
                <CustomInput
                  labelText={t(`${mod}.id`)}
                  id="id"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: member.id,
                    disabled: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} style={cssGrid}>
                <CustomInput
                  labelText={t(`${mod}.name`)}
                  id="name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: member.name,
                    disabled: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} style={cssGrid}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="promotion_code"
                    className={classes.labelSelect}
                  >
                    {t(`${mod}.promotion`)}
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={member.promotion_code}
                    inputProps={{
                      name: "promotion_code",
                    }}
                    onChange={mem.selectChange}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                    >
                      {t("element.pleaseselect")}
                    </MenuItem>
                    {cboPrice.map((item) => (
                      <MenuItem
                        key={item.code}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.code}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12}>
                <Button color="success" onClick={mem.editDiscount}>
                  <SaveIcon /> {t("element.save")}
                </Button>
              </Grid> */}
            </Grid>
          </form>
        );
      else return null;
    },
  };

  /********************************************\
  * ZONE CASHIER MODULE * แคชเชียร์ *
  \********************************************/
  const cah = {
    openCashOut: () => {
      /* cah.openCashOut */
      setCashier({ type: "OUT", amount: "" });
      setModalCashier(true);
    },
    checkEmpty: () => {
      /* cah.checkEmpty */
      let result = true;

      // if (!Func.verifyLength(cashier.amount, 1)) result = false;

      return result;
    },
    cashierOut: () => {
      /* cah.cashierOut */
      setLoading(true);

      PostData(mod, "cashierprint", {
        token: app.token,
        lang: app.lang,
      }).then((result) => {
        setLoading(false);

        if (result.status === "OK") {
          cah.cashierOutExit();
          if (result.action === "PRINT") {
            setSlip(result.data);
            setSlipList(result.member_list);
            setSlipList2(result.payout_list);
            handlePrintRound();
          }
        } else {
          showNotify(result.msg, "danger");
        }
      });
    },
    cashierOutExit: () => {
      /* cah.cashierOutExit */
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="คุณต้องการออกจากงานใช่หรือไม่"
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "cashierout", {
              token: app.token,
              lang: app.lang,
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                Func.exitApp();
              } else {
                showNotify(result.msg, "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          บันทึกออกจากงาน
        </SweetAlert>
      );
    },
    inputChange: (e) => {
      /* cah.inputChange */
      setCashier({ ...cashier, [e.target.id]: e.target.value });
    },
  };

  /********************************************\
  * ZONE CASHIER RENDER * แคชเชียร์ *
  \********************************************/
  const renderCah = {
    modal: () => {
      /* cah.renderCah */
      const cssGrid = { padding: "0 10px" };

      return (
        <Dialog
          classes={{
            root: classes.center,
            paper: classes.modal,
          }}
          open={modalCashier}
          keepMounted
          onClose={() => setModalCashier(false)}
          aria-labelledby="modal-slide-title"
          aria-describedby="modal-slide-description"
          style={{ padding: "0 !important" }}
        >
          <DialogContent
            id="modal-slide-description"
            style={{ padding: "0 !important" }}
          >
            {cashier.type === "IN" ? (
              <h3>
                <i className="fas fa-arrow-circle-right" /> นำเงินเข้า
              </h3>
            ) : (
              <h3>
                <i className="fas fa-arrow-circle-left" /> นำเงินออก
              </h3>
            )}
            <form autoComplete="off">
              <Grid container>
                <Grid item xs={12} style={cssGrid}>
                  <CustomInput
                    id="amount"
                    labelText={t(`${mod}.amount`)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: cah.inputChange,
                      value: cashier.amount,
                    }}
                    alignCenter
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button color="success" onClick={cah.cashierOut}>
                    <SaveIcon /> {t("element.save")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      );
    },
  };

  /********************************************\
  * ZONE PAYMENT MODULE * ชำระเงิน *
  \********************************************/
  const pay = {
    openPayment: () => {
      /* pay.openPayment */
      setBillDay([]);
      setBillAll([]);
      changeTabBill(0);
      setTabPayment(0);
      setAttr({ ...attr, transfer: "1" });

      if (customer.code > 0) {
        PostData(mod, "openpayment", {
          token: app.token,
          lang: app.lang,
          member: customer.code,
        }).then((result) => {
          if (result.status === "OK") {
            let sumDay = 0;
            let sumAll = 0;

            result.billday.map((item, i) => {
              setBillDay((billDay) => [
                ...billDay,
                [
                  i + 1,
                  item.id,
                  Func.dateTimeDisplay(item.date_create),
                  Func.numberDisplay(item.nettotal),
                ],
              ]);

              sumDay += item.nettotal * 1;
            });
            setBillDay((billDay) => [
              ...billDay,
              {
                total: true,
                title: t(`${prefix}.total`),
                colspan: "2",
                amount: Func.numberDisplay(sumDay),
              },
            ]);
            setTotalDay(sumDay);

            result.billall.map((item, i) => {
              setBillAll((billAll) => [
                ...billAll,
                [
                  i + 1,
                  Func.dateDisplay(item.date_record),
                  Func.numberDisplay(item.total),
                ],
              ]);
              sumAll += item.total * 1;
            });
            setBillAll((billAll) => [
              ...billAll,
              {
                total: true,
                title: t(`${prefix}.total`),
                colspan: "1",
                amount: Func.numberDisplay(sumAll),
              },
            ]);
            setTotalAll(sumAll);
          }
        });
      }

      setModalPayment(true);
    },
    numpad: (num) => {
      /* pay.numpad */
      if (num == "-") {
        // money = money.substr(0, money.length - 1);
        setAttr({ ...attr, money: "", money_change: "0.00" });
      } else {
        let money = attr.money;
        let money_change = 0;
        money += "" + num;

        switch (tabBill) {
          case 0:
            money_change = money * 1 - summary.total;
            break;
          case 1:
            money_change = money * 1 - totalDay;
            break;
          case 2:
            money_change = money * 1 - totalAll;
            break;
        }

        if (money_change < 0) money_change = 0;

        setAttr({ ...attr, money: money, money_change: money_change });
      }
    },
    numChange: (num) => {
      /* pay.numChange */
      let money = attr.money * 1 + num;
      let money_change = 0;

      switch (tabBill) {
        case 0:
          money_change = money - summary.total;
          break;
        case 1:
          money_change = money - totalDay;
          break;
        case 2:
          money_change = money - totalAll;
          break;
      }

      if (money_change < 0) money_change = 0;

      setAttr({ ...attr, money: money, money_change: money_change });
    },
    totalShipping: () => {
      /* pay.totalShipping */
      let total = summary.total * 1;
      let shipping = summary.shipping * 1;

      return Func.numberDisplay(total + shipping);
    },
    btnNumpad: (num) => {
      /* pay.btnNumpad */
      return (
        <Button
          simple
          color="primary"
          block
          style={{ fontSize: "20px" }}
          onClick={() => pay.numpad(num)}
        >
          {num}
        </Button>
      );
    },
    btnNumChange: (num) => {
      /* pay.btnNumChange */
      return (
        <Button
          simple
          color="primary"
          block
          style={{ fontSize: "20px" }}
          onClick={() => pay.numChange(num)}
        >
          {num}
        </Button>
      );
    },
    paymentCash: () => {
      /* pay.paymentCash */

      if (txtMoney.current.value <= 0) {
        showNotify("กรุณาระบุจำนวนเงิน", "danger");
      } else if (txtMoney.current.value * 1 < summary.total * 1) {
        showNotify("กรุณาระบุจำนวนเงินให้ถูกต้อง", "danger");
      } else {
        setModalPayment(false);
        setLoading(true);

        PostData(mod, "paymentcash", {
          token: app.token,
          lang: app.lang,
          member: customer.code,
          money: txtMoney.current.value,
          data: {
            member_code: customer.code,
            payment_type: "CASH",
            transfer: attr.transfer,
            branch_transfer: attr.branch_transfer,
            address: attr.transfer === "3" ? attr.address : "",
            branch_warehouse:
              attr.transfer === "3" ? attr.branch_warehouse : "",
            car_code: attr.transfer === "3" ? attr.car_code : "",
            money: attr.money,
            money_change: attr.money_change,
            remark: attr.remark,
            date_shipping: Func.dateFormat(attr.date_shipping),
            time_shipping: attr.time_shipping,
            shipping_price: summary.shipping,
          },
        }).then((result) => {
          setLoading(false);
          setTabPayment(0);
          changeTabBill(0);

          if (result.status === "OK") {
            setCustomer(initCustomer);
            setSummary(initSummary);
            setAttr(initAttr);
            setList([]);

            setSlip(result.order);
            setSlipList(result.list);
            setAdmin(result.admin);
            dispatch(setUser({ ...users, balance: result.admin.balance }));

            handlePrint();

            main.loadProduct();
          }
        });
      }
    },
    paymentTransfer: (item) => {
      /* pay.paymentTransfer */
      setModalPayment(false);
      setLoading(true);

      PostData(mod, "paymenttransfer", {
        token: app.token,
        lang: app.lang,
        member: customer.code,
        money: attr.money,
        type: tabBill,
        data: {
          member_code: customer.code,
          payment_type: "TRANSFER",
          account_code: item.code,
          transfer: attr.transfer,
          branch_transfer: attr.branch_transfer,
          address: attr.transfer === "3" ? attr.address : "",
          branch_warehouse: attr.transfer === "3" ? attr.branch_warehouse : "",
          car_code: attr.transfer === "3" ? attr.car_code : "",
          money: attr.money,
          money_change: attr.money_change,
          remark: attr.remark,
          date_shipping: Func.dateFormat(attr.date_shipping),
          time_shipping: attr.time_shipping,
          shipping_price: summary.shipping,
        },
      }).then((result) => {
        setLoading(false);
        setTabPayment(0);
        changeTabBill(0);

        if (result.status === "OK") {
          setCustomer(initCustomer);
          setSummary(initSummary);
          setAttr(initAttr);
          setList([]);

          setSlip(result.order);
          setSlipList(result.list);
          handlePrint();

          main.loadProduct();
        }
      });
    },
    paymentWallet: () => {
      /* pay.paymentWallet */
      setModalPayment(false);
      setLoading(true);

      PostData(mod, "paymentwallet", {
        token: app.token,
        lang: app.lang,
        member: customer.code,
        money: attr.money,
        type: tabBill,
        data: {
          member_code: customer.code,
          payment_type: "WALLET",
          account_code: 0,
          transfer: attr.transfer,
          branch_transfer: attr.branch_transfer,
          address: attr.transfer === "3" ? attr.address : "",
          branch_warehouse: attr.transfer === "3" ? attr.branch_warehouse : "",
          car_code: attr.transfer === "3" ? attr.car_code : "",
          money: attr.money,
          money_change: attr.money_change,
          remark: attr.remark,
          date_shipping: Func.dateFormat(attr.date_shipping),
          time_shipping: attr.time_shipping,
          shipping_price: summary.shipping,
        },
      }).then((result) => {
        setLoading(false);
        setTabPayment(0);
        changeTabBill(0);

        if (result.status === "OK") {
          setCustomer(initCustomer);
          setSummary(initSummary);
          setAttr(initAttr);
          setList([]);

          setSlip(result.order);
          setSlipList(result.list);
          handlePrint();

          main.loadProduct();
        }
      });
    },
    paymentCredit: () => {
      /* pay.paymentCredit */
      setModalPayment(false);
      setLoading(true);

      PostData(mod, "paymentcredit", {
        token: app.token,
        lang: app.lang,
        member: customer.code,
        money: attr.money,
        type: tabBill,
        data: {
          member_code: customer.code,
          payment_type: "CREDIT",
          transfer: attr.transfer,
          branch_transfer: attr.branch_transfer,
          address: attr.transfer === "3" ? attr.address : "",
          branch_warehouse: attr.transfer === "3" ? attr.branch_warehouse : "",
          car_code: attr.transfer === "3" ? attr.car_code : "",
          remark: attr.remark,
          date_shipping: Func.dateFormat(attr.date_shipping),
          time_shipping: attr.time_shipping,
          shipping_price: summary.shipping,
        },
      }).then((result) => {
        setLoading(false);
        setTabPayment(0);
        changeTabBill(0);

        if (result.status === "OK") {
          setCustomer(initCustomer);
          setSummary(initSummary);
          setAttr(initAttr);
          setList([]);

          setSlip(result.order);
          setSlipList(result.list);
          handlePrint();

          main.loadProduct();
        }
      });
    },
    paymentDay: (type, account) => {
      /* pay.paymentDay */
      setModalPayment(false);
      setLoading(true);

      PostData(mod, "paymentday", {
        token: app.token,
        lang: app.lang,
        member: customer.code,
        data: {
          payment_type: type,
          payment: type == "CASH" ? 1 : type == "TRANSFER" ? 2 : 3,
          account_code: account,
          money: attr.money,
          money_change: attr.money_change,
        },
      }).then((result) => {
        setLoading(false);
        setTabPayment(0);
        changeTabBill(0);
        setCustomer(initCustomer);

        if (result.status === "OK") {
          setAdmin(result.admin);

          dispatch(setUser({ ...users, balance: result.admin.balance }));

          showNotify(t("alert.addcomplete"), "success");
        } else {
          showNotify(t("alert.adderror"), "danger");
        }
      });
    },
    paymentAll: (type, account) => {
      /* pay.paymentAll */
      setModalPayment(false);
      setLoading(true);

      PostData(mod, "paymentall", {
        token: app.token,
        lang: app.lang,
        member: customer.code,
        data: {
          payment_type: type,
          payment: type == "CASH" ? 1 : type == "TRANSFER" ? 2 : 3,
          account_code: account,
          money: attr.money,
          money_change: attr.money_change,
        },
      }).then((result) => {
        setLoading(false);
        setTabPayment(0);
        changeTabBill(0);
        setCustomer(initCustomer);

        if (result.status === "OK") {
          setAdmin(result.admin);
          dispatch(setUser({ ...users, balance: result.admin.balance }));

          showNotify(t("alert.addcomplete"), "success");
        } else {
          showNotify(t("alert.adderror"), "danger");
        }
      });
    },
    keep: () => {
      /* pay.keep */
      PostData(mod, "keeporder", {
        token: app.token,
        lang: app.lang,
        member: customer.code,
      }).then((result) => {
        if (result.status === "OK") {
          setCustomer(initCustomer);
          setSummary(initSummary);
          setList([]);
        }
      });
    },
    reloadPromotion: (date_record) => {
      /* pay.reloadPromotion */
      PostData(mod, "reloadpromotion", {
        token: app.token,
        lang: app.lang,
        member: customer.code,
        date_record: Func.dateFormat(date_record),
      }).then((result) => {
        if (result.status === "OK") {
          setList(result.order);
          setSummary(result.summary);
        }
      });
    },
    addShipping: () => {
      /* pay.addShipping */
      setOrder({ ...order, mode: "shipping", value: "" });
      setModalNumpad(true);
    },
    setShipping: () => {
      /* pay.setShipping */
      setSummary({ ...summary, shipping: order.value });
      setModalNumpad(false);
    },
  };

  const renderPay = {
    modal: () => {
      /* renderPay.modal */
      return (
        <Dialog
          fullScreen
          open={modalPayment}
          onClose={() => setModalPayment(false)}
        >
          <DialogTitle
            id="modal-pay-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setModalPayment(false)}
            >
              <CloseIcon className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>
              การชำระเงิน{" "}
              {customer.code > 0 ? <span>:: {customer.name}</span> : null}
            </h4>
          </DialogTitle>
          <DialogContent
            id="modal-pay-description"
            style={{
              height: "700px",
              overflowY: "scroll",
              width: "100%",
              padding: "0 !important",
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={7}>
                {renderPay.order()}
              </GridItem>
              <GridItem xs={12} sm={5}>
                {renderPay.content()}
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      );
    },
    order: () => {
      /* renderPay.order */
      if (customer.code > 0) {
        return (
          <CustomTabs
            headerColor="danger"
            value={tabBill}
            changeValue={(e, v) => changeTabBill(v)}
            tabs={[
              {
                tabName: t("การขาย"),
                tabIcon: MoneyIcon,
                tabContent: renderPay.billNow(),
              },
              // {
              //   tabName: t("รายวัน"),
              //   tabIcon: AccountBalanceIcon,
              //   tabContent: renderPay.billToday(),
              // },
              // {
              //   tabName: t("ทั้งหมด"),
              //   tabIcon: HelpOutline,
              //   tabContent: renderPay.billAll(),
              // },
            ]}
          />
        );
      } else {
        return (
          <CustomTabs
            headerColor="danger"
            value={tabBill}
            changeValue={(e, v) => changeTabBill(v)}
            tabs={[
              {
                tabName: t("การขาย"),
                tabIcon: Info,
                tabContent: renderPay.billNow(),
              },
            ]}
          />
        );
      }
    },
    billNow: () => {
      /* renderPay.billNow */
      return (
        <Accordion
          active={0}
          collapses={[
            {
              title: "การสั่งซื้อ",
              content: renderPay.billOrder(),
            },
            {
              title: "การจัดส่ง",
              content: renderPay.billShipping(),
            },
          ]}
        />
      );
    },
    billOrder: () => {
      /* renderPay.billOrder */
      if (list.length > 0)
        return (
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  "No",
                  "Product",
                  "Price",
                  "Quantity",
                  "Unit",
                  "Amount",
                ]}
                tableData={list.map((item, i) => {
                  return [
                    i + 1,
                    item.name,
                    item.price,
                    item.quantity,
                    item.unit_name,
                    item.amount,
                  ];
                })}
                customClassesForCells={[0, 2, 3, 5]}
                customCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                  classes.right,
                ]}
                customHeadClassesForCells={[0, 2, 3, 5]}
                customHeadCellClasses={[
                  classes.center,
                  classes.right,
                  classes.right,
                  classes.right,
                ]}
                coloredColls={[2, 3, 5]}
                colorsColls={["primary", "danger", "success"]}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Card>
                <CardBody>
                  <CustomInput
                    labelText={`หมายเหตุ`}
                    id="remark"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) =>
                        setAttr({ ...attr, remark: e.target.value }),
                      value: attr.remark,
                      multiline: true,
                    }}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <GridContainer>
                <GridItem xs={6} className={classes.right}>
                  <h4>น้ำหนักรวม :</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <h4>{Func.numberDisplay(summary.quantity)} Kg</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <h4>Amount :</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <h4>{Func.numberDisplay(summary.amount)}</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <h4>Vat :</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <h4>{Func.numberDisplay(summary.vat)}</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <h4>Total :</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <h4>{Func.numberDisplay(summary.total)}</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <h4>ค่าจัดส่ง :</h4>
                </GridItem>
                {attr.transfer === "3" ? (
                  <GridItem xs={6} className={classes.right}>
                    <Button
                      color="github"
                      simple
                      style={{
                        fontSize: "1.3em",
                        textAlign: "right",
                        margin: "10px 0",
                        padding: 0,
                      }}
                      onClick={pay.addShipping}
                    >
                      <EditIcon className={classes.colorDanger} />{" "}
                      {Func.numberDisplay(summary.shipping)}
                    </Button>
                  </GridItem>
                ) : (
                  <GridItem xs={6} className={classes.right}>
                    <h4>0.00</h4>
                  </GridItem>
                )}
                <GridItem xs={6} className={classes.right}>
                  <h4>Net total :</h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <h4 className={classes.colorPrimary}>
                    {pay.totalShipping()}
                  </h4>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        );
      else return <h3>{t(`${mod}.datanotfound`)}</h3>;
    },
    billShipping: () => {
      /* renderPay.billShipping */
      if (list.length > 0)
        return (
          <GridContainer>
            <GridItem xs={12} style={{ marginTop: "10px" }}>
              {customer.code > 0 ? (
                <div>
                  <div style={{ textAlign: "left" }}>
                    <FormControlLabel
                      control={
                        <Radio
                          checked={attr.transfer === "1"}
                          onChange={(e) =>
                            setAttr({ ...attr, transfer: e.target.value })
                          }
                          value="1"
                          name="transfer_1"
                          aria-label="1"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="รับของทันที"
                    />
                  </div>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                    style={{ textAlign: "left" }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={attr.transfer === "2"}
                          onChange={(e) =>
                            setAttr({ ...attr, transfer: e.target.value })
                          }
                          value="2"
                          name="transfer_2"
                          aria-label="2"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="รับของที่สาขา"
                    />
                  </div>
                  {attr.transfer === "2" ? (
                    <Card style={{ marginTop: 0 }}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="branch_transfer"
                                className={classes.labelSelect}
                              >
                                {t(`${prefix}.branch`)}
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={attr.branch_transfer}
                                inputProps={{
                                  name: "branch_transfer",
                                }}
                                onChange={(e) => {
                                  setAttr({
                                    ...attr,
                                    branch_transfer: e.target.value,
                                  });
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value="0"
                                >
                                  {t(`${prefix}.branch`)}
                                </MenuItem>
                                {cboBranch.map((item) => (
                                  <MenuItem
                                    key={item.code}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={item.code}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.labelDpk}>
                              วันจัดส่ง
                            </InputLabel>
                            <FormControl fullWidth>
                              <Datetime
                                timeFormat={false}
                                inputProps={{
                                  placeholder: "__/__/____",
                                }}
                                dateFormat="DD/MM/yyyy"
                                closeOnSelect
                                value={attr.date_shipping}
                                onChange={(value) => {
                                  setAttr({ ...attr, date_shipping: value });
                                  pay.reloadPromotion(value);
                                }}
                                className={classes.center}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <CustomInput
                              labelText={`เวลาจัดส่ง`}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: (e) =>
                                  setAttr({
                                    ...attr,
                                    time_shipping: e.target.value,
                                  }),
                                onBlur: (e) =>
                                  setAttr({
                                    ...attr,
                                    time_shipping: Func.timeSetup(
                                      e.target.value
                                    ),
                                  }),
                                value: attr.time_shipping,
                              }}
                              alignCenter
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  ) : null}
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                    style={{ textAlign: "left" }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={attr.transfer === "3"}
                          onChange={(e) =>
                            setAttr({ ...attr, transfer: e.target.value })
                          }
                          value="3"
                          name="transfer_3"
                          aria-label="3"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label="จัดส่งสินค้า"
                    />
                  </div>
                  {attr.transfer === "3" ? (
                    <Card style={{ marginTop: 0 }}>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="car_code"
                                className={classes.labelSelect}
                              >
                                รถส่งของ
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={attr.car_code}
                                inputProps={{
                                  name: "car_code",
                                }}
                                onChange={(e) => {
                                  setAttr({
                                    ...attr,
                                    car_code: e.target.value,
                                  });
                                }}
                              >
                                <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value="0"
                                >
                                  -
                                </MenuItem>
                                {cboCar.map((item) => (
                                  <MenuItem
                                    key={item.code}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={item.code}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="branch_warehouse"
                                className={classes.labelSelect}
                              >
                                คลังจัดส่ง
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={attr.branch_warehouse}
                                inputProps={{
                                  name: "branch_warehouse",
                                }}
                                onChange={(e) => {
                                  setAttr({
                                    ...attr,
                                    branch_warehouse: e.target.value,
                                  });
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value="0"
                                >
                                  คลังจัดส่ง
                                </MenuItem>
                                {cboBranch.map((item) => (
                                  <MenuItem
                                    key={item.code}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={item.code}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <CustomInput
                              labelText={`ค่าจัดส่ง`}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onClick: () => pay.addShipping(),
                                value: Func.numberDisplay(summary.shipping),
                              }}
                              alignCenter
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <InputLabel className={classes.labelDpk}>
                              วันจัดส่ง
                            </InputLabel>
                            <FormControl fullWidth>
                              <Datetime
                                timeFormat={false}
                                inputProps={{
                                  placeholder: "__/__/____",
                                }}
                                dateFormat="DD/MM/yyyy"
                                closeOnSelect
                                value={attr.date_shipping}
                                onChange={(value) => {
                                  setAttr({ ...attr, date_shipping: value });
                                  pay.reloadPromotion(value);
                                }}
                                className={classes.center}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            <CustomInput
                              labelText={`เวลาจัดส่ง`}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: (e) =>
                                  setAttr({
                                    ...attr,
                                    time_shipping: e.target.value,
                                  }),
                                onBlur: (e) =>
                                  setAttr({
                                    ...attr,
                                    time_shipping: Func.timeSetup(
                                      e.target.value
                                    ),
                                  }),
                                value: attr.time_shipping,
                              }}
                              alignCenter
                            />
                          </GridItem>
                          <GridItem xs={12}>
                            <CustomInput
                              labelText={`ที่อยู่จัดส่ง`}
                              id="address"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                onChange: (e) =>
                                  setAttr({ ...attr, address: e.target.value }),
                                value: attr.address,
                                multiline: true,
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  ) : null}
                </div>
              ) : null}
            </GridItem>
          </GridContainer>
        );
      else return <h3>{t(`${mod}.datanotfound`)}</h3>;
    },
    billToday: () => {
      /* renderPay.billToday */
      if (billDay.length == 0) {
        return <h3>{t(`${mod}.datanotfound`)}</h3>;
      } else {
        return (
          <GridContainer>
            <GridItem xs={12}>
              <h3>ชำระบิลรายวัน</h3>
            </GridItem>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[t(`no`), t(`รหัส`), t(`วันที่`), t(`จำนวนเงิน`)]}
                tableData={billDay}
                customHeadClassesForCells={[0, 1, 2, 3]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.right,
                ]}
                customClassesForCells={[0, 1, 2, 3]}
                customCellClasses={[
                  classes.center,
                  classes.center,
                  classes.center,
                  classes.right,
                ]}
                coloredColls={[3]}
                colorsColls={["primary"]}
              />
            </GridItem>
          </GridContainer>
        );
      }
    },
    billAll: () => {
      /* renderPay.billAll */
      if (billAll.length == 0) {
        return <h3>{t(`${mod}.datanotfound`)}</h3>;
      } else {
        return (
          <GridContainer>
            <GridItem xs={12}>
              <h3>ชำระบิลทั้งหมด</h3>
            </GridItem>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[t(`no`), t(`วันที่`), t(`จำนวนเงิน`)]}
                tableData={billAll}
                customHeadClassesForCells={[0, 1, 2]}
                customHeadCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right,
                ]}
                customClassesForCells={[0, 1, 2]}
                customCellClasses={[
                  classes.center,
                  classes.center,
                  classes.right,
                ]}
                coloredColls={[2]}
                colorsColls={["primary"]}
              />
            </GridItem>
          </GridContainer>
        );
      }
    },
    content: () => {
      /* renderPay.content */
      if (customer.code > 0)
        return (
          <CustomTabs
            headerColor="primary"
            value={tabPayment}
            changeValue={(e, v) => changeTabPayment(v)}
            tabs={[
              {
                tabName: t("เงินสด"),
                tabIcon: MoneyIcon,
                tabContent: renderPay.cash(),
              },
              {
                tabName: t("โอนเงิน"),
                tabIcon: AccountBalanceIcon,
                tabContent: renderPay.transfer(),
              },
              {
                tabName: t("เงินเชื่อ"),
                tabIcon: CreditCardIcon,
                tabContent: renderPay.credit(),
              },
              {
                tabName: "Wallet",
                tabIcon: MoneyIcon,
                tabContent: renderPay.wallet(),
              },
            ]}
          />
        );
      else
        return (
          <CustomTabs
            headerColor="primary"
            value={tabPayment}
            changeValue={(e, v) => setTabPayment(v)}
            tabs={[
              {
                tabName: t("เงินสด"),
                tabIcon: MoneyIcon,
                tabContent: renderPay.cash(),
              },
              {
                tabName: t("โอนเงิน"),
                tabIcon: AccountBalanceIcon,
                tabContent: renderPay.transfer(),
              },
              {
                tabName: t("เงินเชื่อ"),
                tabIcon: CreditCardIcon,
                tabContent: renderPay.credit(),
              },
            ]}
          />
        );
    },
    cash: () => {
      /* renderPay.cash */
      const cssValue = {
        padding: "10px 20px",
        fontSize: "2em",
        background: "#f2f2f2",
        textAlign: "right",
        color: "#666",
        marginBottom: "10px",
      };

      return (
        <div style={{ overflow: "hidden" }}>
          <div>
            {/* <form noValidate onSubmit={mem.search}> */}
            <CustomInput
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.money,
                inputRef: txtMoney,
                autoComplete: "off",
                placeholder: "0",
                style: cssValue,
                onChange: (e) => {
                  let money = e.target.value;
                  let money_change = money - summary.total;
                  if (money_change < 0) money_change = 0;

                  setAttr({
                    ...attr,
                    money: money,
                    money_change: money_change,
                  });
                },
              }}
              style={{ paddingTop: 0 }}
              alignRight
            />
            {/* </form> */}
          </div>
          <h3 className="center">
            เงินทอน : {Func.numberDisplay(attr.money_change)}
          </h3>
          <table width="100%">
            <tbody>
              <tr>
                <td width="25%">{pay.btnNumpad(7)}</td>
                <td width="25%">{pay.btnNumpad(8)}</td>
                <td width="25%">{pay.btnNumpad(9)}</td>
                <td width="25%">{pay.btnNumChange(1000)}</td>
              </tr>
              <tr>
                <td>{pay.btnNumpad(4)}</td>
                <td>{pay.btnNumpad(5)}</td>
                <td>{pay.btnNumpad(6)}</td>
                <td>{pay.btnNumChange(500)}</td>
              </tr>
              <tr>
                <td>{pay.btnNumpad(1)}</td>
                <td>{pay.btnNumpad(2)}</td>
                <td>{pay.btnNumpad(3)}</td>
                <td>{pay.btnNumChange(100)}</td>
              </tr>
              <tr>
                <td>{pay.btnNumpad(".")}</td>
                <td>{pay.btnNumpad(0)}</td>
                <td>
                  <Button
                    simple
                    color="primary"
                    className={
                      classes.actionButton + " " + classes.actionButtonRound
                    }
                    block
                    size={"lg"}
                    style={{ height: "80px", fontSize: "20px" }}
                    onClick={() => pay.numpad("-")}
                  >
                    <i className="fas fa-backspace"></i>
                  </Button>
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="4">
                  <Button
                    color="success"
                    className={
                      classes.actionButton + " " + classes.actionButtonRound
                    }
                    block
                    size={"lg"}
                    style={{ height: "60px", fontSize: "20px" }}
                    onClick={() => {
                      if (tabBill == 0) pay.paymentCash();
                      else if (tabBill == 1) pay.paymentDay("CASH", 0);
                      else if (tabBill == 2) pay.paymentAll("CASH", 0);
                    }}
                  >
                    ชำระเงิน
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    },
    transfer: () => {
      /* renderPay.transfer */
      return (
        <div>
          {cboBank.map((item) => (
            <button
              key={item.code}
              className="btnbank"
              onClick={() => {
                if (tabBill == 0) pay.paymentTransfer(item);
                else if (tabBill == 1) pay.paymentDay("TRANSFER", item.code);
                else if (tabBill == 2) pay.paymentAll("TRANSFER", item.code);
              }}
            >
              <CardBody
                className="myaccount"
                style={{ backgroundColor: item.bank_color }}
              >
                <table>
                  <tbody>
                    <tr>
                      <td width="90">
                        <img src={item.bank_pic} width="70" />
                      </td>
                      <td>
                        <div>{item.bank_name}</div>
                        <div className="text-account">{item.acc_no}</div>
                        <div>{item.acc_name}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </button>
          ))}
        </div>
      );
    },
    credit: () => {
      /* renderPay.credit */
      if (tabBill > 0) {
        return <h3>{t(`${mod}.datanotfound`)}</h3>;
        // } else if (customer.code == 0) {
        // return <h3>{t(`${mod}.membernotfound`)}</h3>;
      } else {
        return (
          <div>
            {renderPos.member()}
            <Button
              color="success"
              className={classes.actionButton + " " + classes.actionButtonRound}
              block
              size={"lg"}
              style={{ height: "80px", fontSize: "20px" }}
              onClick={() => pay.paymentCredit()}
            >
              <i className="far fa-save fa-3x" style={{ marginRight: "7px" }} />{" "}
              บันทึก
            </Button>
          </div>
        );
      }
    },
    wallet: () => {
      return (
        <form autoComplete="off">
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                labelText="Wallet"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(customer.balance),
                  disabled: true,
                }}
                alignCenter
              />
              <CustomInput
                error
                labelText="เงินที่ชำระ"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.wallet_pay),
                  disabled: true,
                }}
                alignCenter
              />
              <CustomInput
                success
                labelText="Wallet คงเหลือ"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.wallet_balance),
                  disabled: true,
                }}
                alignCenter
              />
            </GridItem>
            <GridItem xs={12}>
              {attr.wallet_pay > 0 ? (
                <Button
                  color="primary"
                  block
                  size={"lg"}
                  onClick={() => {
                    if (tabBill == 0) pay.paymentWallet();
                    else if (tabBill == 1) pay.paymentDay("WALLET", 0);
                    else if (tabBill == 2) pay.paymentAll("WALLET", 0);
                  }}
                >
                  <i className="fas fa-add"></i> ชำระเงิน
                </Button>
              ) : null}
            </GridItem>
          </GridContainer>
        </form>
      );
    },
  };

  /********************************************\
  * ZONE SALE MODULE * รายการขาย *
  \********************************************/
  const sal = {
    show: () => {
      /* sal.show */
      setLoading(true);
      setSaleOrder([]);
      setKeepOrder([]);
      setSaleQuantity([]);
      setTabSummary(0);

      PostData(mod, "saleorder", {
        token: app.token,
        lang: app.lang,
      }).then((result) => {
        setLoading(false);
        if (result.status === "OK") {
          result.keeporder.map((item, i) => {
            setKeepOrder((keepOrder) => [
              ...keepOrder,
              [
                Func.dateTimeDisplay(item.date_create),
                item.member_name,
                Func.numberDisplay(item.total),
                sal.btnKeep(item),
              ],
            ]);
          });

          result.salequantity.map((item, i) => {
            setSaleQuantity((saleQuantity) => [
              ...saleQuantity,
              [
                item.product_id,
                item.product_name,
                Func.numberDisplay(item.quantity),
                item.unit_name,
              ],
            ]);
          });

          setSaleQuantity((salequantity) => [
            ...salequantity,
            {
              total: true,
              title: t(`ยอดขายรอบปัจจุบัน`),
              colspan: "2",
              amount: Func.numberDisplay(result.summary.round),
            },
            {
              total: true,
              title: t(`ยอดขายวันนี้`),
              colspan: "2",
              amount: Func.numberDisplay(result.summary.today),
            },
            {
              total: true,
              title: t(`จำนวนเงินในลิ้นชัก`),
              colspan: "2",
              amount: Func.numberDisplay(result.summary.balance),
            },
          ]);

          setModalSummary(true);
        } else {
          Func.exitApp();
        }
      });
    },
    transferDisplay: (item) => {
      /* sal.transferDisplay */
      if (item.transfer == 2) {
        return <div>จัดส่งสาขา {item.branch_name}</div>;
      } else if (item.transfer == 3) {
        return (
          <div style={{ whiteSpace: "normal", width: "200px" }}>
            {item.address}
          </div>
        );
      } else {
        return null;
      }
    },
    btnTransfer: (item) => {
      /* sal.btnTransfer */
      return (
        <Button
          round
          size="sm"
          justIcon
          color="success"
          onClick={() => mem.openShipping(item.code)}
        >
          <i className="fas fa-shipping-fast" />
        </Button>
      );
    },
    openKeep: (code) => {
      /* sal.openKeep */
      setSummary(initSummary);
      setCustomer(initCustomer);
      setList([]);

      PostData(mod, "openkeep", {
        token: app.token,
        lang: app.lang,
        code: code,
      }).then((result) => {
        if (result.status === "OK") {
          if (result.summary.member_code > 0) {
            setCustomer(result.member);
          }
          setList(result.order);
          setSummary(result.summary);
          if (focus) txtScanBarcode.current.focus();

          setModalSummary(false);
        }
      });
    },
    cancelOrder: (code) => {
      /* sal.cancelOrder */
      PostData(mod, "cancelorder", {
        token: app.token,
        lang: app.lang,
        code: code,
      }).then((result) => {
        if (result.status === "OK") {
          sal.show();
          // setModalSummary(false);
        } else {
          Func.exitApp();
        }
      });
    },
    btnSale: (item) => {
      /* sal.btnSale */
      if (item.status == 9) {
        return <code className="color-danger">ยกเลิก</code>;
      } else {
        return (
          <Button
            round
            size="sm"
            justIcon
            color="danger"
            onClick={() => sal.cancelOrder(item.code)}
          >
            <i className="fas fa-times" />
          </Button>
        );
      }
    },
    btnKeep: (item) => {
      /* sal.btnKeep */
      return (
        <>
          <Button
            color="success"
            className={classes.actionButton}
            size="sm"
            onClick={() => sal.openKeep(item.code)}
          >
            {t(`element.open`)} <OpenInBrowserIcon />
          </Button>
          <Button
            color="danger"
            className={classes.actionButton}
            onClick={() => { }}
            size="sm"
          >
            {t(`element.cancel`)} <CancelIcon />
          </Button>
        </>
      );
    },
  };

  /********************************************\
  * ZONE SALE RENDER * รายการขาย *
  \********************************************/
  const renderSal = {
    modal: () => {
      /* renderSal.modal */
      return (
        <Dialog
          fullScreen
          open={modalSummary}
          onClose={() => {
            main.loadProduct();
            mem.select(customer);
            setModalSummary(false);
          }}
        >
          <DialogTitle
            id="modal-sal-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => {
                main.loadProduct();
                mem.select(customer);
                setModalSummary(false);
              }}
            >
              <CloseIcon className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>
              รายการขาย{" "}
              {customer.code > 0 ? <span>({customer.name})</span> : null}
            </h4>
          </DialogTitle>
          <DialogContent
            id="modal-sal-description"
            style={{
              height: "700px",
              overflowY: "scroll",
              width: "100%",
              padding: "0 !important",
            }}
          >
            <GridContainer>
              <GridItem xs={12}>
                <CustomTabs
                  headerColor="primary"
                  value={tabSummary}
                  changeValue={(e, v) => setTabSummary(v)}
                  tabs={[
                    {
                      tabName: "รายการขาย",
                      tabIcon: MonetizationOnIcon,
                      tabContent: <PosBill member={customer} />,
                    },
                    {
                      tabName: "จัดเก็บ",
                      tabIcon: InboxIcon,
                      tabContent: renderSal.store(),
                    },
                    {
                      tabName: t(`${prefix}.shipping`),
                      tabIcon: LocalShippingIcon,
                      tabContent: <PosTransfer />,
                    },
                    {
                      tabName: t(`${prefix}.return`),
                      tabIcon: MonetizationOnIcon,
                      tabContent: <PosReturn member={customer} />,
                    },
                    {
                      tabName: "ยอดรวม",
                      tabIcon: LocalAtmIcon,
                      tabContent: <PosSummary />,
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      );
    },
    order: () => {
      /* renderSal.order */
      return (
        <div>
          <Table
            striped
            hover
            tableHead={[t(`รหัส`), t(`ลูกค้า`), t(`ยอดเงิน`), t(`ยกเลิก`)]}
            tableData={saleOrder}
            customHeadClassesForCells={[0, 2, 3]}
            customHeadCellClasses={[
              classes.center,
              classes.right,
              classes.center,
            ]}
            customClassesForCells={[0, 2, 3]}
            customCellClasses={[classes.center, classes.right, classes.center]}
            coloredColls={[2]}
            colorsColls={["primary"]}
          />
        </div>
      );
    },
    store: () => {
      /* renderSal.store */
      return (
        <div>
          <Table
            striped
            hover
            tableHead={[t(`เวลา`), t(`ลูกค้า`), t(`ยอดเงิน`), t(`เลือก`)]}
            tableData={keepOrder}
            customHeadClassesForCells={[0, 1, 2, 3]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.center,
            ]}
            customClassesForCells={[0, 1, 2, 3]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.center,
            ]}
            coloredColls={[2]}
            colorsColls={["primary"]}
          />
        </div>
      );
    },
    total: () => {
      /* renderSal.total */
      return (
        <Table
          striped
          hover
          tableHead={[t(`รหัส`), t(`สินค้า`), t(`ปริมาณ`), t(`หน่วย`)]}
          tableData={saleQuantity}
          customHeadClassesForCells={[0, 2, 3]}
          customHeadCellClasses={[
            classes.center,
            classes.right,
            classes.center,
          ]}
          customClassesForCells={[0, 2, 3]}
          customCellClasses={[classes.center, classes.right, classes.center]}
          coloredColls={[2]}
          colorsColls={["primary"]}
        />
      );
    },
    round: () => {
      /* renderSal.round */
      return (
        <div>
          <Table
            striped
            hover
            tableHead={[t(`รอบ`), t(`เวลา`), t(`พนักงาน`), t(`ยอดเงิน`)]}
            tableData={record}
            customHeadClassesForCells={[0]}
            customHeadCellClasses={[classes.center]}
            customClassesForCells={[0]}
            customCellClasses={[classes.center]}
            coloredColls={[]}
            colorsColls={[]}
          />
        </div>
      );
    },
    transfer: () => {
      /* renderSal.transfer */
      return (
        <Table
          striped
          hover
          tableHead={[
            t(`รหัส`),
            t(`วันที่`),
            t(`สมาชิก`),
            t(`สถานที่จัดส่ง`),
            t(`จัดส่ง`),
          ]}
          tableData={memberTransfer}
          customHeadClassesForCells={[0, 1, 3]}
          customHeadCellClasses={[
            classes.center,
            classes.center,
            classes.center,
          ]}
          customClassesForCells={[0, 1, 3]}
          customCellClasses={[classes.center, classes.center, classes.center]}
          coloredColls={[]}
          colorsColls={[]}
        />
      );
    },
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <PosSlip ref={slipRef} attr={slip} list={slipList} />
        <PosSlipRound
          ref={slipRound}
          attr={slip}
          member_list={slipList}
          payout_list={slipList2}
        />
      </div>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  return (
    <div>
      <GridContainer className="pos">
        <GridItem xs={12} sm={12} md={8}>
          <GridContainer>
            <GridItem xs={12}>{renderPos.search()}</GridItem>
            <GridItem xs={12}>{renderPos.category()}</GridItem>
            <GridItem xs={12}>{renderPos.product()}</GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <GridContainer>
            <GridItem xs={12}>{renderPos.order()}</GridItem>
            <GridItem xs={12}>{renderPos.member()}</GridItem>
            <GridItem xs={12}>{renderPos.button()}</GridItem>
          </GridContainer>
          {/* {admin.balance === "NONE" ? null : (
            <GridContainer>
              <GridItem xs={12}>
                <Card>
                  <CardBody
                    className={classes.center}
                    style={{ fontSize: "2em" }}
                  >
                    <i className="fas fa-cash-register color-primary" />{" "}
                    {Func.numberDisplay(admin.balance)} ฿
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )} */}
        </GridItem>
      </GridContainer>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {renderMem.modal()}
      {renderSal.modal()}
      {renderPay.modal()}
      {renderCah.modal()}
      {renderPos.numpad()}
      {renderPos.remark()}
      {renderPrint()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(Pos);
