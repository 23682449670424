/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardAvatar from "components/Card/CardAvatar.js";
import UploadAvatar from "./UploadAvatar";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import LockOpenIcon from "@material-ui/icons/LockOpen";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { setUser } from "../../redux/actions/UserAction";
import { setPerm } from "../../redux/actions/PermAction";
import { useDispatch } from "react-redux";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  name: "",
  surname: "",
  tel: "",
  idcard: "",
  email: "",
  user_name: "",
  user_pass: "",
  user_pass_again: "",
  task_code: "",
  branch_code: "",
  image: "",
  enable: "Y",
  sale: "Y",
  dashboard: "",
};

const cboPerPage = [
  { name: "element.show_10_record", id: 10 },
  { name: "element.show_20_record", id: 20 },
  { name: "element.show_100_record", id: 100 },
];

let page = 1;
let perPage = 10;

/* ========== ZONE Function ========== */
function Employee({ t }) {
  const mod = "employee";
  const classes = useStyles();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.appState);
  const txtSearch = useRef(null);

  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [cboTask, setCboTask] = useState([]);
  const [cboBranch, setCboBranch] = useState([]);
  const [cboDashboard, setCboDashboard] = useState([]);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();

    if (id === undefined) {
      view();
    } else if (id === "add") {
      create();
    } else {
      setTab(0);
      open();
    }
  }, [id]);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const clickPage = (e, p) => {
    page = p;
    view();
  };

  const changePerPage = (e) => {
    page = 1;
    perPage = e.target.value;
    view();
  };

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboTask(result.task);
        setCboBranch(result.branch);
        setCboDashboard(result.dashboard);
      } else {
        Func.exitApp();
      }
    });
  };

  const create = () => {
    setAttr(initAttr);
  };

  const view = () => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: txtSearch.current.value,
      page: page,
      per_page: perPage,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.code,
              item.name,
              item.surname,
              item.task_name,
              item.user_name,
              item.branch_name,
              item.dashboard,
              Func.checkDisplay(item.enable),
              btn(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const open = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.id, 1)) {
      result = false;
    } else if (!Func.verifyLength(attr.name, 1)) {
      result = false;
    }

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              token: app.token,
              lang: app.lang,
              data: {
                id: attr.id,
                name: attr.name,
                surname: attr.surname,
                task_code: attr.task_code,
                idcard: attr.idcard,
                email: attr.email,
                tel: attr.tel,
                branch_code: attr.branch_code,
                enable: attr.enable,
                sale: attr.sale,
                dashboard: attr.dashboard,
              },
            }).then((result) => {
              setAlert(null);
              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                window.location.assign(`/admin/${mod}`);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              data: {
                id: attr.id,
                name: attr.name,
                surname: attr.surname,
                task_code: attr.task_code,
                idcard: attr.idcard,
                email: attr.email,
                tel: attr.tel,
                branch_code: attr.branch_code,
                enable: attr.enable,
                sale: attr.sale,
                dashboard: attr.dashboard,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open();
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const signIn = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t(`${mod}.confirm_login`)}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "signin", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              dispatch(setUser(result.data));
              dispatch(
                setPerm({
                  menu: result.menu,
                  perm: result.perm,
                })
              );

              window.location.replace("/admin/dashboard");
            } else {
              showError(t(`authen.${result.msg}`));
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {item.name} {item.surname}
      </SweetAlert>
    );
  };

  const editTask = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);

          PostData(mod, "edit", {
            code: attr.code,
            data: {
              user_name: attr.user_name,
              task_code: attr.task_code,
            },
          }).then((result) => {
            setAlert(null);
            if (result.status === "OK") {
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const editPass = () => {
    if (password !== passwordAgain) return;

    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);

          PostData(mod, "editpass", {
            code: attr.code,
            data: {
              user_pass: password,
            },
          }).then((result) => {
            setAlert(null);
            if (result.status === "OK") {
              setPassword("");
              setPasswordAgain("");
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <div>
        <NavLink to={`/admin/${mod}/${item.code}`}>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
          >
            <EditIcon className={classes.icon} />
          </Button>
        </NavLink>
        <Button
          round
          color="primary"
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={() => signIn(item)}
        >
          <LockOpenIcon className={classes.icon} />
        </Button>
      </div>
    );
  };

  const uploadAvatar = (filedata) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);

          PostData(mod, "upload", {
            code: attr.code,
            token: app.token,
            lang: app.lang,
            filedata: filedata,
          }).then((result) => {
            setAlert(null);

            if (result.status === "OK") {
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("upload.upload")}
      </SweetAlert>
    );
  };

  const renderViewList = () => {
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="primary" icon>
                <CardIcon color="primary">
                  <AssignmentIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("element.viewlist")}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={6}>
                    <TextField
                      inputRef={txtSearch}
                      style={{ margin: "10px 0 0 10px" }}
                      id="search"
                      InputProps={{
                        onChange: () => view(),
                        startAdornment: (
                          <InputAdornment position="start">
                            {t("element.search")} :
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                    <Button
                      color="white"
                      justIcon
                      round
                      className={classes.searchButton}
                    >
                      <SearchIcon
                        className={
                          classes.headerLinksSvg + " " + classes.searchIcon
                        }
                      />
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={6} className={classes.right}></GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <Table
                      striped
                      hover
                      tableHead={[
                        t(`attr.code`),
                        t(`${mod}.name`),
                        t(`${mod}.surname`),
                        t(`${mod}.task_code`),
                        t(`${mod}.user_name`),
                        t(`${mod}.branch`),
                        "Dashboard",
                        t("attr.enable"),
                        <NavLink to={`/admin/${mod}/add`}>
                          <Button round color="info" size="sm">
                            <AddIcon className={classes.icon} />
                          </Button>
                        </NavLink>,
                      ]}
                      tableData={record}
                      customHeadClassesForCells={[0, 6, 7, 8]}
                      customHeadCellClasses={[
                        classes.center,
                        classes.center,
                        classes.center,
                        classes.right,
                      ]}
                      customClassesForCells={[0, 6, 7, 8]}
                      customCellClasses={[
                        classes.center,
                        classes.center,
                        classes.center,
                        classes.right,
                      ]}
                      coloredColls={[]}
                      colorsColls={[]}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={3} lg={2}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel className={classes.selectLabel}></InputLabel>
                      <Select value={perPage} onChange={changePerPage}>
                        {cboPerPage &&
                          cboPerPage.map((item) => (
                            <MenuItem
                              key={item.id}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.id}
                            >
                              {t(item.name)}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={8}
                    md={9}
                    lg={10}
                    className={classes.right}
                  >
                    <Pagination
                      style={{ float: "right", marginTop: "20px" }}
                      count={pageTotal}
                      showFirstButton
                      showLastButton
                      page={pageNo}
                      onChange={clickPage}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  const renderAdd = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody className={classes.right}>
              <NavLink to={`/admin/${mod}`}>
                <Button color="warning">
                  <ArrowBackIcon /> {t("element.back")}
                </Button>
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.adddata")}</h4>
            </CardHeader>
            <CardBody>
              {renderInfo()}
              <GridContainer>
                <GridItem xs={12} className={classes.right}>
                  <Button color="success" onClick={add}>
                    <SaveIcon /> {t("element.save")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>
                    {t(`${mod}.title`)} : {attr.name}
                  </h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/${mod}`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        <Button color="success" onClick={edit}>
                          <SaveIcon /> {t("element.save")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: t(`${mod}.avatar`),
                tabIcon: DescriptionIcon,
                tabContent: renderAvatar(),
              },
              {
                tabName: t(`${mod}.authen`),
                tabIcon: DescriptionIcon,
                tabContent: renderAuthen(),
              },
              {
                tabName: t(`${mod}.changepass`),
                tabIcon: DescriptionIcon,
                tabContent: renderChangePass(),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              error={!Func.verifyLength(attr.id, 1)}
              labelText={t(`${mod}.id`) + " *"}
              id="id"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.id,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.idcard`)}
              id="idcard"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.idcard,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              error={!Func.verifyLength(attr.name, 1)}
              labelText={t(`${mod}.name`) + " *"}
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.surname`)}
              id="surname"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.surname,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.tel`)}
              id="tel"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.tel,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.email`)}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.email,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="branch_code" className={classes.labelSelect}>
                {t(`${mod}.branch`)}
                <span className={classes.colorDanger}>*</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={attr.branch_code}
                inputProps={{
                  name: "branch_code",
                }}
                onChange={selectChange}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {t("element.pleaseselect")}
                </MenuItem>
                {cboBranch &&
                  cboBranch.map((item) => (
                    <MenuItem
                      key={item.code}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="dashboard" className={classes.labelSelect}>
                Dashboard
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={attr.dashboard}
                inputProps={{
                  name: "dashboard",
                }}
                onChange={selectChange}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {t("element.pleaseselect")}
                </MenuItem>
                {cboDashboard &&
                  cboDashboard.map((item) => (
                    <MenuItem
                      key={item.code}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.enable === "Y"}
                    name="enable"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={t("attr.enable")}
              />
            </div>
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.sale === "Y"}
                    name="sale"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={t(`${mod}.sale`)}
              />
            </div>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderAuthen = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              error={!Func.verifyLength(attr.user_name, 1)}
              labelText={t(`${mod}.user_name`) + " *"}
              id="user_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.user_name,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="task_code" className={classes.selectLabel}>
                {t(`${mod}.task_code`)}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={attr.task_code}
                inputProps={{
                  name: "task_code",
                }}
                onChange={selectChange}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {t("element.pleaseselect")}
                </MenuItem>
                {cboTask &&
                  cboTask.map((item) => (
                    <MenuItem
                      key={item.code}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={6}></GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} className={classes.right}>
            <Button color="success" onClick={editTask}>
              <SaveIcon /> {t("element.save")}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderChangePass = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              error={password !== passwordAgain}
              labelText={t(`${mod}.user_pass`)}
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (e) => {
                  setPassword(e.target.value);
                },
                type: "password",
                value: password,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              error={password !== passwordAgain}
              labelText={t(`${mod}.user_pass_again`)}
              id="password_again"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (e) => {
                  setPasswordAgain(e.target.value);
                },
                type: "password",
                value: passwordAgain,
              }}
            />
          </GridItem>
          <GridItem xs={6}></GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} className={classes.right}>
            <Button color="success" onClick={editPass}>
              <SaveIcon /> {t("element.save")}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderAvatar = () => {
    return (
      <Card profile>
        <p></p>
        <p></p>
        <CardAvatar profile>
          <img src={attr.image} alt="..." />
        </CardAvatar>
        <CardBody profile>
          <UploadAvatar uploadData={uploadAvatar} />
        </CardBody>
      </Card>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else if (id === "add") {
      return renderAdd();
    } else {
      return renderEdit();
    }
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(Employee);
