import { SET_USER, CLEAR_USER } from '../types';

export const setUser = (info) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: info,
  });
};

export const signout = () => (dispatch) => {
  dispatch({
    type: CLEAR_USER,
  });
};
