/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Datetime from "react-datetime";
import Badge from "components/Badge/Badge.js";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Input from "@material-ui/core/Input";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import InputIcon from "@material-ui/icons/Input";
import ListAltIcon from "@material-ui/icons/ListAlt";
import CancelIcon from "@material-ui/icons/Cancel";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  invoice_id: "",
  date_due: new Date(),
  date_invoice: new Date(),
  payment_code: "",
  remark: "",
  status_pay: "",
  status: "0",
  amount: "",
  vat: "",
  total: "",
  po_code: "",
  po_id: "",
  supplier_name: "",
  enable: "Y",
  branch_code: "",
  branch_name: "",
  account_code: "",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "asc",
  page: 1,
  perPage: 100,
  pageTotal: 0,
  date_start: Func.dateAdd(-30),
  date_stop: new Date(),
  status: "1",
};

/* ========== ZONE Function ========== */
function PurchaseInvoice({ t }) {
  const mod = "po_invoice";
  const prefix = "attr";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);

  const initHeader = [
    { code: `po.id`, name: t(`${mod}.po_id`), order: "asc" },
    { code: `tb.supplier_name`, name: t(`${mod}.supplier`) },
    { code: `tb.payment_code`, name: "การชำระ" },
    { code: `tb.invoice_id`, name: t(`${mod}.invoice_id`) },
    { code: "tb.branch_code", name: t(`${prefix}.branch`) },
    { code: `tb.remark`, name: t(`${prefix}.remark`) },
    { code: `tb.date_invoice`, name: t(`${mod}.date`) },
    { code: `tb.date_due`, name: t(`${mod}.date_due`) },
    { code: `tb.amount`, name: t(`${prefix}.amount`) },
    { code: "tb.status", name: t(`${mod}.status_pay`) },
  ];
  const alignValue = [6, 7, 8, 9, 10];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.center,
    classes.right,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cboPayment, setCboPayment] = useState([]);
  const [cboStatus, setCboStatus] = useState([]);
  const [list, setList] = useState([]);
  const [cboAccount, setCboAccount] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboPayment(result.paymenttype);
        setCboStatus(result.stat);
        setCboAccount(result.account);

        if (id === undefined) {
          view(find);
        } else if (id === "add") {
          setAttr({ ...initAttr, branch_name: result.branch });
        } else {
          setTab(0);
          open();
        }
      } else {
        Func.exitApp();
      }
    });
  };

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              btnOpen(item),
              item.supplier_name,
              item.payment_name,
              item.invoice_id,
              item.branch_name,
              item.remark,
              Func.dateDisplay(item.date_invoice),
              Func.dateDisplay(item.date_due),
              Func.numberDisplay(item.total),
              displayStatus(item),
              btn(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const btnOpen = (item) => {
    return (
      <NavLink to={`/admin/purchaseinvoice/${item.code}`}>
        <Button color="primary" simple>
          {item.po_id}
        </Button>
      </NavLink>
    );
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const open = () => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({
          ...result.data,
          date_invoice: new Date(result.data.date_invoice),
          date_due: new Date(result.data.date_due),
        });

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              item.price_display,
              item.quantity_display,
              item.quantity_receive,
              item.amount_display,
            ],
          ]);
        });

        if (result.data.paytype == 2) {
          setList((list) => [
            ...list,
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              <h4>{t(`po.amount`)}</h4>,
              <h4>{Func.numberDisplay(result.data.po_amount)}</h4>,
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              <h4>
                {t(`po.vat`)} {result.vat}%
              </h4>,
              <h4>{Func.numberDisplay(result.data.po_vat)}</h4>,
            ],
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              <h4>{t(`po.total`)}</h4>,
              <h4>{Func.numberDisplay(result.data.po_total)}</h4>,
            ],
          ]);
        } else {
          setList((list) => [
            ...list,
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              <h4>{t(`po.total`)}</h4>,
              <h4>{Func.numberDisplay(result.data.po_total)}</h4>,
            ],
          ]);
        }

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.payment_code, 1)) result = false;
    if (attr.total <= 0) result = false;

    return result;
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              data: {
                remark: attr.remark,
                amount: attr.amount,
                vat: attr.vat,
                total: attr.amount * 1 + attr.vat * 1,
                payment_code: attr.payment_code,
                account_code: attr.account_code,
                date_due: Func.dateFormat(attr.date_due),
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open();
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const editApprove = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edit", {
            token: app.token,
            lang: app.lang,
            code: id,
            data: {
              payment_code: 1,
              status: 3,
            },
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              // open();
              window.location.reload();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const editApproveView = (data) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edit", {
            token: app.token,
            lang: app.lang,
            code: data.code,
            data: {
              payment_code: 1,
              status: 3,
            },
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              // view(find);
              window.location.reload();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{data.code}
      </SweetAlert>
    );
  };

  const editStatus = (status) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "edit", {
            code: attr.code,
            data: {
              status: status,
              account_code: attr.payment_code === "2" ? attr.account_code : "0",
            },
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        {/* <NavLink to={`/admin/${mod}/${item.code}`}>
          <Button color="success" className={classes.actionButton}>
            {t(`element.open`)} <InputIcon />
          </Button>
        </NavLink> */}
        {item.payment_code === "1" && item.status === "1" ? (
          <Button
            color="info"
            className={classes.actionButton}
            onClick={() => editApproveView(item)}
          >
            ชำระเงินสด
          </Button>
        ) : null}
      </>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="search"
                    fullWidth
                    InputProps={{
                      onChange: (e) => {
                        view({ ...find, search: e.target.value, page: 1 });
                        setFind({ ...find, search: e.target.value, page: 1 });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {t("element.search")} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={8} lg={9}></GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => {
                        view({ ...find, date_start: value, page: 1 });
                        setFind({ ...find, date_start: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => {
                        view({ ...find, date_stop: value, page: 1 });
                        setFind({ ...find, date_stop: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${mod}.status_pay`)}
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                      renderHeader(header[8]),
                      renderHeader(header[9]),
                      null,
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[7]}
                    colorsColls={["primary"]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderAdd = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody className={classes.right}>
              <NavLink to={`/admin/${mod}`}>
                <Button color="warning">
                  <ArrowBackIcon /> {t("element.back")}
                </Button>
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.adddata")}</h4>
            </CardHeader>
            <CardBody>{renderInfo()}</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>
                    {t(`${mod}.invoice_id`)} : {attr.invoice_id}
                  </h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/purchaseinvoice`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>{renderStatus()}</GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.center}>
                        {attr.status === "1" && attr.payment_code === "1" ? (
                          <Button color="primary" onClick={() => editApprove()}>
                            <SaveIcon /> ชำระเงินสด
                          </Button>
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: t("element.order"),
                tabIcon: ListAltIcon,
                tabContent: renderOrder(),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderOrder = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`po.num`),
              t(`po.image`),
              t(`po.product_id`),
              t(`po.product_name`),
              t(`po.unit`),
              t(`po.price`),
              t(`po.quantity`),
              t(`po.quantity_receive`),
              t(`po.amount`),
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7, 8]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7, 8]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[5, 6, 7, 8]}
            colorsColls={["primary", "primary", "success", "primary"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              success
              labelText={t(`${prefix}.branch`)}
              id="branch_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.branch_name,
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.po_id`)}
              id="po_id"
              formControlProps={{
                fullWidth: true,
                onClick: () => {
                  if (id === "add") {
                    loadRef("");
                    setModalRef(true);
                    setTimeout(function () {
                      txtSearchRef.current.select();
                    }, 1000);
                  }
                },
              }}
              inputProps={{
                value: attr.po_id,
                disabled: true,
                endAdornment:
                  id === "add" ? (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ) : null,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.supplier`)}
              id="supplier_name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.supplier_name,
                disabled: true,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.invoice_id`)}
              id="invoice_id"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.invoice_id,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <InputLabel className={classes.labelDpk}>
              {t(`${mod}.date_invoice`)}
            </InputLabel>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "__/__/____" }}
                dateFormat="DD/MM/yyyy"
                closeOnSelect
                value={attr.date_invoice}
                onChange={(value) => {
                  setAttr({ ...attr, date_invoice: value });
                }}
                className={classes.center}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <InputLabel className={classes.labelDpk}>
              {t(`${mod}.date_due`)}
            </InputLabel>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                inputProps={{ placeholder: "__/__/____" }}
                dateFormat="DD/MM/yyyy"
                closeOnSelect
                value={attr.date_due}
                onChange={(value) => {
                  setAttr({ ...attr, date_due: value });
                }}
                className={classes.center}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12}></GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="payment_code"
                className={classes.labelSelect}
              >
                {t(`${mod}.payment_type`)}
                <span className={classes.colorDanger}> *</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={attr.payment_code}
                inputProps={{
                  name: "payment_code",
                }}
                onChange={selectChange}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {t("element.pleaseselect")}
                </MenuItem>
                {cboPayment &&
                  cboPayment.map((item) => (
                    <MenuItem
                      key={item.code}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          {attr.payment_code === "2" ? (
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="account_code"
                  className={classes.labelSelect}
                >
                  บัญชีรับโอน
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={attr.account_code}
                  inputProps={{
                    name: "account_code",
                  }}
                  onChange={selectChange}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {t("element.pleaseselect")}
                  </MenuItem>
                  {cboAccount &&
                    cboAccount.map((item) => (
                      <MenuItem
                        key={item.code}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.code}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
          ) : null}
          <GridItem xs={12}>
            <CustomInput
              labelText={t(`${prefix}.remark`)}
              id="remark"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                multiline: true,
                onChange: inputChange,
                value: attr.remark,
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <Table
              striped
              hover
              tableData={[
                [
                  <b>{t(`po.amount`)}</b>,
                  <Input
                    id="amount"
                    placeholder="0.00"
                    inputProps={{
                      "aria-label": "amount",
                      value: attr.amount,
                      type: "number",
                    }}
                    classes={{
                      input: classes.right,
                    }}
                    color="primary"
                    fullWidth={false}
                    onChange={(e) => {
                      setAttr({
                        ...attr,
                        amount: e.target.value,
                        total: e.target.value * 1 + attr.vat * 1,
                      });
                    }}
                  />,
                ],
                [
                  <b>{t(`po.vat`)}</b>,
                  <Input
                    id="quantity"
                    placeholder="0.00"
                    inputProps={{
                      "aria-label": "quantity",
                      value: attr.vat,
                      type: "number",
                    }}
                    classes={{
                      input: classes.right,
                    }}
                    color="primary"
                    fullWidth={false}
                    onChange={(e) => {
                      setAttr({
                        ...attr,
                        vat: e.target.value,
                        total: e.target.value * 1 + attr.amount * 1,
                      });
                    }}
                  />,
                ],
                [
                  <b>{t(`po.total`)}</b>,
                  <span>{Func.numberDisplay(attr.total)}</span>,
                ],
              ]}
              customHeadClassesForCells={[1]}
              customHeadCellClasses={[classes.right]}
              customClassesForCells={[1]}
              customCellClasses={[classes.right]}
              coloredColls={[1]}
              colorsColls={["primary"]}
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status * 1}
        alternativeLabel
        className={classes.bgTransparent}
      >
        <Step>
          <StepLabel>
            <p>
              <b>{t(`${mod}.status_send`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        {attr.status == 9 ? (
          <Step>
            <StepLabel>
              <p>
                <Badge color="danger">{t(`${mod}.status_cancel`)}</Badge>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_9}</p>
              <p className={classes.colorDefault}>{attr.approve_date_9}</p>
            </StepLabel>
          </Step>
        ) : (
          <Step>
            <StepLabel>
              <p>
                <b>{t(`${mod}.status_finish`)}</b>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_3}</p>
              <p className={classes.colorDefault}>{attr.approve_date_3}</p>
            </StepLabel>
          </Step>
        )}
      </Stepper>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else if (id === "add") {
      return renderAdd();
    } else {
      return renderEdit();
    }
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(PurchaseInvoice);
