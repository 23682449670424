/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MuiAlert from "@material-ui/lab/Alert";
import InputIcon from "@material-ui/icons/Input";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "../../services/Request";
import { API_URL } from "services/Define";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
    code: "",
    name_th: "",
    name_en: "",
    enable: "Y",
    status: "99",
    total: "0.00",
    remark: "",
    date_record: new Date(),
    branch_code: "",
    branch_name: "",
    car_code: "",
    member_code: "",
};

const initField = {
    code: "",
    product_id: "",
    product_name: "",
    quantity: "0",
    unit_name: "",
    filepic: "",
};

const initFind = {
    search: "",
    date_start: Func.dateAdd(-30),
    date_stop: new Date(),
    order_by: "tb.id",
    order_sort: "desc",
    status: "ALL",
    page: 1,
    perPage: 10,
    pageTotal: 0,
};

let perPage = 10;

/* ========== ZONE Function ========== */
function DeliveryOrder({ t }) {
    const prefix = "movestore";
    const mod = "delivery_order";
    const classes = useStyles();

    const initHeader = [
        { code: "tb.id", name: t(`${prefix}.id`), order: "desc" },
        { code: "tb.date_record", name: t(`${prefix}.date_record`) },
        { code: "tb.branch_code", name: t(`${prefix}.branch`) },
        { code: "tb.member_code", name: 'ลูกค้า' },
        { code: "tb.car_code", name: 'รถขนส่ง' },
        { code: "tb.remark", name: t(`${prefix}.remark`) },
        { code: "tb.amount", name: 'จำนวนเงิน' },
        { code: "tb.vat", name: 'Vat' },
        { code: "tb.total", name: 'รวม' },
        { code: "tb.shipping_price", name: 'ค่าจัดส่ง' },
        { code: "tb.nettotal", name: 'รวมสุทธิ' },
        { code: "tb.status", name: t(`${prefix}.status`) },
    ];
    const alignValue = [0, 1, 6, 7, 8, 9, 10, 11, 12];
    const alignItem = [
        classes.center,
        classes.center,
        classes.center,
        classes.center,
        classes.right,
        classes.right,
        classes.right,
        classes.right,
        classes.right,
        classes.center,
        classes.center,
    ];
    const cboPerPage = [
        { id: 10, name: "element.show_10_record" },
        { id: 20, name: "element.show_20_record" },
        { id: 100, name: "element.show_100_record" },
    ];

    const app = useSelector((state) => state.appState);
    const txtSearch = useRef(null);
    const txtSearchList = useRef(null);
    const txtQuantity = useRef(null);

    const { id } = useParams();
    const [pageTotal, setPageTotal] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [searchList, setSearchList] = useState("");
    const [cboCar, setCboCar] = useState([]);
    const [cboMember, setCboMember] = useState([]);
    const [record, setRecord] = useState([]);
    const [list, setList] = useState([]);
    const [order, setOrder] = useState([]);
    const [attr, setAttr] = useState(initAttr);
    const [field, setField] = useState(initField);
    const [find, setFind] = useState(initFind);
    const [header, setHeader] = useState(initHeader);
    const [alert, setAlert] = useState(null);
    const [notify, setNotify] = useState(null);
    const [tab, setTab] = useState(0);
    const [modalAdd, setModalAdd] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cboStatus, setCboStatus] = useState([]);

    useEffect(() => {
        i18n.changeLanguage(app.lang);
        init();
    }, [id]);

    const init = () => {
        PostData(mod, "init", {
            token: app.token,
            lang: app.lang,
        }).then((result) => {
            if (result.status === "OK") {
                setCboStatus(result.stat);
                setCboCar(result.car);
                setCboMember(result.member);

                if (id === undefined) {
                    view(find);
                } else if (id === "add") {
                    setAttr({ ...initAttr, branch_name: result.branch_name });
                } else {
                    setTab(0);
                    open();
                }
            } else {
                window.location.replace("/");
            }
        });
    };

    const changeTab = (event, value) => {
        setTab(value);
    };

    const inputChange = (e) => {
        setAttr({ ...attr, [e.target.id]: e.target.value });
    };

    const selectChange = (e) => {
        setAttr({ ...attr, [e.target.name]: e.target.value });
    };

    const clickPage = (e, p) => {
        page = p;
        view();
    };

    const changePerPage = (e) => {
        page = 1;
        perPage = e.target.value;
        view();
    };

    const view = (data) => {
        setLoading(true);
        PostData(mod, "view", {
            token: app.token,
            lang: app.lang,
            search: data.search,
            page: data.page,
            per_page: data.perPage,
            orderby: data.order_by,
            asc: data.order_sort,
            start: Func.dateFormat(data.date_start),
            stop: Func.dateFormat(data.date_stop),
            status: data.status,
        }).then((result) => {
            if (result.status === "OK") {
                setRecord([]);

                result.data.map((item, i) => {
                    setRecord((record) => [
                        ...record,
                        [
                            btnOpen(item),
                            Func.dateDisplay(item.date_record),
                            item.branch_name,
                            item.member_name,
                            item.car_name,
                            item.remark,
                            Func.numberDisplay(item.amount),
                            Func.numberDisplay(item.vat),
                            Func.numberDisplay(item.total),
                            Func.numberDisplay(item.shipping_price),
                            Func.numberDisplay(item.nettotal),
                            displayStatus(item),
                            btn(item),
                        ],
                    ]);
                });

                setPageTotal(result.total_pages);
                setPageNo(result.page);
            }
            setLoading(false);
        });
    };

    const btnOpen = (item) => {
        return (
            <NavLink to={`/admin/${mod}/${item.code}`}>
                <Button color="primary" simple>
                    {item.id}
                </Button>
            </NavLink>
        );
    };

    const displayStatus = (item) => {
        return (
            <code className={`color-${item.color}`}>
                <i className={item.icon} /> {item.status_display}
            </code>
        );
    };

    const searchOrder = (search) => {
        const btnOrder = (item) => {
            return (
                <div>
                    <Button
                        round
                        color="success"
                        className={classes.actionButton + " " + classes.actionButtonRound}
                        onClick={() => addList(item)}
                    >
                        <AddIcon className={classes.icon} />
                    </Button>
                </div>
            );
        };

        PostData(mod, "searchproduct", {
            token: app.token,
            lang: app.lang,
            search: search,
        }).then((result) => {
            if (result.status === "OK") {
                setOrder([]);

                result.list.map((item, i) => {
                    if (item.parent === 'Y')
                        setOrder((order) => [
                            ...order,
                            [
                                '',
                                '',
                                '',
                                '',
                            ],
                            [
                                <div>{item.so_id} {displayStatus(item)}</div>,
                                item.date_record,
                                <div>{item.nettotal} ฿</div>,
                                btnOrder(item),
                            ],
                        ]);
                    else
                        setOrder((order) => [
                            ...order,
                            [
                                <div className={classes.textWrap}><img src={item.filepic} width="30" /> {item.no}. {item.product_name}</div>,
                                '',
                                <div>{item.quantity} {item.unit_name}</div>,
                                '',
                            ],
                        ]);
                });
            }
        });
    };

    const addList = (item) => {
        PostData(mod, "addlist", {
            token: app.token,
            lang: app.lang,
            code: id,
            data: {
                order_code: id,
                so_code: item.code,
            },
        }).then((result) => {
            if (result.status === "OK") {
                setModalAdd(false);
                open();
                showNotify(t("alert.addcomplete"), "success");
            }
        });
    };

    const editList = () => {
        PostData(mod, "editlist", {
            token: app.token,
            lang: app.lang,
            code: field.code,
            quantity: field.quantity,
        }).then((result) => {
            if (result.status === "OK") {
                open();
                setModalEdit(false);
                showNotify(t("alert.editcomplete"), "success");
            }
        });
    };

    const btnList = (item, status) => {
        if (status < 3) {
            return (
                <Button
                    round
                    color="danger"
                    className={classes.actionButton + " " + classes.actionButtonRound}
                    onClick={() => delList(item)}
                >
                    <DeleteIcon className={classes.icon} />
                </Button>
            );
        } else {
            return null;
        }
    };

    const openList = (code) => {
        setLoading(true);
        setField(initField);

        PostData(mod, "openlist", {
            token: app.token,
            lang: app.lang,
            code: code,
        }).then((result) => {
            if (result.status === "OK") {
                setField(result.data);

                setModalEdit(true);

                txtQuantity.current.select();
            }
            setLoading(false);
        });
    };

    const delList = (item) => {
        setAlert(
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title={t("alert.confirm_delete_title")}
                onConfirm={() => {
                    PostData(mod, "dellist", {
                        token: app.token,
                        lang: app.lang,
                        code: item.code,
                        ref_code: id,
                    }).then((result) => {
                        setAlert(null);
                        if (result.status === "OK") {
                            open();
                            showNotify(t("alert.deletecomplete"), "success");
                        } else {
                            showNotify(t("alert.deleteerror"), "danger");
                        }
                    });
                }}
                onCancel={() => setAlert(null)}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText={t("alert.ok")}
                cancelBtnText={t("alert.cancel")}
                showCancel
            >
                {t("alert.confirm_delete_msg")} #{item.code}
            </SweetAlert>
        );
    };

    const open = () => {
        setLoading(true);
        setAttr(initAttr);
        setList([]);

        PostData(mod, "open", {
            token: app.token,
            lang: app.lang,
            code: id,
        }).then((result) => {
            if (result.status === "OK") {
                setAttr({
                    ...result.data,
                    date_record: new Date(result.data.date_record),
                });

                result.list.map((item, i) => {
                    if (item.parent === 'Y')
                        setList((list) => [
                            ...list,
                            [
                                '',
                                '',
                                '',
                                '',
                            ],
                            [
                                <div>{item.no}. {item.so_id} {displayStatus(item)}</div>,
                                item.date_record,
                                <div>{item.nettotal} ฿</div>,
                                btnList(item, result.data.status),
                            ],
                        ]);
                    else
                        setList((list) => [
                            ...list,
                            [
                                <div className={classes.textWrap}><img src={item.filepic} width="30" /> {item.no}. {item.product_name}</div>,
                                '',
                                <div>{item.quantity} {item.unit_name}</div>,
                                '',
                            ],
                        ]);
                });

                setLoading(false);
            }
        });
    };

    const checkEmpty = () => {
        let result = true;

        if (!Func.verifyLength(attr.member_code, 1)) {
            result = false;
        }

        return result;
    };

    const add = () => {
        if (!checkEmpty()) {
            showNotify(t("alert.pleaseinsert"), "danger");
        } else {
            setAlert(
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={t("alert.confirm_add_title")}
                    onConfirm={() => {
                        setAlert(null);
                        setLoading(true);

                        PostData(mod, "add", {
                            data: {
                                member_code: attr.member_code,
                                car_code: attr.car_code,
                                remark: attr.remark,
                                date_record: Func.dateFormat(attr.date_record),
                            },
                        }).then((result) => {
                            setAlert(null);
                            if (result.status === "OK") {
                                showNotify(t("alert.addcomplete"), "success");
                                window.location.assign(`/admin/${mod}/${result.code}`);
                            } else {
                                showNotify(t("alert.adderror"), "danger");
                            }
                        });
                    }}
                    onCancel={() => setAlert(null)}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText={t("alert.ok")}
                    cancelBtnText={t("alert.cancel")}
                    showCancel
                >
                    {t("alert.confirm_add_msg")}
                </SweetAlert>
            );
        }
    };

    const edit = () => {
        if (!checkEmpty()) {
            showNotify(t("alert.pleaseinsert"), "danger");
        } else {
            setAlert(
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={t("alert.confirm_edit_title")}
                    onConfirm={() => {
                        setAlert(null);
                        setLoading(true);

                        PostData(mod, "edit", {
                            code: attr.code,
                            data: {
                                member_code: attr.member_code,
                                car_code: attr.car_code,
                                remark: attr.remark,
                                date_record: Func.dateFormat(attr.date_record),
                            },
                        }).then((result) => {
                            setLoading(false);

                            if (result.status === "OK") {
                                open();
                                showNotify(t("alert.editcomplete"), "success");
                            } else {
                                showNotify(t("alert.editerror"), "danger");
                            }
                        });
                    }}
                    onCancel={() => setAlert(null)}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText={t("alert.ok")}
                    cancelBtnText={t("alert.cancel")}
                    showCancel
                >
                    {t("alert.confirm_edit_msg")} #{attr.code}
                </SweetAlert>
            );
        }
    };

    const print = (code) => {
        window.open(`${API_URL}/app/print/${mod}-${code}.pdf`, "_blank");
    };

    const showNotify = (msg, color) => {
        setNotify(
            <Snackbar
                place="tr"
                color={color}
                icon={AlertIcon}
                message={msg}
                open={true}
                closeNotification={() => setNotify(null)}
                close
            />
        );

        setTimeout(function () {
            setNotify(null);
        }, 3000);
    };

    const btn = (item) => {
        return (
            <>
                <NavLink to={`/admin/${mod}/${item.code}`}>
                    <Button color="success" className={classes.actionButton}>
                        {t(`element.open`)} <InputIcon />
                    </Button>
                </NavLink>
                <Button
                    color="info"
                    className={classes.actionButton}
                    onClick={() => print(item.code)}
                >
                    {t(`element.print`)} <PrintIcon />
                </Button>
            </>
        );
    };

    const editStatus = (key) => {
        let found = false;

        if (attr.status == 0 && key == 1) {
            found = true;
        } else if (attr.status == 1 && key == 3) {
            found = true;
        } else if (attr.status < 2 && key === 9) {
            found = true;
        }

        if (found) {
            setAlert(
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title={t("alert.confirm_edit_title")}
                    onConfirm={() => {
                        setAlert(null);
                        setLoading(true);

                        PostData(mod, "edit", {
                            code: id,
                            data: {
                                status: key,
                            },
                        }).then((result) => {
                            setAlert(null);

                            if (result.status === "OK") {
                                open();
                                setAlert(null);
                                showNotify(t("alert.editcomplete"), "success");
                            } else {
                                showNotify(t("alert.editerror"), "danger");
                            }
                        });
                    }}
                    onCancel={() => setAlert(null)}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText={t("alert.ok")}
                    cancelBtnText={t("alert.cancel")}
                    showCancel
                >
                    {t("alert.confirm_edit_msg")} #{attr.code}
                </SweetAlert>
            );
        }
    };

    const btnAddList = () => {
        if (attr.status < 3) {
            return (
                <Button
                    round
                    color="info"
                    size="sm"
                    onClick={() => {
                        searchOrder(searchList);
                        setModalAdd(true);

                        setTimeout(function () {
                            txtSearchList.current.select();
                        }, 500);
                    }}
                >
                    <AddIcon className={classes.icon} /> {t("element.add")}
                </Button>
            );
        } else {
            return null;
        }
    };

    const renderHeader = (data) => {
        let icon = <i className="fas fa-sort"></i>;
        if (data.order === "desc") {
            icon = <i className="fad fa-sort-up"></i>;
        } else if (data.order === "asc") {
            icon = <i className="fad fa-sort-down"></i>;
        } else {
            icon = <i className="fad fa-sort"></i>;
        }

        if (data.sort === undefined || data.sort)
            return (
                <div
                    onClick={() => {
                        let tmp = [];
                        header.map((item, i) => {
                            if (data.code === item.code) {
                                const ordersort = item.order === "asc" ? "desc" : "asc";
                                view({ ...find, order_sort: ordersort, order_by: data.code });
                                setFind({
                                    ...find,
                                    order_sort: ordersort,
                                    order_by: data.code,
                                });
                                tmp[i] = {
                                    code: item.code,
                                    name: item.name,
                                    order: ordersort,
                                    sort: item.sort,
                                };
                            } else {
                                tmp[i] = {
                                    code: item.code,
                                    name: item.name,
                                    order: "",
                                    sort: item.sort,
                                };
                            }
                        });

                        setHeader(tmp);
                    }}
                    style={{ cursor: "pointer" }}
                >
                    {data.name} {icon}
                </div>
            );
        else return <div>{data.name}</div>;
    };

    const renderViewList = () => {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <AssignmentIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
                        </CardHeader>
                        <CardBody>
                            <GridContainer className="viewlist">
                                <GridItem xs={12} sm={6} md={6}>
                                    <TextField
                                        id="search"
                                        fullWidth
                                        InputProps={{
                                            onChange: (e) => {
                                                view({ ...find, search: e.target.value, page: 1 });
                                                setFind({ ...find, search: e.target.value, page: 1 });
                                            },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {t("element.search")} :
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            autoComplete: "off",
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}></GridItem>
                                <GridItem xs={12} sm={6} md={3}></GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/yyyy"
                                            closeOnSelect
                                            value={find.date_start}
                                            onChange={(value) => {
                                                view({ ...find, date_start: value, page: 1 });
                                                setFind({ ...find, date_start: value, page: 1 });
                                            }}
                                            className={classes.center}
                                            renderInput={(props) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        InputProps={{
                                                            ...props,
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    style={{ marginBottom: "4px" }}
                                                                >
                                                                    {t("element.start")} :
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: <DateRangeIcon />,
                                                            autoComplete: "off",
                                                            readOnly: true,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Datetime
                                            timeFormat={false}
                                            dateFormat="DD/MM/yyyy"
                                            closeOnSelect
                                            value={find.date_stop}
                                            onChange={(value) => {
                                                view({ ...find, date_stop: value, page: 1 });
                                                setFind({ ...find, date_stop: value, page: 1 });
                                            }}
                                            className={classes.center}
                                            renderInput={(props) => {
                                                return (
                                                    <TextField
                                                        fullWidth
                                                        InputProps={{
                                                            ...props,
                                                            startAdornment: (
                                                                <InputAdornment
                                                                    position="start"
                                                                    style={{ marginBottom: "4px" }}
                                                                >
                                                                    {t("element.stop")} :
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: <DateRangeIcon />,
                                                            autoComplete: "off",
                                                            readOnly: true,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={find.status}
                                            onChange={(e) => {
                                                view({ ...find, status: e.target.value, page: 1 });
                                                setFind({ ...find, status: e.target.value, page: 1 });
                                            }}
                                        >
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelected,
                                                }}
                                                value="ALL"
                                            >
                                                {t(`${prefix}.status`)}
                                            </MenuItem>
                                            {cboStatus &&
                                                cboStatus.map((item) => (
                                                    <MenuItem
                                                        key={item.code}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected,
                                                        }}
                                                        value={item.code}
                                                    >
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>

                            <GridContainer>
                                <GridItem xs={12}>
                                    <Table
                                        striped
                                        hover
                                        tableHead={[
                                            renderHeader(header[0]),
                                            renderHeader(header[1]),
                                            renderHeader(header[2]),
                                            renderHeader(header[3]),
                                            renderHeader(header[4]),
                                            renderHeader(header[5]),
                                            renderHeader(header[6]),
                                            renderHeader(header[7]),
                                            renderHeader(header[8]),
                                            renderHeader(header[9]),
                                            renderHeader(header[10]),
                                            renderHeader(header[11]),
                                            <NavLink to={`/admin/${mod}/add`}>
                                                <Button
                                                    color="primary"
                                                    className={classes.actionButton}
                                                    block
                                                >
                                                    {t(`element.add`)}
                                                    <AddIcon />
                                                </Button>
                                            </NavLink>,
                                        ]}
                                        tableData={record}
                                        customHeadClassesForCells={alignValue}
                                        customHeadCellClasses={alignItem}
                                        customClassesForCells={alignValue}
                                        customCellClasses={alignItem}
                                        coloredColls={[10]}
                                        colorsColls={["primary"]}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={4} lg={3}>
                                    <FormControl fullWidth className={classes.selectFormControl}>
                                        <InputLabel className={classes.selectLabel}></InputLabel>
                                        <Select
                                            value={find.perPage}
                                            onChange={(e) => {
                                                view({
                                                    ...find,
                                                    page: 1,
                                                    perPage: e.target.value,
                                                    page: 1,
                                                });
                                                setFind({
                                                    ...find,
                                                    page: 1,
                                                    perPage: e.target.value,
                                                    page: 1,
                                                });
                                            }}
                                        >
                                            {cboPerPage &&
                                                cboPerPage.map((item) => (
                                                    <MenuItem
                                                        key={item.id}
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelected,
                                                        }}
                                                        value={item.id}
                                                    >
                                                        {t(item.name)}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem
                                    xs={12}
                                    sm={6}
                                    md={8}
                                    lg={9}
                                    className={classes.right}
                                >
                                    <Pagination
                                        style={{ float: "right", marginTop: "20px" }}
                                        count={pageTotal}
                                        showFirstButton
                                        showLastButton
                                        page={pageNo}
                                        onChange={(e, p) => {
                                            view({ ...find, page: p });
                                            setFind({ ...find, page: p });
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    const renderAdd = () => {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardBody className={classes.right}>
                            <NavLink to={`/admin/${mod}`}>
                                <Button color="warning">
                                    <ArrowBackIcon /> {t("element.back")}
                                </Button>
                            </NavLink>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                <AssignmentIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t("element.adddata")}</h4>
                        </CardHeader>
                        <CardBody>
                            {renderInfo()}

                            <GridContainer>
                                <GridItem xs={12} className={classes.right}>
                                    <Button color="success" onClick={add}>
                                        <SaveIcon /> {t("element.save")}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    const renderEdit = () => {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Card>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={6}>
                                    <h4>
                                        {t(`${prefix}.id`)} : {attr.id}
                                    </h4>
                                </GridItem>
                                <GridItem xs={6} className={classes.right}>
                                    <NavLink to={`/admin/${mod}`}>
                                        <Button color="warning">
                                            <ArrowBackIcon /> {t("element.back")}
                                        </Button>
                                    </NavLink>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12}>{renderStatus()}</GridItem>
                <GridItem xs={12}>
                    <CustomTabs
                        headerColor="primary"
                        value={tab}
                        changeValue={changeTab}
                        tabs={[
                            {
                                tabName: t("element.info"),
                                tabIcon: DescriptionIcon,
                                tabContent: (
                                    <div>
                                        {renderInfo()}
                                        <GridContainer>
                                            <GridItem xs={12} className={classes.right}>
                                                {attr.status <= 1 ? (
                                                    <Button color="success" onClick={edit}>
                                                        <SaveIcon /> {t("element.save")}
                                                    </Button>
                                                ) : null}
                                                {attr.status <= 3 ? (
                                                    <Button color="info" onClick={() => print(attr.code)}>
                                                        <PrintIcon /> {t("element.print")}
                                                    </Button>
                                                ) : null}
                                                {attr.status <= 1 ? (
                                                    <Button color="danger" onClick={() => editStatus(9)}>
                                                        <CancelIcon /> {t("element.cancel")}
                                                    </Button>
                                                ) : null}
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                ),
                            },
                            {
                                tabName: t("element.order"),
                                tabIcon: ListAltIcon,
                                tabContent: renderOrder(),
                            },
                        ]}
                    />
                </GridItem>
                <GridItem xs={12}>{renderDetail()}</GridItem>
            </GridContainer>
        );
    };

    const renderOrder = () => {
        return (
            <GridContainer>
                <GridItem xs={12}>
                    <Table
                        striped
                        hover
                        tableHead={[
                            'บิลขาย',
                            'วันที่',
                            'จำนวนเงิน',
                            btnAddList(),
                        ]}
                        tableData={list}
                        customHeadClassesForCells={[1, 2, 3]}
                        customHeadCellClasses={[
                            classes.center,
                            classes.right,
                            classes.right,
                        ]}
                        customClassesForCells={[1, 2, 3]}
                        customCellClasses={[
                            classes.center,
                            classes.right,
                            classes.right,
                        ]}
                        coloredColls={[]}
                        colorsColls={[]}
                    />
                </GridItem>
                <GridItem xs={12}>
                    <p></p>
                </GridItem>
                <GridItem xs={12} className={classes.center}>
                    {attr.status == 0 ? (
                        <Button color="success" onClick={() => editStatus(1)}>
                            <CheckCircleIcon /> {t(`${prefix}.status_send`)}
                        </Button>
                    ) : null}
                    {attr.status == 1 ? (
                        <Button color="success" onClick={() => editStatus(3)}>
                            <CheckCircleIcon /> {t(`${prefix}.status_approve`)}
                        </Button>
                    ) : null}
                </GridItem>
            </GridContainer>
        );
    };

    const renderModalAdd = () => {
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={modalAdd}
                maxWidth={"sm"}
                fullWidth
                keepMounted
                onClose={() => setModalAdd(false)}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle
                    id="modal-add-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setModalAdd(false)}
                    >
                        <CloseIcon className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>{t("element.add")}</h4>
                </DialogTitle>
                <DialogContent id="modal-add" className={classes.modalBody}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <CustomInput
                                labelText={t("element.search")}
                                id="search_order"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    inputRef: txtSearchList,
                                    onChange: (e) => {
                                        setSearchList(e.target.value);
                                        searchOrder(e.target.value);
                                    },
                                    value: searchList,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                    autoComplete: "off",
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12}>
                            <Table
                                tableData={order}
                                customHeadClassesForCells={[]}
                                customHeadCellClasses={[]}
                                customClassesForCells={[2, 4]}
                                customCellClasses={[classes.right, classes.right]}
                                coloredColls={[]}
                                colorsColls={[]}
                            />
                        </GridItem>
                    </GridContainer>
                </DialogContent>
            </Dialog>
        );
    };

    const renderModalEdit = () => {
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal,
                }}
                open={modalEdit}
                maxWidth={"sm"}
                fullWidth
                keepMounted
                onClose={() => setModalEdit(false)}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description"
            >
                <DialogTitle
                    id="modal-edit-title"
                    disableTypography
                    className={classes.modalHeader}
                >
                    <Button
                        justIcon
                        className={classes.modalCloseButton}
                        key="close"
                        aria-label="Close"
                        color="transparent"
                        onClick={() => setModalEdit(false)}
                    >
                        <CloseIcon className={classes.modalClose} />
                    </Button>
                    <h4 className={classes.modalTitle}>{t("element.edit")}</h4>
                </DialogTitle>
                <DialogContent id="modal-edit" className={classes.modalBody}>
                    <form autoComplete="off">
                        <GridContainer>
                            <GridItem xs={12} sm={4}>
                                <img src={field.filepic} width="100%" />
                            </GridItem>
                            <GridItem xs={12} sm={8}>
                                <CustomInput
                                    labelText={t(`${prefix}.product_id`)}
                                    id="product_id"
                                    success
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: field.product_id,
                                        disabled: true,
                                    }}
                                />
                                <CustomInput
                                    labelText={t(`${prefix}.product_name`)}
                                    id="product_name"
                                    success
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    inputProps={{
                                        value: field.product_name,
                                        disabled: true,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <p></p>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Table
                                    striped
                                    hover
                                    tableData={[
                                        [
                                            <b>
                                                {t(`${prefix}.quantity`)} [{field.unit_name}]
                                            </b>,
                                            <Input
                                                inputRef={txtQuantity}
                                                id="quantity"
                                                placeholder="0.00"
                                                inputProps={{
                                                    "aria-label": "quantity",
                                                    value: field.quantity,
                                                }}
                                                classes={{
                                                    input: classes.right,
                                                }}
                                                color="primary"
                                                fullWidth={false}
                                                onChange={(e) => {
                                                    setField({ ...field, quantity: e.target.value });
                                                }}
                                            />,
                                        ],
                                    ]}
                                    customHeadClassesForCells={[1]}
                                    customHeadCellClasses={[classes.right]}
                                    customClassesForCells={[1]}
                                    customCellClasses={[classes.right]}
                                    coloredColls={[1]}
                                    colorsColls={["primary"]}
                                />
                            </GridItem>
                        </GridContainer>
                    </form>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                    <Button onClick={editList} color="success">
                        <SaveIcon /> {t(`element.save`)}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const renderInfo = () => {
        return (
            <form autoComplete="off">
                <GridContainer>
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                        <CustomInput
                            success
                            labelText={t(`${prefix}.branch`)}
                            id="branch_name"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                value: attr.branch_name,
                                disabled: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                htmlFor="member_code"
                                className={classes.labelSelect}
                            >
                                ลูกค้า
                                <span className={classes.colorDanger}>*</span>
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={attr.member_code}
                                inputProps={{
                                    name: "member_code",
                                }}
                                onChange={selectChange}
                            >
                                <MenuItem
                                    disabled
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {t("element.pleaseselect")}
                                </MenuItem>
                                {cboMember &&
                                    cboMember.map((item) => (
                                        <MenuItem
                                            key={item.code}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value={item.code}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth className={classes.selectFormControl}>
                            <InputLabel
                                htmlFor="car_code"
                                className={classes.labelSelect}
                            >
                                รถส่งของ
                                <span className={classes.colorDanger}>*</span>
                            </InputLabel>
                            <Select
                                MenuProps={{
                                    className: classes.selectMenu,
                                }}
                                classes={{
                                    select: classes.select,
                                }}
                                value={attr.car_code}
                                inputProps={{
                                    name: "car_code",
                                }}
                                onChange={selectChange}
                            >
                                <MenuItem
                                    disabled
                                    classes={{
                                        root: classes.selectMenuItem,
                                    }}
                                >
                                    {t("element.pleaseselect")}
                                </MenuItem>
                                {cboCar &&
                                    cboCar.map((item) => (
                                        <MenuItem
                                            key={item.code}
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelected,
                                            }}
                                            value={item.code}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={4} lg={3}>
                        <InputLabel className={classes.labelDpk}>
                            {t(`${prefix}.date_record`)}
                        </InputLabel>
                        <FormControl fullWidth>
                            <Datetime
                                timeFormat={false}
                                inputProps={{ placeholder: "__/__/____" }}
                                dateFormat="DD/MM/yyyy"
                                closeOnSelect
                                value={attr.date_record}
                                onChange={(value) => {
                                    setAttr({ ...attr, date_record: value });
                                }}
                                className={classes.center}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4} lg={6}>
                        <CustomInput
                            labelText={
                                <>
                                    {t(`${prefix}.remark`)}
                                    <span className={classes.colorDanger}></span>
                                </>
                            }
                            id="remark"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            inputProps={{
                                onChange: inputChange,
                                value: attr.remark,
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </form>
        );
    };

    const renderStatus = () => {
        if (attr.status == 9) {
            return (
                <MuiAlert
                    elevation={3}
                    variant="filled"
                    severity="error"
                    style={{ marginBottom: "15px" }}
                >
                    {t(`${prefix}.status_cancel`)}
                </MuiAlert>
            );
        } else if (attr.status <= 4) {
            return (
                <Stepper
                    activeStep={attr.status * 1}
                    alternativeLabel
                    className={classes.bgTransparent}
                >
                    <Step>
                        <StepLabel>
                            <p>
                                <b>รอจัดส่ง</b>
                            </p>
                            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
                            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
                        </StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>
                            <p>
                                <b>จัดส่งแล้ว</b>
                            </p>
                            <p className={classes.colorDefault}>{attr.approve_user_3}</p>
                            <p className={classes.colorDefault}>{attr.approve_date_3}</p>
                        </StepLabel>
                    </Step>
                </Stepper>
            );
        }
    };

    const renderDetail = () => {
        return (
            <GridContainer>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="warning">
                                <PersonIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
                        </CardHeader>
                        <CardBody>
                            <p className={classes.statusCenter}>{attr.user_create}</p>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="warning">
                                <PersonOutlineOutlinedIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
                        </CardHeader>
                        <CardBody>
                            <p className={classes.statusCenter}>{attr.date_create}</p>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="warning">
                                <DateRangeIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
                        </CardHeader>
                        <CardBody>
                            <p className={classes.statusCenter}>{attr.user_update}</p>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                    <Card>
                        <CardHeader icon>
                            <CardIcon color="warning">
                                <DateRangeOutlinedIcon />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
                        </CardHeader>
                        <CardBody>
                            <p className={classes.statusCenter}>{attr.date_update}</p>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    };

    const renderLoading = () => {
        return (
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    };

    const renderPage = () => {
        if (id === undefined) {
            return renderViewList();
        } else if (id === "add") {
            return renderAdd();
        } else {
            return renderEdit();
        }
    };

    /* ========== ZONE Render ========== */
    return (
        <div>
            {renderPage()}
            {renderModalAdd()}
            {renderModalEdit()}
            {renderLoading()}
            {alert}
            {notify}
        </div>
    );
}

export default withNamespaces()(DeliveryOrder);
