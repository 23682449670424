/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/AddCircle";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import EditIcon from "@material-ui/icons/EditOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Input from "@material-ui/core/Input";
import i18n from "components/Translation/Translate.js";

import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  filepic: "",
  product_code: "",
  product_id: "",
  product_name: "",
  kind: "ADD",
  unit_code: "",
  unit_name: "",
  quantity: "",
  qty: "",
  remark: "",
  stock: "",
  active: "N",
};

/* ========== ZONE Function ========== */
function CheckStock({ t }) {
  const mod = "checkstock";
  const classes = useStyles();

  const app = useSelector((state) => state.appState);
  const txtQuantity = useRef(null);
  const [attr, setAttr] = useState(initAttr);
  const [stock, setStock] = useState({});
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cboProduct, setCboProduct] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [checkStock, setCheckStock] = useState("N");

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      setCboProduct([]);
      setStock({});
      if (result.status === "OK") {
        if (result.checkstock === "Y") {
          setCboProduct(result.product);
          setStock(result.stock);
        }
        setCheckStock(result.checkstock);
      } else {
        Func.exitApp();
      }
    });
  };

  const openProduct = (item, type) => {
    txtQuantity.current.value = "";

    setAttr({
      ...attr,
      type: type,
      kind: type,
      quantity: "",
      qty: item.quantity,
      code: item.code,
      product_code: item.product_code,
      product_id: item.id,
      product_name: item.name,
      filepic: item.filepic,
      unit_code: item.unit_code,
      unit_name: item.unit_name,
      remark: item.remark,
      active: item.active,
      stock: item.stock,
    });
    setModalAdd(true);

    setTimeout(function () {
      txtQuantity.current.select();
    }, 500);
  };

  const checkEmpty = () => {
    let result = true;

    if (attr.quantity < 0) {
      result = false;
    }

    return result;
  };

  const save = () => {
    if (attr.kind == "ADD") {
      edit();
    } else if (attr.kind == "EDIT") {
      add();
    }
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setModalAdd(false);
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              product_code: attr.product_code,
              data: {
                quantity: attr.quantity == "" ? 0 : attr.quantity,
                remark: attr.remark,
                active: "Y",
              },
            }).then((result) => {
              setLoading(false);
              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                setAttr(initAttr);
                init();
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setModalAdd(false);
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              product_code: attr.product_code,
              data: {
                quantity: attr.quantity == "" ? 0 : attr.quantity,
                remark: attr.remark,
                active: "Y",
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.editcomplete"), "success");
                setAttr(initAttr);
                init();
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderAdd = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  {checkStock === "Y" ? (
                    <h4>
                      {t(`attr.id`)} : {stock.id}
                    </h4>
                  ) : (
                    <h4>
                      <i className="fas fa-check-circle" />{" "}
                      {t(`${mod}.checkstockcomplete`)}
                    </h4>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderInfo()}
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <GridContainer>
        {cboProduct.map((item) => (
          <GridItem xs={12} sm={12} md={3} lg={2} key={item.code}>
            <Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <a href="#">
                  <img src={item.filepic} alt="..." />
                </a>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <h4>{item.product_id}</h4>
                </div>
                <h4 className={classes.center}>{item.name}</h4>
                {item.active == "Y" ? (
                  <div className={classes.center}>
                    <Button
                      color="success"
                      onClick={() => openProduct(item, "EDIT")}
                    >
                      เพิ่มปริมาณ
                    </Button>
                  </div>
                ) : (
                  <div className={classes.center}>
                    <Button
                      color="info"
                      onClick={() => openProduct(item, "ADD")}
                    >
                      <AddIcon />
                      นับสต็อค
                    </Button>
                  </div>
                )}
                {/* <div className="center">
                  {item.stock < 0 ? (
                    <code className="color-danger">
                      QTY: {Func.numberDisplay(item.stock)}
                    </code>
                  ) : (
                    <code className="color-success">
                      QTY: {Func.numberDisplay(item.stock)}
                    </code>
                  )}
                </div> */}
              </CardBody>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderModalAdd = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalAdd}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalAdd(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-add-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalAdd(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {attr.kind === "ADD" ? (
              <span>นับสต็อค</span>
            ) : (
              <span>เพิ่มปริมาณ</span>
            )}
          </h4>
        </DialogTitle>
        <DialogContent id="modal-add" className={classes.modalBody}>
          <br />
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <img src={attr.filepic} width="100%" />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText={t(`${mod}.product_id`)}
                  id="product_id"
                  success
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.product_id,
                    disabled: true,
                  }}
                />
                <CustomInput
                  labelText={t(`${mod}.product_name`)}
                  id="product_name"
                  success
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.product_name,
                    disabled: true,
                  }}
                />
                {attr.type === "ADD" ? null : (
                  <CustomInput
                    labelText={<>ปริมาณนับได้ [{attr.unit_name}]</>}
                    id="qty"
                    success
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: attr.qty,
                      disabled: true,
                    }}
                  />
                )}
              </GridItem>
              <GridItem xs={12}>
                <hr />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText={
                    <>
                      {attr.kind === "ADD" ? (
                        <span>นับสต็อค</span>
                      ) : (
                        <span>เพิ่มปริมาณ</span>
                      )}{" "}
                      {t(`${mod}.quantity`)} [{attr.unit_name}]
                    </>
                  }
                  id="quantity"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtQuantity,
                    placeholder: "0.00",
                    onChange: (e) =>
                      setAttr({ ...attr, quantity: e.target.value }),
                    value: attr.quantity,
                  }}
                  alignRight
                />
              </GridItem>
              <GridItem xs={12} sm={6}></GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${mod}.remark`)}
                  id="remark"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: (e) =>
                      setAttr({ ...attr, remark: e.target.value }),
                    value: attr.remark,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={save} color="success">
            <SaveIcon /> {t(`element.save`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderAdd()}
      {renderModalAdd()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(CheckStock);
