/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/main-style.js";
import "views/Print/PrintStyle.css";

/* ========== ZONE Function ========== */
function PrintFullReceipt({ t, attr, list }) {
  const prefix = "bill";

  const renderHeader = () => {
    return (
      <div>
        <table width="100%" cellPadding={5}>
          <tbody>
            <tr>
              <td align="center" colSpan={2}>
                <h3>ห้างหุ้นส่วนจำกัด เส้นก๋วยเตี๋ยว ไทยเจริญผล</h3>
              </td>
            </tr>
            <tr>
              <td align="center" width="85%">
                เลขที่ 99/1 หมู่ที่ 14 ต.ท่าช้าง อ.บางกล่ำ จ.สงขลา
                โทร.081-5997507
              </td>
              <td>
                {attr.branch_code === "1" ? (
                  <i className="far fa-check-square" />
                ) : (
                  <i className="far fa-square" />
                )}{" "}
                สำนักงานใหญ่
              </td>
            </tr>
            <tr>
              <td align="center">
                เลขที่ 11/1 ถ.พูลสุวรรณ ต.หาดใหญ่ อ.หาดใหญ่ จ.สงขลา
                โทร.074-245879
              </td>
              <td>
                {attr.branch_code === "2" ? (
                  <i className="far fa-check-square" />
                ) : (
                  <i className="far fa-square" />
                )}{" "}
                สาขาที่ 1
              </td>
            </tr>
            <tr>
              <td align="center" colSpan={2}>
                เลขประจำตัวผู้เสียภาษี 0 9035 60003 50 6
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" cellPadding={5}>
          <tbody>
            <tr>
              <td align="center" colSpan={2}>
                <h3>ใบเสร็จรับเงิน/ใบกำกับภาษี</h3>
              </td>
            </tr>
            <tr>
              <td align="center" width="50%">
                {attr.forcustomer === "Y" ? "สำหรับลูกค้า" : "สำหรับบริษัท"}
              </td>
              <td align="center">NO.{attr.receipt_id}</td>
            </tr>
            <tr>
              <td></td>
              <td>วันที่ {attr.date_bill}</td>
            </tr>
            <tr>
              <td colSpan={2}>นาม {attr.company_name}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                ที่อยู่ {attr.address} {attr.district} {attr.city}{" "}
                {attr.province_name} {attr.zipcode}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div className="line"></div>
              </td>
            </tr>
            <tr>
              <td>เลขประจำตัวผู้เสียภาษีอากร {attr.company_id}</td>
              <td>
                <i className="far fa-square" /> สำนักงานใหญ่{" "}
                <i className="far fa-square" /> สาขาที่ 1
              </td>
            </tr>
          </tbody>
        </table>
        <table width="100%" cellPadding={5}>
          <tbody>
            <tr>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderOrder = () => {
    return (
      <table width="100%" cellPadding={5} border="1">
        <thead>
          <tr>
            <th width="10%">ลำดับที่</th>
            <th>รายการ</th>
            <th width="15%">จำนวน</th>
            <th width="15%">หน่วยละ</th>
            <th width="15%" colSpan={2}>
              จำนวนเงิน
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, i) => (
            <tr key={item.code}>
              <td align="center" valign="top">
                {i + 1}
              </td>
              <td valign="top">{item.product_name}</td>
              <td align="right" valign="top">
                {Func.numberDisplay(item.quantity)}
              </td>
              <td align="right" valign="top">
                {Func.numberDisplay(item.price)}
              </td>
              <td align="right" valign="top">
                {Func.numberDisplay(item.amount)}
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
        <tbody>
          <tr>
            <td colSpan={3} rowSpan={5} valign="bottom" className="boxtext">
              <div>จำนวนเงินรวมทั้งสิ้น (อักษร)</div>
              <div className="pricetext">{attr.pricetext}</div>
            </td>
            <th align="right">{t(`${prefix}.amount`)}</th>
            <td align="right">{Func.numberDisplay(attr.amount)}</td>
            <td></td>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.vat`)}</th>
            <td align="right">{Func.numberDisplay(attr.vat)}</td>
            <td></td>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.total`)}</th>
            <td align="right">{Func.numberDisplay(attr.total)}</td>
            <td></td>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.shipping_price`)}</th>
            <td align="right">{Func.numberDisplay(attr.shipping_price)}</td>
            <td></td>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.nettotal`)}</th>
            <td align="right">{Func.numberDisplay(attr.nettotal)}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderFooter = () => {
    return (
      <table width="100%" colSpan={5}>
        <tbody>
          <tr>
            <td colSpan={2}>
              <br />
              <br />
              <br />
            </td>
          </tr>
          <tr>
            <td align="center" width="50%">
              ผู้รับสินค้า ....................................................
            </td>
            <td align="center">
              ผู้รับเงิน ....................................................
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="print">
      <div className="a4">
        {renderHeader()}
        {renderOrder()}
        {renderFooter()}
      </div>
    </div>
  );
}

export default withNamespaces()(PrintFullReceipt);

PrintFullReceipt.defaultProps = {
  attr: {},
  list: [],
};
