/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Badge from "@material-ui/core/Badge";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import AlertIcon from "@material-ui/icons/AddAlert";
import PrintIcon from "@material-ui/icons/Print";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import MoneyIcon from "@material-ui/icons/Money";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ListAltIcon from "@material-ui/icons/ListAlt";

// core components
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";

import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import { API_URL } from "services/Define";
import * as Func from "services/Function";
import UploadPicture from "./UploadPicture";

import styles from "assets/jss/main-style.js";
import PosSlipDay from "views/Pos/PosSlipDay";
import CustomerPrint from "./CustomerPrint";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name: "",
  tel: "",
  image: "",
  balance: "",
  order_balance: "",
  amount: "",
  money: "",
  money_change: "",
  payment_name: "",
  account_bank: "",
  account_name: "",
  account_accno: "",
  account_code: "",
  wallet_pay: "",
  wallet_balance: "",
  transfer_bank: "",
  transfer_date: new Date(),
  transfer_time: "",
  transfer_remark: "",
};

const initFind = {
  search: "",
  date_start: Func.dateAdd(-30),
  date_stop: Func.dateAdd(7),
  order_by: "tb.date_record",
  order_sort: "desc",
  status: "ALL",
  shipping: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

const initSummary = {
  amount: 0.0,
  vat: 0.0,
  discount: 0.0,
  shipping: 0.0,
  quantity: 0.0,
  total: 0.0,
};

/* ========== ZONE Function ========== */
function CustomerDay({ t, changeDate, changeReceipt, openInvoice }) {
  const mod = "customerday";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.date_record", name: t(`${prefix}.date_record`), order: "desc" },
    { code: "", name: t(`${prefix}.count_bill`), sort: false },
    { code: "", name: t(`${prefix}.count_waiting`), sort: false },
    { code: "", name: "รออนุมัติ", sort: false },
    { code: "", name: t(`${prefix}.count_complete`), sort: false },
    { code: "", name: t(`${prefix}.count_cancel`), sort: false },
    { code: "", name: t(`${prefix}.count_waitshipping`), sort: false },
    { code: "", name: t(`${prefix}.count_shipping`), sort: false },
    { code: "", name: t(`${prefix}.sum_waiting`), sort: false },
    { code: "", name: t(`${prefix}.sum_total`), sort: false },
    { code: "", name: "กำกับภาษี", sort: false },
  ];
  const alignValue = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.center,
    classes.center,
  ];

  const app = useSelector((state) => state.appState);
  const slipRef = useRef(null);
  const { id } = useParams();
  const [cboStatus, setCboStatus] = useState([]);
  const [cboShipping, setCboShipping] = useState([]);
  const [cboBank, setCboBank] = useState([]);
  const [cboAccount, setCboAccount] = useState([]);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalList, setModalList] = useState(false);
  const [checked, setChecked] = useState([]);
  const [modalPayment, setModalPayment] = useState(false);
  const [modalPrint, setModalPrint] = useState(false);
  const [tabPayment, setTabPayment] = useState(0);
  const txtMoney = useRef(null);
  const [list, setList] = useState([]);
  const [slip, setSlip] = useState({});
  const [slipList, setSlipList] = useState([]);
  const [summary, setSummary] = useState(initSummary);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboShipping(result.shipping);
        setCboBank(result.bank);
        setCboAccount(result.account);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    setRecord([]);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      shipping: data.shipping,
      member: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);
      }
      setLoading(false);
    });
  };

  const handleCheck = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  const numberDisplay = (inp, p) => {
    if (inp > 0) return Func.numberDisplay(inp, p);
    else return "-";
  };

  const print = (chk) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "print", {
      token: app.token,
      lang: app.lang,
      member_code: id,
      checked: chk,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);
        handlePrint();
      } else {
        showNotify('ไม่พบข้อมูล', "danger");
      }
    });
  };

  const deliveryOrder = (code) => {
    window.open(`${API_URL}/app/print/delivery_order-${code}.pdf`, "_blank");
  };

  const addReceipt = (date_record) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="คุณต้องการพิมพ์ใบเสร็จใช่หรือไม่"
        onConfirm={() => {
          setLoading(true);
          setAlert(null);

          PostData(mod, "addreceipt", {
            token: app.token,
            lang: app.lang,
            member_code: id,
            date_record: date_record,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              changeReceipt(date_record);
            } else if (result.status === "ALERT") {
              showNotify(result.msg, "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        พิมพ์ใบกำกับภาษี : {Func.dateDisplay(date_record, 3)}
      </SweetAlert>
    );
  };

  const billDisplay = (inp, p) => {
    if (inp > 0)
      return (
        <code className="color-red">{Func.numberDisplay(inp, p)} บิล</code>
      );
    else return "-";
  };

  const invoiceDisplay = (inp, p) => {
    if (inp > 0)
      return (
        <code className="color-success">{Func.numberDisplay(inp, p)} บิล</code>
      );
    else return "-";
  };

  const open = (item) => {
    changeDate(item.date_record);
  };

  const addPay = (type, account) => {
    if (attr.order_balance <= 0) {
      showNotify(t(`${prefix}.nobalancepay`), "danger");
    } else if (attr.amount <= 0) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else if (type == "CASH" && attr.money <= 0) {
      showNotify("กรุณาเพิ่มจำนวนเงิน", "danger");
    } else {
      if (confirm("คุณต้องการบันทึกข้อมูล ใช่หรือไม่?")) {
        setLoading(true);
        setModalPayment(false);

        PostData(mod, "addpay", {
          member_code: id,
          checked: checked,
          amount: attr.amount,
          type: type,
          data: {
            payment_type: type,
            payment_main: type,
            amount: type == "WALLET" ? attr.wallet_pay : attr.amount,
            account_code: account,
            money: type == "CASH" ? attr.money : 0,
            money_change: type == "CASH" ? attr.money_change : 0,
          },
        }).then((result) => {
          setLoading(false);

          if (result.status === "OK") {
            view(find);
            openInvoice();
            setChecked([]);

            showNotify(t("alert.editcomplete"), "success");
          } else {
            showNotify(t("alert.editerror"), "danger");
          }
        });
      }
    }
  };

  const addPay_ = (type, account) => {
    if (attr.order_balance <= 0) {
      showNotify(t(`${prefix}.nobalancepay`), "danger");
    } else if (attr.amount <= 0) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else if (type == "CASH" && attr.money <= 0) {
      showNotify("กรุณาเพิ่มจำนวนเงิน", "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);
            setModalPayment(false);

            PostData(mod, "addpay", {
              member_code: id,
              checked: checked,
              amount: attr.amount,
              type: type,
              data: {
                payment_type: type,
                payment_main: type,
                amount: type == "WALLET" ? attr.wallet_pay : attr.amount,
                account_code: account,
                money: type == "CASH" ? attr.money : 0,
                money_change: type == "CASH" ? attr.money_change : 0,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                view(find);
                openInvoice();
                setChecked([]);

                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")}
        </SweetAlert>
      );
    }
  };

  const transferPay = (account) => {
    if (attr.order_balance <= 0) {
      showNotify(t(`${prefix}.nobalancepay`), "danger");
    } else if (attr.amount <= 0) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);
            setModalPayment(false);

            PostData(mod, "waitingpay", {
              member_code: id,
              checked: checked,
              amount: attr.amount,
              data: {
                payment_type: "WAITING",
                amount: attr.amount,
                account_code: account,
                money: 0,
                money_change: 0,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                view(find);
                openInvoice();
                setChecked([]);

                openInvoicePay(result);

                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")}
        </SweetAlert>
      );
    }
  };

  const openInvoicePay = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "openinvoice", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({
          ...result.data,
          transfer_date: new Date(result.data.transfer_date),
        });

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              item.so_id,
              Func.dateTimeDisplay(item.date_create),
              displayStatus(item),

              Func.numberDisplay(item.nettotal),
              Func.numberDisplay(item.pay),
              Func.numberDisplay(item.balance),
            ],
          ]);
        });

        setLoading(false);
        setModalList(true);
      }
    });
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const uploadPicture = (filedata) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "upload", {
            code: attr.code,
            token: app.token,
            lang: app.lang,
            filedata: filedata,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              setAttr({ ...attr, image: result.image });
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("upload.upload")}
      </SweetAlert>
    );
  };

  const waitingPay = () => {
    if (attr.order_balance <= 0) {
      showNotify(t(`${prefix}.nobalancepay`), "danger");
    } else if (attr.amount <= 0) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);
            setModalPayment(false);

            PostData(mod, "waitingpay", {
              member_code: id,
              checked: checked,
              amount: attr.amount,
              data: {
                payment_type: "WAITING",
                amount: attr.amount,
                account_code: 0,
                money: 0,
                money_change: 0,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                view(find);
                openInvoice();
                setChecked([]);

                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")}
        </SweetAlert>
      );
    }
  };

  const openPay = (chk) => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
      checked: chk,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setChecked(chk);

        setLoading(false);
        setModalPayment(true);
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {Func.dateDisplay(item.code)}
      </Button>
    );
  };

  const btn = (item) => {
    return (
      <>
        {item.sum_waiting > 0 ?
          <Button
            color="success"
            className={classes.actionButton}
            onClick={() => openPay([item.code])}
            size="sm"
          >
            ชำระเงิน
          </Button> : null}

        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print([item.code])}
          size="sm"
        >
          แจ้งหนี้
        </Button>
        {item.delivery > 0 ?
          <Button
            color="danger"
            className={classes.actionButton}
            onClick={() => deliveryOrder(item.delivery)}
            size="sm"
          >
            ใบส่งของ
          </Button> : null}
      </>
    );
  };

  const btnInvoice = (item) => {
    return (
      <Button
        color="primary"
        className={classes.actionButton}
        onClick={() => addReceipt(item.code)}
        size="sm"
      >
        {item.count_receipt > 0 ? (
          <Badge badgeContent={item.count_receipt * 1} color="secondary">
            ใบกำกับภาษี <PrintIcon />
          </Badge>
        ) : (
          <span>
            ใบกำกับภาษี <PrintIcon />
          </span>
        )}
      </Button>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => {
                        view({ ...find, date_start: value, page: 1 });
                        setFind({ ...find, date_start: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => {
                        view({ ...find, date_stop: value, page: 1 });
                        setFind({ ...find, date_stop: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.shipping}
                      onChange={(e) => {
                        view({ ...find, shipping: e.target.value, page: 1 });
                        setFind({ ...find, shipping: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.shipping`)}
                      </MenuItem>
                      {cboShipping.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderCheckAll(),
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  renderHeader(header[6]),
                  renderHeader(header[7]),
                  renderHeader(header[8]),
                  renderHeader(header[9]),
                  renderAction(),
                ]}
                tableData={record.map((item) => {
                  return [
                    renderCheck(item),
                    btnOpen(item),
                    numberDisplay(item.count_bill, 0),
                    billDisplay(item.count_waiting, 0),
                    invoiceDisplay(item.count_invoice, 0),
                    numberDisplay(item.count_complete, 0),
                    numberDisplay(item.count_cancel, 0),
                    numberDisplay(item.count_waitshipping, 0),
                    numberDisplay(item.count_shipping, 0),
                    numberDisplay(item.sum_waiting, 2),
                    numberDisplay(item.sum_total, 2),
                    btn(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[9, 10]}
                colorsColls={["danger", "primary"]}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderCheckAll = () => {
    return (
      <Checkbox
        key="ALL"
        className={classes.positionAbsolute}
        tabIndex={-1}
        onClick={(e) => {
          setChecked([]);
          if (e.target.checked)
            record.map((item) => {
              setChecked((checked) => [...checked, item.code]);
            });
        }}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot,
        }}
      />
    );
  };

  const renderCheck = (item) => {
    if (item.count_waiting > 0)
      return (
        <Checkbox
          key={item.code}
          className={classes.positionAbsolute}
          tabIndex={-1}
          checked={checked.indexOf(item.code) !== -1}
          onClick={() => handleCheck(item.code)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
        />
      );
    else return "";
  };

  const renderAction = () => {
    return (
      <CustomDropdown
        dropup
        buttonText={t(`${prefix}.action`)}
        buttonProps={{
          color: "primary",
          size: "sm",
        }}
        dropdownList={[
          {
            id: "payment",
            title: (
              <div>
                <i className="fas fa-file-invoice-dollar" />{" "}
                {t(`${prefix}.payment`)}
              </div>
            ),
          },
          {
            id: "print",
            title: (
              <div>
                <i className="fas fa-print" /> {t(`${prefix}.printinvoice`)}
              </div>
            ),
          },
          {
            id: "printlog",
            title: (
              <div>
                <i className="fas fa-file" /> ใบแจ้งหนี้
              </div>
            ),
          },
        ]}
        onClick={(item) => {
          switch (item.id) {
            case "payment":
              openPay(checked);
              break;
            case "print":
              print(checked);
              break;
            case "printlog":
              setModalPrint(true);
              break;
          }
        }}
      />
    );
  };

  const editInvoice = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editinvoice", {
            token: app.token,
            lang: app.lang,
            code: attr.code,
            data: {
              transfer_bank: attr.transfer_bank,
              transfer_date: Func.dateFormat(attr.transfer_date),
              transfer_time: attr.transfer_time,
              transfer_remark: attr.transfer_remark,
            },
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const pay = {
    numpad: (num) => {
      if (num == "-") {
        setAttr({ ...attr, money: "", money_change: "0.00" });
      } else {
        let money = attr.money;
        let amount = attr.amount * 1;
        let money_change = 0;
        money += "" + num;

        money_change = money * 1 - amount;

        if (money_change < 0) money_change = 0;

        setAttr({ ...attr, money: money, money_change: money_change });
      }
    },
    numChange: (num) => {
      let money = attr.money * 1 + num;
      let amount = attr.amount * 1;
      let money_change = 0;

      money_change = money - amount;

      if (money_change < 0) money_change = 0;

      setAttr({ ...attr, money: money, money_change: money_change });
    },
    payChange: (value) => {
      let amount = value * 1;
      let money = attr.money * 1;
      let balance = attr.balance * 1;
      let order_balance = attr.order_balance * 1;
      let money_change = 0;
      let wallet_pay = balance < amount ? balance : amount;
      wallet_pay = wallet_pay > order_balance ? order_balance : wallet_pay;
      let wallet_balance = balance - wallet_pay;

      money_change = money - amount;

      if (money_change < 0) money_change = 0;

      amount = amount === 0 ? "" : amount;
      money = money === 0 ? "" : money;
      setAttr({
        ...attr,
        money: money,
        money_change: money_change,
        amount: amount,
        wallet_pay: wallet_pay,
        wallet_balance: wallet_balance,
      });
    },
    btnNumpad: (num) => {
      return (
        <Button
          simple
          color="primary"
          className={classes.actionButton + " " + classes.actionButtonRound}
          block
          size={"lg"}
          style={{ height: "80px", fontSize: "20px" }}
          onClick={() => pay.numpad(num)}
        >
          {num}
        </Button>
      );
    },
    btnNumChange: (num) => {
      return (
        <Button
          simple
          color="primary"
          className={classes.actionButton + " " + classes.actionButtonRound}
          block
          size={"lg"}
          style={{ height: "80px", fontSize: "20px" }}
          onClick={() => pay.numChange(num)}
        >
          {num}
        </Button>
      );
    },
    paymentCash: () => {
      setModalPayment(false);
      setLoading(true);

      PostData(mod, "paymentcash", {
        token: app.token,
        lang: app.lang,
        money: txtMoney.current.value,
        data: {
          member_code: id,
          payment_type: "CASH",
          transfer: attr.transfer,
          branch_transfer: attr.branch_transfer,
          address: attr.transfer === "3" ? attr.address : "",
          branch_warehouse: attr.transfer === "3" ? attr.branch_warehouse : "",
          money: attr.money,
          money_change: attr.money_change,
          shipping_price: summary.shipping,
        },
      }).then((result) => {
        setLoading(false);
        setTabPayment(0);
        changeTabBill(0);

        if (result.status === "OK") {
          setCustomer(initCustomer);
          setSummary(initSummary);
          setList([]);

          setSlip(result.order);
          setSlipList(result.list);
          handlePrint();

          main.loadProduct();
        }
      });
    },
    paymentTransfer: (item) => {
      setModalPayment(false);
      setLoading(true);

      PostData(mod, "paymenttransfer", {
        token: app.token,
        lang: app.lang,
        member: id,
        money: attr.money,
        type: tabBill,
        data: {
          member_code: id,
          payment_type: "TRANSFER",
          account_code: item.code,
          transfer: attr.transfer,
          branch_transfer: attr.branch_transfer,
          address: attr.transfer === "3" ? attr.address : "",
          branch_warehouse: attr.transfer === "3" ? attr.branch_warehouse : "",
          money: attr.money,
          money_change: attr.money_change,
          shipping_price: summary.shipping,
        },
      }).then((result) => {
        setLoading(false);
        setTabPayment(0);
        changeTabBill(0);

        if (result.status === "OK") {
          setCustomer(initCustomer);
          setSummary(initSummary);
          setList([]);

          setSlip(result.order);
          setSlipList(result.list);
          handlePrint();

          main.loadProduct();
        }
      });
    },
  };

  const renderPay = {
    modal: () => {
      return (
        <Dialog
          fullScreen
          open={modalPayment}
          onClose={() => setModalPayment(false)}
        >
          <DialogTitle
            id="modal-pay-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setModalPayment(false)}
            >
              <CloseIcon className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>การชำระเงิน</h4>
          </DialogTitle>
          <DialogContent
            id="modal-pay-description"
            style={{
              height: "700px",
              overflowY: "scroll",
              width: "100%",
              padding: "0 !important",
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={7}>
                {renderPay.order()}
              </GridItem>
              <GridItem xs={12} sm={5}>
                {renderPay.content()}
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      );
    },
    order: () => {
      const cssValue = {
        padding: "10px 20px",
        fontSize: "2em",
        background: "#f2f2f2",
        textAlign: "right",
        color: "#666",
        marginBottom: "10px",
      };

      return (
        <form autoComplete="off">
          <GridContainer>
            <GridItem xs={12}>
              <br />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={t(`${prefix}.member`)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.name,
                  disabled: true,
                }}
              />
              <CustomInput
                labelText={t(`${prefix}.tel`)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.tel,
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={t(`${prefix}.wallet`)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.balance),
                  disabled: true,
                }}
                alignRight
              />
              <CustomInput
                labelText={t(`${prefix}.balance`)}
                error
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.order_balance),
                  disabled: true,
                }}
                alignRight
              />
              {/* <CustomInput
                labelText={t(`${prefix}.payamount`)}
                formControlProps={{
                  fullWidth: true,
                }}
                placeholder="0.00"
                inputProps={{
                  type: "number",
                  value: attr.amount,
                  onChange: (e) => pay.payChange(e.target.value),
                }}
                alignRight
              /> */}
              <CustomInput
                success
                labelText={t(`${prefix}.payamount`)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  placeholder: "0.00",
                  style: cssValue,
                  type: "number",
                  value: attr.amount === "0" ? "" : attr.amount,
                  onChange: (e) => pay.payChange(e.target.value),
                }}
                style={{ paddingTop: 0 }}
                alignRight
              />
            </GridItem>
          </GridContainer>
        </form>
      );
    },
    content: () => {
      return (
        <CustomTabs
          headerColor="primary"
          value={tabPayment}
          changeValue={(e, v) => setTabPayment(v)}
          tabs={[
            {
              tabName: "เงินสด",
              tabIcon: MoneyIcon,
              tabContent: renderPay.cash(),
            },
            {
              tabName: "โอนเงิน",
              tabIcon: AccountBalanceIcon,
              tabContent: renderPay.transfer(),
            },
            {
              tabName: "Wallet",
              tabIcon: MoneyIcon,
              tabContent: renderPay.wallet(),
            },
          ]}
        />
      );
    },
    cash: () => {
      const cssValue = {
        padding: "10px 20px",
        fontSize: "2em",
        background: "#f2f2f2",
        textAlign: "right",
        color: "#666",
        marginBottom: "10px",
      };

      return (
        <div style={{ overflow: "hidden" }}>
          <div>
            <CustomInput
              labelText="จำนวนเงินที่รับ"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.money,
                inputRef: txtMoney,
                autoComplete: "off",
                placeholder: "0",
                style: cssValue,
                onChange: (e) => {
                  let money = e.target.value;
                  // let money_change = money - summary.total;
                  // if (money_change < 0) money_change = 0;

                  // setAttr({
                  //   ...attr,
                  //   money: money,
                  //   money_change: money_change,
                  // });
                  let amount = attr.amount * 1;
                  let money_change = 0;

                  money_change = money - amount;

                  if (money_change < 0) money_change = 0;

                  setAttr({
                    ...attr,
                    money: money,
                    money_change: money_change,
                  });
                },
              }}
              style={{ paddingTop: 0 }}
              alignRight
            />
          </div>
          <h3 className="center">
            เงินทอน : {Func.numberDisplay(attr.money_change)}
          </h3>
          <table width="100%">
            <tbody>
              <tr>
                <td width="25%">{pay.btnNumpad(7)}</td>
                <td width="25%">{pay.btnNumpad(8)}</td>
                <td width="25%">{pay.btnNumpad(9)}</td>
                <td width="25%">{pay.btnNumChange(1000)}</td>
              </tr>
              <tr>
                <td>{pay.btnNumpad(4)}</td>
                <td>{pay.btnNumpad(5)}</td>
                <td>{pay.btnNumpad(6)}</td>
                <td>{pay.btnNumChange(500)}</td>
              </tr>
              <tr>
                <td>{pay.btnNumpad(1)}</td>
                <td>{pay.btnNumpad(2)}</td>
                <td>{pay.btnNumpad(3)}</td>
                <td>{pay.btnNumChange(100)}</td>
              </tr>
              <tr>
                <td>{pay.btnNumpad(".")}</td>
                <td>{pay.btnNumpad(0)}</td>
                <td>
                  <Button
                    simple
                    color="primary"
                    className={
                      classes.actionButton + " " + classes.actionButtonRound
                    }
                    block
                    size={"lg"}
                    style={{ height: "80px", fontSize: "20px" }}
                    onClick={() => pay.numpad("-")}
                  >
                    <i className="fas fa-backspace"></i>
                  </Button>
                </td>
                <td>
                  <Button
                    simple
                    color="danger"
                    className={
                      classes.actionButton + " " + classes.actionButtonRound
                    }
                    block
                    size={"lg"}
                    style={{ height: "80px", fontSize: "20px" }}
                    onClick={() => addPay("CASH", 0)}
                  >
                    ตกลง
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    },
    transfer: () => {
      return (
        <div>
          {cboAccount.map((item) => (
            <button
              key={item.code}
              className="btnbank"
              onClick={() => transferPay(item.code)}
            >
              <CardBody
                className="myaccount"
                style={{ backgroundColor: item.bank_color }}
              >
                <table>
                  <tbody>
                    <tr>
                      <td width="90">
                        <img src={item.bank_pic} width="70" />
                      </td>
                      <td>
                        <div>{item.bank_name}</div>
                        <div className="text-account">{item.acc_no}</div>
                        <div>{item.acc_name}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </button>
          ))}
        </div>
      );
    },
    wallet: () => {
      return (
        <form autoComplete="off">
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                labelText="Wallet"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.balance),
                  disabled: true,
                }}
                alignCenter
              />
              <CustomInput
                error
                labelText="เงินที่ชำระ"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.wallet_pay),
                  disabled: true,
                }}
                alignCenter
              />
              <CustomInput
                success
                labelText="Wallet คงเหลือ"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.wallet_balance),
                  disabled: true,
                }}
                alignCenter
              />
            </GridItem>
            <GridItem xs={12}>
              <Button
                color="primary"
                block
                size={"lg"}
                onClick={() => addPay("WALLET", 0)}
              >
                <i className="fas fa-add"></i> ชำระเงิน
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      );
    },
    waiting: () => {
      /* renderPay.waiting */
      return (
        <div>
          <Button size={"lg"} onClick={() => waitingPay()}>
            ส่งรายการรับชำระ
          </Button>
        </div>
      );
    },
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status * 1}
        alternativeLabel
        className={classes.bgTransparent}
      >
        <Step>
          <StepLabel>
            <p>
              <b>รอชำระ</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        {attr.status == 9 ? (
          <Step>
            <StepLabel>
              <p>
                <Badge color="danger">ยกเลิก</Badge>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_9}</p>
              <p className={classes.colorDefault}>{attr.approve_date_9}</p>
            </StepLabel>
          </Step>
        ) : (
          <Step>
            <StepLabel>
              <p>
                <b>ชำระแล้ว</b>
              </p>
              <p className={classes.colorDefault}>{attr.approve_user_3}</p>
              <p className={classes.colorDefault}>{attr.approve_date_3}</p>
            </StepLabel>
          </Step>
        )}
      </Stepper>
    );
  };

  const renderList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              "เลขที่บิล",
              "วันที่",
              "สถานะ",
              "จำนวนเงิน",
              "ชำระแล้ว",
              "ค้างชำระ",
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[2, 3, 4]}
            colorsColls={["primary", "success", "danger"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderStatus()}
          <CustomTabs
            headerColor="rose"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.order"),
                tabIcon: ListAltIcon,
                tabContent: renderList(),
              },
              {
                tabName: "การชำระเงิน",
                tabIcon: ListAltIcon,
                tabContent: renderSlip(),
              },
            ]}
          />
        </DialogContent>
      </Dialog>
    );
  };

  const renderSlip = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                labelText="การชำระเงิน"
                id="payment_name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.account_code > 0 ? "โอนเงิน" : "เงินสด",
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="ธนาคารรับเงิน"
                id="account_bank"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.account_bank,
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="บัญชีรับเงิน"
                id="account_name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.account_name,
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="เลขบัญชีรับเงิน"
                id="account_accno"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.account_accno,
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="transfer_bank"
                  className={classes.labelSelect}
                >
                  ธนาคารที่โอน
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={attr.transfer_bank}
                  inputProps={{
                    name: "transfer_bank",
                  }}
                  onChange={selectChange}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                  >
                    {t("element.pleaseselect")}
                  </MenuItem>
                  {cboBank &&
                    cboBank.map((item) => (
                      <MenuItem
                        key={item.code}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.code}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputLabel className={classes.labelDpk}>วันที่โอน</InputLabel>
              <FormControl fullWidth>
                <Datetime
                  timeFormat={false}
                  inputProps={{
                    placeholder: "__/__/____",
                  }}
                  dateFormat="DD/MM/yyyy"
                  closeOnSelect
                  value={attr.transfer_date}
                  onChange={(value) => {
                    setAttr({ ...attr, transfer_date: value });
                  }}
                  className={classes.center}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText="เวลาโอน"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) =>
                    setAttr({
                      ...attr,
                      transfer_time: e.target.value,
                    }),
                  onBlur: (e) =>
                    setAttr({
                      ...attr,
                      transfer_time: Func.timeSetup(e.target.value),
                    }),
                  value: attr.transfer_time,
                }}
                alignCenter
              />
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText="หมายเหตุ"
                id="transfer_remark"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: inputChange,
                  value: attr.transfer_remark,
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <Button color="success" onClick={editInvoice}>
                <SaveIcon /> {t("element.save")}
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardBody>
              <a href={attr.image} target="_blank">
                <div className="fileinput text-center">
                  <div className={"thumbnail"} style={{ maxWidth: "100%" }}>
                    <img src={attr.image} alt="..." />
                  </div>
                </div>
              </a>
              <UploadPicture uploadData={uploadPicture} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalPrint = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalPrint}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalPrint(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-print-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalPrint(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            ใบแจ้งหนี้ (
            <span className="color-danger">
              ใช้สำหรับพิมพ์ย้อนหลัง โดยใช้ข้อมูล ณ ขณะนั้น
            </span>
            )
          </h4>
        </DialogTitle>
        <DialogContent
          id="modal-print"
          className={classes.modalBody}
          style={{
            height: "700px",
            overflowY: "scroll",
            width: "100%",
          }}
        >
          <CustomerPrint />
        </DialogContent>
      </Dialog>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <PosSlipDay ref={slipRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalList()}
      {renderModalPrint()}
      {renderLoading()}
      {renderPrint()}
      {renderPay.modal()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(CustomerDay);
