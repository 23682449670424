/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

/* ========== ZONE Function ========== */
function PosSlipRound({ t, attr, member_list, payout_list }) {
  const mod = "posbill";
  const prefix = "bill";
  const classes = useStyles();

  const renderMember = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td></td>
          </tr>
          {member_list.map((item, i) => (
            <tr key={item.code}>
              <td align="left" valign="top">
                {i + 1}.
              </td>
              <td align="left" valign="top">
                {item.name}
              </td>
              <td align="right" valign="top">
                {Func.numberDisplay(item.total)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderPayout = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td></td>
          </tr>
          {payout_list.map((item, i) => (
            <tr key={item.code}>
              <td align="left" valign="top">
                {i + 1}.
              </td>
              <td align="left" valign="top">
                {item.detail}
              </td>
              <td align="right" valign="top">
                {Func.numberDisplay(item.price)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="print">
      <div className="slips">
        <table width="100%">
          <tbody>
            <tr>
              <td align="center" colSpan={2}>
                <h3>ใบปิดยอด</h3>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>ชื่อ cashier:</td>
              <td align="right">{attr.cashier}</td>
            </tr>
            <tr>
              <td>เวลา:</td>
              <td align="right">
                {Func.dateTimeDisplay(attr.start, 3)} -{" "}
                {Func.dateTimeDisplay(attr.stop, 3)}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p></p>
              </td>
            </tr>
            {/* <tr>
              <td>เงินเหลือ:</td>
              <td align="right">{Func.numberDisplay(attr.wallet)}</td>
            </tr> */}
            <tr>
              <td>ขายปลีก:</td>
              <td align="right">{Func.numberDisplay(attr.sum_customer)}</td>
            </tr>
            <tr>
              <td>ขายส่ง:</td>
              <td align="right">{Func.numberDisplay(attr.sum_member)}</td>
            </tr>
            <tr>
              <td colSpan={2}>{renderMember()}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>รวมเงินเชื่อ:</td>
              <td align="right">{Func.numberDisplay(attr.sum_credit)}</td>
            </tr>
            <tr>
              <td>Admin นำเงินเข้า:</td>
              <td align="right">{Func.numberDisplay(attr.cash_in)}</td>
            </tr>
            <tr>
              <td>Admin นำเงินออก:</td>
              <td align="right">{Func.numberDisplay(attr.cash_out)}</td>
            </tr>
            <tr>
              <td>รวมรายจ่าย:</td>
              <td align="right">{Func.numberDisplay(attr.payout)}</td>
            </tr>
            <tr>
              <td colSpan={2}>{renderPayout()}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <p></p>
              </td>
            </tr>
            <tr>
              <td>รวมรายรับ:</td>
              <td align="right">{Func.numberDisplay(attr.balance)}</td>
            </tr>
            <tr>
              <td>เงินเริ่มต้น:</td>
              <td align="right">{Func.numberDisplay(attr.wallet_start)}</td>
            </tr>
            <tr>
              <td>เงินคงเหลือ:</td>
              <td align="right">{Func.numberDisplay(attr.wallet)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default withNamespaces()(PosSlipRound);

PosSlipRound.defaultProps = {
  attr: {},
  list: [],
};
