/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

/* ========== ZONE Function ========== */
function FullBill({ t, attr, list }) {
  const mod = "posbill";
  const prefix = "bill";
  const classes = useStyles();

  const quantityDisplay = (num) => {
    const fl = Math.floor(num);
    if (fl == num) return Func.numberDisplay(num, 0);
    else return Func.numberDisplay(num);
  };

  const renderHeader = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td align="center">
              <h3>ใบเสร็จ</h3>
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">เลขที่ใบเสร็จ: {attr.id}</td>
          </tr>
          <tr>
            <td align="left">
              วันที่: {Func.dateTimeDisplay(attr.date_create, 3)}
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">ลูกค้า: {attr.member_name}</td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">
              ยอดค้างชำระ: {Func.numberDisplay(attr.balance)}
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderOrder = () => {
    return (
      <table width="100%">
        <thead>
          <tr>
            <th align="left" colSpan={3}>
              {t(`${prefix}.order`)}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
          </tr>
          {list.map((item, i) => (
            <tr key={item.code}>
              <td align="left" valign="top">
                {i + 1}.
              </td>
              <td align="left" valign="top">
                {item.product_name} ({quantityDisplay(item.quantity)}{" "}
                {item.unit_name} x {Func.numberDisplay(item.price)} ฿)
                {item.quantity_return > 0 ?
                  <span> / คืน {quantityDisplay(item.quantity_return)} {item.unit_name}</span> : null}
              </td>
              <td align="right" valign="top">
                {Func.numberDisplay(item.amount)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderSummary = () => {
    return (
      <table width="100%">
        <thead>
          <tr>
            <th align="right" width="70%">
              {t(`${prefix}.amount`)}
            </th>
            <th align="right">{Func.numberDisplay(attr.amount)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.vat`)}</th>
            <th align="right">{Func.numberDisplay(attr.vat)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.total`)}</th>
            <th align="right">{Func.numberDisplay(attr.total)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.shipping_price`)}</th>
            <th align="right">{Func.numberDisplay(attr.shipping_price)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.nettotal`)}</th>
            <th align="right">{Func.numberDisplay(attr.nettotal)}</th>
          </tr>
        </thead>
      </table>
    );
  };

  const renderFooter = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="2">
              <p>
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td align="right">ผู้รับ:</td>
            <td align="center">....................................</td>
          </tr>
        </tbody>
      </table>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="print">
      <div className="slips">
        {renderHeader()}
        {renderOrder()}
        {renderSummary()}
        {renderFooter()}
      </div>
    </div>
  );
}

export default withNamespaces()(FullBill);

FullBill.defaultProps = {
  attr: {},
  list: [],
};
