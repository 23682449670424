import axios from "axios";

export function GetData(mod, id, myData, setUrl = "") {
  let url = `/app/req/${mod}-${id}`;

  if (setUrl) url = setUrl;

  return new Promise((resolve, reject) => {
    axios(url, {
      headers: { "Content-Type": "application/json" },
      method: "GET",
      data: myData,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function PostData(mod, id, myData, setUrl = "") {
  let url = `/app/req/${mod}-${id}`;

  if (setUrl) url = setUrl;

  return new Promise((resolve, reject) => {
    axios(url, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      data: myData,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function PutData(mod, id, myData, setUrl = "") {
  let url = `/app/req/${mod}-${id}`;

  if (setUrl) url = setUrl;

  return new Promise((resolve, reject) => {
    axios(url, {
      headers: { "Content-Type": "application/json" },
      method: "PUT",
      data: myData,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}

export function DeleteData(mod, id, myData, setUrl = "") {
  let url = `/app/req/${mod}-${id}`;

  if (setUrl) url = setUrl;

  return new Promise((resolve, reject) => {
    axios(url, {
      headers: { "Content-Type": "application/json" },
      method: "DELETE",
      data: myData,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
}
