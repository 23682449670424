/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadImage from "./UploadImage";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import StyleIcon from "@material-ui/icons/Style";
import StoreIcon from "@material-ui/icons/Store";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "../../services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  kind: "",
  name_th: "",
  name_en: "",
  brand_code: "",
  unit_code: "",
  type_code: "",
  minstock: "",
  weight: "",
  cost: "",
  price: "",
  promotion: "",
  promotion_status: "N",
  image: ``,
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.id",
  order_sort: "desc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function RawMaterial({ t }) {
  const mod = "product";
  const classes = useStyles();

  const initHeader = [
    { code: "image", name: t(`${mod}.image`), sort: false },
    { code: "tb.id", name: t(`${mod}.id`), order: "desc" },
    { code: "tb.name_th", name: t(`${mod}.name`) },
    { code: "bd.name_th", name: t(`${mod}.brand`) },
    { code: "ut.name_th", name: t(`${mod}.unit`) },
    { code: "tb.cost", name: t(`${mod}.cost`) },
    { code: "tb.price", name: t(`${mod}.price`) },
    { code: "quantity", name: t(`${mod}.quantity`) },
    { code: "tb.enable", name: t(`attr.enable`) },
  ];
  const alignValue = [5, 6, 7, 8, 9];
  const alignItem = [
    classes.right,
    classes.right,
    classes.right,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState([]);
  const [cboBrand, setCboBrand] = useState([]);
  const [cboUnit, setCboUnit] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const clickPage = (e, p) => {
    page = p;
    view();
  };

  const changePerPage = (e) => {
    page = 1;
    perPage = e.target.value;
    view();
  };

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboBrand(result.brand);
        setCboUnit(result.unit);

        if (id === undefined) {
          view(find);
        } else if (id === "add") {
          create();
        } else {
          setTab(0);
          open();
        }
      } else {
        window.location.replace("/auth/login");
      }
    });
  };

  const create = () => {
    setAttr(initAttr);
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      kind: "RM",
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              img(item.image),
              item.id,
              item.name,
              item.brand_name,
              item.unit_name,
              item.cost,
              item.price,
              item.quantity,
              Func.checkDisplay(item.enable),
              btn(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const img = (filename) => {
    return <img src={filename} width="30" />;
  };

  const open = () => {
    setAttr(initAttr);
    setStock([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        result.stock.map((item, i) => {
          setStock((stock) => [
            ...stock,
            [
              i + 1,
              item.branch_name,
              item.warehouse_name,
              item.lot,
              item.type_name,
              Func.dateDisplay(item.date_create),
              Func.numberDisplay(item.quantity_all),
              Func.numberDisplay(item.quantity_use),
              Func.numberDisplay(item.quantity),
            ],
          ]);
        });

        setStock((stock) => [
          ...stock,
          [
            "",
            "",
            "",
            "",
            "",
            <h4>{t(`${mod}.total`)}</h4>,
            <h4>{Func.numberDisplay(result.data.quantity_all)}</h4>,
            <h4>{Func.numberDisplay(result.data.quantity_use)}</h4>,
            <h4>{Func.numberDisplay(result.data.quantity)}</h4>,
          ],
        ]);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.id, 1)) {
      result = false;
    } else if (!Func.verifyLength(attr.name_th, 1)) {
      result = false;
    } else if (!Func.verifyLength(attr.unit_code, 1)) {
      result = false;
    }

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              token: app.token,
              lang: app.lang,
              data: {
                kind: "RM",
                id: attr.id,
                name_th: attr.name_th,
                name_en: attr.name_en,
                brand_code: attr.brand_code,
                unit_code: attr.unit_code,
                weight: attr.weight,
                cost: attr.cost,
                price: attr.price,
                minstock: attr.minstock,
                enable: attr.enable,
              },
            }).then((result) => {
              setAlert(null);
              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                window.location.assign(`/admin/rawmaterial`);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              data: {
                id: attr.id,
                name_th: attr.name_th,
                name_en: attr.name_en,
                brand_code: attr.brand_code,
                unit_code: attr.unit_code,
                weight: attr.weight,
                cost: attr.cost,
                price: attr.price,
                minstock: attr.minstock,
                enable: attr.enable,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open();
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const del = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "delete", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <div>
        <NavLink to={`/admin/rawmaterial/${item.code}`}>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
          >
            <EditIcon className={classes.icon} />
          </Button>
        </NavLink>
        <Button
          round
          color="danger"
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={() => del(item)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </div>
    );
  };

  const uploadImage = (filedata) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "upload", {
            code: attr.code,
            token: app.token,
            lang: app.lang,
            filedata: filedata,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => {
          setAlert(null);
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("upload.upload")}
      </SweetAlert>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="success">
                  <StyleIcon />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  {t("element.viewlist")}
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer className="viewlist">
                  <GridItem xs={12} sm={6} md={6}>
                    <TextField
                      id="search"
                      fullWidth
                      InputProps={{
                        onChange: (e) => {
                          view({ ...find, search: e.target.value, page: 1 });
                          setFind({ ...find, search: e.target.value, page: 1 });
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            {t("element.search")} :
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12}>
                    <Table
                      striped
                      hover
                      tableHead={[
                        renderHeader(header[0]),
                        renderHeader(header[1]),
                        renderHeader(header[2]),
                        renderHeader(header[3]),
                        renderHeader(header[4]),
                        renderHeader(header[5]),
                        renderHeader(header[6]),
                        renderHeader(header[7]),
                        renderHeader(header[8]),
                        <NavLink to={`/admin/rawmaterial/add`}>
                          <Button
                            color="primary"
                            className={classes.actionButton}
                            block
                          >
                            {t(`element.add`)}
                            <AddIcon />
                          </Button>
                        </NavLink>,
                      ]}
                      tableData={record}
                      customHeadClassesForCells={alignValue}
                      customHeadCellClasses={alignItem}
                      customClassesForCells={alignValue}
                      customCellClasses={alignItem}
                      coloredColls={[5, 6, 7]}
                      colorsColls={["danger", "success", "primary"]}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel className={classes.selectLabel}></InputLabel>
                      <Select
                        value={find.perPage}
                        onChange={(e) => {
                          view({
                            ...find,
                            page: 1,
                            perPage: e.target.value,
                            page: 1,
                          });
                          setFind({
                            ...find,
                            page: 1,
                            perPage: e.target.value,
                            page: 1,
                          });
                        }}
                      >
                        {cboPerPage &&
                          cboPerPage.map((item) => (
                            <MenuItem
                              key={item.id}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.id}
                            >
                              {t(item.name)}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={6}
                    md={8}
                    lg={9}
                    className={classes.right}
                  >
                    <Pagination
                      style={{ float: "right", marginTop: "20px" }}
                      count={pageTotal}
                      showFirstButton
                      showLastButton
                      page={pageNo}
                      onChange={(e, p) => {
                        view({ ...find, page: p });
                        setFind({ ...find, page: p });
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  const renderAdd = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody className={classes.right}>
              <NavLink to={`/admin/rawmaterial`}>
                <Button color="warning">
                  <ArrowBackIcon /> {t("element.back")}
                </Button>
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.adddata")}</h4>
            </CardHeader>
            <CardBody>
              {renderInfo()}
              <GridContainer>
                <GridItem xs={12} className={classes.right}>
                  <Button color="success" onClick={add}>
                    <SaveIcon /> {t("element.save")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>
                    {t(`${mod}.id`)} : {attr.id}
                  </h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/rawmaterial`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        <Button color="success" onClick={edit}>
                          <SaveIcon /> {t("element.save")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: t("upload.avatar"),
                tabIcon: DescriptionIcon,
                tabContent: <div>{renderImage()}</div>,
              },
              {
                tabName: t(`${mod}.stock`),
                tabIcon: StoreIcon,
                tabContent: renderStock(),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderImage = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardBody>
              <div className="fileinput text-center">
                <div
                  className={"thumbnail"}
                  style={{ maxWidth: "100%", textAlign: "center" }}
                >
                  <img src={attr.image} alt="..." />
                </div>
              </div>
              <UploadImage uploadData={uploadImage} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={
                <div>
                  {t(`${mod}.id`) + " / " + t(`${mod}.barcode`)}
                  <span className={classes.colorDanger}> *</span>
                </div>
              }
              id="id"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.id,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="brand_code" className={classes.labelSelect}>
                {t(`${mod}.brand`)}
                <span className={classes.colorDanger}></span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={attr.brand_code}
                inputProps={{
                  name: "brand_code",
                }}
                onChange={selectChange}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {t("element.pleaseselect")}
                </MenuItem>
                {cboBrand &&
                  cboBrand.map((item) => (
                    <MenuItem
                      key={item.code}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="unit_code" className={classes.labelSelect}>
                {t(`${mod}.unit`)}
                <span className={classes.colorDanger}> *</span>
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={attr.unit_code}
                inputProps={{
                  name: "unit_code",
                }}
                onChange={selectChange}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  {t("element.pleaseselect")}
                </MenuItem>
                {cboUnit &&
                  cboUnit.map((item) => (
                    <MenuItem
                      key={item.code}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.code}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={
                <div>
                  {t(`${mod}.name_th`)}
                  <span className={classes.colorDanger}> *</span>
                </div>
              }
              id="name_th"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_th,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${mod}.name_en`)}
              id="name_en"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_en,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.weight`)}
              id="weight"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "0.00",
                onChange: inputChange,
                value: attr.weight,
                type: "number",
              }}
              alignCenter
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.cost`)}
              id="cost"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "0.00",
                onChange: inputChange,
                value: attr.cost,
                type: "number",
              }}
              alignCenter
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.price`)}
              id="price"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "0.00",
                onChange: inputChange,
                value: attr.price,
                type: "number",
              }}
              alignCenter
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <CustomInput
              labelText={t(`${mod}.minstock`)}
              id="minstock"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "0.00",
                onChange: inputChange,
                value: attr.minstock,
                type: "number",
              }}
              alignCenter
            />
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.enable === "Y"}
                    name="enable"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={t("attr.enable")}
              />
            </div>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else if (id === "add") {
      return renderAdd();
    } else {
      return renderEdit();
    }
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderStock = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${mod}.num`),
              t(`${mod}.branch`),
              t(`${mod}.warehouse`),
              t(`${mod}.lot`),
              t(`${mod}.typestock`),
              t(`${mod}.date_receive`),
              t(`${mod}.quantity_all`),
              t(`${mod}.quantity_use`),
              t(`${mod}.quantity_remain`),
            ]}
            tableData={stock}
            customHeadClassesForCells={[0, 1, 5, 6, 7, 8]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 5, 6, 7, 8]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[6, 7, 8]}
            colorsColls={["primary", "danger", "success"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(RawMaterial);
