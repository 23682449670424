/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Datetime from "react-datetime";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";

// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CloseIcon from "@material-ui/icons/Close";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Table from "components/Table/Table.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initFind = {
  search: "",
  date_start: Func.dateAdd(-28),
  date_stop: new Date(),
  round_start: moment(Func.dateFirstMonth()),
  round_stop: moment(new Date()),
  member: "ALL",
  member_name: "",
  branch: "ALL",
  shift_code: "ALL",
  round: "",
};

/* ========== ZONE Function ========== */
function RpProductDaily({ t }) {
  const mod = "rp_productdaily";
  const prefix = "report";
  const classes = useStyles();

  const app = useSelector((state) => state.appState);

  const txtMember = useRef(null);
  const txtRound = useRef(null);
  const [find, setFind] = useState(initFind);
  const [loading, setLoading] = useState(false);
  const [modalCustomer, setModalCustomer] = useState(false);
  const [modalRound, setModalRound] = useState(false);
  const [cboBranch, setCboBranch] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [roundList, setRoundList] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboBranch(result.branch);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const getColor = (num, opacity) => {
    let color = [
      "rgba(255, 0, 0, {opacity})",
      "rgba(0, 255, 0, {opacity})",
      "rgba(0, 255, 255, {opacity})",
      "rgba(255, 128, 64, {opacity})",
      "rgba(255, 0, 255, {opacity})",
      "rgba(0, 64, 128, {opacity})",
      "rgba(128, 0, 255, {opacity})",
    ];

    num = num < color.length ? num : 0;

    return color[num].split("{opacity}").join(opacity);
  };

  const findRound = (item) => {
    setFind({
      ...find,
      shift_code: item.code,
      round: `${item.id} - ${item.emp_name}`,
    });
    view({
      ...find,
      shift_code: item.code,
      round: `${item.id} - ${item.emp_name}`,
    });

    setModalRound(false);
  };

  const view = (data) => {
    setFind(data);
    setLoading(true);

    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      member: data.member,
      branch: data.branch,
      shift_code: data.shift_code,
    }).then((result) => {
      setLoading(false);
      if (result.status === "OK") {
        let list = [];

        setChartLabel(result.label);

        result.data.map((item, i) => {
          list.push({
            label: `${item.title.product_name} (${item.title.unit_name})`,
            data: item.list,
            fill: true,
            backgroundColor: getColor(i, "0.2"),
            borderColor: getColor(i, "1.0"),
            cubicInterpolationMode: "monotone",
            tension: 0.4,
          });
        });
        setChartData(list);
      }
    });
  };

  const searchCustomer = (e) => {
    e.preventDefault();

    setMemberList([]);

    if (txtMember.current.value == "") return;

    PostData(mod, "membersearch", {
      token: app.token,
      lang: app.lang,
      search: txtMember.current.value,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setMemberList((memberList) => [
            ...memberList,
            [displayName(item), displayTel(item), displaySelect(item)],
          ]);
        });
      }
    });
  };

  const searchRound = (e) => {
    e.preventDefault();

    setRoundList([]);

    if (txtRound.current.value == "") return;

    PostData(mod, "roundsearch", {
      token: app.token,
      lang: app.lang,
      search: txtRound.current.value,
      start: Func.dateTimeFormat(find.round_start.toDate()),
      stop: Func.dateTimeFormat(find.round_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRoundList((roundList) => [
            ...roundList,
            [
              <Button simple color="github" onClick={() => findRound(item)}>
                {item.id}
              </Button>,
              <Button simple color="github" onClick={() => findRound(item)}>
                <i className="far fa-user" /> {item.emp_name}
              </Button>,
              displayStatus(item),
              <div>
                <div>เข้า : {Func.dateTimeDisplay(item.date_start)}</div>
                <div>ออก : {Func.dateTimeDisplay(item.date_stop)}</div>
              </div>,
            ],
          ]);
        });
      }
    });
  };

  const displayName = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="far fa-user" /> {item.name}
      </Button>
    );
  };

  const displayTel = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="fas fa-phone-volume" /> {item.tel}
      </Button>
    );
  };

  const displaySelect = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="fas fa-chevron-right" />
      </Button>
    );
  };

  const findMember = (item) => {
    view({ ...find, member: item.code, member_name: item.name });

    setModalCustomer(false);
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const renderDateRound = () => {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
      "5 Days": [moment(start).subtract(5, "days"), moment(end)],
      "1 Week": [moment(start).subtract(7, "days"), moment(end)],
      "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
      "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    };
    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={find.round_start}
          end={find.round_stop}
          local={{ format: "DD/MM/YYYY HH:mm", sundayFirst: false }}
          applyCallback={(start, stop) => {
            setFind({ ...find, round_start: start, round_stop: stop });
            searchRoundDate({ ...find, round_start: start, round_stop: stop });
          }}
        >
          <TextField
            fullWidth
            value={`${find.round_start.format("DD/MM/YYYY HH:mm")} 
            ${t(`element.to`)} 
            ${find.round_stop.format("DD/MM/YYYY HH:mm")}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t("element.date")} :
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </DateTimeRangeContainer>
      </div>
    );
  };

  const searchRoundDate = (data) => {
    setRoundList([]);

    PostData(mod, "roundsearch", {
      token: app.token,
      lang: app.lang,
      search: txtRound.current.value,
      start: Func.dateTimeFormat(data.round_start.toDate()),
      stop: Func.dateTimeFormat(data.round_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRoundList((roundList) => [
            ...roundList,
            [
              <Button simple color="github" onClick={() => findRound(item)}>
                {item.id}
              </Button>,
              <Button simple color="github" onClick={() => findRound(item)}>
                <i className="far fa-user" /> {item.emp_name}
              </Button>,
              displayStatus(item),
              <div>
                <div>เข้า : {Func.dateTimeDisplay(item.date_start)}</div>
                <div>ออก : {Func.dateTimeDisplay(item.date_stop)}</div>
              </div>,
            ],
          ]);
        });
      }
    });
  };

  const renderChart = () => {
    return (
      <Card>
        <CardBody>
          <Line
            height={100}
            options={{
              responsive: true,
              interaction: {
                intersect: false,
              },
              scales: {
                x: {
                  display: true,
                  title: {
                    display: true,
                  },
                },
                y: {
                  display: true,
                  title: {
                    display: true,
                  },
                  suggestedMin: 0,
                },
              },
            }}
            data={{
              labels: chartLabel,
              datasets: chartData,
            }}
          />
        </CardBody>
      </Card>
    );
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.boxPrimary}>
            <CardHeader color="primary" icon className={classes.boxHead}>
              <h4 className={classes.boxTitle}>
                <i className="fas fa-chart-line color-primary" />
                {t("element.report")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => view({ ...find, date_start: value })}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => view({ ...find, date_stop: value })}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12}></GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    InputProps={{
                      value: find.member_name,
                      startAdornment: (
                        <InputAdornment position="start">
                          {t(`${prefix}.member`)} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      readOnly: true,
                    }}
                    onClick={() => {
                      setModalCustomer(true);

                      setTimeout(function () {
                        txtMember.current.select();
                      }, 500);
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    InputProps={{
                      value: find.round,
                      startAdornment: (
                        <InputAdornment position="start">รอบ :</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      readOnly: true,
                    }}
                    onClick={() => {
                      setModalRound(true);

                      setTimeout(function () {
                        txtRound.current.select();
                      }, 500);
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.branch}
                      onChange={(e) =>
                        view({ ...find, branch: e.target.value })
                      }
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.branch`)} :
                      </MenuItem>
                      {cboBranch.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>{renderChart()}</GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalCustomer = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalCustomer}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalCustomer(false)}
        aria-labelledby="modal-customer-title"
        aria-describedby="modal-customer-description"
      >
        <DialogTitle
          id="modal-customer-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalCustomer(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t(`${prefix}.member`)}</h4>
        </DialogTitle>
        <DialogContent id="modal-customer" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchCustomer}>
                <CustomInput
                  labelText={t("element.search")}
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtMember,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>
              <Table
                hover
                tableData={memberList}
                customHeadClassesForCells={[2]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[2]}
                customCellClasses={[classes.right]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              view({ ...find, member: "ALL", member_name: "" });
              setModalCustomer(false);
            }}
            color="info"
          >
            <PeopleOutlineIcon /> {t(`${prefix}.showall`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderModalRound = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalRound}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalRound(false)}
        aria-labelledby="modal-round-title"
        aria-describedby="modal-round-description"
      >
        <DialogTitle
          id="modal-round-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalRound(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>รอบ</h4>
        </DialogTitle>
        <DialogContent id="modal-round" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchRound}>
                <CustomInput
                  labelText="พนักงาน"
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtRound,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>{renderDateRound()}</GridItem>
            <GridItem xs={12}>
              <Table
                hover
                tableData={roundList}
                customHeadClassesForCells={[2]}
                customHeadCellClasses={[classes.center]}
                customClassesForCells={[2]}
                customCellClasses={[classes.center]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              view({ ...find, shift_code: "ALL", round: "" });
              setModalRound(false);
            }}
            color="info"
          >
            <PeopleOutlineIcon /> {t(`report.showall`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderModalCustomer()}
      {renderModalRound()}
      {renderViewList()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(RpProductDaily);
