/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import AlertIcon from "@material-ui/icons/AddAlert";
import PrintIcon from "@material-ui/icons/Print";
import DateRangeIcon from "@material-ui/icons/DateRange";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";

import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";
import TransferPrint from "./TransferPrint";
import TransferPrintInvoice from "./TransferPrintInvoice";
import TransferPrintSum from "./TransferPrintSum";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name: "",
  tel: "",
  image: "",
  balance: "",
  order_balance: "",
  amount: "",
  money: "",
  money_change: "",
  payment_name: "",
  account_bank: "",
  account_name: "",
  account_accno: "",
  account_code: "",
  wallet_pay: "",
  wallet_balance: "",
  transfer_bank: "",
  transfer_date: new Date(),
  transfer_time: "",
  transfer_remark: "",
};

const initFind = {
  search: "",
  date_start: Func.dateAdd(-30),
  date_stop: Func.dateAdd(7),
  order_by: "tb.date_record",
  order_sort: "desc",
  status: "ALL",
  shipping: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function TransferDay({ t, changeDate, reloadCustomer }) {
  const mod = "transferday";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.date_record", name: t(`${prefix}.date_record`), order: "desc" },
    { code: "", name: t(`${prefix}.count_bill`), sort: false },
    { code: "", name: "ยอดรวม", sort: false },
    { code: "", name: "ค้างชำระ", sort: false },
    { code: "", name: "รอส่ง", sort: false },
    { code: "", name: "ส่งแล้ว", sort: false },
  ];
  const alignValue = [0, 1, 2, 3, 4, 5, 6, 7];
  const alignItem = [
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.center,
  ];

  const app = useSelector((state) => state.appState);
  const slipRef = useRef(null);
  const slipSum = useRef(null);
  const txtAmount = useRef(null);
  const { id } = useParams();
  const [cboStatus, setCboStatus] = useState([]);
  const [cboShipping, setCboShipping] = useState([]);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [modalPayment, setModalPayment] = useState(false);
  const [modalPrint, setModalPrint] = useState(false);
  const [order, setOrder] = useState([]);
  const [slip, setSlip] = useState({});
  const [slipList, setSlipList] = useState([]);
  const [sumList, setSumList] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id, init]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboShipping(result.shipping);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    setRecord([]);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      shipping: data.shipping,
      car: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);
      }
      setLoading(false);
    });
  };

  const handleCheck = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  const handlePrintSum = useReactToPrint({
    content: () => slipSum.current,
  });

  const numberDisplay = (inp, p) => {
    if (inp > 0) return Func.numberDisplay(inp, p);
    else return "-";
  };

  const print = (chk) => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "print", {
      token: app.token,
      lang: app.lang,
      car_code: id,
      checked: chk,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);
        handlePrint();
      }
    });
  };

  const printSum = (item, show) => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "printsum", {
      token: app.token,
      lang: app.lang,
      car_code: id,
      code: item.code,
      show: show,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSumList(result.list);
        handlePrintSum();
      }
    });
  };

  const open = (item) => {
    changeDate(item.date_record);
  };

  const addPay = () => {
    if (confirm("คุณต้องการบันทึกข้อมูล ใช่หรือไม่?")) {
      setLoading(true);
      setModalPayment(false);

      PostData(mod, "addpay", {
        car_code: id,
        checked: checked,
        amount: attr.amount,
      }).then((result) => {
        setLoading(false);

        if (result.status === "OK") {
          view(find);
          setChecked([]);

          showNotify(t("alert.editcomplete"), "success");
        } else {
          showNotify(t("alert.editerror"), "danger");
        }
      });
    }
  };

  const openPay = (chk) => {
    setLoading(true);
    setAttr(initAttr);
    setOrder([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
      checked: chk,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setChecked(chk);

        result.list.map((item, i) => {
          setOrder((order) => [...order, [item.id, item.balance]]);
        });

        setLoading(false);
        setModalPayment(true);

        setTimeout(function () {
          txtAmount.current.select();
        }, 500);
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {Func.dateDisplay(item.code)}
      </Button>
    );
  };

  const btn = (item) => {
    return (
      <>
        <Button
          color="success"
          className={classes.actionButton}
          onClick={() => openPay([item.code])}
          size="sm"
        >
          ชำระ <MonetizationOnIcon />
        </Button>
        <Button
          color="primary"
          className={classes.actionButton}
          onClick={() => printSum(item, "ALL")}
          size="sm"
        >
          พิมพ์ทั้งหมด <PrintIcon />
        </Button>
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => printSum(item, "")}
          size="sm"
        >
          พิมพ์รอส่ง <PrintIcon />
        </Button>
      </>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => {
                        view({ ...find, date_start: value, page: 1 });
                        setFind({ ...find, date_start: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => {
                        view({ ...find, date_stop: value, page: 1 });
                        setFind({ ...find, date_stop: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.shipping}
                      onChange={(e) => {
                        view({ ...find, shipping: e.target.value, page: 1 });
                        setFind({ ...find, shipping: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.shipping`)}
                      </MenuItem>
                      {cboShipping.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderCheckAll(),
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  renderAction(),
                ]}
                tableData={record.map((item) => {
                  return [
                    renderCheck(item),
                    btnOpen(item),
                    numberDisplay(item.count_bill, 0),
                    numberDisplay(item.sum_total),
                    numberDisplay(item.sum_balance),
                    numberDisplay(item.count_waitshipping, 0),
                    numberDisplay(item.count_shipping, 0),
                    btn(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[3, 4]}
                colorsColls={["primary", "danger"]}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderCheckAll = () => {
    return (
      <Checkbox
        key="ALL"
        className={classes.positionAbsolute}
        tabIndex={-1}
        onClick={(e) => {
          setChecked([]);
          if (e.target.checked)
            record.map((item) => {
              setChecked((checked) => [...checked, item.code]);
            });
        }}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot,
        }}
      />
    );
  };

  const renderCheck = (item) => {
    if (item.count_transfer > 0)
      return (
        <Checkbox
          key={item.code}
          className={classes.positionAbsolute}
          tabIndex={-1}
          checked={checked.indexOf(item.code) !== -1}
          onClick={() => handleCheck(item.code)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
        />
      );
    else return "";
  };

  const renderAction = () => {
    return (
      <CustomDropdown
        dropup
        buttonText={t(`${prefix}.action`)}
        buttonProps={{
          color: "primary",
          size: "sm",
        }}
        dropdownList={[
          {
            id: "payment",
            title: (
              <div>
                <i className="fas fa-file-invoice-dollar" />{" "}
                {t(`${prefix}.payment`)}
              </div>
            ),
          },
          {
            id: "print",
            title: (
              <div>
                <i className="fas fa-print" /> {t(`${prefix}.printinvoice`)}
              </div>
            ),
          },
          {
            id: "printlog",
            title: (
              <div>
                <i className="fas fa-file" /> ใบแจ้งหนี้
              </div>
            ),
          },
        ]}
        onClick={(item) => {
          switch (item.id) {
            case "payment":
              openPay(checked);
              break;
            case "print":
              print(checked);
              break;
            case "printlog":
              setModalPrint(true);
              break;
          }
        }}
      />
    );
  };

  const renderModalPay = () => {
    const cssValue = {
      padding: "10px 20px",
      fontSize: "2em",
      background: "#f2f2f2",
      textAlign: "right",
      color: "#666",
      marginBottom: "10px",
    };

    return (
      <Dialog
        size={"sm"}
        open={modalPayment}
        onClose={() => setModalPayment(false)}
      >
        <DialogTitle
          id="modal-pay-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalPayment(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>การชำระเงิน</h4>
        </DialogTitle>
        <DialogContent id="modal-pay-description">
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12}>
                <br />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Table
                  tableData={order}
                  customHeadClassesForCells={[1]}
                  customHeadCellClasses={[classes.right]}
                  customClassesForCells={[1]}
                  customCellClasses={[classes.right]}
                  coloredColls={[1]}
                  colorsColls={["primary"]}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <CustomInput
                  labelText={t(`${prefix}.balance`)}
                  error
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: Func.numberDisplay(attr.order_balance),
                    disabled: true,
                  }}
                  alignRight
                />
                <CustomInput
                  success
                  labelText={t(`${prefix}.payamount`)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtAmount,
                    autoComplete: "off",
                    placeholder: "0.00",
                    style: cssValue,
                    type: "number",
                    value: attr.amount === "0" ? "" : attr.amount,
                    onChange: (e) =>
                      setAttr({ ...attr, amount: e.target.value }),
                  }}
                  style={{ paddingTop: 0 }}
                  alignRight
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={addPay} color="success">
            <SaveIcon /> {t(`element.save`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderModalPrint = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalPrint}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalPrint(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-print-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalPrint(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>ใบแจ้งหนี้</h4>
        </DialogTitle>
        <DialogContent
          id="modal-print"
          className={classes.modalBody}
          style={{
            height: "700px",
            overflowY: "scroll",
            width: "100%",
          }}
        >
          <TransferPrint />
        </DialogContent>
      </Dialog>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <TransferPrintInvoice ref={slipRef} attr={slip} list={slipList} />
        <TransferPrintSum ref={slipSum} attr={slip} list={sumList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalPrint()}
      {renderLoading()}
      {renderPrint()}
      {renderModalPay()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(TransferDay);
