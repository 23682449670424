/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertIcon from "@material-ui/icons/AddAlert";
import DescriptionIcon from "@material-ui/icons/Description";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "../../services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  name: "",
  surname: "",
  tel: "",
  idcard: "",
  email: "",
  user_name: "",
  user_pass: "",
  user_pass_again: "",
  task_code: "",
  image: "",
  enable: "Y",
};

/* ========== ZONE Function ========== */
function EditProfile({ t }) {
  const mod = "employee";
  const classes = useStyles();
  const app = useSelector((state) => state.appState);

  const { id } = useParams();
  const [attr, setAttr] = useState(initAttr);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    profile();
  }, [id]);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const profile = () => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "profile", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.id, 1)) {
      result = false;
    } else if (!Func.verifyLength(attr.name, 1)) {
      result = false;
    }

    return result;
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              token: app.token,
              lang: app.lang,
              code: attr.code,
              data: {
                id: attr.id,
                name: attr.name,
                surname: attr.surname,
                task_code: attr.task_code,
                idcard: attr.idcard,
                email: attr.email,
                tel: attr.tel,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <div>
        <NavLink to={`/admin/${mod}/${item.code}`}>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
          >
            <EditIcon className={classes.icon} />
          </Button>
        </NavLink>
        <Button
          round
          color="danger"
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={() => del(item)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </div>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        <Button color="success" onClick={edit}>
                          <SaveIcon /> {t("element.save")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              error={!Func.verifyLength(attr.id, 1)}
              labelText={t(`${mod}.id`) + " *"}
              id="id"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.id,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${mod}.idcard`)}
              id="idcard"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.idcard,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              error={!Func.verifyLength(attr.name, 1)}
              labelText={t(`${mod}.name`) + " *"}
              id="name"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${mod}.surname`)}
              id="surname"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.surname,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${mod}.tel`)}
              id="tel"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.tel,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${mod}.email`)}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.email,
              }}
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderEdit()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(EditProfile);
