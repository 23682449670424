/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import DateRangeIcon from "@material-ui/icons/DateRange";
import CancelIcon from "@material-ui/icons/Cancel";
import AlertIcon from "@material-ui/icons/AddAlert";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import PrintIcon from "@material-ui/icons/Print";

// core components
import Snackbar from "components/Snackbar/Snackbar.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import i18n from "components/Translation/Translate.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import PrintReceiptPay from "views/Print/PrintReceiptPay";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initFind = {
  show: "TIME",
  search: "",
  date_start: Func.dateAdd(-30),
  date_stop: new Date(),
  order_by: "tb.code",
  order_sort: "desc",
  status: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

const initAttr = {
  code: "",
  id: "",
};

/* ========== ZONE Function ========== */
function CustomerPay({ t }) {
  const mod = "customerpay";
  const prefix = "bill";
  const classes = useStyles();
  const slipRef = useRef(null);

  const initHeader = [
    { code: "tb.code", name: t(`attr.code`), order: "desc" },
    { code: "tb.amount", name: t(`${prefix}.amount`) },
    { code: "tb.payment_type", name: t(`${prefix}.payment_type`) },
    { code: "tb.account_code", name: t(`${prefix}.account`) },
    { code: "tb.date_create", name: t(`${prefix}.date_record`) },
    { code: "tb.status", name: t(`${prefix}.status`) },
  ];
  const alignValue = [0, 1, 2, 3, 4, 5, 6];
  const alignItem = [
    classes.center,
    classes.right,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [cboStatus, setCboStatus] = useState([]);
  const [record, setRecord] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [list, setList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [modalList, setModalList] = useState(false);
  const [slip, setSlip] = useState({});
  const [slipList, setSlipList] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      show: data.show,
      member: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const print = (item, forcustomer) => {
    setLoading(true);

    PostData("rp_invoicepay", "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
      forcustomer: forcustomer
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);

        handlePrint();
      }
    });
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        {item.enable === "N" ? (
          <code className={`color-danger`}>
            <i className="fas fa-times" /> ยกเลิกแล้ว
          </code>
        ) : item.cancel === "Y" ? (
          <Button
            color="danger"
            className={classes.actionButton}
            size="sm"
            onClick={() => cancelPay(item.code)}
          >
            ยกเลิก <CancelIcon />
          </Button>
        ) : null}
      </>
    );
  };

  const btnPrint = (item) => {
    if (item.enable === "N") return null;
    if (item.gen_receipt === "N") return null;
    return (
      <>
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print(item, "Y")}
          size="sm"
        >
          สำหรับลูกค้า <PrintIcon />
        </Button>
        <Button
          color="primary"
          className={classes.actionButton}
          onClick={() => print(item, "N")}
          size="sm"
        >
          สำหรับบริษัท <PrintIcon />
        </Button>
      </>
    );
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              item.so_id,
              Func.dateTimeDisplay(item.date_create),
              Func.numberDisplay(item.nettotal),
              Func.numberDisplay(item.pay),
              Func.numberDisplay(item.balance),
              Func.checkDisplay(item.enable),
            ],
          ]);
        });

        setLoading(false);
        setModalList(true);
      }
    });
  };

  const cancelPay = (code) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "cancelpay", {
            code: code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{code}
      </SweetAlert>
    );
  };

  const displayType = (item) => {
    if (item.payment_type == "CASH") return t(`${prefix}.payment-CASH`);
    else if (item.payment_type == "TRANSFER")
      return t(`${prefix}.payment-TRANSFER`);
    else if (item.payment_type == "WALLET") return "Wallet";
    // else return t(`${prefix}.payment-CASH`);
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {item.id}
      </Button>
    );
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.show}
                      onChange={(e) => {
                        view({ ...find, show: e.target.value, page: 1 });
                        setFind({ ...find, show: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="TIME"
                      >
                        แสดงตามช่วงเวลา
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        แสดงทั้งหมด
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                {find.show === "TIME" ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/yyyy"
                        closeOnSelect
                        value={find.date_start}
                        onChange={(value) => {
                          view({ ...find, date_start: value, page: 1 });
                          setFind({ ...find, date_start: value, page: 1 });
                        }}
                        className={classes.center}
                        renderInput={(props) => {
                          return (
                            <TextField
                              fullWidth
                              InputProps={{
                                ...props,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginBottom: "4px" }}
                                  >
                                    {t("element.start")} :
                                  </InputAdornment>
                                ),
                                endAdornment: <DateRangeIcon />,
                                autoComplete: "off",
                                readOnly: true,
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                ) : null}
                {find.show === "TIME" ? null : (
                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="search"
                      value={find.search}
                      fullWidth
                      InputProps={{
                        onChange: (e) => {
                          view({ ...find, search: e.target.value, page: 1 });
                          setFind({ ...find, search: e.target.value, page: 1 });
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            {t("element.search")} :
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </GridItem>
                )}
                {find.show === "TIME" ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/yyyy"
                        closeOnSelect
                        value={find.date_stop}
                        onChange={(value) => {
                          view({ ...find, date_stop: value, page: 1 });
                          setFind({ ...find, date_stop: value, page: 1 });
                        }}
                        className={classes.center}
                        renderInput={(props) => {
                          return (
                            <TextField
                              fullWidth
                              InputProps={{
                                ...props,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginBottom: "4px" }}
                                  >
                                    {t("element.stop")} :
                                  </InputAdornment>
                                ),
                                endAdornment: <DateRangeIcon />,
                                autoComplete: "off",
                                readOnly: true,
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                ) : null}
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  "",
                  "",
                ]}
                tableData={record.map((item) => {
                  return [
                    btnOpen(item),
                    Func.numberDisplay(item.amount),
                    displayType(item),
                    item.bank_name,
                    Func.dateTimeDisplay(item.date_create),
                    displayStatus(item),
                    btnPrint(item),
                    btn(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[1]}
                colorsColls={["primary"]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}></InputLabel>
                <Select
                  value={find.perPage}
                  onChange={(e) => {
                    view({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                    setFind({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                  }}
                >
                  {cboPerPage &&
                    cboPerPage.map((item) => (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {t(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                count={pageTotal}
                showFirstButton
                showLastButton
                page={pageNo}
                onChange={(e, p) => {
                  view({ ...find, page: p });
                  setFind({ ...find, page: p });
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderList()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              "เลขที่บิล",
              "วันที่",
              "จำนวนเงิน",
              "ชำระแล้ว",
              "ค้างชำระ",
              "สถานะ",
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 2, 3, 4, 5]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 2, 3, 4, 5]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[2, 3, 4]}
            colorsColls={["primary", "success", "danger"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <PrintReceiptPay ref={slipRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalList()}
      {renderPrint()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(CustomerPay);
