/*eslint-disable*/
import React from "react";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

/* ========== ZONE Variable ========== */

/* ========== ZONE Function ========== */
function SlipPayout({ t, attr }) {
  const renderHeader = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td align="center">
              <h3>บิลการจ่าย</h3>
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">เลขที่ใบเสร็จ: {attr.id}</td>
          </tr>
          <tr>
            <td align="left">สาขา: {attr.branch_name}</td>
          </tr>
          <tr>
            <td align="left">
              วันที่จ่าย: {Func.dateDisplay(attr.date_record, 3)}
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">ประเภท: {attr.type_name}</td>
          </tr>
          <tr>
            <td align="left">รายการ: {attr.detail}</td>
          </tr>
          <tr>
            <td align="left">ราคา: {Func.numberDisplay(attr.price, 2)} บาท</td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderFooter = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="2">
              <p>
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td align="right">พนักงาน:</td>
            <td align="center">....................................</td>
          </tr>
          <tr>
            <td></td>
            <td align="center">({attr.emp_name})</td>
          </tr>
          <tr>
            <td></td>
            <td align="center">{Func.dateTimeDisplay(attr.date_now, 3)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="print">
      <div className="slips">
        {renderHeader()}
        {renderFooter()}
      </div>
    </div>
  );
}

export default withNamespaces()(SlipPayout);
