/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Badge from "components/Badge/Badge.js";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import CancelIcon from "@material-ui/icons/Cancel";
import DateRangeIcon from "@material-ui/icons/DateRange";
import InputIcon from "@material-ui/icons/Input";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EditIcon from "@material-ui/icons/Edit";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import PosSlip from "./PosSlip";

import styles from "assets/jss/main-style.js";
import FullBill from "views/Print/FullBill";

function StepIcon(props) {
  const { active, completed } = props;
  const icons_complete = {
    1: <AddCircleIcon color="primary" />,
    2: <LocalShippingIcon color="primary" />,
    3: <MonetizationOnIcon color="primary" />,
    4: <CancelIcon color="error" />,
  };
  const icons = {
    1: <AddCircleIcon color="disabled" />,
    2: <LocalShippingIcon color="disabled" />,
    3: <MonetizationOnIcon color="disabled" />,
    4: <CancelIcon color="disabled" />,
  };
  return (
    <div>
      {completed
        ? icons_complete[String(props.icon)]
        : icons[String(props.icon)]}
    </div>
  );
}

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
};

const initField = {
  code: "",
  product_id: "",
  product_code: "",
  product_name: "",
  quantity: "",
  quantity_return: "",
};

const initFind = {
  search: "",
  date_start: Func.dateAdd(-30),
  date_stop: new Date(),
  order_by: "tb.id",
  order_sort: "desc",
  status: "ALL",
  customer: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function PosBill({ t, member }) {
  const mod = "posbill";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "", name: "พิมพ์", sort: false },
    { code: "", name: "ยกเลิก", sort: false },
    { code: "tb.id", name: t(`${prefix}.id`), order: "desc" },
    { code: "tb.member_code", name: t(`${prefix}.member`) },
    { code: "tb.date_record", name: "วันที่ส่ง" },
    { code: "tb.date_create", name: "วันที่สร้าง" },
    { code: "tb.total", name: t(`${prefix}.amount`) },
    { code: "tb.shipping", name: t(`${prefix}.shipping`) },
    { code: "tb.remark", name: t(`${prefix}.remark`) },
    { code: "tb.payment", name: t(`${prefix}.payment`) },
    { code: "tb.emp_code", name: "พนักงาน" },
    { code: "tb.status", name: t(`${prefix}.status`) },
  ];
  const alignValue = [0, 1, 2, 4, 5, 6, 9, 11];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [cboStatus, setCboStatus] = useState([]);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [slipList, setSlipList] = useState([]);
  const [slip, setSlip] = useState(initAttr);
  const [field, setField] = useState(initField);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalList, setModalList] = useState(false);
  const [modalNumpad, setModalNumpad] = useState(false);
  const [msg, setMsg] = useState("");
  const slipRef = useRef();
  const fullRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  const handleFullPrint = useReactToPrint({
    content: () => fullRef.current,
  });

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {item.id}
      </Button>
    );
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      member: data.customer === "MEMBER" ? member.code : "ALL",
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
        {item.status !== "9" ? null : <span> [{item.remark_cancel}]</span>}
      </code>
    );
  };

  const displayShipping = (item) => {
    if (item.transfer == 1)
      return (
        <code className="color-default">
          <i className="far fa-times-circle" /> ไม่จัดส่ง
        </code>
      );
    else if (item.transfer == 2)
      return (
        <code className="color-info">
          <i className="fas fa-store-alt" /> {item.branch_transfer}
        </code>
      );
    else if (item.transfer == 3)
      return (
        <code className="color-primary">
          <i className="fas fa-shipping-fast" /> จัดส่ง
        </code>
      );
  };

  const runderNumpad = () => {
    const cssValue = {
      padding: "10px 20px",
      fontSize: "2em",
      background: "#f2f2f2",
      textAlign: "right",
      color: "#666",
      marginBottom: "10px",
    };

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modalNumpad}
        keepMounted
        onClose={() => setModalNumpad(false)}
        aria-labelledby="modal-numpad-title"
        aria-describedby="modal-numpad-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalNumpad(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{field.product_name}</h4>
        </DialogTitle>
        <DialogContent id="modal-numpad-description">
          <div style={{ minWidth: "300px", paddingTop: "20px" }}>
            <div style={{ overflow: "hidden" }}>
              <div>
                <form>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: field.quantity_return,
                      autoComplete: "off",
                      placeholder: "0",
                      style: cssValue,
                      onChange: (e) =>
                        setField({ ...field, quantity_return: e.target.value }),
                    }}
                    alignRight
                  />
                </form>
              </div>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="25%">{btnNumpad(7)}</td>
                    <td width="25%">{btnNumpad(8)}</td>
                    <td width="25%">{btnNumpad(9)}</td>
                  </tr>
                  <tr>
                    <td>{btnNumpad(4)}</td>
                    <td>{btnNumpad(5)}</td>
                    <td>{btnNumpad(6)}</td>
                  </tr>
                  <tr>
                    <td>{btnNumpad(1)}</td>
                    <td>{btnNumpad(2)}</td>
                    <td>{btnNumpad(3)}</td>
                  </tr>
                  <tr>
                    <td>{btnNumpad(".")}</td>
                    <td>{btnNumpad(0)}</td>
                    <td>
                      <Button
                        simple
                        color="primary"
                        className={
                          classes.actionButton + " " + classes.actionButtonRound
                        }
                        block
                        size={"lg"}
                        style={{ height: "80px", fontSize: "20px" }}
                        onClick={() => addNumpad("-")}
                      >
                        <i className="fas fa-backspace"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <Button
                        simple
                        color="danger"
                        className={
                          classes.actionButton + " " + classes.actionButtonRound
                        }
                        block
                        size={"lg"}
                        style={{ height: "80px", fontSize: "20px" }}
                        onClick={() => editReturn()}
                      >
                        {t(`${prefix}.return`)}
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <h3>{msg}</h3>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const btnNumpad = (num) => {
    return (
      <Button
        simple
        color="primary"
        className={classes.actionButton + " " + classes.actionButtonRound}
        block
        size={"lg"}
        style={{ height: "80px", fontSize: "20px" }}
        onClick={() => addNumpad(num)}
      >
        {num}
      </Button>
    );
  };

  const addNumpad = (num) => {
    let value = field.quantity_return;

    if (num == "-") {
      value = value.substr(0, value.length - 1);
    } else {
      value += "" + num;
    }

    setField({ ...field, quantity_return: value });
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              item.price,
              item.quantity,
              item.amount,
              btnUndo(item, result.data),
              btnReturn(item, result.data),
            ],
          ]);
        });

        setList((list) => [
          ...list,
          {
            total: true,
            title: t(`${prefix}.amount`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.amount),
          },
          {
            total: true,
            title: t(`${prefix}.vat`) + " " + result.vat + " %",
            colspan: "8",
            amount: Func.numberDisplay(result.data.vat),
          },
          {
            total: true,
            title: t(`${prefix}.total`),
            colspan: "8",
            amount: displayTotal(result.data.total, classes.colorPrimary),
          },
          {
            total: true,
            title: t(`${prefix}.shipping`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.shipping_price),
          },
          {
            total: true,
            title: t(`${prefix}.nettotal`),
            colspan: "8",
            amount: displayTotal(result.data.nettotal, classes.colorPrimary),
          },
        ]);

        setModalList(true);
      }
      setLoading(false);
    });
  };

  const displayTotal = (number, cls) => {
    return <div className={cls}>{Func.numberDisplay(number)}</div>;
  };

  const btnReturn = (item, data) => {
    if (data.status === "1" && data.time_limit === "Y")
      return (
        <Button
          color="danger"
          simple
          style={{
            fontSize: "1.2em",
            textAlign: "right",
            margin: "10px 0",
            padding: 0,
          }}
          onClick={() => {
            setMsg("");
            setField({
              ...item,
              quantity_return: "",
            });
            setModalNumpad(true);
          }}
        >
          <EditIcon className={classes.colorDanger} />{" "}
          {Func.numberDisplay(item.quantity_return)}
        </Button>
      );
    else return Func.numberDisplay(item.quantity_return);
  };

  const btnUndo = (item, data) => {
    if (data.status === "1" && data.time_limit === "Y") {
      if (item.quantity_old === item.quantity) return "-";
      else
        return (
          <Button
            color="github"
            simple
            style={{
              fontSize: "1.2em",
              textAlign: "right",
              margin: "10px 0",
              padding: 0,
            }}
            onClick={() => editUndo(item)}
          >
            <i className="fas fa-undo" />
            {item.quantity_old}
          </Button>
        );
    } else {
      if (item.quantity_old === item.quantity) return "-";
      else
        return item.quantity_old;
    }
  };

  const editUndo = (item) => {
    setLoading(true);
    PostData(mod, "editundo", {
      code: item.code,
    }).then((result) => {
      setLoading(false);
      if (result.status === "OK") {
        open({ code: result.so_code });
      } else {
        setMsg(t("alert.editerror"));
      }
    });
  };

  const editReturn = () => {
    if (
      field.quantity_return * 1 <= 0 ||
      field.quantity_return * 1 > field.quantity * 1
    ) {
      setMsg("ปริมาณไม่ถูกต้อง");
    } else {
      setLoading(true);
      setModalNumpad(false);

      PostData(mod, "editreturn", {
        token: app.token,
        lang: app.lang,
        code: field.code,
        quantity_return: field.quantity_return * 1,
      }).then((result) => {
        setLoading(false);

        if (result.status === "OK") {
          open({ code: result.so_code });
        } else {
          setMsg(t("alert.editerror"));
        }
      });
    }
  };

  const print = (item) => {
    setLoading(true);
    setSlip({});
    setSlipList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);

        handlePrint();
      }
    });
  };

  const fullPrint = (item) => {
    setLoading(true);
    setSlip({});
    setSlipList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
      print: "full",
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);

        handleFullPrint();
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btnPrint = (item) => {
    return (
      <>
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print(item)}
          size="sm"
        >
          ย่อ <PrintIcon />
        </Button>
        <Button
          color="primary"
          className={classes.actionButton}
          onClick={() => fullPrint(item)}
          size="sm"
        >
          เต็ม <PrintIcon />
        </Button>
      </>
    );
  };

  const btnCancel = (item) => {
    return (
      <>
        {item.status === "1" && item.time_limit === "Y" ? (
          <Button
            color="danger"
            className={classes.actionButton}
            size="sm"
            onClick={() => editCancel(item)}
          >
            {t(`element.cancel`)} <CancelIcon />
          </Button>
        ) : null}
      </>
    );
  };

  const editCancel = (item) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        cancelBtnBsStyle="light"
        style={{ display: "block", marginTop: "-100px" }}
        title="คุณต้องการยกเลิกรายการใช่หรือไม่"
        placeHolder="หมายเหตุ"
        onConfirm={(response) => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editcancel", {
            code: item.code,
            remark: response,
          }).then((result) => {
            setLoading(false);
            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
              view(find);
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.danger}
        cancelBtnCssClass={classes.button}
        confirmBtnText="ยืนยันการยกเลิก"
        cancelBtnText="ปิด"
      >
        ยกเลิกรายการ #{item.id}
      </SweetAlert>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_start}
                      onChange={(value) => {
                        view({ ...find, date_start: value, page: 1 });
                        setFind({ ...find, date_start: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.start")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Datetime
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      closeOnSelect
                      value={find.date_stop}
                      onChange={(value) => {
                        view({ ...find, date_stop: value, page: 1 });
                        setFind({ ...find, date_stop: value, page: 1 });
                      }}
                      className={classes.center}
                      renderInput={(props) => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              ...props,
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  style={{ marginBottom: "4px" }}
                                >
                                  {t("element.stop")} :
                                </InputAdornment>
                              ),
                              endAdornment: <DateRangeIcon />,
                              autoComplete: "off",
                              readOnly: true,
                            }}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.customer}
                      onChange={(e) => {
                        view({ ...find, customer: e.target.value, page: 1 });
                        setFind({ ...find, customer: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.member`)} ทั้งหมด
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="MEMBER"
                      >
                        {member.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  renderHeader(header[6]),
                  renderHeader(header[7]),
                  renderHeader(header[8]),
                  renderHeader(header[9]),
                  renderHeader(header[10]),
                  renderHeader(header[11]),
                ]}
                tableData={record.map((item) => {
                  return [
                    btnPrint(item),
                    btnCancel(item),
                    btnOpen(item),
                    item.member_name,
                    Func.dateDisplay(item.date_record),
                    Func.dateTimeDisplay(item.date_create),
                    Func.numberDisplay(item.nettotal),
                    displayShipping(item),
                    item.remark,
                    item.payment_name,
                    item.emp_name,
                    displayStatus(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[6]}
                colorsColls={["primary"]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}></InputLabel>
                <Select
                  value={find.perPage}
                  onChange={(e) => {
                    view({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                    setFind({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                  }}
                >
                  {cboPerPage &&
                    cboPerPage.map((item) => (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {t(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                count={pageTotal}
                showFirstButton
                showLastButton
                page={pageNo}
                onChange={(e, p) => {
                  view({ ...find, page: p });
                  setFind({ ...find, page: p });
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderStatus()}
          {renderOrder()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderOrder = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${prefix}.num`),
              t(`${prefix}.image`),
              t(`${prefix}.product_id`),
              t(`${prefix}.product_name`),
              t(`${prefix}.unit`),
              t(`${prefix}.price`),
              t(`${prefix}.quantity`),
              t(`${prefix}.amount`),
              "ปริมาณเดิม",
              t(`${prefix}.return`),
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[5, 6, 7]}
            colorsColls={["primary", "primary", "success"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status * 1}
        alternativeLabel
        className={classes.bgTransparent}
        style={{ paddingBottom: 0 }}
      >
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_1`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_2`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_2}</p>
            <p className={classes.colorDefault}>{attr.approve_date_2}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_3`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_3}</p>
            <p className={classes.colorDefault}>{attr.approve_date_3}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              {attr.status == 9 ? (
                <Badge color="danger">{t(`${prefix}.step_9`)}</Badge>
              ) : (
                <b>{t(`${prefix}.step_9`)}</b>
              )}
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_9}</p>
            <p className={classes.colorDefault}>{attr.approve_date_9}</p>
          </StepLabel>
        </Step>
      </Stepper>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <PosSlip ref={slipRef} attr={slip} list={slipList} />
        <FullBill ref={fullRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalList()}
      {runderNumpad()}
      {renderPrint()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(PosBill);
