/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  price: "",
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "desc",
  status: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function MemberTransfer({ t }) {
  const mod = "membertransfer";
  const prefix = "attr";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.code", name: t(`${prefix}.code`), order: "desc" },
    { code: "tb.name", name: `คนขับรถ` },
    { code: "tb.price", name: `ค่าส่ง/Kg` },
    { code: "tb.enable", name: t(`${prefix}.enable`) },
  ];
  const alignValue = [0, 2, 3, 4];
  const alignItem = [
    classes.center,
    classes.right,
    classes.center,
    classes.center,
  ];

  const app = useSelector((state) => state.appState);
  const txtPrice = useRef(null);
  const { id } = useParams();
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const selectChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.value });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      status: data.status,
      ref: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.code,
              item.name,
              item.price,
              Func.checkDisplay(item.enable),
              btn(item),
            ],
          ]);
        });
      }
      setLoading(false);
    });
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
      ref: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);
        setModal(true);

        setLoading(false);

        setTimeout(function () {
          txtPrice.current.select();
        }, 500);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    return result;
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setModal(false);
            setLoading(true);

            PostData(mod, "edit", {
              code: attr.code,
              data: {
                member_code: id,
                car_code: attr.code,
                price: attr.price,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                view(find);
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <Button
        round
        color="success"
        className={classes.actionButton + " " + classes.actionButtonRound}
        onClick={() => {
          open(item);
        }}
      >
        <EditIcon className={classes.icon} />
      </Button>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <div>
        <GridContainer className="viewlist">
          <br />
        </GridContainer>

        <GridContainer>
          <GridItem xs={12}>
            <Table
              striped
              hover
              tableHead={[
                renderHeader(header[0]),
                renderHeader(header[1]),
                renderHeader(header[2]),
                renderHeader(header[3]),
                "",
              ]}
              tableData={record}
              customHeadClassesForCells={alignValue}
              customHeadCellClasses={alignItem}
              customClassesForCells={alignValue}
              customCellClasses={alignItem}
              coloredColls={[]}
              colorsColls={[]}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12}>
            <CustomInput
              labelText="ค่าส่ง / Kg"
              id="price"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                inputRef: txtPrice,
                onChange: inputChange,
                value: attr.price,
              }}
              alignCenter
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModal = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modal}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>แก้ไข</h4>
        </DialogTitle>
        <DialogContent id="modal-edit" className={classes.modalBody}>
          {renderInfo()}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={edit} color="success">
            <SaveIcon /> {t(`element.save`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModal()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(MemberTransfer);
