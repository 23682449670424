/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

/* ========== ZONE Function ========== */
function PosSlipDay({ t, attr, list }) {
  const mod = "posbill";
  const prefix = "bill";
  const classes = useStyles();

  const quantityDisplay = (num) => {
    const fl = Math.floor(num);
    if (fl == num) return Func.numberDisplay(num, 0);
    else return Func.numberDisplay(num);
  };

  const renderHeader = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td align="center">
              <h3>ใบเสร็จรวม</h3>
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">เลขที่ใบเสร็จ: {attr.id}</td>
          </tr>
          <tr>
            <td align="left">วันที่: {Func.dateDisplay(attr.date_bill, 3)}</td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">ลูกค้า: {attr.member_name}</td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">
              ยอดค้างชำระ: {Func.numberDisplay(attr.balance)} ฿
            </td>
          </tr>
          <tr>
            <td align="left">
              ยอดค้างชำระทั้งหมด: {Func.numberDisplay(attr.balance_all)} ฿
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderOrder = () => {
    return (
      <table width="100%" cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            <th align="left">{t(`${prefix}.order`)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td>
              {list.map((item, i) => (
                <table
                  width="100%"
                  cellPadding={3}
                  cellSpacing={0}
                  key={item.code}
                >
                  <thead>
                    <tr>
                      <th align="left" valign="top" colSpan={2}>
                        {Func.dateDisplay(item.name, 3)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.data.map((value, j) => (
                      <tr key={value.code}>
                        <td align="left" valign="top">
                          {" "}
                          {j + 1}. {value.product_name} (
                          {quantityDisplay(value.quantity)} {value.unit_name} x{" "}
                          {Func.numberDisplay(value.price)} ฿)
                          {value.quantity_return > 0 ?
                            <span> / คืน {quantityDisplay(value.quantity_return)} {value.unit_name}</span> : null}
                        </td>
                        <td align="right" valign="top">
                          {Func.numberDisplay(value.amount)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th align="right" valign="top">
                        รวม/วัน
                      </th>
                      <th align="right" valign="top">
                        {Func.numberDisplay(item.total)}
                      </th>
                    </tr>
                  </tfoot>
                </table>
              ))}
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderSummary = () => {
    return (
      <table width="100%">
        <thead>
          <tr>
            <th align="right" width="70%">
              {t(`${prefix}.amount`)}
            </th>
            <th align="right">{Func.numberDisplay(attr.amount)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.vat`)}</th>
            <th align="right">{Func.numberDisplay(attr.vat)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.total`)}</th>
            <th align="right">{Func.numberDisplay(attr.total)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.shipping_price`)}</th>
            <th align="right">{Func.numberDisplay(attr.shipping_price)}</th>
          </tr>
          <tr>
            <th align="right">{t(`${prefix}.nettotal`)}</th>
            <th align="right">{Func.numberDisplay(attr.nettotal)}</th>
          </tr>
        </thead>
      </table>
    );
  };

  const renderFooter = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td colSpan="2">
              <p>
                <br />
              </p>
            </td>
          </tr>
          <tr>
            <td align="right">พนักงานขาย:</td>
            <td align="center">....................................</td>
          </tr>
          <tr>
            <td></td>
            <td align="center">({attr.emp_name})</td>
          </tr>
          <tr>
            <td></td>
            <td align="center">{Func.dateTimeDisplay(attr.date_create, 3)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="print">
      <div className="slips">
        {renderHeader()}
        {renderOrder()}
        {renderSummary()}
        {renderFooter()}
      </div>
    </div>
  );
}

export default withNamespaces()(PosSlipDay);

PosSlipDay.defaultProps = {
  attr: {},
  list: [],
};
