import React, { useEffect } from "react";
import { useSelector } from "react-redux";

// core components
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";

function DashboardDefault({ t }) {
  const mod = "dashboard";
  const app = useSelector((state) => state.appState);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
  }, []);

  return <div></div>;
}

export default withNamespaces()(DashboardDefault);
