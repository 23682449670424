/*eslint-disable*/
import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChartistGraph from "react-chartist";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardText from "components/Card/CardText.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

var Chartist = require("chartist");
var delays = 80,
  durations = 500;
var delays2 = 80,
  durations2 = 500;

const initChart = {
  labels: [],
  series: [],
};

/* ========== ZONE Function ========== */
function CustomerAnalysis({ t }) {
  const mod = "customeranalysis";
  const prefix = "bill";
  const classes = useStyles();

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [barChart, setBarChart] = useState(initChart);
  const [lineChart, setLineChart] = useState(initChart);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    init();
  }, [id]);

  const init = () => {
    setLoading(true);
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
      member: id,
    }).then((result) => {
      setLoading(false);
      if (result.status === "OK") {
        setLineChart({
          labels: result.linechart.label,
          series: [
            result.linechart.line_deposit,
            result.linechart.line_withdraw,
            result.linechart.line_balance,
          ],
        });
        setBarChart({
          labels: result.barchart.label,
          series: [result.barchart.bar],
        });
      } else {
        Func.exitApp();
      }
    });
  };

  const setupBarChart = {
    options: {
      seriesBarDistance: 10,
      axisX: {
        showGrid: false,
      },
      height: "300px",
    },
    responsiveOptions: [
      [
        "screen and (max-width: 640px)",
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc: function (value) {
              return value[0];
            },
          },
        },
      ],
    ],
    animation: {
      draw: function (data) {
        if (data.type === "bar") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
      },
    },
  };

  const setupLineChart = {
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 10,
      }),
      axisY: {
        showGrid: true,
        offset: 40,
      },
      axisX: {
        showGrid: false,
      },
      showPoint: true,
      height: "300px",
    },
    animation: {
      draw: function (data) {
        if (data.type === "line" || data.type === "area") {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path
                .clone()
                .scale(1, 0)
                .translate(0, data.chartRect.height())
                .stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint,
            },
          });
        } else if (data.type === "point") {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: "ease",
            },
          });
        }
      },
    },
  };

  const renderLine = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                รายได้ <small>- 28 วันหลังสุด</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={lineChart}
                type="Line"
                options={setupLineChart.options}
                listener={setupLineChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderBar = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                รายได้ <small>- 28 วันหลังสุด</small>
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={barChart}
                type="Bar"
                options={setupBarChart.options}
                listener={setupBarChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderLine()}
      {renderBar()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(CustomerAnalysis);
