/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import Check from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import AlertIcon from "@material-ui/icons/AddAlert";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";
import CancelIcon from "@material-ui/icons/Cancel";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MoneyIcon from "@material-ui/icons/Money";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";

// core components
import Badge from "components/Badge/Badge.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import Datetime from "react-datetime";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

import PosSlip from "views/Pos/PosSlip";
import FullBill from "views/Print/FullBill";
import PosSlipDay from "views/Pos/PosSlipDay";
import CustomerPrint from "./CustomerPrint";

function StepIcon(props) {
  const { active, completed } = props;
  const icons_complete = {
    1: <AddCircleIcon color="primary" />,
    2: <LocalShippingIcon color="primary" />,
    3: <MonetizationOnIcon color="primary" />,
    4: <CancelIcon color="error" />,
  };
  const icons = {
    1: <AddCircleIcon color="disabled" />,
    2: <LocalShippingIcon color="disabled" />,
    3: <MonetizationOnIcon color="disabled" />,
    4: <CancelIcon color="disabled" />,
  };
  return (
    <div>
      {completed
        ? icons_complete[String(props.icon)]
        : icons[String(props.icon)]}
    </div>
  );
}

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  branch_code: "",
  member_code: "",
  payment_type: "",
  date_record: "",
  date_shipping: "",
  time_shipping: "",
  name: "",
  tel: "",
  date_due: "",
  payment: "",
  remark: "",
  amount: "",
  discount: "",
  transfer: "",
  car_code: "",
  shipping: "",
  shipping_price: "",
  vat: "",
  total: "",
  money: "",
  pay: "",
  balance: "",
  money_change: "",
  address: "",
  emp_code: "",
  branch_code: "",
  branch_name: "",
  branch_warehouse: "",
  wallet_pay: "",
  wallet_balance: "",
};

const initFind = {
  show: "TIME",
  search: "",
  date_start: new Date(),
  date_stop: new Date(),
  order_by: "tb.id",
  order_sort: "desc",
  status: "ALL",
  status_invoice: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

const initField = {
  code: "",
  product_id: "",
  product_code: "",
  product_name: "",
  quantity: "",
  quantity_return: "",
};

const initSummary = {
  amount: 0.0,
  vat: 0.0,
  discount: 0.0,
  shipping: 0.0,
  quantity: 0.0,
  total: 0.0,
};

/* ========== ZONE Function ========== */
function CustomerBill({ t, dateRecord, openInvoice }) {
  const mod = "customerbill";
  const prefix = "bill";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.id", name: t(`${prefix}.id`), order: "desc" },
    { code: "tb.branch_code", name: t(`${prefix}.branch`) },
    { code: "tb.date_create", name: "วันที่สร้าง" },
    { code: "tb.date_shipping", name: "วันที่รับของ" },
    { code: "tb.total", name: t(`${prefix}.amount`) },
    { code: "tb.shipping_price", name: t(`${prefix}.shipping_price`) },
    { code: "tb.nettotal", name: t(`${prefix}.nettotal`) },
    { code: "tb.balance", name: t(`${prefix}.balance`) },
    { code: "tb.shipping", name: t(`${prefix}.shipping`) },
    { code: "tb.remark", name: t(`${prefix}.remark`) },
    { code: "tb.payment", name: t(`${prefix}.payment`) },
    { code: "tb.status", name: t(`${prefix}.status`) },
    { code: "", name: "ยกเลิก", sort: false },
  ];
  const alignValue = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.right,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const slipDayRef = useRef(null);
  const slipRef = useRef(null);
  const fullRef = useRef();
  const txtMoney = useRef(null);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [cboStatus, setCboStatus] = useState([]);
  const [cboTransfer, setCboTransfer] = useState([]);
  const [cboBranch, setCboBranch] = useState([]);
  const [cboBank, setCboBank] = useState([]);
  const [cboCar, setCboCar] = useState([]);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [field, setField] = useState(initField);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState([]);
  const [modalList, setModalList] = useState(false);
  const [modalPayment, setModalPayment] = useState(false);
  const [tab, setTab] = useState(0);
  const [slip, setSlip] = useState({});
  const [slipList, setSlipList] = useState([]);
  const [printList, setPrintList] = useState([]);
  const [tabPayment, setTabPayment] = useState(0);
  const [modalPrint, setModalPrint] = useState(false);
  const [summary, setSummary] = useState(initSummary);
  const [modalNumpad, setModalNumpad] = useState(false);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    setFind({
      ...find,
      date_start: new Date(dateRecord),
      date_stop: new Date(dateRecord),
    });
    init();
  }, [id]);

  const changeTab = (event, value) => {
    setTab(value);
  };

  const handlePrint = useReactToPrint({
    content: () => slipRef.current,
  });

  const handleFullPrint = useReactToPrint({
    content: () => fullRef.current,
  });

  const handlePrintDay = useReactToPrint({
    content: () => slipDayRef.current,
  });

  const handleCheck = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const init = () => {
    setSlip({});
    setSlipList([]);

    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboTransfer(result.transfer);
        setCboBranch(result.branch);
        setCboBank(result.bank);
        setCboCar(result.car);

        view({
          ...find,
          date_start: new Date(dateRecord),
          date_stop: new Date(dateRecord),
        });
      } else {
        Func.exitApp();
      }
    });
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateFormat(data.date_start),
      stop: Func.dateFormat(data.date_stop),
      status: data.status,
      show: data.show,
      member: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord(result.data);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const displayStatus = (item) => {
    if (item.status === "9")
      return (
        <code className={`color-${item.color}`}>
          <i className={item.icon} /> {item.remark_cancel}
        </code>
      );
    else
      return (
        <code className={`color-${item.color}`}>
          <i className={item.icon} /> {item.status_display}
        </code>
      );
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);
    setTab(0);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({
          ...result.data,
          date_record: new Date(result.data.date_record),
          date_due: new Date(result.data.date_due),
          date_shipping: new Date(result.data.date_shipping),
        });

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              item.price,
              item.quantity,
              item.amount,
              btnUndo(item),
              btnReturn(item, result.data),
            ],
          ]);
        });

        setList((list) => [
          ...list,
          {
            total: true,
            title: t(`${prefix}.amount`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.amount),
          },
          {
            total: true,
            title: t(`${prefix}.vat`) + " " + result.vat + " %",
            colspan: "8",
            amount: Func.numberDisplay(result.data.vat),
          },
          {
            total: true,
            title: t(`${prefix}.total`),
            colspan: "8",
            amount: displayTotal(result.data.total, classes.colorPrimary),
          },
          {
            total: true,
            title: "ปริมาณ (Kg)",
            colspan: "8",
            amount: Func.numberDisplay(result.data.quantity),
          },
          {
            total: true,
            title: "ค่าขนส่ง / Kg",
            colspan: "8",
            amount: Func.numberDisplay(result.data.car_price),
          },
          {
            total: true,
            title: "ค่าขนส่ง",
            colspan: "8",
            amount: Func.numberDisplay(result.data.car_total),
          },
          {
            total: true,
            title: t(`${prefix}.shipping`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.shipping_price),
          },
          {
            total: true,
            title: t(`${prefix}.nettotal`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.nettotal),
          },
          {
            total: true,
            title: t(`${prefix}.pay`),
            colspan: "8",
            amount: displayTotal(result.data.pay, classes.colorSuccess),
          },
          {
            total: true,
            title: t(`${prefix}.balance`),
            colspan: "8",
            amount: displayTotal(result.data.balance, classes.colorDanger),
          },
        ]);

        setModalList(true);
      }
      setLoading(false);
    });
  };

  const displayTotal = (number, cls) => {
    return <div className={cls}>{Func.numberDisplay(number)}</div>;
  };

  const print = (item) => {
    setLoading(true);
    setSlip({});
    setPrintList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setPrintList(result.list);
        handlePrint();
      }
    });
  };

  const fullPrint = (item) => {
    setLoading(true);
    setSlip({});
    setPrintList([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
      print: "full",
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setPrintList(result.list);

        handleFullPrint();
      }
    });
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {item.id}
      </Button>
    );
  };

  const btn = (item) => {
    return (
      <>
        <Button
          color="success"
          className={classes.actionButton}
          onClick={() => openPay([item.code])}
          size="sm"
        >
          ชำระ <MonetizationOnIcon />
        </Button>
        <Button
          color="info"
          className={classes.actionButton}
          onClick={() => print(item)}
          size="sm"
        >
          ย่อ <PrintIcon />
        </Button>
        <Button
          color="primary"
          className={classes.actionButton}
          onClick={() => fullPrint(item)}
          size="sm"
        >
          เต็ม <PrintIcon />
        </Button>
      </>
    );
  };

  const btnCancel = (item) => {
    return (
      <>
        {item.status != 1 ? null : (
          <Button
            color="danger"
            className={classes.actionButton}
            onClick={() => editCancel(item)}
            size="sm"
          >
            {t(`element.cancel`)} <CloseIcon />
          </Button>
        )}
      </>
    );
  };

  const btnUndo = (item) => {
    if (item.quantity_old === item.quantity) return "-";
    else
      return (
        <Button
          color="github"
          simple
          style={{
            fontSize: "1.2em",
            textAlign: "right",
            margin: "10px 0",
            padding: 0,
          }}
          onClick={() => editUndo(item)}
        >
          <i className="fas fa-undo" />
          {item.quantity_old}
        </Button>
      );
  };

  const editUndo = (item) => {
    setLoading(true);
    PostData(mod, "editundo", {
      code: item.code,
    }).then((result) => {
      setLoading(false);
      if (result.status === "OK") {
        open({ code: result.so_code });
        view(find);
      } else {
        setMsg(t("alert.editerror"));
      }
    });
  };

  const transferPay = (account) => {
    if (attr.order_balance <= 0) {
      showNotify(t(`${prefix}.nobalancepay`), "danger");
    } else if (attr.amount <= 0) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);
            setModalPayment(false);

            PostData(mod, "waitingpay", {
              member_code: id,
              checked: checked,
              amount: attr.amount,
              data: {
                payment_type: "WAITING",
                amount: attr.amount,
                account_code: account,
                money: 0,
                money_change: 0,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                view(find);
                openInvoice();
                setChecked([]);

                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")}
        </SweetAlert>
      );
    }
  };

  const waitingPay = () => {
    if (attr.order_balance <= 0) {
      showNotify(t(`${prefix}.nobalancepay`), "danger");
    } else if (attr.amount <= 0) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);
            setModalPayment(false);

            PostData(mod, "waitingpay", {
              member_code: id,
              checked: checked,
              amount: attr.amount,
              data: {
                payment_type: "WAITING",
                amount: attr.amount,
                account_code: 0,
                money: 0,
                money_change: 0,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                view(find);
                openInvoice();
                setChecked([]);

                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")}
        </SweetAlert>
      );
    }
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card style={{ margin: 0 }}>
            <CardBody style={{ paddingBottom: 0 }}>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.show}
                      onChange={(e) => {
                        view({ ...find, show: e.target.value, page: 1 });
                        setFind({ ...find, show: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="TIME"
                      >
                        แสดงตามช่วงเวลา
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        แสดงทั้งหมด
                      </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                {find.show === "TIME" ? null : (
                  <GridItem xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="search"
                      value={find.search}
                      fullWidth
                      InputProps={{
                        onChange: (e) => {
                          view({ ...find, search: e.target.value, page: 1 });
                          setFind({ ...find, search: e.target.value, page: 1 });
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            {t("element.search")} :
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        autoComplete: "off",
                      }}
                    />
                  </GridItem>
                )}
                {find.show === "TIME" ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/yyyy"
                        closeOnSelect
                        value={find.date_start}
                        onChange={(value) => {
                          view({ ...find, date_start: value, page: 1 });
                          setFind({ ...find, date_start: value, page: 1 });
                        }}
                        className={classes.center}
                        renderInput={(props) => {
                          return (
                            <TextField
                              fullWidth
                              InputProps={{
                                ...props,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginBottom: "4px" }}
                                  >
                                    {t("element.start")} :
                                  </InputAdornment>
                                ),
                                endAdornment: <DateRangeIcon />,
                                autoComplete: "off",
                                readOnly: true,
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                ) : null}
                {find.show === "TIME" ? (
                  <GridItem xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/yyyy"
                        closeOnSelect
                        value={find.date_stop}
                        onChange={(value) => {
                          view({ ...find, date_stop: value, page: 1 });
                          setFind({ ...find, date_stop: value, page: 1 });
                        }}
                        className={classes.center}
                        renderInput={(props) => {
                          return (
                            <TextField
                              fullWidth
                              InputProps={{
                                ...props,
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    style={{ marginBottom: "4px" }}
                                  >
                                    {t("element.stop")} :
                                  </InputAdornment>
                                ),
                                endAdornment: <DateRangeIcon />,
                                autoComplete: "off",
                                readOnly: true,
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </GridItem>
                ) : null}
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderCheckAll(),
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  renderHeader(header[6]),
                  renderHeader(header[7]),
                  renderHeader(header[8]),
                  renderHeader(header[9]),
                  renderHeader(header[10]),
                  renderHeader(header[11]),
                  renderAction(),
                  renderHeader(header[12]),
                ]}
                tableData={record.map((item) => {
                  return [
                    renderCheck(item),
                    btnOpen(item),
                    item.branch_name,
                    Func.dateTimeDisplay(item.date_create),
                    Func.dateTimeDisplay(item.date_shipping),
                    Func.numberDisplay(item.total),
                    Func.numberDisplay(item.shipping_price),
                    Func.numberDisplay(item.nettotal),
                    Func.numberDisplay(item.balance),
                    displayShipping(item),
                    item.remark,
                    item.payment_name,
                    displayStatus(item),
                    btn(item),
                    btnCancel(item),
                  ];
                })}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[7, 8]}
                colorsColls={["success", "danger"]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}></InputLabel>
                <Select
                  value={find.perPage}
                  onChange={(e) => {
                    view({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                    setFind({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                  }}
                >
                  {cboPerPage &&
                    cboPerPage.map((item) => (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {t(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                count={pageTotal}
                showFirstButton
                showLastButton
                page={pageNo}
                onChange={(e, p) => {
                  view({ ...find, page: p });
                  setFind({ ...find, page: p });
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderCheckAll = () => {
    return (
      <Checkbox
        key="ALL"
        className={classes.positionAbsolute}
        tabIndex={-1}
        onClick={(e) => {
          setChecked([]);
          if (e.target.checked)
            record.map((item) => {
              setChecked((checked) => [...checked, item.code]);
            });
        }}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{
          checked: classes.checked,
          root: classes.checkRoot,
        }}
      />
    );
  };

  const renderCheck = (item) => {
    if (item.status != 1) return null;
    else
      return (
        <Checkbox
          key={item.code}
          className={classes.positionAbsolute}
          tabIndex={-1}
          checked={checked.indexOf(item.code) !== -1}
          onClick={() => handleCheck(item.code)}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
        />
      );
  };

  const displayShipping = (item) => {
    if (item.transfer == 1)
      return (
        <code className="color-default">
          <i className="far fa-times-circle" /> ไม่จัดส่ง
        </code>
      );
    else if (item.transfer == 2)
      return (
        <code className="color-info">
          <i className="fas fa-store-alt" /> {item.branch_transfer}
        </code>
      );
    else if (item.transfer == 3)
      return (
        <code className="color-primary">
          <i className="fas fa-shipping-fast" /> จัดส่ง
        </code>
      );
  };

  const displayPayment = (inp) => {
    if (inp == "CASH") return "เงินสด";
    else if (inp == "TRANSFER") return "โอนเงิน";
    else if (inp == "CREDIT") return "เงินเชื่อ";
    else if (inp == "WALLET") return "Wallet";
    else return "";
  };

  const renderAction = () => {
    return (
      <CustomDropdown
        dropup
        buttonText={t(`${prefix}.action`)}
        buttonProps={{
          color: "primary",
          size: "sm",
        }}
        dropdownList={[
          {
            id: "payment",
            title: (
              <div>
                <i className="fas fa-file-invoice-dollar" />{" "}
                {t(`${prefix}.payment`)}
              </div>
            ),
          },
          {
            id: "print",
            title: (
              <div>
                <i className="fas fa-print" /> {t(`${prefix}.printinvoice`)}
              </div>
            ),
          },
          {
            id: "printlog",
            title: (
              <div>
                <i className="fas fa-file" /> ใบแจ้งหนี้
              </div>
            ),
          },
        ]}
        onClick={(item) => {
          switch (item.id) {
            case "payment":
              openPay(checked);
              break;
            case "print":
              printPay(checked);
              break;
            case "printlog":
              setModalPrint(true);
              break;
          }
        }}
      />
    );
  };

  const openPay = (chk) => {
    setLoading(true);
    setAttr(initAttr);

    PostData(mod, "openpay", {
      token: app.token,
      lang: app.lang,
      code: id,
      checked: chk,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setAttr(result.data);
        setChecked(chk);

        setModalPayment(true);
      }
    });
  };

  const printPay = (chk) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);
    setSlip({});
    setSlipList([]);

    PostData(mod, "print", {
      token: app.token,
      lang: app.lang,
      member_code: id,
      checked: chk,
    }).then((result) => {
      setLoading(false);

      if (result.status === "OK") {
        setSlip(result.data);
        setSlipList(result.list);
        handlePrintDay();
      }
    });
  };

  const addPay = (type, account) => {
    if (attr.order_balance <= 0) {
      showNotify(t(`${prefix}.nobalancepay`), "danger");
    } else if (attr.amount <= 0) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else if (type == "CASH" && attr.money <= 0) {
      showNotify("กรุณาเพิ่มจำนวนเงิน", "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);
            setModalPayment(false);

            PostData(mod, "addpay", {
              member_code: id,
              checked: checked,
              amount: type == "WALLET" ? attr.wallet_pay : attr.amount,
              type: type,
              account: account,
              money: type == "CASH" ? attr.money : 0,
              money_change: type == "CASH" ? attr.money_change : 0,
              data: {
                payment_type: type,
                payment_main: type,
                amount: type == "WALLET" ? attr.wallet_pay : attr.amount,
                account_code: account,
                money: type == "CASH" ? attr.money : 0,
                money_change: type == "CASH" ? attr.money_change : 0,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                view(find);
                openInvoice();
                setChecked([]);

                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);
          setModalList(false);

          PostData(mod, "edit", {
            code: attr.code,
            data: {
              transfer: attr.transfer,
              branch_transfer: attr.branch_transfer,
              address: attr.transfer === "3" ? attr.address : "",
              branch_warehouse:
                attr.transfer === "3" ? attr.branch_warehouse : "",
              car_code: attr.transfer === "3" ? attr.car_code : "",
              remark: attr.remark,
              date_shipping: Func.dateFormat(attr.date_shipping),
              time_shipping: attr.time_shipping,
              shipping_price: attr.shipping_price,
              date_record: Func.dateFormat(attr.date_record),
              date_due: Func.dateFormat(attr.date_due),
            },
          }).then((result) => {
            view(find);
            setLoading(false);

            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const editCancel = (item) => {
    setAlert(
      <SweetAlert
        input
        required
        inputType="text"
        style={{ display: "block", marginTop: "-100px" }}
        title={t("คุณต้องการยกเลิกรายการใช่หรือไม่")}
        validationMsg="กรุณาป้อนหมายเหตุ"
        onConfirm={(remark) => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "editcancel", {
            code: item.code,
            remark_cancel: remark,
          }).then((result) => {
            setLoading(false);
            if (result.status === "OK") {
              showNotify(t("alert.editcomplete"), "success");
              view(find);
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.danger}
        cancelBtnCssClass={classes.button}
        confirmBtnText={t("ยืนยันการยกเลิก")}
        cancelBtnText={t("ปิด")}
        showCancel
      />
    );
  };

  const btnReturn = (item, data) => {
    if (data.status === "1" && data.time_limit === "Y")
      return (
        <Button
          color="danger"
          simple
          style={{
            fontSize: "1.2em",
            textAlign: "right",
            margin: "10px 0",
            padding: 0,
          }}
          onClick={() => {
            setMsg("");
            setField({
              ...item,
              quantity_return: "",
            });
            setModalNumpad(true);
          }}
        >
          <EditIcon className={classes.colorDanger} />{" "}
          {Func.numberDisplay(item.quantity_return)}
        </Button>
      );
    else return Func.numberDisplay(item.quantity_return);
  };

  const editReturn = () => {
    if (
      field.quantity_return * 1 <= 0 ||
      field.quantity_return * 1 > field.quantity * 1
    ) {
      setMsg("ปริมาณไม่ถูกต้อง");
    } else {
      setLoading(true);
      setModalNumpad(false);

      PostData(mod, "editreturn", {
        token: app.token,
        lang: app.lang,
        code: field.code,
        quantity_return: field.quantity_return * 1,
      }).then((result) => {
        setLoading(false);

        if (result.status === "OK") {
          open({ code: result.so_code });
          view(find);
        } else {
          setMsg(t("alert.editerror"));
        }
      });
    }
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderStatus()}
          <CustomTabs
            headerColor="rose"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.order"),
                tabIcon: ListAltIcon,
                tabContent: renderOrder(),
              },
              {
                tabName: t("element.info"),
                tabIcon: ListAltIcon,
                tabContent: renderInfo(),
              },
            ]}
          />
          {renderDetail()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderShipping = () => {
    return (
      <GridContainer>
        <GridItem xs={12} style={{ marginTop: "10px" }}>
          <div>
            <div style={{ textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Radio
                    checked={attr.transfer === "1"}
                    onChange={(e) =>
                      setAttr({ ...attr, transfer: e.target.value })
                    }
                    value="1"
                    name="transfer_1"
                    aria-label="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="รับของทันที"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
              style={{ textAlign: "left" }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={attr.transfer === "2"}
                    onChange={(e) =>
                      setAttr({ ...attr, transfer: e.target.value })
                    }
                    value="2"
                    name="transfer_2"
                    aria-label="2"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="รับของที่สาขา"
              />
            </div>
            {attr.transfer === "2" ? (
              <Card style={{ marginTop: 0 }}>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="branch_warehouse"
                          className={classes.labelSelect}
                        >
                          {t(`${prefix}.branch`)}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={attr.branch_transfer}
                          inputProps={{
                            name: "branch_transfer",
                          }}
                          onChange={(e) => {
                            setAttr({
                              ...attr,
                              branch_transfer: e.target.value,
                            });
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="0"
                          >
                            {t(`${prefix}.branch`)}
                          </MenuItem>
                          {cboBranch.map((item) => (
                            <MenuItem
                              key={item.code}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.code}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <InputLabel className={classes.labelDpk}>
                        วันจัดส่ง
                      </InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          inputProps={{
                            placeholder: "__/__/____",
                          }}
                          dateFormat="DD/MM/yyyy"
                          closeOnSelect
                          value={attr.date_shipping}
                          onChange={(value) => {
                            setAttr({ ...attr, date_shipping: value });
                          }}
                          className={classes.center}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={`เวลาจัดส่ง`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: e.target.value,
                            }),
                          onBlur: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: Func.timeSetup(e.target.value),
                            }),
                          value: attr.time_shipping,
                        }}
                        alignCenter
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            ) : null}
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
              style={{ textAlign: "left" }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={attr.transfer === "3"}
                    onChange={(e) =>
                      setAttr({ ...attr, transfer: e.target.value })
                    }
                    value="3"
                    name="transfer_3"
                    aria-label="3"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="จัดส่งสินค้า"
              />
            </div>
            {attr.transfer === "3" ? (
              <Card style={{ marginTop: 0 }}>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="car_code"
                          className={classes.labelSelect}
                        >
                          รถส่งของ
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={attr.car_code}
                          inputProps={{
                            name: "car_code",
                          }}
                          onChange={(e) => {
                            setAttr({
                              ...attr,
                              car_code: e.target.value,
                            });
                          }}
                        >
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="0"
                          >
                            -
                          </MenuItem>
                          {cboCar.map((item) => (
                            <MenuItem
                              key={item.code}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.code}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="branch_warehouse"
                          className={classes.labelSelect}
                        >
                          คลังจัดส่ง
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={attr.branch_warehouse}
                          inputProps={{
                            name: "branch_warehouse",
                          }}
                          onChange={(e) => {
                            setAttr({
                              ...attr,
                              branch_warehouse: e.target.value,
                            });
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="0"
                          >
                            คลังจัดส่ง
                          </MenuItem>
                          {cboBranch.map((item) => (
                            <MenuItem
                              key={item.code}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.code}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={`ค่าจัดส่ง`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({
                              ...attr,
                              shipping_price: e.target.value,
                            }),
                          value: attr.shipping_price,
                        }}
                        alignCenter
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <InputLabel className={classes.labelDpk}>
                        วันจัดส่ง
                      </InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          inputProps={{
                            placeholder: "__/__/____",
                          }}
                          dateFormat="DD/MM/yyyy"
                          closeOnSelect
                          value={attr.date_shipping}
                          onChange={(value) => {
                            setAttr({ ...attr, date_shipping: value });
                          }}
                          className={classes.center}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={`เวลาจัดส่ง`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: e.target.value,
                            }),
                          onBlur: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: Func.timeSetup(e.target.value),
                            }),
                          value: attr.time_shipping,
                        }}
                        alignCenter
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={`ที่อยู่จัดส่ง`}
                        id="address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({ ...attr, address: e.target.value }),
                          value: attr.address,
                          multiline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            ) : null}
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText={t(`${prefix}.branch`)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.branch_name,
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="ประเภท"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: displayPayment(attr.payment_main),
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText={t(`${prefix}.payment_type`)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: displayPayment(attr.payment_type),
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.labelDpk}>
                  {t(`${prefix}.date_record`)}
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "__/__/____",
                    }}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    value={attr.date_record}
                    onChange={(value) => {
                      setAttr({ ...attr, date_record: value });
                    }}
                    className={classes.center}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.labelDpk}>
                  {t(`${prefix}.date_due`)}
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "__/__/____",
                    }}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    value={attr.date_due}
                    onChange={(value) => {
                      setAttr({ ...attr, date_due: value });
                    }}
                    className={classes.center}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${prefix}.remark`)}
                  id="remark"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.remark,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText="หมายเหตุ ยกเลิก"
                  id="remark_cancel"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.remark_cancel,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6}>
            {renderShipping()}
          </GridItem>
          <GridItem xs={12} className={classes.right}>
            <Button onClick={edit} color="success">
              <SaveIcon /> {t(`element.save`)}
            </Button>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderOrder = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${prefix}.num`),
              t(`${prefix}.image`),
              t(`${prefix}.product_id`),
              t(`${prefix}.product_name`),
              t(`${prefix}.unit`),
              t(`${prefix}.price`),
              t(`${prefix}.quantity`),
              t(`${prefix}.amount`),
              "ปริมาณเดิม",
              t(`${prefix}.return`),
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[5, 6, 7]}
            colorsColls={["primary", "primary", "success"]}
          />
        </GridItem>
        <GridItem xs={12} className={classes.center}>
          <Button onClick={() => print(attr)} color="info">
            <PrintIcon /> ย่อ
          </Button>
          <Button onClick={() => fullPrint(attr)} color="primary">
            <PrintIcon /> เต็ม
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.user_create")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.date_create")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                {Func.dateTimeDisplay(attr.date_create)}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.user_update")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.date_update")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                {Func.dateTimeDisplay(attr.date_update)}
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status * 1}
        alternativeLabel
        className={classes.bgTransparent}
        style={{ paddingBottom: 0 }}
      >
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_1`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_2`)}</b>
            </p>
            {attr.status * 1 === 3 ? (
              <p className={classes.colorDefault}>{attr.approve_user_2}</p>
            ) : null}
            {attr.status * 1 === 3 ? (
              <p className={classes.colorDefault}>{attr.approve_date_2}</p>
            ) : null}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_3`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_3}</p>
            <p className={classes.colorDefault}>{attr.approve_date_3}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              {attr.status == 9 ? (
                <Badge color="danger">{t(`${prefix}.step_9`)}</Badge>
              ) : (
                <b>{t(`${prefix}.step_9`)}</b>
              )}
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_9}</p>
            <p className={classes.colorDefault}>{attr.approve_date_9}</p>
          </StepLabel>
        </Step>
      </Stepper>
    );
  };

  const pay = {
    numpad: (num) => {
      if (num == "-") {
        setAttr({ ...attr, money: "", money_change: "0.00" });
      } else {
        let money = attr.money;
        let amount = attr.amount * 1;
        let money_change = 0;
        money += "" + num;

        money_change = money * 1 - amount;

        if (money_change < 0) money_change = 0;

        setAttr({ ...attr, money: money, money_change: money_change });
      }
    },
    numChange: (num) => {
      let money = attr.money * 1 + num;
      let amount = attr.amount * 1;
      let money_change = 0;

      money_change = money - amount;

      if (money_change < 0) money_change = 0;

      setAttr({ ...attr, money: money, money_change: money_change });
    },
    payChange: (value) => {
      let amount = value * 1;
      let money = attr.money * 1;
      let balance = attr.balance * 1;
      let order_balance = attr.order_balance * 1;
      let money_change = 0;
      let wallet_pay = balance < amount ? balance : amount;
      wallet_pay = wallet_pay > order_balance ? order_balance : wallet_pay;
      let wallet_balance = balance - wallet_pay;

      money_change = money - amount;

      if (money_change < 0) money_change = 0;

      setAttr({
        ...attr,
        money: money,
        money_change: money_change,
        amount: amount,
        wallet_pay: wallet_pay,
        wallet_balance: wallet_balance,
      });
    },
    // payChange: (value) => {
    //   let amount = value * 1;
    //   let money = attr.money * 1;
    //   let money_change = 0;

    //   money_change = money - amount;

    //   if (money_change < 0) money_change = 0;

    //   setAttr({
    //     ...attr,
    //     money: money,
    //     money_change: money_change,
    //     amount: amount,
    //   });
    // },
    btnNumpad: (num) => {
      return (
        <Button
          simple
          color="primary"
          className={classes.actionButton + " " + classes.actionButtonRound}
          block
          size={"lg"}
          style={{ height: "80px", fontSize: "20px" }}
          onClick={() => pay.numpad(num)}
        >
          {num}
        </Button>
      );
    },
    btnNumChange: (num) => {
      return (
        <Button
          simple
          color="primary"
          className={classes.actionButton + " " + classes.actionButtonRound}
          block
          size={"lg"}
          style={{ height: "80px", fontSize: "20px" }}
          onClick={() => pay.numChange(num)}
        >
          {num}
        </Button>
      );
    },
    paymentCash: () => {
      setModalPayment(false);
      setLoading(true);
      setSlip({});
      setSlipList([]);

      PostData(mod, "paymentcash", {
        token: app.token,
        lang: app.lang,
        money: txtMoney.current.value,
        data: {
          member_code: id,
          payment_type: "CASH",
          transfer: attr.transfer,
          branch_transfer: attr.branch_transfer,
          address: attr.transfer === "3" ? attr.address : "",
          branch_warehouse: attr.transfer === "3" ? attr.branch_warehouse : "",
          money: attr.money,
          money_change: attr.money_change,
          shipping_price: summary.shipping,
        },
      }).then((result) => {
        setLoading(false);
        setTabPayment(0);
        changeTabBill(0);

        if (result.status === "OK") {
          setCustomer(initCustomer);
          setSummary(initSummary);
          setList([]);

          setSlip(result.order);
          setSlipList(result.list);
          handlePrint();

          main.loadProduct();
        }
      });
    },
    paymentTransfer: (item) => {
      setModalPayment(false);
      setLoading(true);
      setSlip({});
      setSlipList([]);

      PostData(mod, "paymenttransfer", {
        token: app.token,
        lang: app.lang,
        member: id,
        money: attr.money,
        type: tabBill,
        data: {
          member_code: id,
          payment_type: "TRANSFER",
          account_code: item.code,
          transfer: attr.transfer,
          branch_transfer: attr.branch_transfer,
          address: attr.transfer === "3" ? attr.address : "",
          branch_warehouse: attr.transfer === "3" ? attr.branch_warehouse : "",
          money: attr.money,
          money_change: attr.money_change,
          shipping_price: summary.shipping,
        },
      }).then((result) => {
        setLoading(false);
        setTabPayment(0);
        changeTabBill(0);

        if (result.status === "OK") {
          setCustomer(initCustomer);
          setSummary(initSummary);
          setList([]);

          setSlip(result.order);
          setSlipList(result.list);
          handlePrint();

          main.loadProduct();
        }
      });
    },
  };

  const renderPay = {
    modal: () => {
      return (
        <Dialog
          fullScreen
          open={modalPayment}
          onClose={() => setModalPayment(false)}
        >
          <DialogTitle
            id="modal-pay-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              justIcon
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => setModalPayment(false)}
            >
              <CloseIcon className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>การชำระเงิน</h4>
          </DialogTitle>
          <DialogContent
            id="modal-pay-description"
            style={{
              height: "700px",
              overflowY: "scroll",
              width: "100%",
              padding: "0 !important",
            }}
          >
            <GridContainer>
              <GridItem xs={12} sm={7}>
                {renderPay.order()}
              </GridItem>
              <GridItem xs={12} sm={5}>
                {renderPay.content()}
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      );
    },
    order: () => {
      const cssValue = {
        padding: "10px 20px",
        fontSize: "2em",
        background: "#f2f2f2",
        textAlign: "right",
        color: "#666",
        marginBottom: "10px",
      };

      return (
        <form autoComplete="off">
          <GridContainer>
            <GridItem xs={12}>
              <br />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={t(`${prefix}.member`)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.name,
                  disabled: true,
                }}
              />
              <CustomInput
                labelText={t(`${prefix}.tel`)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: attr.tel,
                  disabled: true,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <CustomInput
                labelText={t(`${prefix}.wallet`)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.balance),
                  disabled: true,
                }}
                alignRight
              />
              <CustomInput
                labelText={t(`${prefix}.balance`)}
                error
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.order_balance),
                  disabled: true,
                }}
                alignRight
              />
              {/* <CustomInput
                labelText={t(`${prefix}.payamount`)}
                formControlProps={{
                  fullWidth: true,
                }}
                placeholder="0.00"
                inputProps={{
                  type: "number",
                  value: attr.amount,
                  onChange: (e) => pay.payChange(e.target.value),
                }}
                alignRight
              /> */}
              <CustomInput
                success
                labelText={t(`${prefix}.payamount`)}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  autoComplete: "off",
                  placeholder: "0.00",
                  style: cssValue,
                  type: "number",
                  value: attr.amount,
                  onChange: (e) => pay.payChange(e.target.value),
                }}
                style={{ paddingTop: 0 }}
                alignRight
              />
            </GridItem>
          </GridContainer>
        </form>
      );
    },
    content: () => {
      return (
        <CustomTabs
          headerColor="primary"
          value={tabPayment}
          changeValue={(e, v) => setTabPayment(v)}
          tabs={[
            {
              tabName: t("เงินสด"),
              tabIcon: MoneyIcon,
              tabContent: renderPay.cash(),
            },
            {
              tabName: t("โอนเงิน"),
              tabIcon: AccountBalanceIcon,
              tabContent: renderPay.transfer(),
            },
            {
              tabName: "Wallet",
              tabIcon: MoneyIcon,
              tabContent: renderPay.wallet(),
            },
          ]}
        />
      );
    },
    cash: () => {
      const cssValue = {
        padding: "10px 20px",
        fontSize: "2em",
        background: "#f2f2f2",
        textAlign: "right",
        color: "#666",
        marginBottom: "10px",
      };

      return (
        <div style={{ overflow: "hidden" }}>
          <div>
            <CustomInput
              labelText="จำนวนเงินที่รับ"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: attr.money,
                inputRef: txtMoney,
                autoComplete: "off",
                placeholder: "0",
                style: cssValue,
                onChange: (e) => {
                  let money = e.target.value;
                  let money_change = money - summary.total;
                  if (money_change < 0) money_change = 0;

                  setAttr({
                    ...attr,
                    money: money,
                    money_change: money_change,
                  });
                },
              }}
              style={{ paddingTop: 0 }}
              alignRight
            />
          </div>
          <h3 className="center">
            เงินทอน : {Func.numberDisplay(attr.money_change)}
          </h3>
          <table width="100%">
            <tbody>
              <tr>
                <td width="25%">{pay.btnNumpad(7)}</td>
                <td width="25%">{pay.btnNumpad(8)}</td>
                <td width="25%">{pay.btnNumpad(9)}</td>
                <td width="25%">{pay.btnNumChange(1000)}</td>
              </tr>
              <tr>
                <td>{pay.btnNumpad(4)}</td>
                <td>{pay.btnNumpad(5)}</td>
                <td>{pay.btnNumpad(6)}</td>
                <td>{pay.btnNumChange(500)}</td>
              </tr>
              <tr>
                <td>{pay.btnNumpad(1)}</td>
                <td>{pay.btnNumpad(2)}</td>
                <td>{pay.btnNumpad(3)}</td>
                <td>{pay.btnNumChange(100)}</td>
              </tr>
              <tr>
                <td>{pay.btnNumpad(".")}</td>
                <td>{pay.btnNumpad(0)}</td>
                <td>
                  <Button
                    simple
                    color="primary"
                    className={
                      classes.actionButton + " " + classes.actionButtonRound
                    }
                    block
                    size={"lg"}
                    style={{ height: "80px", fontSize: "20px" }}
                    onClick={() => pay.numpad("-")}
                  >
                    <i className="fas fa-backspace"></i>
                  </Button>
                </td>
                <td>
                  <Button
                    simple
                    color="danger"
                    className={
                      classes.actionButton + " " + classes.actionButtonRound
                    }
                    block
                    size={"lg"}
                    style={{ height: "80px", fontSize: "20px" }}
                    onClick={() => addPay("CASH", 0)}
                  >
                    ตกลง
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    },
    transfer: () => {
      return (
        <div>
          {cboBank.map((item) => (
            <button
              key={item.code}
              className="btnbank"
              onClick={() => transferPay(item.code)}
            >
              <CardBody
                className="myaccount"
                style={{ backgroundColor: item.bank_color }}
              >
                <table>
                  <tbody>
                    <tr>
                      <td width="90">
                        <img src={item.bank_pic} width="70" />
                      </td>
                      <td>
                        <div>{item.bank_name}</div>
                        <div className="text-account">{item.acc_no}</div>
                        <div>{item.acc_name}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </button>
          ))}
        </div>
      );
    },
    wallet: () => {
      return (
        <form autoComplete="off">
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                labelText="Wallet"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.balance),
                  disabled: true,
                }}
                alignCenter
              />
              <CustomInput
                error
                labelText="เงินที่ชำระ"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.wallet_pay),
                  disabled: true,
                }}
                alignCenter
              />
              <CustomInput
                success
                labelText="Wallet คงเหลือ"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: Func.numberDisplay(attr.wallet_balance),
                  disabled: true,
                }}
                alignCenter
              />
            </GridItem>
            <GridItem xs={12}>
              <Button
                color="primary"
                block
                size={"lg"}
                onClick={() => addPay("WALLET", 0)}
              >
                <i className="fas fa-add"></i> ชำระเงิน
              </Button>
            </GridItem>
          </GridContainer>
        </form>
      );
    },
    waiting: () => {
      /* renderPay.waiting */
      return (
        <div>
          <Button size={"lg"} onClick={() => waitingPay()}>
            ส่งรายการรับชำระ
          </Button>
        </div>
      );
    },
  };

  const runderNumpad = () => {
    const cssValue = {
      padding: "10px 20px",
      fontSize: "2em",
      background: "#f2f2f2",
      textAlign: "right",
      color: "#666",
      marginBottom: "10px",
    };

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal,
        }}
        open={modalNumpad}
        keepMounted
        onClose={() => setModalNumpad(false)}
        aria-labelledby="modal-numpad-title"
        aria-describedby="modal-numpad-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalNumpad(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{field.product_name}</h4>
        </DialogTitle>
        <DialogContent id="modal-numpad-description">
          <div style={{ minWidth: "300px", paddingTop: "20px" }}>
            <div style={{ overflow: "hidden" }}>
              <div>
                <form>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: field.quantity_return,
                      autoComplete: "off",
                      placeholder: "0",
                      style: cssValue,
                      onChange: (e) =>
                        setField({ ...field, quantity_return: e.target.value }),
                    }}
                    alignRight
                  />
                </form>
              </div>
              <table width="100%">
                <tbody>
                  <tr>
                    <td width="25%">{btnNumpad(7)}</td>
                    <td width="25%">{btnNumpad(8)}</td>
                    <td width="25%">{btnNumpad(9)}</td>
                  </tr>
                  <tr>
                    <td>{btnNumpad(4)}</td>
                    <td>{btnNumpad(5)}</td>
                    <td>{btnNumpad(6)}</td>
                  </tr>
                  <tr>
                    <td>{btnNumpad(1)}</td>
                    <td>{btnNumpad(2)}</td>
                    <td>{btnNumpad(3)}</td>
                  </tr>
                  <tr>
                    <td>{btnNumpad(".")}</td>
                    <td>{btnNumpad(0)}</td>
                    <td>
                      <Button
                        simple
                        color="primary"
                        className={
                          classes.actionButton + " " + classes.actionButtonRound
                        }
                        block
                        size={"lg"}
                        style={{ height: "80px", fontSize: "20px" }}
                        onClick={() => addNumpad("-")}
                      >
                        <i className="fas fa-backspace"></i>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <Button
                        simple
                        color="danger"
                        className={
                          classes.actionButton + " " + classes.actionButtonRound
                        }
                        block
                        size={"lg"}
                        style={{ height: "80px", fontSize: "20px" }}
                        onClick={() => editReturn()}
                      >
                        {t(`${prefix}.return`)}
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <h3>{msg}</h3>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  const btnNumpad = (num) => {
    return (
      <Button
        simple
        color="primary"
        className={classes.actionButton + " " + classes.actionButtonRound}
        block
        size={"lg"}
        style={{ height: "80px", fontSize: "20px" }}
        onClick={() => addNumpad(num)}
      >
        {num}
      </Button>
    );
  };

  const addNumpad = (num) => {
    let value = field.quantity_return;

    if (num == "-") {
      value = value.substr(0, value.length - 1);
    } else {
      value += "" + num;
    }

    setField({ ...field, quantity_return: value });
  };

  const renderPrintDay = () => {
    return (
      <div style={{ display: "none" }}>
        <PosSlipDay ref={slipDayRef} attr={slip} list={slipList} />
      </div>
    );
  };

  const renderModalPrint = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalPrint}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalPrint(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-print-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalPrint(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>ใบแจ้งหนี้</h4>
        </DialogTitle>
        <DialogContent
          id="modal-print"
          className={classes.modalBody}
          style={{
            height: "700px",
            overflowY: "scroll",
            width: "100%",
          }}
        >
          <CustomerPrint />
        </DialogContent>
      </Dialog>
    );
  };

  const renderPrint = () => {
    return (
      <div style={{ display: "none" }}>
        <PosSlip ref={slipRef} attr={slip} list={printList} />
        <FullBill ref={fullRef} attr={slip} list={printList} />
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalList()}
      {renderPrintDay()}
      {renderPrint()}
      {renderLoading()}
      {runderNumpad()}
      {renderModalPrint()}
      {renderPay.modal()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(CustomerBill);
