/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import UploadImage from "./UploadImage";

// @material-ui/icons
import Input from "@material-ui/core/Input";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CheckIcon from "@material-ui/icons/Check";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// core components
import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";
import ProductSort from "./ProductSort";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  name_th: "",
  name_en: "",
  enable: "Y",
};

const initField = {
  code: "",
  product_id: "",
  product_name: "",
  quantity: "0",
  price: "0.00",
  group_code: "0",
  filepic: "",
};

const initFind = {
  search: "",
  order_by: "tb.name_th",
  order_sort: "asc",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function Promotion({ t }) {
  const mod = "promotion";
  const prefix = "attr";
  const classes = useStyles();
  const txtSearchList = useRef(null);
  const txtPrice = useRef(null);
  const txtQuantity = useRef(null);
  const txtQuantityGroup = useRef(null);

  const initHeader = [
    { code: "tb.code", name: t(`${prefix}.code`) },
    { code: "tb.name_th", name: t(`${prefix}.name_th`), order: "asc" },
    { code: "tb.name_en", name: t(`${prefix}.name_en`) },
    { code: "tb.enable", name: t(`${prefix}.enable`) },
  ];
  const alignValue = [0, 3, 4];
  const alignItem = [classes.center, classes.center, classes.center];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [searchList, setSearchList] = useState("");
  const [promotion, setPromotion] = useState([]);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [member, setMember] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [order, setOrder] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [tab, setTab] = useState(0);
  const [field, setField] = useState(initField);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalGroup, setModalGroup] = useState(false);
  const [groupCode, setGroupCode] = useState(0);
  const [groupQty, setGroupQty] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        if (id === undefined) {
          view(find);
        } else if (id === "add") {
          setAttr(initAttr);
        } else {
          setTab(0);
          open();
        }
      } else {
        Func.exitApp();
      }
    });
  };

  const changeTab = (event, value) => {
    setTab(value);
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const checkChange = (e) => {
    setAttr({ ...attr, [e.target.name]: e.target.checked ? "Y" : "N" });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.code,
              item.name_th,
              item.name_en,
              Func.checkDisplay(item.enable),
              btn(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const open = () => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);
    setMember([]);
    setPromotion([]);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: id,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr(result.data);

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              Func.numberDisplay(item.quantity, 0),
              item.unit_name,
              Func.numberDisplay(item.price),
              btnList(item),
            ],
          ]);
        });

        result.member.map((item, i) => {
          setMember((member) => [...member, [i + 1, item.name]]);
        });

        result.promotion.map((item, i) => {
          setPromotion((promotion) => [
            ...promotion,
            {
              title:
                `${i + 1}. ` +
                t(`${mod}.quantity_min`) +
                ` : ` +
                Func.numberDisplay(item.data.quantity, 0) +
                ` ${item.data.unit_name}`,
              content: tablePromotion(item.data, item.list),
            },
          ]);
        });

        setLoading(false);
      }
    });
  };

  const checkEmpty = () => {
    let result = true;

    if (!Func.verifyLength(attr.name_th, 1)) {
      result = false;
    }

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              data: {
                name_th: attr.name_th,
                name_en: attr.name_en,
                enable: attr.enable,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                window.location.assign(`/admin/${mod}/${result.code}`);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const edit = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_edit_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "edit", {
              code: attr.code,
              data: {
                name_th: attr.name_th,
                name_en: attr.name_en,
                enable: attr.enable,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                open();
                showNotify(t("alert.editcomplete"), "success");
              } else {
                showNotify(t("alert.editerror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_edit_msg")} #{attr.code}
        </SweetAlert>
      );
    }
  };

  const del = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "delete", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const copy = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setLoading(true);

            PostData(mod, "copy", {
              ref_code: id,
              data: {
                name_th: attr.name_th,
                name_en: attr.name_en,
              },
            }).then((result) => {
              setAlert(null);
              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                window.location.assign(`/admin/${mod}/${result.code}`);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const addGroup = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_add_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);
          setModalGroup(false);

          PostData(mod, "addgroup", {
            token: app.token,
            lang: app.lang,
            code: id,
            data: {
              promotion_code: id,
              quantity: groupQty,
            },
          }).then((result) => {
            setAlert(null);
            if (result.status === "OK") {
              open();
              showNotify(t("alert.addcomplete"), "success");
            } else {
              showNotify(t("alert.adderror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_add_msg")}
      </SweetAlert>
    );
  };

  const editGroup = () => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_edit_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);
          setModalGroup(false);

          PostData(mod, "editgroup", {
            token: app.token,
            lang: app.lang,
            code: groupCode,
            data: {
              promotion_code: id,
              quantity: groupQty,
            },
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              open();
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_edit_msg")} #{attr.code}
      </SweetAlert>
    );
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const btn = (item) => {
    return (
      <>
        <NavLink to={`/admin/${mod}/${item.code}`}>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
          >
            <EditIcon className={classes.icon} />
          </Button>
        </NavLink>
        <Button
          round
          color="danger"
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={() => del(item)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </>
    );
  };

  const saveSort = (item) => {
    setAlert(
      <SweetAlert
        input
        showCancel
        cancelBtnBsStyle="light"
        title={t(`${prefix}.sort`) + " : " + item.sort}
        placeHolder="Write something"
        onConfirm={(sort) => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "savesort", {
            token: app.token,
            lang: app.lang,
            code: item.code,
            sort: sort,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.editcomplete"), "success");
            } else {
              showNotify(t("alert.editerror"), "danger");
            }
          });
        }}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        onCancel={() => setAlert(null)}
      >
        {item.name_th}
      </SweetAlert>
    );
  };

  const btnSort = (item) => {
    return (
      <Button simple color="rose" onClick={() => saveSort(item)}>
        <span>{item.sort}</span>
      </Button>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.viewlist")}</h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="search"
                    fullWidth
                    InputProps={{
                      onChange: (e) => {
                        view({ ...find, search: e.target.value, page: 1 });
                        setFind({ ...find, search: e.target.value, page: 1 });
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          {t("element.search")} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      <NavLink to={`/admin/${mod}/add`}>
                        <Button round color="info" size="sm">
                          <AddIcon className={classes.icon} />{" "}
                          {t("element.add")}
                        </Button>
                      </NavLink>,
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[]}
                    colorsColls={[]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderAdd = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody className={classes.right}>
              <NavLink to={`/admin/${mod}`}>
                <Button color="warning">
                  <ArrowBackIcon /> {t("element.back")}
                </Button>
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <AssignmentIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("element.adddata")}</h4>
            </CardHeader>
            <CardBody>
              {renderInfo()}

              <GridContainer>
                <GridItem xs={12} className={classes.right}>
                  <Button color="success" onClick={add}>
                    <SaveIcon /> {t("element.save")}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderEdit = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <h4>
                    {t(`${prefix}.name`)} : {attr.name_th}
                  </h4>
                </GridItem>
                <GridItem xs={6} className={classes.right}>
                  <NavLink to={`/admin/${mod}`}>
                    <Button color="warning">
                      <ArrowBackIcon /> {t("element.back")}
                    </Button>
                  </NavLink>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <CustomTabs
            headerColor="primary"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.info"),
                tabIcon: DescriptionIcon,
                tabContent: (
                  <div>
                    {renderInfo()}
                    <GridContainer>
                      <GridItem xs={12} className={classes.right}>
                        <Button color="success" onClick={edit}>
                          <SaveIcon /> {t("element.save")}
                        </Button>
                        <Button color="info" onClick={copy}>
                          <FileCopyIcon /> {t("element.copy")}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </div>
                ),
              },
              {
                tabName: t(`${mod}.promotiongroup`),
                tabIcon: ListAltIcon,
                tabContent: renderPromotionGroup(),
              },
              {
                tabName: "ลูกค้า",
                tabIcon: ListAltIcon,
                tabContent: renderMember(),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>{renderDetail()}</GridItem>
      </GridContainer>
    );
  };

  const renderPromotionGroup = () => {
    return (
      <GridContainer>
        <GridItem xs={12} className={classes.right}>
          <Button
            round
            color="info"
            onClick={() => {
              setGroupCode(0);
              setGroupQty(1);
              setModalGroup(true);

              setTimeout(function () {
                txtQuantityGroup.current.select();
              }, 500);
            }}
          >
            <AddToPhotosIcon className={classes.icon} />
            {t("element.add_group")}
          </Button>
        </GridItem>
        <GridItem xs={12}>
          <Accordion active={0} collapses={promotion} />
        </GridItem>
      </GridContainer>
    );
  };

  const renderMember = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[t(`${mod}.num`), "ลูกค้า"]}
            tableData={member}
            customHeadClassesForCells={[0, 1]}
            customHeadCellClasses={[classes.center, classes.center]}
            customClassesForCells={[0, 1]}
            customCellClasses={[classes.center, classes.center]}
            coloredColls={[]}
            colorsColls={[]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalGroup = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalGroup}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalGroup(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-edit-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalGroup(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {groupCode > 0 ? t(`${mod}.edit_group`) : t(`${mod}.add_group`)}
          </h4>
        </DialogTitle>
        <DialogContent id="modal-edit" className={classes.modalBody}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12}>
                <br />
              </GridItem>
              <GridItem xs={12}>
                <Table
                  striped
                  hover
                  tableData={[
                    [
                      <b>{t(`${mod}.quantity_min`)}</b>,
                      <Input
                        inputRef={txtQuantityGroup}
                        id="quantity_group"
                        placeholder="0"
                        inputProps={{
                          "aria-label": "quantity",
                          value: groupQty,
                        }}
                        classes={{
                          input: classes.right,
                        }}
                        color="primary"
                        fullWidth={false}
                        onChange={(e) => setGroupQty(e.target.value)}
                      />,
                    ],
                  ]}
                  customHeadClassesForCells={[1]}
                  customHeadCellClasses={[classes.right]}
                  customClassesForCells={[1]}
                  customCellClasses={[classes.right]}
                  coloredColls={[1]}
                  colorsColls={["primary"]}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              if (groupCode > 0) editGroup();
              else addGroup();
            }}
            color="success"
          >
            <SaveIcon /> {t(`element.save`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderModalEdit = () => {
    let data = [];

    if (field.group_code > 0) {
      data = [
        [
          <b>{t(`${mod}.price`)}</b>,
          <Input
            inputRef={txtPrice}
            id="price"
            placeholder="0.00"
            inputProps={{
              "aria-label": "price",
              value: field.price,
            }}
            classes={{
              input: classes.right,
            }}
            color="primary"
            fullWidth={false}
            onChange={(e) => {
              setField({ ...field, price: e.target.value });
            }}
          />,
        ],
      ];
    } else {
      data = [
        [
          <b>{t(`${mod}.quantity_min`)}</b>,
          <Input
            inputRef={txtQuantity}
            id="quantity"
            placeholder="0"
            inputProps={{
              "aria-label": "quantity",
              value: field.quantity,
            }}
            classes={{
              input: classes.right,
            }}
            color="primary"
            fullWidth={false}
            onChange={(e) => {
              setField({ ...field, quantity: e.target.value });
            }}
          />,
        ],
        [
          <b>{t(`${mod}.price`)}</b>,
          <Input
            inputRef={txtPrice}
            id="price"
            placeholder="0.00"
            inputProps={{
              "aria-label": "price",
              value: field.price,
            }}
            classes={{
              input: classes.right,
            }}
            color="primary"
            fullWidth={false}
            onChange={(e) => {
              setField({ ...field, price: e.target.value });
            }}
          />,
        ],
      ];
    }

    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalEdit}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalEdit(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-edit-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalEdit(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t("element.edit")}</h4>
        </DialogTitle>
        <DialogContent id="modal-edit" className={classes.modalBody}>
          <form autoComplete="off">
            <GridContainer>
              <GridItem xs={12} sm={4}>
                <img src={field.filepic} width="100%" />
              </GridItem>
              <GridItem xs={12} sm={8}>
                <CustomInput
                  labelText={t(`${mod}.product_id`)}
                  id="product_id"
                  success
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: field.product_id,
                    disabled: true,
                  }}
                />
                <CustomInput
                  labelText={t(`${mod}.product_name`)}
                  id="product_name"
                  success
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: field.product_name,
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <p></p>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <Table
                  striped
                  hover
                  tableData={data}
                  customHeadClassesForCells={[1]}
                  customHeadCellClasses={[classes.right]}
                  customClassesForCells={[1]}
                  customCellClasses={[classes.right]}
                  coloredColls={[1]}
                  colorsColls={["primary"]}
                />
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={editList} color="success">
            <SaveIcon /> {t(`element.save`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${prefix}.name_th`)}
              id="name_th"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_th,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={6}>
            <CustomInput
              labelText={t(`${prefix}.name_en`)}
              id="name_en"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: inputChange,
                value: attr.name_en,
              }}
            />
          </GridItem>
          <GridItem xs={12}>
            <div className={classes.checkboxAndRadio}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={attr.enable === "Y"}
                    name="enable"
                    tabIndex={-1}
                    onChange={checkChange}
                    checkedIcon={<CheckIcon className={classes.checkedIcon} />}
                    icon={<CheckIcon className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label={t(`${prefix}.enable`)}
              />
            </div>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const tablePromotion = (data, list) => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${mod}.num`),
              t(`${mod}.image`),
              t(`${mod}.product_id`),
              t(`${mod}.product_name`),
              t(`${mod}.price`),
              ``,
            ]}
            tableData={
              list &&
              list.map((item, i) => [
                i + 1,
                <img src={item.filepic} width="30" />,
                item.product_id,
                item.product_name,
                Func.numberDisplay(item.price),
                btnList(item),
              ])
            }
            customHeadClassesForCells={[0, 1, 4, 5]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.right,
              classes.right,
            ]}
            coloredColls={[4]}
            colorsColls={["primary"]}
          />
        </GridItem>
        <GridItem
          xs={12}
          className={classes.center}
          style={{ marginTop: "20px" }}
        >
          <Button round color="info" onClick={() => addProduct(data)}>
            <AddIcon className={classes.icon} />
            {t(`${mod}.add_product`)}
          </Button>
          <Button
            round
            color="success"
            onClick={() => {
              setGroupCode(data.code);
              setGroupQty(data.quantity);
              setModalGroup(true);

              setTimeout(function () {
                txtQuantityGroup.current.select();
              }, 500);
            }}
          >
            <EditIcon className={classes.icon} />
            {t(`${mod}.edit_group`)}
          </Button>
          <Button round color="danger" onClick={() => delGroup(data)}>
            <DeleteForeverIcon className={classes.icon} />
            {t(`${mod}.del_group`)}
          </Button>
        </GridItem>
      </GridContainer>
    );
  };

  const delGroup = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          PostData(mod, "delgroup", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setAlert(null);
            if (result.status === "OK") {
              open();
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const searchOrder = (e) => {
    const btnOrder = (item) => {
      return (
        <div>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={() => addList(item)}
          >
            <AddIcon className={classes.icon} />
          </Button>
        </div>
      );
    };

    setSearchList(e.target.value);

    PostData(mod, "searchproduct", {
      token: app.token,
      lang: app.lang,
      search: e.target.value,
    }).then((result) => {
      if (result.status === "OK") {
        setOrder([]);

        result.data.map((item, i) => {
          setOrder((order) => [
            ...order,
            [
              <img src={item.filepic} width="30" />,
              item.name,
              item.price,
              btnOrder(item),
            ],
          ]);
        });
      }
    });
  };

  const addList = (item) => {
    PostData(mod, "addlist", {
      token: app.token,
      lang: app.lang,
      code: id,
      data: {
        promotion_code: id,
        group_code: groupCode,
        quantity: 1,
        price: item.price,
        amount: item.price,
        product_code: item.code,
      },
    }).then((result) => {
      if (result.status === "OK") {
        open();
        showNotify(t("alert.addcomplete"), "success");

        setModalAdd(false);
        openList(result.code);
      }
    });
  };

  const editList = () => {
    PostData(mod, "editlist", {
      token: app.token,
      lang: app.lang,
      code: field.code,
      data: {
        price: field.price,
        quantity: field.quantity,
      },
    }).then((result) => {
      if (result.status === "OK") {
        open();
        setModalEdit(false);
        showNotify(t("alert.editcomplete"), "success");
      }
    });
  };

  const openList = (code) => {
    setLoading(true);
    setField(initField);

    PostData(mod, "openlist", {
      token: app.token,
      lang: app.lang,
      code: code,
    }).then((result) => {
      if (result.status === "OK") {
        setField(result.data);

        setModalEdit(true);
        txtPrice.current.select();
      }
      setLoading(false);
    });
  };

  const delList = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          PostData(mod, "dellist", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setAlert(null);
            if (result.status === "OK") {
              open();
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const btnList = (item) => {
    return (
      <div>
        <Button
          round
          color="success"
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={() => openList(item.code)}
        >
          <EditIcon className={classes.icon} />
        </Button>
        <Button
          round
          color="danger"
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={() => delList(item)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </div>
    );
  };

  const addProduct = (data) => {
    if (data === undefined) {
      setGroupCode("0");
    } else {
      setGroupCode(data.code);
    }

    setOrder([]);
    setSearchList("");
    setModalAdd(true);

    setTimeout(function () {
      txtSearchList.current.select();
    }, 500);
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_create")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.user_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{t("attr.date_update")}</h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.date_update}</p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalAdd = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalAdd}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalAdd(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-add-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalAdd(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t("element.add")}</h4>
        </DialogTitle>
        <DialogContent id="modal-add" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                labelText={t("element.search")}
                id="search_order"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  inputRef: txtSearchList,
                  onChange: searchOrder,
                  value: searchList,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableData={order}
                customHeadClassesForCells={[2, 3]}
                customHeadCellClasses={[classes.right, classes.right]}
                customClassesForCells={[2, 3]}
                customCellClasses={[classes.right, classes.right]}
                coloredColls={[2]}
                colorsColls={["primary"]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const renderPage = () => {
    if (id === undefined) {
      return renderViewList();
    } else if (id === "add") {
      return renderAdd();
    } else {
      return renderEdit();
    }
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderPage()}
      {renderModalAdd()}
      {renderModalEdit()}
      {renderModalGroup()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(Promotion);
