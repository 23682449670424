/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// @material-ui/icons
import PrintIcon from "@material-ui/icons/Print";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import DateRangeIcon from "@material-ui/icons/DateRange";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";

// core components
import Badge from "components/Badge/Badge.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import i18n from "components/Translation/Translate.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/CustomButtons/Button.js";
import CardIcon from "components/Card/CardIcon.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import { API_URL } from "services/Define";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";
import { dateTimeDisplay } from "services/Function";

function StepIcon(props) {
  const { active, completed } = props;
  const icons_complete = {
    1: <AddCircleIcon color="primary" />,
    2: <LocalShippingIcon color="primary" />,
    3: <MonetizationOnIcon color="primary" />,
    4: <CancelIcon color="error" />,
  };
  const icons = {
    1: <AddCircleIcon color="disabled" />,
    2: <LocalShippingIcon color="disabled" />,
    3: <MonetizationOnIcon color="disabled" />,
    4: <CancelIcon color="disabled" />,
  };
  return (
    <div>
      {completed
        ? icons_complete[String(props.icon)]
        : icons[String(props.icon)]}
    </div>
  );
}

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  id: "",
  branch_code: "",
  member_code: "",
  payment_type: "",
  date_record: "",
  date_shipping: "",
  time_shipping: "",
  name: "",
  tel: "",
  date_due: "",
  payment: "",
  remark: "",
  amount: "",
  discount: "",
  transfer: "",
  shipping: "",
  shipping_price: "",
  vat: "",
  total: "",
  money: "",
  pay: "",
  balance: "",
  money_change: "",
  address: "",
  emp_code: "",
  branch_code: "",
  branch_name: "",
  branch_warehouse: "",
  wallet_pay: "",
  wallet_balance: "",
};

const initFind = {
  search: "",
  date_start: moment(Func.dateFirstMonth()),
  date_stop: moment(new Date()),
  round_start: moment(Func.dateFirstMonth()),
  round_stop: moment(new Date()),
  order_by: "tb.code",
  order_sort: "desc",
  status: "ALL",
  branch: "ALL",
  member: "ALL",
  member_name: "",
  shift_code: "ALL",
  account: "ALL",
  round: "",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function RpPayTransfer({ t }) {
  const mod = "rp_paytransfer";
  const prefix = "bill";
  const txtMember = useRef(null);
  const txtRound = useRef(null);
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const [modalCustomer, setModalCustomer] = useState(false);
  const [modalRound, setModalRound] = useState(false);
  const [modalList, setModalList] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [roundList, setRoundList] = useState([]);
  const [cboAccount, setCboAccount] = useState([]);

  const initHeader = [
    { code: `tb.code`, name: t(`${prefix}.id`), order: "desc" },
    { code: "tb.branch_code", name: t(`attr.branch`) },
    { code: `tb.emp_code`, name: "ผู้ทำรายการ" },
    { code: `tb.type_code`, name: t(`attr.type`) },
    { code: `tb.detail`, name: "รายการ" },
    { code: `tb.account_code`, name: `บัญชีโอน` },
    { code: `tb.date_record`, name: t(`attr.date`) },
    { code: `tb.price`, name: t(`attr.price`) },
    { code: "tb.enable", name: t(`attr.enable`) },
  ];
  const alignValue = [0, 1, 6, 7, 8];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);

  const [attr, setAttr] = useState(initAttr);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [list, setList] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [loading, setLoading] = useState(false);
  const [cboStatus, setCboStatus] = useState([]);
  const [cboBranch, setCboBranch] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboBranch(result.branch);
        setCboAccount(result.account);

        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const changeTab = (event, value) => {
    setTab(value);
  };

  const view = (data) => {
    setFind(data);
    setLoading(true);
    setRecord([]);

    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateTimeFormat(data.date_start.toDate()),
      stop: Func.dateTimeFormat(data.date_stop.toDate()),
      status: data.status,
      branch: data.branch,
      member: data.member,
      account: data.account,
      shift_code: data.shift_code,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.id,
              item.branch_name,
              item.emp_name,
              item.type_name,
              item.detail,
              item.account_name,
              Func.dateDisplay(item.date_record),
              Func.numberDisplay(item.price),
              displayStatus(item),
            ],
          ]);
        });

        if (result.data.length > 0)
          setRecord((record) => [
            ...record,
            [
              "",
              "",
              "",
              "",
              "",
              "",
              <h5>{t(`${prefix}.total`)}</h5>,
              <h5>{Func.numberDisplay(result.sum.total)}</h5>,
              "",
            ],
          ]);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const excel = (data) => {
    setFind(data);
    setLoading(true);

    PostData(mod, "excel", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateTimeFormat(data.date_start.toDate()),
      stop: Func.dateTimeFormat(data.date_stop.toDate()),
      status: data.status,
      branch: data.branch,
      member: data.member,
      account: data.account,
      shift_code: data.shift_code,
    }).then((result) => {
      if (result.status === "OK") {
        window.open(`${API_URL}/app/print/${mod}-${result.code}.pdf`, "_self");
      }
      setLoading(false);
    });
  };

  const open = (item) => {
    setLoading(true);
    setAttr(initAttr);
    setList([]);
    setTab(0);

    PostData(mod, "open", {
      token: app.token,
      lang: app.lang,
      code: item.code,
    }).then((result) => {
      if (result.status === "OK") {
        setAttr({
          ...result.data,
          date_record: new Date(result.data.date_record),
          date_due: new Date(result.data.date_due),
          date_shipping: new Date(result.data.date_shipping),
        });

        result.list.map((item, i) => {
          setList((list) => [
            ...list,
            [
              i + 1,
              <img src={item.filepic} width="30" />,
              item.product_id,
              item.product_name,
              item.unit_name,
              item.price,
              item.quantity,
              item.amount,
              btnUndo(item),
              btnReturn(item, result.data),
            ],
          ]);
        });

        setList((list) => [
          ...list,
          {
            total: true,
            title: t(`${prefix}.amount`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.amount),
          },
          {
            total: true,
            title: t(`${prefix}.vat`) + " " + result.vat + " %",
            colspan: "8",
            amount: Func.numberDisplay(result.data.vat),
          },
          {
            total: true,
            title: t(`${prefix}.total`),
            colspan: "8",
            amount: displayTotal(result.data.total, classes.colorPrimary),
          },
          {
            total: true,
            title: t(`${prefix}.shipping`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.shipping_price),
          },
          {
            total: true,
            title: t(`${prefix}.nettotal`),
            colspan: "8",
            amount: Func.numberDisplay(result.data.nettotal),
          },
          {
            total: true,
            title: t(`${prefix}.pay`),
            colspan: "8",
            amount: displayTotal(result.data.pay, classes.colorSuccess),
          },
          {
            total: true,
            title: t(`${prefix}.balance`),
            colspan: "8",
            amount: displayTotal(result.data.balance, classes.colorDanger),
          },
        ]);

        setModalList(true);
      }
      setLoading(false);
    });
  };

  const btnOpen = (item) => {
    return (
      <Button color="primary" simple onClick={() => open(item)}>
        {item.id}
      </Button>
    );
  };

  const btnUndo = (item) => {
    if (item.quantity_old === item.quantity) return "-";
    else return item.quantity_old;
  };

  const btnReturn = (item, data) => {
    if (data.status === "1" && data.time_limit === "Y")
      return Func.numberDisplay(item.quantity_return);
    else return Func.numberDisplay(item.quantity_return);
  };

  const searchCustomer = (e) => {
    e.preventDefault();

    setMemberList([]);

    if (txtMember.current.value == "") return;

    PostData(mod, "membersearch", {
      token: app.token,
      lang: app.lang,
      search: txtMember.current.value,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setMemberList((memberList) => [
            ...memberList,
            [displayName(item), displayTel(item), displaySelect(item)],
          ]);
        });
      }
    });
  };

  const displayTotal = (number, cls) => {
    return <div className={cls}>{Func.numberDisplay(number)}</div>;
  };

  const displayPayment = (inp) => {
    if (inp == "CASH") return "เงินสด";
    else if (inp == "TRANSFER") return "โอนเงิน";
    else if (inp == "CREDIT") return "เงินเชื่อ";
    else if (inp == "WALLET") return "Wallet";
    else return "";
  };

  const searchRound = (e) => {
    e.preventDefault();

    setRoundList([]);

    if (txtRound.current.value == "") return;

    PostData(mod, "roundsearch", {
      token: app.token,
      lang: app.lang,
      search: txtRound.current.value,
      start: Func.dateTimeFormat(find.round_start.toDate()),
      stop: Func.dateTimeFormat(find.round_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRoundList((roundList) => [
            ...roundList,
            [
              <Button simple color="github" onClick={() => findRound(item)}>
                {item.id}
              </Button>,
              <Button simple color="github" onClick={() => findRound(item)}>
                <i className="far fa-user" /> {item.emp_name}
              </Button>,
              displayStatus(item),
              <div>
                <div>เข้า : {dateTimeDisplay(item.date_start)}</div>
                <div>ออก : {dateTimeDisplay(item.date_stop)}</div>
              </div>,
            ],
          ]);
        });
      }
    });
  };

  const searchRoundDate = (data) => {
    setRoundList([]);

    PostData(mod, "roundsearch", {
      token: app.token,
      lang: app.lang,
      search: txtRound.current.value,
      start: Func.dateTimeFormat(data.round_start.toDate()),
      stop: Func.dateTimeFormat(data.round_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRoundList((roundList) => [
            ...roundList,
            [
              <Button simple color="github" onClick={() => findRound(item)}>
                {item.id}
              </Button>,
              <Button simple color="github" onClick={() => findRound(item)}>
                <i className="far fa-user" /> {item.emp_name}
              </Button>,
              displayStatus(item),
              <div>
                <div>เข้า : {dateTimeDisplay(item.date_start)}</div>
                <div>ออก : {dateTimeDisplay(item.date_stop)}</div>
              </div>,
            ],
          ]);
        });
      }
    });
  };

  const displayName = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="far fa-user" /> {item.name}
      </Button>
    );
  };

  const displayTel = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="fas fa-phone-volume" /> {item.tel}
      </Button>
    );
  };

  const displaySelect = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="fas fa-chevron-right" />
      </Button>
    );
  };

  const findMember = (item) => {
    setFind({ ...find, member: item.code, member_name: item.name });

    setModalCustomer(false);
  };

  const findRound = (item) => {
    setFind({
      ...find,
      shift_code: item.code,
      round: `${item.id} - ${item.emp_name}`,
    });

    setModalRound(false);
  };

  const displayStatus = (item) => {
    return (
      <code className={`color-${item.color}`}>
        <i className={item.icon} /> {item.status_display}
      </code>
    );
  };

  const renderSearchDate = () => {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
      "5 Days": [moment(start).subtract(5, "days"), moment(end)],
      "1 Week": [moment(start).subtract(7, "days"), moment(end)],
      "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
      "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    };
    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={find.date_start}
          end={find.date_stop}
          local={{ format: "DD/MM/YYYY HH:mm", sundayFirst: false }}
          applyCallback={(start, stop) => {
            setFind({ ...find, date_start: start, date_stop: stop });
          }}
        >
          <TextField
            fullWidth
            value={`${find.date_start.format("DD/MM/YYYY HH:mm")} 
            ${t(`element.to`)} 
            ${find.date_stop.format("DD/MM/YYYY HH:mm")}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t("element.date")} :
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </DateTimeRangeContainer>
      </div>
    );
  };

  const renderDateRound = () => {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
      "5 Days": [moment(start).subtract(5, "days"), moment(end)],
      "1 Week": [moment(start).subtract(7, "days"), moment(end)],
      "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
      "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    };
    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={find.round_start}
          end={find.round_stop}
          local={{ format: "DD/MM/YYYY HH:mm", sundayFirst: false }}
          applyCallback={(start, stop) => {
            setFind({ ...find, round_start: start, round_stop: stop });
            searchRoundDate({ ...find, round_start: start, round_stop: stop });
          }}
        >
          <TextField
            fullWidth
            value={`${find.round_start.format("DD/MM/YYYY HH:mm")} 
            ${t(`element.to`)} 
            ${find.round_stop.format("DD/MM/YYYY HH:mm")}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t("element.date")} :
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </DateTimeRangeContainer>
      </div>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.boxPrimary}>
            <CardHeader color="primary" icon className={classes.boxHead}>
              <h4 className={classes.boxTitle}>
                <i className="fas fa-file-alt color-primary" />
                {t("element.report")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={12} md={6}>
                  {renderSearchDate()}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <TextField
                    fullWidth
                    InputProps={{
                      value: find.round,
                      startAdornment: (
                        <InputAdornment position="start">รอบ :</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      readOnly: true,
                    }}
                    onClick={() => {
                      setModalRound(true);

                      setTimeout(function () {
                        txtRound.current.select();
                      }, 500);
                    }}
                  />
                </GridItem>
                <GridItem xs={12}></GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.branch}
                      onChange={(e) => {
                        setFind({ ...find, branch: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.branch`)}
                      </MenuItem>
                      {cboBranch.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        {t(`${prefix}.status`)}
                      </MenuItem>
                      {cboStatus.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Select
                      value={find.account}
                      onChange={(e) => {
                        setFind({
                          ...find,
                          account: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        บัญชีโอน
                      </MenuItem>
                      {cboAccount.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} className={classes.center}>
                  <Button color="info" onClick={() => view(find)}>
                    <i className="fas fa-file-alt fa-fw" /> Show report
                  </Button>
                  <Button color="success" onClick={() => excel(find)}>
                    <i className="fas fa-file-excel fa-fw" /> Export To Excel
                  </Button>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                      renderHeader(header[8]),
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[7]}
                    colorsColls={["primary"]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalCustomer = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalCustomer}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalCustomer(false)}
        aria-labelledby="modal-customer-title"
        aria-describedby="modal-customer-description"
      >
        <DialogTitle
          id="modal-customer-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalCustomer(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t(`${prefix}.member`)}</h4>
        </DialogTitle>
        <DialogContent id="modal-customer" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchCustomer}>
                <CustomInput
                  labelText={t("element.search")}
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtMember,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>
              <Table
                hover
                tableData={memberList}
                customHeadClassesForCells={[2]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[2]}
                customCellClasses={[classes.right]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              view({ ...find, member: "ALL", member_name: "" });
              setModalCustomer(false);
            }}
            color="info"
          >
            <PeopleOutlineIcon /> {t(`report.showall`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderModalRound = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalRound}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalRound(false)}
        aria-labelledby="modal-round-title"
        aria-describedby="modal-round-description"
      >
        <DialogTitle
          id="modal-round-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalRound(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>รอบ</h4>
        </DialogTitle>
        <DialogContent id="modal-round" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchRound}>
                <CustomInput
                  labelText="พนักงาน"
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtRound,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>{renderDateRound()}</GridItem>
            <GridItem xs={12}>
              <Table
                hover
                tableData={roundList}
                customHeadClassesForCells={[2]}
                customHeadCellClasses={[classes.center]}
                customClassesForCells={[2]}
                customCellClasses={[classes.center]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              view({ ...find, shift_code: "ALL", round: "" });
              setModalRound(false);
            }}
            color="info"
          >
            <PeopleOutlineIcon /> {t(`report.showall`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderModalList = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalList}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModalList(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-list-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalList(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{attr.id}</h4>
        </DialogTitle>
        <DialogContent
          id="modal-list"
          className={classes.modalBody}
          style={{ height: "700px", overflowY: "scroll", width: "100%" }}
        >
          {renderStatus()}
          <CustomTabs
            headerColor="rose"
            value={tab}
            changeValue={changeTab}
            tabs={[
              {
                tabName: t("element.order"),
                tabIcon: ListAltIcon,
                tabContent: renderOrder(),
              },
              {
                tabName: t("element.info"),
                tabIcon: ListAltIcon,
                tabContent: renderInfo(),
              },
            ]}
          />
          {renderDetail()}
        </DialogContent>
      </Dialog>
    );
  };

  const renderDetail = () => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.user_create")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_create}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <PersonOutlineOutlinedIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.date_create")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                {Func.dateTimeDisplay(attr.date_create)}
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.user_update")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>{attr.user_update}</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <Card>
            <CardHeader icon>
              <CardIcon color="warning">
                <DateRangeOutlinedIcon />
              </CardIcon>
              <h4
                className={classes.cardIconTitle}
                style={{ textAlign: "left" }}
              >
                {t("attr.date_update")}
              </h4>
            </CardHeader>
            <CardBody>
              <p className={classes.statusCenter}>
                {Func.dateTimeDisplay(attr.date_update)}
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderOrder = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Table
            striped
            hover
            tableHead={[
              t(`${prefix}.num`),
              t(`${prefix}.image`),
              t(`${prefix}.product_id`),
              t(`${prefix}.product_name`),
              t(`${prefix}.unit`),
              t(`${prefix}.price`),
              t(`${prefix}.quantity`),
              t(`${prefix}.amount`),
              "ปริมาณเดิม",
              t(`${prefix}.return`),
            ]}
            tableData={list}
            customHeadClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customHeadCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            customClassesForCells={[0, 1, 4, 5, 6, 7, 8, 9]}
            customCellClasses={[
              classes.center,
              classes.center,
              classes.center,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
              classes.right,
            ]}
            coloredColls={[5, 6, 7]}
            colorsColls={["primary", "primary", "success"]}
          />
        </GridItem>
      </GridContainer>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={6}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText={t(`${prefix}.branch`)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.branch_name,
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText="ประเภท"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: displayPayment(attr.payment_main),
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={4}>
                <CustomInput
                  labelText={t(`${prefix}.payment_type`)}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: displayPayment(attr.payment_type),
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.labelDpk}>
                  {t(`${prefix}.date_record`)}
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "__/__/____",
                    }}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    value={attr.date_record}
                    onChange={(value) => {
                      setAttr({ ...attr, date_record: value });
                    }}
                    className={classes.center}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.labelDpk}>
                  {t(`${prefix}.date_due`)}
                </InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{
                      placeholder: "__/__/____",
                    }}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect
                    value={attr.date_due}
                    onChange={(value) => {
                      setAttr({ ...attr, date_due: value });
                    }}
                    className={classes.center}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText={t(`${prefix}.remark`)}
                  id="remark"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.remark,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText="หมายเหตุ ยกเลิก"
                  id="remark_cancel"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.remark_cancel,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6}>
            {renderShipping()}
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderShipping = () => {
    return (
      <GridContainer>
        <GridItem xs={12} style={{ marginTop: "10px" }}>
          <div>
            <div style={{ textAlign: "left" }}>
              <FormControlLabel
                control={
                  <Radio
                    checked={attr.transfer === "1"}
                    onChange={(e) =>
                      setAttr({ ...attr, transfer: e.target.value })
                    }
                    value="1"
                    name="transfer_1"
                    aria-label="1"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="รับของทันที"
              />
            </div>
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
              style={{ textAlign: "left" }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={attr.transfer === "2"}
                    onChange={(e) =>
                      setAttr({ ...attr, transfer: e.target.value })
                    }
                    value="2"
                    name="transfer_2"
                    aria-label="2"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="รับของที่สาขา"
              />
            </div>
            {attr.transfer === "2" ? (
              <Card style={{ marginTop: 0 }}>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="branch_warehouse"
                          className={classes.labelSelect}
                        >
                          {t(`${prefix}.branch`)}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={attr.branch_transfer}
                          inputProps={{
                            name: "branch_transfer",
                          }}
                          onChange={(e) => {
                            setAttr({
                              ...attr,
                              branch_transfer: e.target.value,
                            });
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="0"
                          >
                            {t(`${prefix}.branch`)}
                          </MenuItem>
                          {cboBranch.map((item) => (
                            <MenuItem
                              key={item.code}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.code}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <InputLabel className={classes.labelDpk}>
                        วันจัดส่ง
                      </InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          inputProps={{
                            placeholder: "__/__/____",
                          }}
                          dateFormat="DD/MM/yyyy"
                          closeOnSelect
                          value={attr.date_shipping}
                          onChange={(value) => {
                            setAttr({ ...attr, date_shipping: value });
                          }}
                          className={classes.center}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={`เวลาจัดส่ง`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: e.target.value,
                            }),
                          onBlur: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: Func.timeSetup(e.target.value),
                            }),
                          value: attr.time_shipping,
                        }}
                        alignCenter
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            ) : null}
            <div
              className={
                classes.checkboxAndRadio +
                " " +
                classes.checkboxAndRadioHorizontal
              }
              style={{ textAlign: "left" }}
            >
              <FormControlLabel
                control={
                  <Radio
                    checked={attr.transfer === "3"}
                    onChange={(e) =>
                      setAttr({ ...attr, transfer: e.target.value })
                    }
                    value="3"
                    name="transfer_3"
                    aria-label="3"
                    icon={
                      <FiberManualRecord className={classes.radioUnchecked} />
                    }
                    checkedIcon={
                      <FiberManualRecord className={classes.radioChecked} />
                    }
                    classes={{
                      checked: classes.radio,
                      root: classes.radioRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                  root: classes.labelRoot,
                }}
                label="จัดส่งสินค้า"
              />
            </div>
            {attr.transfer === "3" ? (
              <Card style={{ marginTop: 0 }}>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="branch_warehouse"
                          className={classes.labelSelect}
                        >
                          คลังจัดส่ง
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu,
                          }}
                          classes={{
                            select: classes.select,
                          }}
                          value={attr.branch_warehouse}
                          inputProps={{
                            name: "branch_warehouse",
                          }}
                          onChange={(e) => {
                            setAttr({
                              ...attr,
                              branch_warehouse: e.target.value,
                            });
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value="0"
                          >
                            คลังจัดส่ง
                          </MenuItem>
                          {cboBranch.map((item) => (
                            <MenuItem
                              key={item.code}
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected,
                              }}
                              value={item.code}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={`ค่าจัดส่ง`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({
                              ...attr,
                              shipping_price: e.target.value,
                            }),
                          value: attr.shipping_price,
                        }}
                        alignCenter
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <InputLabel className={classes.labelDpk}>
                        วันจัดส่ง
                      </InputLabel>
                      <FormControl fullWidth>
                        <Datetime
                          timeFormat={false}
                          inputProps={{
                            placeholder: "__/__/____",
                          }}
                          dateFormat="DD/MM/yyyy"
                          closeOnSelect
                          value={attr.date_shipping}
                          onChange={(value) => {
                            setAttr({ ...attr, date_shipping: value });
                          }}
                          className={classes.center}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={`เวลาจัดส่ง`}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: e.target.value,
                            }),
                          onBlur: (e) =>
                            setAttr({
                              ...attr,
                              time_shipping: Func.timeSetup(e.target.value),
                            }),
                          value: attr.time_shipping,
                        }}
                        alignCenter
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        labelText={`ที่อยู่จัดส่ง`}
                        id="address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) =>
                            setAttr({ ...attr, address: e.target.value }),
                          value: attr.address,
                          multiline: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            ) : null}
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  const renderStatus = () => {
    return (
      <Stepper
        activeStep={attr.status * 1}
        alternativeLabel
        className={classes.bgTransparent}
        style={{ paddingBottom: 0 }}
      >
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_1`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_1}</p>
            <p className={classes.colorDefault}>{attr.approve_date_1}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_2`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_2}</p>
            <p className={classes.colorDefault}>{attr.approve_date_2}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              <b>{t(`${prefix}.step_3`)}</b>
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_3}</p>
            <p className={classes.colorDefault}>{attr.approve_date_3}</p>
          </StepLabel>
        </Step>
        <Step>
          <StepLabel StepIconComponent={StepIcon}>
            <p>
              {attr.status == 9 ? (
                <Badge color="danger">{t(`${prefix}.step_9`)}</Badge>
              ) : (
                <b>{t(`${prefix}.step_9`)}</b>
              )}
            </p>
            <p className={classes.colorDefault}>{attr.approve_user_9}</p>
            <p className={classes.colorDefault}>{attr.approve_date_9}</p>
          </StepLabel>
        </Step>
      </Stepper>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalList()}
      {renderModalCustomer()}
      {renderModalRound()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(RpPayTransfer);
