import {
  cardTitle,
  defaultFont,
  grayColor,
  dangerColor,
  successColor,
  warningColor,
  infoColor,
  primaryColor,
} from "assets/jss/material-dashboard-pro-react.js";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.js";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const notificationsStyle = (theme) => ({
  ...customCheckboxRadioSwitch,
  ...hoverCardStyle,
  cardTitle: {
    marginTop: "0",
    marginBottom: "3px",
    color: grayColor[2],
    fontSize: "18px",
  },
  cardHeader: {
    zIndex: "3",
  },
  cardSubtitle: {
    ...defaultFont,
    color: grayColor[0],
    fontSize: "14px",
    margin: "0 0 10px",
  },
  center: {
    textAlign: "center !important",
  },
  right: {
    textAlign: "right !important",
  },
  left: {
    textAlign: "left",
  },
  marginRight: {
    marginRight: "5px",
  },
  modalSectionTitle: {
    marginTop: "30px",
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px",
    },
  },
  cardIconTitle: {
    ...cardTitle,
    color: "#3C4858",
    marginTop: "15px",
    marginBottom: "0px",
  },
  boxDefault: {
    marginTop: "10px",
    borderTop: "3px solid #999999",
  },
  boxPrimary: {
    marginTop: "10px",
    borderTop: "3px solid #007bff",
  },
  boxInfo: {
    marginTop: "10px",
    borderTop: "3px solid #17a2b8",
  },
  boxSuccess: {
    marginTop: "10px",
    borderTop: "3px solid #28a745",
  },
  boxWarning: {
    marginTop: "10px",
    borderTop: "3px solid #ffc107",
  },
  boxDanger: {
    marginTop: "10px",
    borderTop: "3px solid #dc3545",
  },
  boxHead: {
    borderBottom: "1px solid rgba(0,0,0,.125)",
    margin: "0 !important",
    padding: "10px 15px !important",
  },
  boxTitle: {
    ...cardTitle,
    color: "#3C4858",
    fontWeight: "500",
    margin: "0",
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0",
  },
  statusCenter: {
    color: grayColor[1],
    textAlign: "center",
    fontSize: "15px",
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "200",
    fontSize: "18px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },
  labelDpk: {
    ...defaultFont,
    marginTop: "12px",
    color: grayColor[3] + " !important",
    fontWeight: "200",
    fontSize: "13px",
    lineHeight: "1.42857",
    top: "10px",
    textAlign: "left",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },
  labelSelect: {
    ...defaultFont,
    fontSize: "17px",
    textTransform: "uppercase",
    color: grayColor[3] + " !important",
    top: "2px",
  },
  textWrap: {
    whiteSpace: "nowrap",
    width: "180px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modalTitle: {
    textAlign: "left !important",
  },
  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px",
    },
  },
  bgTransparent: {
    background: "transparent",
  },
  colorPrimary: {
    color: primaryColor[2] + " !important",
  },
  colorInfo: {
    color: infoColor[4] + " !important",
  },
  colorSuccess: {
    color: successColor[4] + " !important",
  },
  colorWarning: {
    color: warningColor[4] + " !important",
  },
  colorDanger: {
    color: dangerColor[4] + " !important",
  },
  colorDefault: {
    color: grayColor[6] + " !important",
  },
  colorGray: {
    color: grayColor[0] + " !important",
  },
  colorWhite: {
    color: "#ffffff !important",
  },
  noMargin: {
    margin: "0",
  },
  dpk: {
    marginTop: "27px",
    background: "pink",
  },
  ...customSelectStyle,
  ...buttonStyle,
  ...modalStyle(theme),
});

export default notificationsStyle;
