/*eslint-disable*/
import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

/* ========== ZONE Function ========== */
function TransferPrintInvoice({ t, attr, list }) {
  const mod = "posbill";
  const prefix = "bill";
  const classes = useStyles();

  const quantityDisplay = (num) => {
    const fl = Math.floor(num);
    if (fl == num) return Func.numberDisplay(num, 0);
    else return Func.numberDisplay(num);
  };

  const renderHeader = () => {
    return (
      <table width="100%">
        <tbody>
          <tr>
            <td align="center">
              <h3>ใบแจ้งหนี้</h3>
            </td>
          </tr>
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
          <tr>
            <td align="left">รถส่งของ: {attr.car_name}</td>
          </tr>
          <tr>
            <td align="left">วันที่: {Func.dateDisplay(attr.date_bill, 3)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderOrder = () => {
    return (
      <table width="100%" cellPadding={0} cellSpacing={0}>
        <thead>
          <tr>
            <th align="left" valign="top" colSpan={2}>
              รายการ
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, i) => (
            <tr key={item.code}>
              <td>
                <p>
                  {item.date_record} : {item.balance}
                </p>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              <p></p>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderSummary = () => {
    return (
      <table width="100%">
        <thead>
          <tr>
            <th align="right" width="70%">
              รวม
            </th>
            <th align="right">{Func.numberDisplay(attr.balance)}</th>
          </tr>
        </thead>
      </table>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div className="print">
      <div className="slips">
        {renderHeader()}
        {renderOrder()}
        {renderSummary()}
      </div>
    </div>
  );
}

export default withNamespaces()(TransferPrintInvoice);

TransferPrintInvoice.defaultProps = {
  attr: {},
  list: [],
};
