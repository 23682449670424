/*eslint-disable*/
import React from "react";

// @material-ui/icons

// core components
import * as Func from "services/Function";
import { withNamespaces } from "react-i18next";

import "views/Print/PrintStyle.css";

/* ========== ZONE Function ========== */
function PrintReceiptPay({ t, attr, list }) {
    const prefix = "bill";

    const renderHeader = () => {
        return (
            <div>
                <table width="100%" cellPadding={5}>
                    <tbody>
                        <tr>
                            <td align="center" colSpan={2}>
                                <h3>ห้างหุ้นส่วนจำกัด เส้นก๋วยเตี๋ยว ไทยเจริญผล</h3>
                            </td>
                        </tr>
                        <tr>
                            <td align="center" width="85%">
                                เลขที่ 99/1 หมู่ที่ 14 ต.ท่าช้าง อ.บางกล่ำ จ.สงขลา
                                โทร.081-5997507
                            </td>
                            <td>
                                {attr.branch_code === "1" ? (
                                    <i className="far fa-check-square" />
                                ) : (
                                    <i className="far fa-square" />
                                )}{" "}
                                สำนักงานใหญ่
                            </td>
                        </tr>
                        <tr>
                            <td align="center">
                                เลขที่ 11/1 ถ.พูลสุวรรณ ต.หาดใหญ่ อ.หาดใหญ่ จ.สงขลา
                                โทร.074-245879
                            </td>
                            <td>
                                {attr.branch_code === "2" ? (
                                    <i className="far fa-check-square" />
                                ) : (
                                    <i className="far fa-square" />
                                )}{" "}
                                สาขาที่ 1
                            </td>
                        </tr>
                        <tr>
                            <td align="center" colSpan={2}>
                                เลขประจำตัวผู้เสียภาษี 0 9035 60003 50 6
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" cellPadding={5}>
                    <tbody>
                        <tr>
                            <td align="center" colSpan={2}>
                                <h3>ใบเสร็จรับเงิน</h3>
                            </td>
                        </tr>
                        <tr>
                            <td align="center" width="50%">
                                {attr.forcustomer === "Y" ? "สำหรับลูกค้า" : "สำหรับบริษัท"}
                            </td>
                            <td align="right">เลขที่ {attr.id}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} align="right">วันที่ {attr.date_bill}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>ลูกค้า {attr.company_name}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                ที่อยู่ {attr.address} {attr.district} {attr.city}{" "}
                                {attr.province_name} {attr.zipcode}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <div className="line"></div>
                            </td>
                        </tr>
                        <tr>
                            <td>เลขประจำตัวผู้เสียภาษีอากร {attr.company_id}</td>
                            <td>
                                <i className="far fa-square" /> สำนักงานใหญ่{" "}
                                <i className="far fa-square" /> สาขาที่ 1
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%" cellPadding={5}>
                    <tbody>
                        <tr>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    const renderOrder = () => {
        return (
            <table width="100%" cellPadding={5} border="1">
                <thead>
                    <tr>
                        <th width="10%">ลำดับที่</th>
                        <th colSpan={2}>รายการ</th>
                        <th width="15%">
                            จำนวนเงิน
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, i) => (
                        item.addrow === "N" ?
                            <tr key={item.code}>
                                <td align="center" valign="top">
                                    {i + 1}
                                </td>
                                <td valign="top" colSpan={2}>
                                    อ้างถึงใบแจ้งหนี้เลขที่ : {item.so_id}
                                    {item.finish_pay === "N" ? " บางส่วน" : ""}
                                </td>
                                <td align="right" valign="top">
                                    {Func.numberDisplay(item.pay)}
                                </td>
                            </tr> : <tr key={item.code}>
                                <td>&nbsp;</td>
                                <td colSpan={2}>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                    ))}
                </tbody>
                <tbody>
                    <tr>
                        <td colSpan={2} valign="bottom" className="boxtext">
                            <div>จำนวนเงินรวมทั้งสิ้น (อักษร)</div>
                            <div className="pricetext">{attr.pricetext}</div>
                        </td>
                        <th align="right">{t(`${prefix}.total`)}</th>
                        <td align="right">{Func.numberDisplay(attr.amount)}</td>
                    </tr>
                </tbody>
            </table>
        );
    };

    const renderFooter = () => {
        return (
            <table width="100%" colSpan={5}>
                <tbody>
                    <tr>
                        <td colSpan={2}>
                            <br />
                            <br />
                            <br />
                        </td>
                    </tr>
                    <tr>
                        <td align="center" width="50%">
                            ผู้รับสินค้า ....................................................
                        </td>
                        <td align="center">
                            ผู้รับเงิน ....................................................
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    };

    /* ========== ZONE Render ========== */
    return (
        <div className="print">
            <div className="a4">
                {renderHeader()}
                {renderOrder()}
                {renderFooter()}
            </div>
        </div>
    );
}

export default withNamespaces()(PrintReceiptPay);

PrintReceiptPay.defaultProps = {
    attr: {},
    list: [],
};
