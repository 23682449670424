/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/AddCircle";
import CloseIcon from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  product_code: "",
  minstock: "",
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "asc",
  status: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function SupplierProduct({ t, id }) {
  const mod = "supplierproduct";
  const prefix = "attr";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.code", name: t(`${prefix}.code`), order: "asc" },
    { code: "pd.filepic", name: "รูป" },
    { code: "pd.name_th", name: "สินค้า" },
    { code: "pd.kind", name: "ประเภท" },
    { code: "pd.cost", name: "ราคา" },
    { code: "pd.minstock", name: "ปริมาณขั้นต่ำ" },
  ];
  const alignValue = [0, 1, 3, 4, 5, 6];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const txtSearchList = useRef(null);
  const [searchList, setSearchList] = useState("");
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [order, setOrder] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      status: data.status,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.code,
              <img src={item.filepic} width="30" />,
              btnOpen(item),
              kindDisplay(item),
              Func.numberDisplay(item.cost),
              Func.numberDisplay(item.minstock),
              btn(item),
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const btnOpen = (item) => {
    return (
      <NavLink to={`/admin/product/${item.code}`}>
        <Button color="primary" simple>
          {item.product_name}
        </Button>
      </NavLink>
    );
  };

  const del = (item) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title={t("alert.confirm_delete_title")}
        onConfirm={() => {
          setAlert(null);
          setLoading(true);

          PostData(mod, "delete", {
            token: app.token,
            lang: app.lang,
            code: item.code,
          }).then((result) => {
            setLoading(false);

            if (result.status === "OK") {
              view(find);
              showNotify(t("alert.deletecomplete"), "success");
            } else {
              showNotify(t("alert.deleteerror"), "danger");
            }
          });
        }}
        onCancel={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={t("alert.ok")}
        cancelBtnText={t("alert.cancel")}
        showCancel
      >
        {t("alert.confirm_delete_msg")} #{item.code}
      </SweetAlert>
    );
  };

  const kindDisplay = (item) => {
    if (item.kind === "RM")
      return <code className="color-danger">Raw material</code>;
    if (item.kind === "FG")
      return <code className="color-success">Finished goods</code>;
    else return "";
  };

  const add = (item) => {
    if (item.code <= 0) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setAlert(null);
            setModalAdd(false);
            setLoading(true);

            PostData(mod, "add", {
              token: app.token,
              lang: app.lang,
              data: {
                supplier_code: id,
                product_code: item.code,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                view(find);
                showNotify(t("alert.addcomplete"), "success");
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const searchOrder = (search) => {
    const btnOrder = (item) => {
      return (
        <div>
          <Button
            round
            color="success"
            className={classes.actionButton + " " + classes.actionButtonRound}
            onClick={() => add(item)}
          >
            <AddIcon className={classes.icon} />
          </Button>
        </div>
      );
    };

    PostData(mod, "searchproduct", {
      token: app.token,
      lang: app.lang,
      search: search,
    }).then((result) => {
      if (result.status === "OK") {
        setOrder([]);

        result.data.map((item, i) => {
          setOrder((order) => [
            ...order,
            [
              <img src={item.filepic} width="30" />,
              item.name,
              kindDisplay(item),
              btnOrder(item),
            ],
          ]);
        });
      }
    });
  };

  const btnAddList = () => {
    return (
      <Button
        round
        color="info"
        size="sm"
        onClick={() => {
          searchOrder(searchList);
          setModalAdd(true);

          setTimeout(function () {
            txtSearchList.current.select();
          }, 500);
        }}
      >
        <AddIcon className={classes.icon} /> {t("element.add")}
      </Button>
    );
  };

  const btn = (item) => {
    return (
      <>
        <Button
          round
          color="danger"
          className={classes.actionButton + " " + classes.actionButtonRound}
          onClick={() => del(item)}
        >
          <DeleteIcon className={classes.icon} />
        </Button>
      </>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <br />
        </GridItem>
        <GridItem xs={12}>
          <GridContainer className="viewlist">
            <GridItem xs={12} sm={6} md={6}>
              <TextField
                id="search"
                fullWidth
                InputProps={{
                  onChange: (e) => {
                    view({ ...find, search: e.target.value, page: 1 });
                    setFind({ ...find, search: e.target.value, page: 1 });
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      {t("element.search")} :
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableHead={[
                  renderHeader(header[0]),
                  renderHeader(header[1]),
                  renderHeader(header[2]),
                  renderHeader(header[3]),
                  renderHeader(header[4]),
                  renderHeader(header[5]),
                  btnAddList(),
                ]}
                tableData={record}
                customHeadClassesForCells={alignValue}
                customHeadCellClasses={alignItem}
                customClassesForCells={alignValue}
                customCellClasses={alignItem}
                coloredColls={[4, 5]}
                colorsColls={["primary", "danger"]}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={4} lg={3}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel className={classes.selectLabel}></InputLabel>
                <Select
                  value={find.perPage}
                  onChange={(e) => {
                    view({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                    setFind({
                      ...find,
                      page: 1,
                      perPage: e.target.value,
                      page: 1,
                    });
                  }}
                >
                  {cboPerPage &&
                    cboPerPage.map((item) => (
                      <MenuItem
                        key={item.id}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={item.id}
                      >
                        {t(item.name)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
              <Pagination
                style={{ float: "right", marginTop: "20px" }}
                count={pageTotal}
                showFirstButton
                showLastButton
                page={pageNo}
                onChange={(e, p) => {
                  view({ ...find, page: p });
                  setFind({ ...find, page: p });
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalAdd = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalAdd}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalAdd(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-add-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalAdd(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t("element.add")}</h4>
        </DialogTitle>
        <DialogContent id="modal-add" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                labelText={t("element.search")}
                id="search_order"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  inputRef: txtSearchList,
                  onChange: (e) => {
                    setSearchList(e.target.value);
                    searchOrder(e.target.value);
                  },
                  value: searchList,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <Table
                striped
                hover
                tableData={order}
                customHeadClassesForCells={[2, 3]}
                customHeadCellClasses={[classes.right, classes.right]}
                customClassesForCells={[2, 3]}
                customCellClasses={[classes.right, classes.right]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalAdd()}
      {renderLoading()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(SupplierProduct);
