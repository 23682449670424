import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import AddAlert from "@material-ui/icons/AddAlert";
import Check from "@material-ui/icons/Check";

// core components
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import i18n from "components/Translation/Translate.js";

import { PostData } from "services/Request";
import { setUser } from "redux/actions/UserAction";
import { setPerm } from "redux/actions/PermAction";
import { setLanguage } from "redux/actions/AppStateAction";
import { useDispatch } from "react-redux";
import { usePageName } from "react-page-name";
import { withNamespaces } from "react-i18next";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

function Login({ t }) {
  const mod = "authen";
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const app = useSelector((state) => state.appState);
  const users = useSelector((state) => state.user);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [alert, setAlert] = useState(null);
  const [btn, setBtn] = useState(false);

  usePageName(t("app.name") + " :: " + t(`${mod}.title`));

  useEffect(() => {
    i18n.changeLanguage(app.lang);

    if (users.remember) {
      setUsername(users.username);
      setPassword(users.password);
      setRemember(true);
    } else {
      setUsername("");
      setPassword("");
      setRemember(false);
    }
  }, []);

  const signIn = (event) => {
    setAlert(null);
    event.preventDefault();

    if (!btn) {
      setBtn(true);

      PostData(mod, "signin", {
        username: username,
        password: password,
      }).then((result) => {
        if (result.status === "OK") {
          if (remember) {
            dispatch(
              setUser({
                ...result.data,
                remember: true,
                username: username,
                password: password,
              })
            );
          } else {
            dispatch(
              setUser({
                ...result.data,
                remember: false,
                username: "",
                password: "",
              })
            );
          }

          changeLanguage(result.data.language);
          dispatch(
            setPerm({
              menu: result.menu,
              perm: result.perm,
            })
          );

          // history.push("/admin/dashboard");
          window.location.replace("/admin/dashboard");
        } else {
          setBtn(false);
          showError(t(`${mod}.${result.msg}`));
        }
      });
    }
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    dispatch(setLanguage(lang));
  };

  const showError = (msg) => {
    setAlert(
      <CardFooter className={classes.justifyContentCenter}>
        <SnackbarContent message={msg} icon={AddAlert} color="rose" />
      </CardFooter>
    );
  };

  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form noValidate onSubmit={signIn}>
            <Card login>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>
                  {t(`${mod}.administrator`)}
                </h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText={t(`${mod}.username`)}
                  id="username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: username,
                    onChange: (event) => setUsername(event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText={t(`${mod}.password`)}
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: password,
                    onChange: (event) => setPassword(event.target.value),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
                <div className={classes.checkboxAndRadio}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={remember}
                        name="remember"
                        tabIndex={-1}
                        onChange={(e) => {
                          if (!e.target.checked) {
                            dispatch(
                              setUser({
                                ...users,
                                remember: false,
                                username: "",
                                password: "",
                              })
                            );
                            setUsername("");
                            setPassword("");
                          }
                          setRemember(e.target.checked);
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                      root: classes.labelRoot,
                    }}
                    label={"จำรหัสผ่าน"}
                  />
                </div>
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  color="primary"
                  simple
                  size="lg"
                  block
                  disabled={btn}
                  style={{ fontSize: "1.5em", padding: "0 0 10px 0" }}
                >
                  {t(`${mod}.signin`)}
                </Button>
              </CardFooter>
              {alert}
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withNamespaces()(Login);
