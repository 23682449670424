/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import SearchIcon from "@material-ui/icons/Search";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CloseIcon from "@material-ui/icons/Close";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from "components/Table/Table.js";
import CardHeader from "components/Card/CardHeader.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initFind = {
  search: "",
  date_start: moment(Func.dateAdd(-30)),
  date_stop: moment(Func.dateAdd(1)),
  round_start: moment(Func.dateAdd(-30)),
  round_stop: moment(Func.dateAdd(1)),
  order_by: "tb.date_create",
  order_sort: "desc",
  status: "ALL",
  emp: "ALL",
  round: "",
  shift_code: "ALL",
  product: "ALL",
  branch: "ALL",
  member: "ALL",
  member_name: "",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function RpAllLog({ t }) {
  const mod = "rp_alllog";
  const prefix = "report";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.id", name: t(`${prefix}.code`) },
    { code: "tb.logtype", name: `ประเภท` },
    { code: "tb.branch_code", name: `สาขา` },
    { code: "tb.date_create", name: "วันที่", order: "desc" },
    { code: "tb.emp_name", name: `พนักงาน` },
    { code: "tb.shift_code", name: `รอบ` },
    { code: "tb.date_start", name: `เข้างาน` },
    { code: "tb.date_stop", name: `ออกงาน` },
    { code: "tb.member_name", name: "หัวข้อ" },
    { code: "tb.amount", name: "จำนวน" },
    { code: "tb.status", name: "สถานะ" },
  ];
  const alignValue = [2, 3, 4, 5, 6, 9, 10];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);

  const txtRound = useRef(null);
  const txtMember = useRef(null);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [loading, setLoading] = useState(false);
  const [cboStatus, setCboStatus] = useState([]);
  const [cboEmp, setCboEmp] = useState([]);
  const [cboBranch, setCboBranch] = useState([]);
  const [modalRound, setModalRound] = useState(false);
  const [roundList, setRoundList] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [modalCustomer, setModalCustomer] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, []);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        setCboStatus(result.stat);
        setCboEmp(result.employee);
        setCboBranch(result.branch);
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const view = (data) => {
    setFind(data);
    setLoading(true);
    setRecord([]);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      start: Func.dateTimeFormat(data.date_start.toDate()),
      stop: Func.dateTimeFormat(data.date_stop.toDate()),
      status: data.status,
      emp: data.emp,
      shift_code: data.shift_code,
      product: data.product,
      branch: data.branch,
      member: data.member,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              item.id,
              displayType(item),
              item.branch_name,
              Func.dateTimeDisplay(item.date_create),
              item.emp_name,
              item.shift_id,
              Func.dateTimeDisplay(item.date_start),
              Func.dateTimeDisplay(item.date_stop),
              item.member_name,
              Func.numberDisplay(item.amount),
              displayStatus(item),
            ],
          ]);
        });

        if (result.data.length > 0)
          setRecord((record) => [
            ...record,
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              <b>Total</b>,
              <b>{Func.numberDisplay(result.sum.amount)}</b>,
              "",
            ],
          ]);

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const displayStatus = (item) => {
    switch (item.viewtype) {
      case "robot":
        return Func.checkDisplay(item.enable);
      default:
        return (
          <code className={`color-${item.status_color}`}>
            <i className={item.status_icon} /> {item.status_name}
          </code>
        );
    }
  };

  const searchCustomer = (e) => {
    e.preventDefault();

    setMemberList([]);

    if (txtMember.current.value == "") return;

    PostData(mod, "membersearch", {
      token: app.token,
      lang: app.lang,
      search: txtMember.current.value,
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setMemberList((memberList) => [
            ...memberList,
            [displayName(item), displayTel(item), displaySelect(item)],
          ]);
        });
      }
    });
  };

  const displayType = (item) => {
    return (
      <code className={`color-${item.type_color}`}>
        <i className={item.type_icon} /> {item.type_name}
      </code>
    );
  };

  const findRound = (item) => {
    view({
      ...find,
      shift_code: item.code,
      round: `${item.id} - ${item.emp_name}`,
    });

    setModalRound(false);
  };

  const searchRound = (e) => {
    e.preventDefault();

    setRoundList([]);

    if (txtRound.current.value == "") return;

    PostData(mod, "roundsearch", {
      token: app.token,
      lang: app.lang,
      search: txtRound.current.value,
      start: Func.dateTimeFormat(find.round_start.toDate()),
      stop: Func.dateTimeFormat(find.round_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRoundList((roundList) => [
            ...roundList,
            [
              <Button simple color="github" onClick={() => findRound(item)}>
                {item.id}
              </Button>,
              <Button simple color="github" onClick={() => findRound(item)}>
                <i className="far fa-user" /> {item.emp_name}
              </Button>,
              displayStatus(item),
              <div>
                <div>เข้า : {Func.dateTimeDisplay(item.date_start)}</div>
                <div>ออก : {Func.dateTimeDisplay(item.date_stop)}</div>
              </div>,
            ],
          ]);
        });
      }
    });
  };

  const searchRoundDate = (data) => {
    setRoundList([]);

    PostData(mod, "roundsearch", {
      token: app.token,
      lang: app.lang,
      search: txtRound.current.value,
      start: Func.dateTimeFormat(data.round_start.toDate()),
      stop: Func.dateTimeFormat(data.round_stop.toDate()),
    }).then((result) => {
      if (result.status === "OK") {
        result.data.map((item, i) => {
          setRoundList((roundList) => [
            ...roundList,
            [
              <Button simple color="github" onClick={() => findRound(item)}>
                {item.id}
              </Button>,
              <Button simple color="github" onClick={() => findRound(item)}>
                <i className="far fa-user" /> {item.emp_name}
              </Button>,
              displayStatus(item),
              <div>
                <div>เข้า : {dateTimeDisplay(item.date_start)}</div>
                <div>ออก : {dateTimeDisplay(item.date_stop)}</div>
              </div>,
            ],
          ]);
        });
      }
    });
  };

  const renderDateRound = () => {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
      "5 Days": [moment(start).subtract(5, "days"), moment(end)],
      "1 Week": [moment(start).subtract(7, "days"), moment(end)],
      "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
      "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    };
    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={find.round_start}
          end={find.round_stop}
          local={{ format: "DD/MM/YYYY HH:mm", sundayFirst: false }}
          applyCallback={(start, stop) => {
            setFind({ ...find, round_start: start, round_stop: stop });
            searchRoundDate({ ...find, round_start: start, round_stop: stop });
          }}
        >
          <TextField
            fullWidth
            value={`${find.round_start.format("DD/MM/YYYY HH:mm")} 
            ${t(`element.to`)} 
            ${find.round_stop.format("DD/MM/YYYY HH:mm")}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t("element.date")} :
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </DateTimeRangeContainer>
      </div>
    );
  };

  const displayName = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="far fa-user" /> {item.name}
      </Button>
    );
  };

  const displayTel = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="fas fa-phone-volume" /> {item.tel}
      </Button>
    );
  };

  const findMember = (item) => {
    view({ ...find, member: item.code, member_name: item.name });
    setFind({ ...find, member: item.code, member_name: item.name });

    setModalCustomer(false);
  };

  const displaySelect = (item) => {
    return (
      <Button simple color="github" onClick={() => findMember(item)}>
        <i className="fas fa-chevron-right" />
      </Button>
    );
  };

  const btnOpen = (item) => {
    return (
      <NavLink to={`/admin/${item.viewtype}/${item.code}`}>
        <Button
          color="primary"
          simple
          className={classes.actionButton + " " + classes.actionButtonRound}
        >
          {item.id}
        </Button>
      </NavLink>
    );
  };

  const renderSearchDate = () => {
    let now = new Date();
    let start = moment(
      new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
    );
    let end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
      "Today Only": [moment(start), moment(end)],
      "Yesterday Only": [
        moment(start).subtract(1, "days"),
        moment(end).subtract(1, "days"),
      ],
      "3 Days": [moment(start).subtract(3, "days"), moment(end)],
      "5 Days": [moment(start).subtract(5, "days"), moment(end)],
      "1 Week": [moment(start).subtract(7, "days"), moment(end)],
      "2 Weeks": [moment(start).subtract(14, "days"), moment(end)],
      "1 Month": [moment(start).subtract(1, "months"), moment(end)],
    };
    return (
      <div>
        <DateTimeRangeContainer
          ranges={ranges}
          start={find.date_start}
          end={find.date_stop}
          local={{ format: "DD/MM/YYYY HH:mm", sundayFirst: false }}
          applyCallback={(start, stop) => {
            view({ ...find, date_start: start, date_stop: stop });
            setFind({ ...find, date_start: start, date_stop: stop });
          }}
        >
          <TextField
            fullWidth
            value={`${find.date_start.format("DD/MM/YYYY HH:mm")} 
            ${t(`element.to`)} 
            ${find.date_stop.format("DD/MM/YYYY HH:mm")}`}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {t("element.date")} :
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <DateRangeIcon />
                </InputAdornment>
              ),
              autoComplete: "off",
              readOnly: true,
            }}
          />
        </DateTimeRangeContainer>
      </div>
    );
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const renderViewList = () => {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.boxPrimary}>
            <CardHeader color="primary" icon className={classes.boxHead}>
              <h4 className={classes.boxTitle}>
                <i className="fas fa-file-alt color-primary" />
                {t("element.report")}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer className="viewlist">
                <GridItem xs={12} sm={12} md={6}>
                  {renderSearchDate()}
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField
                    fullWidth
                    InputProps={{
                      value: find.round,
                      startAdornment: (
                        <InputAdornment position="start">รอบ :</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      readOnly: true,
                    }}
                    onClick={() => {
                      setModalRound(true);

                      setTimeout(function () {
                        txtRound.current.select();
                      }, 500);
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.status}
                      onChange={(e) => {
                        view({ ...find, status: e.target.value, page: 1 });
                        setFind({ ...find, status: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        ประเภท
                      </MenuItem>
                      {cboStatus &&
                        cboStatus.map((item) => (
                          <MenuItem
                            key={item.code}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.code}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.emp}
                      onChange={(e) => {
                        view({ ...find, emp: e.target.value, page: 1 });
                        setFind({ ...find, emp: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        พนักงาน
                      </MenuItem>
                      {cboEmp.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Select
                      value={find.branch}
                      onChange={(e) => {
                        view({ ...find, branch: e.target.value, page: 1 });
                        setFind({ ...find, branch: e.target.value, page: 1 });
                      }}
                    >
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value="ALL"
                      >
                        สาขา
                      </MenuItem>
                      {cboBranch.map((item) => (
                        <MenuItem
                          key={item.code}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={item.code}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                  <TextField
                    fullWidth
                    InputProps={{
                      value: find.member_name,
                      startAdornment: (
                        <InputAdornment position="start">
                          {t(`${prefix}.member`)} :
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                      readOnly: true,
                    }}
                    onClick={() => {
                      setModalCustomer(true);

                      setTimeout(function () {
                        txtMember.current.select();
                      }, 500);
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <Table
                    striped
                    hover
                    tableHead={[
                      renderHeader(header[0]),
                      renderHeader(header[1]),
                      renderHeader(header[2]),
                      renderHeader(header[3]),
                      renderHeader(header[4]),
                      renderHeader(header[5]),
                      renderHeader(header[6]),
                      renderHeader(header[7]),
                      renderHeader(header[8]),
                      renderHeader(header[9]),
                      renderHeader(header[10]),
                    ]}
                    tableData={record}
                    customHeadClassesForCells={alignValue}
                    customHeadCellClasses={alignItem}
                    customClassesForCells={alignValue}
                    customCellClasses={alignItem}
                    coloredColls={[9]}
                    colorsColls={["primary"]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel className={classes.selectLabel}></InputLabel>
                    <Select
                      value={find.perPage}
                      onChange={(e) => {
                        view({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                        setFind({
                          ...find,
                          page: 1,
                          perPage: e.target.value,
                          page: 1,
                        });
                      }}
                    >
                      {cboPerPage &&
                        cboPerPage.map((item) => (
                          <MenuItem
                            key={item.id}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected,
                            }}
                            value={item.id}
                          >
                            {t(item.name)}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={8}
                  lg={9}
                  className={classes.right}
                >
                  <Pagination
                    style={{ float: "right", marginTop: "20px" }}
                    count={pageTotal}
                    showFirstButton
                    showLastButton
                    page={pageNo}
                    onChange={(e, p) => {
                      view({ ...find, page: p });
                      setFind({ ...find, page: p });
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  };

  const renderModalRound = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalRound}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalRound(false)}
        aria-labelledby="modal-round-title"
        aria-describedby="modal-round-description"
      >
        <DialogTitle
          id="modal-round-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalRound(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>รอบ</h4>
        </DialogTitle>
        <DialogContent id="modal-round" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchRound}>
                <CustomInput
                  labelText="พนักงาน"
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtRound,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>{renderDateRound()}</GridItem>
            <GridItem xs={12}>
              <Table
                hover
                tableData={roundList}
                customHeadClassesForCells={[2]}
                customHeadCellClasses={[classes.center]}
                customClassesForCells={[2]}
                customCellClasses={[classes.center]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              view({ ...find, shift_code: "ALL", round: "" });
              setModalRound(false);
            }}
            color="info"
          >
            <PeopleOutlineIcon /> {t(`report.showall`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderModalCustomer = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modalCustomer}
        maxWidth={"sm"}
        fullWidth
        keepMounted
        onClose={() => setModalCustomer(false)}
        aria-labelledby="modal-customer-title"
        aria-describedby="modal-customer-description"
      >
        <DialogTitle
          id="modal-customer-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModalCustomer(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{t(`${prefix}.member`)}</h4>
        </DialogTitle>
        <DialogContent id="modal-customer" className={classes.modalBody}>
          <GridContainer>
            <GridItem xs={12}>
              <form noValidate onSubmit={searchCustomer}>
                <CustomInput
                  labelText={t("element.search")}
                  id="search"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtMember,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </form>
            </GridItem>
            <GridItem xs={12}>
              <Table
                hover
                tableData={memberList}
                customHeadClassesForCells={[2]}
                customHeadCellClasses={[classes.right]}
                customClassesForCells={[2]}
                customCellClasses={[classes.right]}
                coloredColls={[]}
                colorsColls={[]}
              />
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => {
              view({ ...find, member: "ALL", member_name: "" });
              setModalCustomer(false);
            }}
            color="info"
          >
            <PeopleOutlineIcon /> {t(`report.showall`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderModalRound()}
      {renderModalCustomer()}
      {renderLoading()}
    </div>
  );
}

export default withNamespaces()(RpAllLog);
