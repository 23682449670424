/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { NavLink, useParams } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Pagination from "@material-ui/lab/Pagination";
import InputAdornment from "@material-ui/core/InputAdornment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import SaveIcon from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";
import AlertIcon from "@material-ui/icons/AddAlert";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CloseIcon from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import i18n from "components/Translation/Translate.js";
import { withNamespaces } from "react-i18next";
import { PostData } from "services/Request";
import * as Func from "services/Function";

import styles from "assets/jss/main-style.js";

/* ========== ZONE Variable ========== */
const useStyles = makeStyles(styles);

const initAttr = {
  code: "",
  stock_type: "",
  payout_code: "",
  product_code: "",
  product_name: "",
  quantity: "",
  remark: "",
  enable: "Y",
};

const initFind = {
  search: "",
  order_by: "tb.code",
  order_sort: "desc",
  status: "ALL",
  page: 1,
  perPage: 10,
  pageTotal: 0,
};

/* ========== ZONE Function ========== */
function PayoutStock({ t }) {
  const mod = "payoutstock";
  const prefix = "attr";
  const classes = useStyles();

  const initHeader = [
    { code: "tb.filepic", name: `Image` },
    { code: "tb.code", name: t(`${prefix}.code`), order: "desc" },
    { code: "tb.stock_type", name: `Type` },
    { code: "tb.product_code", name: `สินค้า` },
    { code: "tb.remark", name: `หมายเหตุ` },
    { code: "tb.quantity", name: `ปริมาณ` },
    { code: "tb.wallet_before", name: `ก่อน` },
    { code: "tb.wallet_after", name: `หลัง` },
    { code: "pd.unit_code", name: `หน่วย` },
  ];
  const alignValue = [0, 1, 2, 5, 6, 7, 8, 9];
  const alignItem = [
    classes.center,
    classes.center,
    classes.center,
    classes.right,
    classes.right,
    classes.right,
    classes.center,
    classes.center,
  ];
  const cboPerPage = [
    { id: 10, name: "element.show_10_record" },
    { id: 20, name: "element.show_20_record" },
    { id: 100, name: "element.show_100_record" },
  ];

  const app = useSelector((state) => state.appState);
  const { id } = useParams();
  const txtSearchList = useRef(null);
  const [pageTotal, setPageTotal] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState([]);
  const [attr, setAttr] = useState(initAttr);
  const [find, setFind] = useState(initFind);
  const [header, setHeader] = useState(initHeader);
  const [alert, setAlert] = useState(null);
  const [notify, setNotify] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [searchList, setSearchList] = useState("");
  const [order, setOrder] = useState([]);

  useEffect(() => {
    i18n.changeLanguage(app.lang);
    init();
  }, [id]);

  const init = () => {
    PostData(mod, "init", {
      token: app.token,
      lang: app.lang,
    }).then((result) => {
      if (result.status === "OK") {
        view(find);
      } else {
        Func.exitApp();
      }
    });
  };

  const inputChange = (e) => {
    setAttr({ ...attr, [e.target.id]: e.target.value });
  };

  const view = (data) => {
    setLoading(true);
    PostData(mod, "view", {
      token: app.token,
      lang: app.lang,
      search: data.search,
      page: data.page,
      per_page: data.perPage,
      orderby: data.order_by,
      asc: data.order_sort,
      status: data.status,
      payout: id,
    }).then((result) => {
      if (result.status === "OK") {
        setRecord([]);

        result.data.map((item, i) => {
          setRecord((record) => [
            ...record,
            [
              <img src={item.filepic} width="30" />,
              item.code,
              displayType(item.stock_type),
              item.product_name,
              item.remark,
              item.quantity,
              item.quantity_before,
              item.quantity_after,
              item.unit_name,
              item.date_create,
            ],
          ]);
        });

        setPageTotal(result.total_pages);
        setPageNo(result.page);
      }
      setLoading(false);
    });
  };

  const displayType = (inp) => {
    if (inp == "ADD")
      return (
        <code className={`color-success`}>
          <i className="fad fa-plus-circle" /> เพิ่ม
        </code>
      );
    else if (inp == "REMOVE")
      return (
        <code className={`color-danger`}>
          <i className="fad fa-minus-circle" /> ลด
        </code>
      );
    else return null;
  };

  const checkEmpty = () => {
    let result = true;

    if (attr.product_code * 1 <= 0) {
      result = false;
    } else if (attr.quantity * 1 <= 0) {
      result = false;
    }

    return result;
  };

  const add = () => {
    if (!checkEmpty()) {
      showNotify(t("alert.pleaseinsert"), "danger");
    } else {
      setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title={t("alert.confirm_add_title")}
          onConfirm={() => {
            setModal(false);
            setAlert(null);
            setLoading(true);

            PostData(mod, "add", {
              code: id,
              quantity: attr.quantity,
              data: {
                payout_code: id,
                stock_type: attr.stock_type,
                product_code: attr.product_code,
                quantity: attr.quantity,
                remark: attr.remark,
              },
            }).then((result) => {
              setLoading(false);

              if (result.status === "OK") {
                showNotify(t("alert.addcomplete"), "success");
                view(find);
              } else {
                showNotify(t("alert.adderror"), "danger");
              }
            });
          }}
          onCancel={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          cancelBtnCssClass={classes.button + " " + classes.danger}
          confirmBtnText={t("alert.ok")}
          cancelBtnText={t("alert.cancel")}
          showCancel
        >
          {t("alert.confirm_add_msg")}
        </SweetAlert>
      );
    }
  };

  const showNotify = (msg, color) => {
    setNotify(
      <Snackbar
        place="tr"
        color={color}
        icon={AlertIcon}
        message={msg}
        open={true}
        closeNotification={() => setNotify(null)}
        close
      />
    );

    setTimeout(function () {
      setNotify(null);
    }, 3000);
  };

  const renderHeader = (data) => {
    let icon = <i className="fas fa-sort"></i>;
    if (data.order === "desc") {
      icon = <i className="fad fa-sort-up"></i>;
    } else if (data.order === "asc") {
      icon = <i className="fad fa-sort-down"></i>;
    } else {
      icon = <i className="fad fa-sort"></i>;
    }

    if (data.sort === undefined || data.sort)
      return (
        <div
          onClick={() => {
            let tmp = [];
            header.map((item, i) => {
              if (data.code === item.code) {
                const ordersort = item.order === "asc" ? "desc" : "asc";
                view({ ...find, order_sort: ordersort, order_by: data.code });
                setFind({
                  ...find,
                  order_sort: ordersort,
                  order_by: data.code,
                });
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: ordersort,
                  sort: item.sort,
                };
              } else {
                tmp[i] = {
                  code: item.code,
                  name: item.name,
                  order: "",
                  sort: item.sort,
                };
              }
            });

            setHeader(tmp);
          }}
          style={{ cursor: "pointer" }}
        >
          {data.name} {icon}
        </div>
      );
    else return <div>{data.name}</div>;
  };

  const searchOrder = (search) => {
    const btnOrder = (item) => {
      return (
        <div>
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              setAttr({
                ...attr,
                product_code: item.code,
                product_name: item.name,
              });
            }}
          >
            <AddIcon className={classes.icon} /> เลือก
          </Button>
        </div>
      );
    };

    PostData(mod, "searchproduct", {
      token: app.token,
      lang: app.lang,
      search: search,
      id: id,
    }).then((result) => {
      if (result.status === "OK") {
        setOrder([]);

        result.data.map((item, i) => {
          setOrder((order) => [
            ...order,
            [
              <img src={item.filepic} width="30" />,
              item.name,
              item.quantity,
              item.unit_name,
              btnOrder(item),
            ],
          ]);
        });
      }
    });
  };

  const renderViewList = () => {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12}>
            <Table
              striped
              hover
              tableHead={[
                renderHeader(header[0]),
                renderHeader(header[1]),
                renderHeader(header[2]),
                renderHeader(header[3]),
                renderHeader(header[4]),
                renderHeader(header[5]),
                renderHeader(header[6]),
                renderHeader(header[7]),
                renderHeader(header[8]),
                <div>
                  <Button
                    color="success"
                    className={classes.actionButton}
                    onClick={() => {
                      setAttr({ ...initAttr, stock_type: "ADD" });
                      setSearchList("");
                      setOrder([]);
                      setModal(true);
                      setTimeout(function () {
                        txtSearchList.current.select();
                      }, 500);
                    }}
                  >
                    เพิ่มปริมาณ
                    <AddIcon />
                  </Button>
                  <Button
                    color="danger"
                    className={classes.actionButton}
                    onClick={() => {
                      setAttr({ ...initAttr, stock_type: "REMOVE" });
                      setSearchList("");
                      setOrder([]);
                      setModal(true);
                      setTimeout(function () {
                        txtSearchList.current.select();
                      }, 500);
                    }}
                  >
                    <RemoveIcon />
                    ลดปริมาณ
                  </Button>
                </div>,
              ]}
              tableData={record}
              customHeadClassesForCells={alignValue}
              customHeadCellClasses={alignItem}
              customClassesForCells={alignValue}
              customCellClasses={alignItem}
              coloredColls={[5, 6, 7]}
              colorsColls={["primary", "danger", "success"]}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel className={classes.selectLabel}></InputLabel>
              <Select
                value={find.perPage}
                onChange={(e) => {
                  view({
                    ...find,
                    page: 1,
                    perPage: e.target.value,
                    page: 1,
                  });
                  setFind({
                    ...find,
                    page: 1,
                    perPage: e.target.value,
                    page: 1,
                  });
                }}
              >
                {cboPerPage &&
                  cboPerPage.map((item) => (
                    <MenuItem
                      key={item.id}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={item.id}
                    >
                      {t(item.name)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={6} md={8} lg={9} className={classes.right}>
            <Pagination
              style={{ float: "right", marginTop: "20px" }}
              count={pageTotal}
              showFirstButton
              showLastButton
              page={pageNo}
              onChange={(e, p) => {
                view({ ...find, page: p });
                setFind({ ...find, page: p });
              }}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  };

  const renderInfo = () => {
    return (
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={12} sm={8}>
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  labelText="ค้นหาสินค้า"
                  id="search_order"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    inputRef: txtSearchList,
                    onChange: (e) => {
                      setSearchList(e.target.value);
                      searchOrder(e.target.value);
                    },
                    value: searchList,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    autoComplete: "off",
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <Table
                  striped
                  hover
                  tableData={order}
                  customHeadClassesForCells={[2, 4]}
                  customHeadCellClasses={[classes.right, classes.right]}
                  customClassesForCells={[2, 4]}
                  customCellClasses={[classes.right, classes.right]}
                  coloredColls={[2]}
                  colorsColls={["primary"]}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  labelText="สินค้า"
                  id="product_name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: attr.product_name,
                    disabled: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText="ปริมาณ"
                  id="quantity"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.quantity,
                  }}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomInput
                  labelText="หมายเหตุ"
                  id="remark"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    onChange: inputChange,
                    value: attr.remark,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </form>
    );
  };

  const renderModal = () => {
    return (
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal,
        }}
        open={modal}
        maxWidth={"lg"}
        fullWidth
        keepMounted
        onClose={() => setModal(false)}
        aria-labelledby="modal-slide-title"
        aria-describedby="modal-slide-description"
      >
        <DialogTitle
          id="modal-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => setModal(false)}
          >
            <CloseIcon className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>
            {attr.stock_type === "ADD"
              ? "เพิ่ม Stock"
              : attr.stock_type === "REMOVE"
              ? "ลด Stock"
              : ""}
          </h4>
        </DialogTitle>
        <DialogContent id="modal-edit" className={classes.modalBody}>
          {renderInfo()}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={add} color="success">
            <SaveIcon /> {t(`element.save`)}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderLoading = () => {
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  /* ========== ZONE Render ========== */
  return (
    <div>
      {renderViewList()}
      {renderLoading()}
      {renderModal()}
      {alert}
      {notify}
    </div>
  );
}

export default withNamespaces()(PayoutStock);
